import { FeatureGroup } from 'src/app/models';
import { PUBLICATION_TYPE } from 'src/app/const';
import mockImage from 'src/assets/images/enova-laptop.png';
import { Publication } from 'src/app/models/publication';

export const publications: Publication[] = [];

export const products = publications
  .filter((publication) => publication.type === PUBLICATION_TYPE.SALE)
  .map((publication) => ({
    productId: 20, // TODO: me lo tiene que mandar Rodo
    name: publication.product,
    sku: 'xdd', // todo: creo q no hace falta
    price: publication.price,
    old_price: publication.old_price,
    images: [mockImage],
    description: 'cualquier cosa', // TODO: me lo va a mandar rodo
    priority: 'nose', // TODO: no hace falta tampoco
  }));

const FeatureGroupMock1: FeatureGroup[] = [];

const FeatureGroupMock2: FeatureGroup[] = [];

const FeatureGroupMock3: FeatureGroup[] = [];

const FeatureGroupMock4: FeatureGroup[] = [];

export const FeatureGroupMock = [FeatureGroupMock1, FeatureGroupMock2, FeatureGroupMock3, FeatureGroupMock4];
