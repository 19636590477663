import { useEffect } from 'react';
import { useFormContext, useTemplateContext } from '../../../../lib/templates';
import { summaryAddressTemplate } from '../templates/summaryAddress';
import { deliveryInitialValue } from '../initialValues';
import { APP_ECOMMERCE } from '../../../../config';
import { AnyObject } from '../../../../commons';

export const useBillingAddressLogic = () => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { form, values } = useFormContext<AnyObject>();
  const { setTemplate } = useTemplateContext();

  useEffect(() => {
    // not valid for detecnologia
    if (APP_ECOMMERCE === 'detecnologia') return;

    if (values.useDeliveryAddress) {
      // aqui quito el template adicional del summary card si existe
      setTemplate((template) => {
        if (!template.template.find((property) => property.name === summaryAddressTemplate.name)) {
          return template;
        }
        form.change(summaryAddressTemplate.name, undefined);
        return {
          ...template,
          template: template.template.filter((property) => property.name !== summaryAddressTemplate.name),
        };
      });
    } else {
      // aqui agrego el template adicional del summary card si no existe
      setTemplate((template) => {
        if (template.template.find((property) => property.name === summaryAddressTemplate.name)) {
          return template;
        }
        form.change(summaryAddressTemplate.name, deliveryInitialValue);
        return {
          ...template,
          template: [...template.template, summaryAddressTemplate],
        };
      });
    }
  }, [setTemplate, values, form]);
};
