import { EcommerceGeneral } from '../../../app/models/general/ecommerceGeneral';

export const general: EcommerceGeneral = {
  nameMp: 'Detecnologia',
  address: '',
  contactUrl: 'hola@detecnologia.com.ar',
  facebookUrl: 'https://www.facebook.com/Detecnologia-107987098354601',
  instagramUrl: 'https://www.instagram.com/detecnologia.com.ar/',
  title: 'detecnologia- Encontrá lo que buscás',
  description: 'Tecnología diseñada para acompañarte en cada momento de tu vida.',
};
