import React from 'react';
import { RecoveryView } from 'src/app/views';
import { StepFormRecord } from 'src/commons';
import { useRecoveryFormContext, useStepTemplateLogic } from '../hooks';

interface RecoveryFormProps {
  activeStep: number;
  steps: StepFormRecord[];
  completedSteps: Set<number>;
}

export const RecoveryForm = ({ activeStep, steps, completedSteps }: RecoveryFormProps) => {
  useStepTemplateLogic(activeStep, completedSteps, steps);
  const { values } = useRecoveryFormContext();

  return <RecoveryView steps={steps} activeStep={activeStep} email={values.email} />;
};
