import { routes } from './routes';
import { MenuItem } from '../../../../commons';

export const cyberMenu: MenuItem = {
  key: 'publicPromotions',
  basePath: '/cyber',
  enabled: true,
  icon: 'List',
  text: 'Cyber',
  children: routes,
};
