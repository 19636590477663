import { EcommerceDAO } from './EcommerceDAO';
import { Cart } from '../../../app/models';

export class LocationDAO extends EcommerceDAO<Cart> {
  constructor() {
    super('location', 'locationId');
  }

  async getStates(): Promise<void> {
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/location/state`);
    return response.json();
  }

  async getCityByState(idState: string, city: string): Promise<void> {
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/location/city/state/${idState}?city=${city}`);
    return response.json();
  }
}
