import { makeStyles } from '@material-ui/core';

export const approvedPayment = makeStyles((theme) => ({
  icon: {
    height: '100px',
    width: '100px',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
      width: '60px',
    },
    color: ({ color }: { color: string }) => color,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  lastLink: {
    color: '#3D2CFC!important',
  },
}));
