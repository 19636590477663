import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const aboutMenu: MenuItem = {
  key: 'privateAbout',
  basePath: '/nosotros',
  enabled: true,
  icon: 'List',
  text: 'Nosotros',
  children: routes,
  default: true,
};
