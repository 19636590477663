import { useCartContext } from 'src/context';
import { useConfigurationsContext } from 'src/context/Configuration';
import { optionsDelivery } from '../../../constants';

export const useEnablDeliveries = () => {
  const { configurations } = useConfigurationsContext();
  const { cartState } = useCartContext();
  // Reviso si hay lavarropas dentro de la compra ya que HOP no permite comprar bultos de ese tamaño.
  const isHeavy = cartState.items.find((item) => item.publication.shipment_category_id === 2);
  // Tomo de las configuraciones las correspondientes a tipos de envio y devuelvo las que estan activas.
  const deliveries = ['store_pickup', 'HOP'];
  const enables = configurations.filter((config) => deliveries.includes(config.name) && !config.value.enable).map((config) => config.name);
  // Luego filtro las que se van a mostrar con las que tome como enables.
  const filterByEnables = optionsDelivery.filter((option) => !enables.includes(option.id));
  const removingHop = isHeavy? filterByEnables.filter((delivery) => delivery.id !== 'HOP') : filterByEnables;
  const cleanedDeliveries = removingHop;
  // Esta validación es la que se va a encargar de esconder todo menos la opción de envio a domicilio en caso de estar todo apagado.
  const hideDeliveries = cleanedDeliveries.length === 1 && cleanedDeliveries[0].id === 'delivered';

  return { cleanedDeliveries, hideDeliveries };
};
