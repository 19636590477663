import React, { useCallback } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useScrollTrigger, Collapse, Typography, Box, PaperProps, ModalProps, BackdropProps, ClickAwayListener } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import { UserIcon } from 'src/assets/images/svgs';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import { ListItemCollapse } from './components';

const drawerWidth = 277;

interface SideMenuIcons {
  id: number;
  title: React.ReactNode;
  icon?: React.ReactNode;
  path?: string;
  subListItem?: Array<{ id: number; title: React.ReactNode; icon?: React.ReactNode; path?: string }>;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'hidden',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
  }),
);

export function HideOnScroll(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window });
  return (
    <Collapse appear={false} in={!trigger}>
      {children}
    </Collapse>
  );
}

export const sideMenuIcons: SideMenuIcons[] = [
  {
    id: 1,
    title: 'Perfil',
    icon: UserIcon,
    path: '/dashboard/profile',
  },
  {
    id: 3,
    title: 'Compras',
    icon: <LocalMallOutlinedIcon />,
    path: '/dashboard/purchases',
    subListItem: [
      {
        id: 1,
        title: 'Compras',
        path: '/dashboard/purchases',
      },
      {
        id: 2,
        title: 'Favoritos',
        path: '/dashboard/favorites',
      },
    ],
  },
  {
    id: 4,
    title: 'Configuración',
    icon: <BuildOutlinedIcon style={{ transform: 'rotateY(-180deg)' }} />,
    path: '/dashboard/configuration/security',
    subListItem: [
      {
        id: 1,
        title: 'Seguridad',
        path: '/dashboard/configuration/security',
      },
      /*     {
        id: 2,
        title: 'Notificaciones',
        path: '/dashboard/configuration/notifications',
      }, */
    ],
  },
];

interface SideMenuProps {
  modalsProps?: Partial<ModalProps>;
  paperProps?: Partial<PaperProps>;
  backdropProps?: Partial<BackdropProps>;
}

export function SideMenu({ modalsProps, paperProps, backdropProps }: SideMenuProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = useCallback(() => setOpen(!open), [open]);

  const handleOutsideClick = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  return (
    <Box width="100%" height="100%" id="drawer-container" component="div">
      <ClickAwayListener onClickAway={handleOutsideClick}>
        <Drawer
          className={[classes.drawer, open ? classes.drawerOpen : classes.drawerClose].join(' ')}
          variant="permanent"
          classes={{
            paper: open ? classes.drawerOpen : classes.drawerClose,
          }}
          ModalProps={modalsProps}
          BackdropProps={backdropProps}
          PaperProps={paperProps}
        >
          <Box ml={1.3} overflow="hidden">
            <List>
              <ListItem button key="VER MAS" onClick={handleDrawerToggle}>
                <ListItemIcon>
                  <MenuIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="h6" color="textSecondary">
                      Mi cuenta
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem />
              {sideMenuIcons.map((sideIcon) => (
                <Box component="div" key={`${sideIcon.id}-${sideIcon.title?.toString()}`}>
                  <ListItemCollapse listItem={sideIcon} isOpen={open} />
                </Box>
              ))}
            </List>
          </Box>
        </Drawer>
      </ClickAwayListener>
    </Box>
  );
}
