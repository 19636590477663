import React, { useMemo } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { DinamycMuiIcon } from 'src/assets/MuiIcons';
import { Loading } from 'src/commons';
import { SkeletonComponentProps, SOURCE } from 'src/app/models/Site';
import { ListBannerFooter } from 'src/commons/ui/view/BannerFooter';
import { BannerCarousel } from '../../../../commons/ui/view/BannerCarousel';
import { ConfigValues, ConfigValuesImages } from '../../../models';
import { PUBLICATION_TYPE } from '../../../const';

type fontSizeType = 'small' | 'large';

export const SkeletonBannerCarousel = ({ data }: SkeletonComponentProps) => {
  const { data: { publications } = { publications: [] }, working } = SOURCE.ZONE(data.source_id);
  const isMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const items = useMemo<ConfigValues[]>(
    () =>
      publications
        .filter((publication) => publication.type === PUBLICATION_TYPE.BANNER)
        .map<ConfigValues>((publication) => publication.config_values as ConfigValues),
    [publications],
  );

  const paymentImages = useMemo<ConfigValuesImages>(() => {
    const imgs = publications
      .filter((publication) => publication.type === PUBLICATION_TYPE.IMAGES)
      .map<ConfigValuesImages>((publication) => publication.config_values as ConfigValuesImages)[0] || { images: [] };
    return { ...imgs, images: [...imgs.images.filter((img) => img.enable)] };
  }, [publications]);

  const iconProps = useMemo(() => ({ htmlColor: 'rgba(0, 0, 0, 0.87)', fontSize: (isMdDown ? 'small' : 'large') as fontSizeType }), [isMdDown]);

  const dataFooter: ListBannerFooter[] = useMemo(
    () =>
      paymentImages.iconsUrl?.map(({ nameMuiIcon, url, title }) => ({
        icon: DinamycMuiIcon({ name: nameMuiIcon, props: iconProps }),
        title,
        url,
      })) || [],
    [iconProps, paymentImages.iconsUrl],
  );

  return working ? <Loading /> : items.length ? <BannerCarousel items={items} paymentImages={paymentImages} listItemFooter={dataFooter} transitionTime={data.transition_time}/> : <></>;
};
