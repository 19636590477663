import { useMemo } from 'react';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { IFieldComponent } from '../../FieldComponent';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { State, City } from '../../../../../../app/models';
import { useGenerateValues } from './useGenerateValues';
import { checkoutAllValues } from '../../../initialValues';
import { validateZipCode } from '../validators';

export const useFields = () => {
  const { form, values } = useCheckoutFormContext();
  const { cities, provinces, loading, search, loadingProvinces, onInputChangeCity } = useGenerateValues();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const selectedProvince = useMemo(() => valuesForm.deliveryInfo.province?.id !== '', [valuesForm.deliveryInfo.province?.id]);

  const data: IFieldComponent[] = useMemo(
    () => [
      {
        key: 'useDeliveryAddress',
        xs: 6,
        component: FORM_FIELD_TYPE.CHECKBOX,
        componentProps: {
          form,
          label: 'La dirección es la misma que la de envío',
          name: 'useDeliveryAddress',
        },
      },
      {
        xs: 6,
        component: FORM_FIELD_TYPE.EMPTY,
      },
      {
        key: 'alumnInfo.province',
        lg: 5,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.SELECT,
        componentProps: {
          form,
          loading: loadingProvinces,
          fullWidth: true,
          label: 'Provincia',
          name: 'alumnInfo.province',
          content: provinces,
          valueKey: 'id',
          labelKey: 'name',
          helperText: 'El campo Provincia es requerido',
          inputLabelProps: { shrink: true },
          optionToValue: (value: string, options: State[]) => options?.find((province) => province.id === value),
          valueToOption: (value: State, options: State[]) => options.find((option: State) => option.id === value.id)?.id || search,
        },
      },
      {
        key: 'alumnInfo.city',
        lg: 5,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.AUTOCOMPLETE_FETCH,
        componentProps: {
          disabled: !selectedProvince,
          name: 'alumnInfo.city',
          form,
          label: 'Ciudad',
          options: cities,
          optionLabelKey: 'name',
          optionToValue: (option: City) => option,
          valueToOption: (value: City, options: City[]) =>
            search?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && search?.length > 0 && options.length > 0
              ? options.find((option: City) => option.id === value.id)?.name
              : search,
          helperText: 'El campo Ciudad es requerido',
          getOptionSelected: (option: City, value: City) => option.id === value?.id,
          onInputChange: onInputChangeCity,
          inputLabelProps: { shrink: true },
          loading,
          enabledOpenAutocomplete: search.length > 1,
        },
      },
      {
        key: 'alumnInfo.zipCode',
        lg: 2,
        md: 6,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `alumnInfo.zipCode`,
          label: 'Cód. postal',
          required: true,
          id: 'alumnInfo.zipCode',
          autoComplete: 'postal-code',
          validator: validateZipCode,
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'alumnInfo.street',
        lg: 6,
        md: 5,
        xs: 8,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `alumnInfo.street`,
          label: 'Calle',
          required: true,
          id: 'alumnInfo.street',
          autoComplete: 'street-address',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'alumnInfo.streetNumber',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `alumnInfo.streetNumber`,
          label: 'Altura',
          lg: 2,
          md: 2,
          xs: 4,
          required: true,
          id: 'alumnInfo',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'alumnInfo.floor',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `alumnInfo.floor`,
          label: 'Piso',
          required: true,
          id: 'deliveryInfoFloor',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'alumnInfo.apartament',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `alumnInfo.apartament`,
          label: 'Dpto.',
          required: true,
          id: 'alumnInfo',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'alumnInfo.img_dni_front',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.UPLOAD_FILE,
        componentProps: {
          form,
          accept: 'image/png,image/jpeg,application/pdf,application/msword',
          name: `alumnInfo.img_dni_front`,
          label: 'DNI frente',
          id: 'Img_dni_front',
        },
      },
      {
        key: 'alumnInfo.img_dni_back',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.UPLOAD_FILE,
        componentProps: {
          form,
          accept: 'image/png,image/jpeg,application/pdf,application/msword',
          name: `alumnInfo.img_dni_back`,
          label: 'DNI atras',
          id: 'img_dni_back',
        },
      },
      {
        key: 'alumnInfo.img_certificate',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.UPLOAD_FILE,
        componentProps: {
          form,
          accept: 'image/png,image/jpeg,application/pdf,application/msword',
          name: `alumnInfo.img_certificate`,
          label: 'Certificado de estudio',
          id: 'img_certificate',
        },
      },
    ],
    [cities, form, loading, loadingProvinces, onInputChangeCity, provinces, search, selectedProvince],
  );
  return { fields: data.filter((field) => !field.hidden) };
};
