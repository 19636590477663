import { TemplateProperty } from '../../../../../lib/templates';

export const dniTemplate: TemplateProperty = {
  name: 'document',
  type: { name: 'string' },
  label: 'Documento',
  validators: [
    { name: 'pattern', signature: ['^[0-9]+$'] },
    { name: 'min', signature: [7] },
    { name: 'max', signature: [8] },
    { name: 'empty', errorMessage: 'Completa este dato.' },
  ],
};
