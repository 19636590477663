import React from 'react';
import { IconButton, Typography, Box } from '@material-ui/core';
import { AddShoppingCart } from 'src/assets/images/svgs';

interface CartMenuProps {
  count: number;
  onOpenMenu: () => void;
}

export const CartMenu = ({ count, onOpenMenu }: CartMenuProps) => (
  <IconButton onClick={onOpenMenu} style={{ padding: 0 }}>
    <AddShoppingCart color="black" opacity="0.87" />
    <Box position="absolute" mb="18px" ml="1px" mt="-10px">
      <Typography variant="body1" color="primary">
        {count}
      </Typography>
    </Box>
  </IconButton>
);
