import { useMemo, useState, useCallback } from 'react';
import { useEquifaxDAO } from 'src/app/business/IdentityValidationEquifaxAPI/EquifaxBusinessGenerated';
import { useFormContext } from 'src/lib/templates';
import {
  CompletedForm,
  EquifaxResponse,
  EquifaxUser,
  TransactionCompleted,
  Error,
} from 'src/screens/Common/Checkout/components/IdentityValidationForm/interfaces';
import { useCheckoutFormContext } from 'src/screens/Common/Checkout/hooks/CheckoutFormContext';
import { checkoutAllValues } from 'src/screens/Common/Checkout/initialValues';
import { AnyObject } from '../types';
import { MultiStepFormSteps } from '../../lib/MultiStepForm/types';

const initialError = {
  code: 777,
  description: 'Ocurrió un error inesperado.',
  message: 'Ocurrió un error inesperado..',
};

interface useIdentityValidationProps {
  allSteps?: MultiStepFormSteps[];
  setSteps?: React.Dispatch<React.SetStateAction<MultiStepFormSteps[]>>;
  questionary?: EquifaxResponse;
}

export const useIdentityValidation = ({ allSteps, setSteps, questionary }: useIdentityValidationProps) => {
  const equifaxDAO = useEquifaxDAO();
  const [questionaryResponse, setQuestionaryResponse] = useState<EquifaxResponse>();
  const [isWhiteList, setIsWhiteList] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>();
  const [working, setWorking] = useState<boolean>(false);
  const [fail, setFail] = useState<boolean>(false);
  const [bloqued, setBloqued] = useState<boolean>(false);
  const [error, setError] = useState<Error>(initialError);
  const [approved, setApproved] = useState<TransactionCompleted>();
  const { values } = useCheckoutFormContext();
  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);
  const { form } = useFormContext<AnyObject>();
  const documentNumber = valuesForm.personalInfo.document;
  const { gender } = valuesForm.personalInfo;

  const questionnaireResponse = valuesForm.equifaxForm.request.map((item) => ({ idOption: item.selectedOptionId, idQuestion: item.id }));

  const handleSubmitEquifax = useCallback(
    async (documentNumber, fullName, gender) => {
      await equifaxDAO.sendUserDataToGetQuestions(documentNumber, fullName, gender).then((response: any) => {
        if (response.questionnaire && !response.errors?.length) {
          setQuestionaryResponse(response);
          setIsSend(true);
        } else if (response.validationTypeDescription === 'WHITE LIST') {
          setIsWhiteList(true);

          if (allSteps && setSteps) {
            const newSteper = allSteps.map((step: any) => {
              if (step.title === 'Cuestionario de verificación') return { ...step, hidden: true };
              return step;
            });

            if (JSON.stringify(newSteper) !== JSON.stringify(allSteps)) {
              setSteps(newSteper);
            }
          }
        } else {
          if (response?.additionalErrorDetails?.errors?.length) setError(response.additionalErrorDetails.errors[0]);
          setBloqued(true);
          setIsSend(false);
        }
      });
    },
    [allSteps, equifaxDAO, setSteps],
  );

  const handleSubmitApproved = async (e: Event) => {
    e.preventDefault();

    const user: EquifaxUser = {
      name: valuesForm.personalInfo.name,
      lastName: valuesForm.personalInfo.lastName,
      document: documentNumber,
      gender,
    };

    const completedForm: CompletedForm = {
      idQuestionnaireGenerated: questionary?.questionnaire.id,
      idTransaction: questionary?.idTransaccion,
      questionnaireResponse,
    };
    setWorking(true);
    setSubmited(false);
    await equifaxDAO.sendForm(completedForm).then((res: TransactionCompleted) => {
      if ((res && res.name === 'Error') || res.payload.transactionStateDescription === 'TRANSACCION DESAPROBADA' || res.errors.length) {
        setFail(true);
        setApproved(res);
      } else {
        setFail(false);
        setApproved(res);
        form.change('equifaxForm.validClient', true);
        // i need to store this info of the user in case the person change it
        form.change('equifaxForm.userToValidate', user);
        // added other boolean to keep track of the original
        form.change('equifaxForm.isPerson', true);
      }
    });
    setSubmited(true);
    setWorking(false);
  };

  return { error, questionary: questionaryResponse, handleSubmitEquifax, approved, isSend, handleSubmitApproved, fail, bloqued, submited, working, isWhiteList };
};
