import { EcommerceDAO } from './EcommerceDAO';
import { tokenManager } from '../TokenManager';

export class ReloginDAO extends EcommerceDAO<any> {
  constructor() {
    super('relogin', 'relogin_id');
  }

  async reload() {
    const res = await this.fetchData('/relogin', { method: 'post' });
    tokenManager.persist(res.headers.get('X-JustRPC-Token') || '');
    return res.json();
  }
}
