import { routes } from './routes';
import { MenuItem } from '../../../../commons';

export const hotsaleMenu: MenuItem = {
  key: 'hotsalePromotion',
  basePath: '/hotsale',
  enabled: true,
  icon: 'List',
  text: 'Hotsale',
  children: routes,
};
