import { makeStyles } from '@material-ui/core';

export const genericFilterStyles = makeStyles((theme) => ({
  root: {},
  body: {
    fontStyle: 'normal',
    fontWeight: 450,
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '0.01em',
    color: '#3D2CFC',
  },
  listItem: {
    paddingLeft: 32,
  },
  listItemChild: {
    paddingLeft: 64,
    background: 'white',
  },
  accordion: {
    width: 'inherit',
    minHeight: 0,
    boxShadow: 'none',
    '& .MuiAccordionDetails-root': {
      paddingTop: '0px',
    },
    '& .MuiAccordion-root:before': {
      height: 0,
    },
    '& .Mui-expanded': {
      background: '#F5F5F5',
      margin: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 10,
    },
  },
  accordionSummaryItem: {
    height: 39,
    marginTop: 0,
    width: 265,
    paddingRight: 0,
  },
  mainAccordionDetails: { padding: '8px 0', display: 'flex', flexDirection: 'column' },
}));
