import React, { useMemo } from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { useMultiStepFormContext } from 'src/lib/MultiStepForm';
import { useCheckoutFormContext } from '../../hooks/CheckoutFormContext';
import { FieldComponent } from '../FieldComponent';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';
import { AnyObject, Loading, useIdentityValidation } from '../../../../../commons';
import { useFields } from './hooks/useFields';
import { ResponseModalEquifax } from './components/ResponseModalEquifax';
import { couponFormStyles as useStyles } from '../../../../../app/views/styles/couponForm';
import { useFormContext } from '../../../../../lib/templates';

export const EquifaxQuestionary = () => {
  const { errors, handleSubmit } = useCheckoutFormContext();
  const { useMakeDown } = useBreakpointsState();
  const {
    equifax: { error, questionary, isSend, bloqued },
  } = useMultiStepFormContext();
  const { approved, submited, fail, handleSubmitApproved, working } = useIdentityValidation({ questionary });
  const { fields } = useFields(questionary, approved, submited, fail, handleSubmitApproved, working);
  const { values, form } = useFormContext<AnyObject>();
  const classes = useStyles();
  const isDownSm = useMakeDown('sm');

  // IMPORTANT = This was the only way i could find to keep the checkout blocked in case of trying to cheat the equifax form with the useIsOtherPersonHook
  // playing with the validClient property in the template allows to move on the flow or get stuck
  if (values.equifaxForm?.validClient && !values.equifaxForm?.isPerson) {
    form.change('equifaxForm.validClient', false);
  } else if (!values.equifaxForm?.validClient && values.equifaxForm?.isPerson) {
    form.change('equifaxForm.validClient', true);
  }

  const isApproved = useMemo(
    () => values.equifaxForm?.validClient && values.equifaxForm?.isPerson,
    [values.equifaxForm?.validClient, values.equifaxForm?.isPerson],
  );

  return (
    <Box paddingBottom={3} bgcolor="white" borderRadius={8} boxShadow={3} display="content" flexDirection="column">
      {bloqued || !!isApproved || fail ? (
        <Grid container>
          <Box width="90%" display="flex" flexDirection="column" margin="auto">
            <ResponseModalEquifax bloqued={bloqued} approved={isApproved} failed={fail} error={error} />
            {isDownSm && isApproved && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  disabled={Object.keys(errors as any).length > 0}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Continuar
                </Button>
              </Grid>
            )}
          </Box>
        </Grid>
      ) : (
        <Box padding={isDownSm ? 2 : 3}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={isDownSm ? '1px solid' : undefined}>
                <Typography variant="h5" color="textPrimary">
                  Verificación de identidad
                </Typography>
              </Box>
            </Grid>

            {!isSend ? (
              <Loading />
            ) : isApproved ? (
              <ResponseModalEquifax bloqued={bloqued} approved={isApproved} failed={fail} />
            ) : (
              fields?.map((field) => <FieldComponent {...field} />)
            )}

            {isDownSm && (
              <Grid item xs={12}>
                <Typography className={classes.TypographyLight}>
                  {fail ? 'Identidad verificada.' : 'Confirmá tu identidad para continuar la operación.'}
                </Typography>
                <Button
                  fullWidth
                  disabled={Object.keys(errors as any).length > 0}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Continuar
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
};
