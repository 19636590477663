import React, { useState, useCallback, useMemo } from 'react';
import { orderBy } from 'lodash';
import { Box, Grid, Typography, Radio, FormControlLabel, Button } from '@material-ui/core';
import { BuyerAddressInfo } from '../../../../../../../app/models';
import { OptionsModal, OptionsModalOption, ModalDialog } from '../../../../../../../commons';
import { TransformFunction } from '../../../../types';
import { IDeliveryAddressInfo, deliveryInitialValue } from '../../../../initialValues';
import { AddressManager } from './AddressManager';

interface AddressFormProps {
  options: BuyerAddressInfo[];
  recall: () => Promise<void>;
}

interface AddressState {
  open: boolean;
  isNew: boolean;
  loading: boolean;
  data?: BuyerAddressInfo;
}

const initialAddressState: AddressState = { open: false, isNew: false, loading: false, data: undefined };
const initialAddress = { ...deliveryInitialValue, is_selected: false };
delete initialAddress.buyer_address_id;

export const transformer: TransformFunction<IDeliveryAddressInfo & { is_selected: boolean }, BuyerAddressInfo> = (value) => ({
  city: { ...value.city, id: value.city.id.toString() },
  province: { ...value.state, id: value.state.id.toString() },
  street: value.street,
  streetNumber: value.number.toString(),
  floor: value.floor,
  apartament: value.department,
  zipCode: value.zip,
  additionalInfo: value.comments,
  is_selected: value.is_selected,
});

export const AddressForm = ({ options, recall }: AddressFormProps) => {
  const [state, setState] = useState<AddressState>(initialAddressState);

  const handleNewState = useCallback((newValue: Partial<AddressState>) => {
    setState((old) => ({ ...old, ...newValue }));
  }, []);

  const optionsMenu = useMemo<OptionsModalOption<BuyerAddressInfo>[]>(
    () => [
      {
        label: 'Modificar datos del domicilio',
        onClick: (data) => handleNewState({ open: true, isNew: false, data }),
        hide: false,
        disable: false,
        id: 1,
      },
    ],
    [handleNewState],
  );

  return (
    <Box width="100%">
      {orderBy(options, 'is_selected', 'desc').map((address, index) => (
        <Box
          key={address.buyer_address_id}
          width="100%"
          paddingBottom={index + 1 !== options.length ? 3 : 0}
          mb={index + 1 !== options.length ? 3 : 0}
          borderBottom={index + 1 !== options.length ? '1px solid #DADBE4' : ''}
        >
          <Grid container direction="row" item xs={12}>
            <Grid item container alignItems="center" justify="flex-start" xs={11}>
              <FormControlLabel
                value={address.buyer_address_id}
                control={<Radio id={address.buyer_address_id} color="primary" />}
                label={
                  <Grid item container direction="column" spacing={1}>
                    <Grid item container direction="row" spacing={1} alignItems="center">
                      <Grid item>
                        <Typography variant="body1" color="textPrimary">
                          {`${address.street} ${address.number}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container direction="column">
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {`${address.state.name} / ${address.city.name} , ${address.zip}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {`Piso: ${address.floor} / Dpto: ${address.department}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {address.comments}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>

            <Grid item xs={1} container alignItems="center" justify="flex-end">
              <OptionsModal key={address.buyer_address_id} options={optionsMenu} data={address} color="default" />
            </Grid>
          </Grid>
        </Box>
      ))}

      <ModalDialog
        title={state.isNew ? 'Nuevo domicilio' : 'Editar domicilio'}
        open={state.open}
        onHandleClose={() => handleNewState(initialAddressState)}
      >
        <AddressManager
          initialValue={state.isNew ? initialAddress : state.data ? transformer(state.data) : initialAddress}
          raw={state.isNew ? undefined : state.data}
          recall={recall}
          onCancel={() => handleNewState(initialAddressState)}
        />
      </ModalDialog>
      <Box mt={2}>
        <Grid container justify="flex-end">
          <Button variant="text" color="primary" style={{ textTransform: 'none' }} onClick={() => handleNewState({ open: true, isNew: true })}>
            Nuevo domicilio
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};
