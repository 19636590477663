import { MenuItem } from '../../../../commons/ui/menu';
import { ComoComprar } from '../../../../screens/Public/comoComprar';

export const routes: MenuItem[] = [
  {
    key: 'publicComoComprarMain',
    basePath: '/',
    target: {
      component: ComoComprar,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Como comprar',
    children: [],
    default: true,
  },
];
