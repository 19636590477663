import React, { useEffect, useState } from 'react';
import { useHistory, Prompt, useLocation } from 'react-router-dom';
import { DialogTitle, DialogActions, Dialog, Button } from '@material-ui/core';

interface BlockRouteInterface {
  isBlocked: boolean;
  content: string;
}

export const BlockRoute = ({ isBlocked, content }: BlockRouteInterface) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState<boolean>(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const handleBlockedRoute = (nextLocation: any) => {
    if (!confirmedNavigation && isBlocked) {
      setIsModalOpen(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: any) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = content;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isBlocked, content, shouldUnload]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <Dialog maxWidth="md" open={isModalOpen} onClose={closeModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Seguro quieres salir de la compra ?</DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmNavigationClick} color="primary">
            Acepto
          </Button>
          <Button onClick={closeModal} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
