import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

interface ButtonConfirmCheckoutInterface {
  isDownSm: boolean;
  disabled: boolean;
  onClickStep: () => void;
}

const buttonConfirm = makeStyles(() => ({
  buttonStep: {
    marginTop: '16px',
    marginBottom: '16px',
  },
}));

export const ButtonConfirmCheckout = ({ isDownSm, disabled, onClickStep }: ButtonConfirmCheckoutInterface) => {
  const classes = buttonConfirm();
  return (
    <>
      {isDownSm && (
        <Grid item xs={12}>
          <Button className={classes.buttonStep} fullWidth disabled={disabled} size="large" variant="contained" color="primary" onClick={onClickStep}>
            Continuar
          </Button>
        </Grid>
      )}
    </>
  );
};
