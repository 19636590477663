import { TemplateProperty } from '../../../../lib/templates';

export const tokenCardTemplate: TemplateProperty = {
  name: 'tokenCardInfo',
  type: {
    name: 'object',
    kind: [
      { name: 'token', type: { name: 'string' } },
      { name: 'payment_method_id', type: { name: 'number' } },
      { name: 'bin', type: { name: 'string' } },
      { name: 'last_four_digits', type: { name: 'string' } },
      { name: 'expiration_month', type: { name: 'string' } },
      { name: 'expiration_year', type: { name: 'string' } },
      {
        name: 'card_holder',
        type: {
          name: 'object',
          kind: [
            {
              name: 'identification',
              type: {
                name: 'object',
                kind: [
                  { name: 'type', type: { name: 'string' } },
                  { name: 'number', type: { name: 'string' } },
                ],
              },
            },
            { name: 'name', type: { name: 'string' } },
            { name: 'nro_puerta', type: { name: 'number' }, optional: true },
            { name: 'birthday', type: { name: 'number' }, optional: true },
          ],
        },
      },
      { name: 'expired', type: { name: 'boolean' } },
    ],
  },
};
