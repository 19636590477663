import { makeStyles, withStyles, Tooltip } from '@material-ui/core';

export const CustomToolTip = withStyles((theme) => ({
  tooltip: {
    maxWidth: '450px',
    padding: '16px',
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    borderRadius: '4px',
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down(500)]: {
      width: '330px',
    },
  },
}))(Tooltip);

export const regretFoormStyle = makeStyles((theme) => ({
  containerBox: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    transition: 'all ease-in-out 1s',
  },
  containerLoading: {
    opacity: '0.7',
  },
  containerInputs: {
    [theme.breakpoints.down(500)]: {
      flexDirection: 'column',
      marginBottom: '0',
    },
    justifyContent: 'space-between',
    marginBottom: '25px',
  },
  generalInput: {
    width: '48%',
    [theme.breakpoints.down(500)]: {
      width: '100%',
      marginBottom: '25px',
    },
  },
  reasonInput: {
    width: '100%',
  },
  btnEnviar: {
    marginLeft: '10px',
  },
  loading: {
    position: 'absolute',
    top: '64%',
    left: '35%',
    [theme.breakpoints.down(769)]: {
      top: '86%',
      left: '48%',
    },
    [theme.breakpoints.down(500)]: {
      top: '100%',
      left: '48%',
    },
    [theme.breakpoints.down(411)]: {
      top: '125%',
      left: '48%',
    },
    [theme.breakpoints.down(376)]: {
      top: '110%',
      left: '48%',
    },
    [theme.breakpoints.down(361)]: {
      top: '120%',
      left: '48%',
    },
    [theme.breakpoints.down(321)]: {
      top: '150%',
      left: '48%',
    },
  },
  alert: {
    backgroundColor: theme.palette.primary.dark,
  },
}));
