/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, NavigateBeforeRounded, NavigateNextRounded } from '@material-ui/icons';
import { Box, IconButton, makeStyles, Theme, useMediaQuery } from '@material-ui/core';

type ScrollCarousel = {
  data: JSX.Element[];
};
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  containerItems: {
    overflowX: 'auto',
    display: '-webkit-box',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    minHeight: 410,
  },
}));

export const ScrollCarousel = ({ data }: ScrollCarousel) => {
  const classes = useStyles();
  const carouselRef = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLHeadingElement>(null);
  const carouselContainer = data.map((item) => (
    <Box key={item.key} pl="1px" pr={2} pt={2}>
      {item}
    </Box>
  ));
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [showArrows, setShowArrows] = useState(false);
  const [onHover, setOnHover] = useState(false);

  useEffect(() => {
    if (carouselRef.current !== null && containerRef.current !== null && carouselRef.current.scrollWidth > containerRef.current?.offsetWidth) {
      setShowArrows(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselRef, containerRef, setShowArrows, window.innerWidth]);

  return (
    <div ref={containerRef} className={classes.container} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
      {showArrows && !isDownSm && (
        <ArrowScrollCarousel
          direction="left"
          onClick={() => sideScroll({ element: carouselRef.current, direction: 'left' })}
          parentOnHover={onHover}
        />
      )}
      <div ref={carouselRef} className={classes.containerItems}>
        {carouselContainer}
      </div>
      {showArrows && !isDownSm && (
        <ArrowScrollCarousel
          direction="right"
          onClick={() => sideScroll({ element: carouselRef.current, direction: 'right' })}
          parentOnHover={onHover}
        />
      )}
    </div>
  );
};

type SideScroll = {
  element: any;
  direction: 'left' | 'right';
  speed?: number;
  distance?: number;
  step?: number;
};

const sideScroll = ({ element, direction, speed = 30, distance = 400, step = 150 }: SideScroll) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    if (direction === 'left') {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
};

interface ArrowProps {
  direction: 'left' | 'right' | 'up' | 'down';
  disabled?: boolean;
  onClick?: () => void;
  parentOnHover: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
}

const useStylesArrow = makeStyles((theme) => ({
  arrow: {
    display: 'none',
    boxShadow: theme.shadows[1],
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: theme.shadows[3],
    },
  },
  onHoverClass: {
    display: 'block',
  },
}));

export const ArrowScrollCarousel = ({ direction, disabled, onClick, parentOnHover, color = 'secondary' }: ArrowProps) => {
  const classes = useStylesArrow();

  let icon;
  switch (direction) {
    case 'left':
      icon = <NavigateBeforeRounded color={color} fontSize="large" />;
      break;
    case 'up':
      icon = <KeyboardArrowUpRounded color={color} fontSize="large" />;
      break;
    case 'right':
      icon = <NavigateNextRounded color={color} fontSize="large" />;
      break;
    case 'down':
      icon = <KeyboardArrowDownRounded color={color} fontSize="large" />;
      break;
    default:
      break;
  }

  return (
    <Box
      position={direction === 'left' || direction === 'right' ? 'absolute' : 'relative'}
      zIndex={5}
      right={direction === 'right' ? '-2%' : ''}
      left={direction === 'left' ? '-2%' : ''}
    >
      <IconButton
        disabled={disabled}
        onClick={onClick}
        classes={{ root: classes.arrow }}
        className={`${classes.arrow} ${parentOnHover && classes.onHoverClass}`}
      >
        {icon}
      </IconButton>
    </Box>
  );
};
