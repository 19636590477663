export const PROMOTION_SLUG: { [k: string]: number } = {
  'novatech-vueltaalcole': 1,
  'bancoprovincia-vueltaalcole': 2,
  'correocompras-vueltaalcole': 3,
};

export const PROMOTION_ID_SLUG: { [k: number]: string } = {
  1: 'novatech-vueltaalcole',
  2: 'bancoprovincia-vueltaalcole',
  3: 'correocompras-vueltaalcole',
};

export const PROMOTION_VUELTA_AL_COLE_TYPE: {
  NOVATECH: number;
  BANCO_PROVINCIA: number;
  CORREO_COMPRAS: number;
} = {
  NOVATECH: 1,
  BANCO_PROVINCIA: 2,
  CORREO_COMPRAS: 3,
};
