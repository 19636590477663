import React, { useCallback, useState } from 'react';
import {
  GridSize,
  Grid,
  Divider,
  IconButton,
  useMediaQuery,
  Theme,
  Typography,
  Box,
  Button,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  Fade,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import GridOnSharpIcon from '@material-ui/icons/GridOnSharp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cx from 'classnames';
import { SortOptions } from 'src/commons';
import { categoriesViewStyles as useStyles } from '../../../../styles';

interface ViewGridContentProps {
  xs?: GridSize;
  setViewGrid: (value: boolean) => void;
  sortOpenModal: boolean;
  setSortOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSortFilter: (value: SortOptions) => void;
  sortFilter: SortOptions;
  sortOptions: SortOptions[];
  viewGrid: boolean;
}

export const ViewGridContent = ({
  xs,
  setViewGrid,
  sortOpenModal,
  sortFilter,
  setSortOpenModal,
  sortOptions,
  setSortFilter,
  viewGrid,
}: ViewGridContentProps) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const isMdUp = useMediaQuery('(min-width:50em)');

  const handleClose = useCallback(
    (option: SortOptions) => {
      setAnchorEl(null);
      if (option.id) setSortFilter(option);
      setSortOpenModal(false);
    },
    [setSortFilter, setSortOpenModal],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
      setSortOpenModal(true);
    },
    [setSortOpenModal],
  );

  return (
    <Grid item xs={xs} container justify="flex-end" alignItems="center">
      <Grid item>
        <Box mr={2}>
          <Grid container alignItems="center">
            <Grid item>
              <Button
                className={classes.filterButton}
                endIcon={
                  <ExpandMoreIcon className={cx(classes.dropdown, sortOpenModal ? classes.dropdownOpen : classes.dropdownClosed)} color="primary" />
                }
                aria-controls={sortOpenModal ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                id="sort-button"
              >
                {!isMdUp ? (
                  <Typography variant="body2">Ordenar por</Typography>
                ) : (
                  <Typography variant="body2">
                    Ordenar por:
                    <Box display="inline" color="primary.main">
                      {` ${sortFilter?.name || ''}`}
                    </Box>
                  </Typography>
                )}
              </Button>
              <Menu
                elevation={1}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                id="fade-menu"
                anchorEl={anchorEl}
                TransitionComponent={Fade}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {sortOptions.map((option) => (
                  <MenuItem key={option.id} onClick={() => handleClose(option)} id={`${option.id}-sort`}>
                    <ListItemIcon>{sortFilter?.id === option.id && <CheckIcon fontSize="large" color="primary" />}</ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.onOptionHover} color={sortFilter?.id === option.id ? 'primary' : 'textPrimary'}>
                          {option.name}
                        </Typography>
                      }
                    />
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item>
        <Divider style={{ width: '1px', height: '27px' }} orientation="vertical" />
      </Grid>
      <Grid item>
        <IconButton className={classes[isDownSm ? 'buttonViewGridSmall' : 'buttonViewGridLarge']} color={viewGrid ? 'primary' : 'default'} onClick={() => setViewGrid(true)} id="grid-view">
          <GridOnSharpIcon className={classes[isDownSm ? 'iconViewGridSmall' : 'iconViewGridLarge']} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton className={classes[isDownSm ? 'buttonViewGridSmall' : 'buttonViewGridLarge']}color={viewGrid ? 'default' : 'primary'} onClick={() => setViewGrid(false)} id="list-view">
          <ViewListOutlinedIcon className={classes[isDownSm ? 'iconViewGridRowSmall' : 'iconViewGridRowLarge']} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
