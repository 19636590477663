import React, { useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Emitter } from '../../../models';
import { PaymentCard } from '../PaymentCard';

interface EmitterMapper {
  id: string;
  keys: string[];
}

type Label = keyof Emitter;

export interface EmittersViewProps {
  options: Emitter[];
  mapper: EmitterMapper[];
  labelToFilter: Label;
  labelKey: Label;
  valueKey: Label;
}

export const EmittersView = ({ options, mapper, labelKey, valueKey, labelToFilter }: EmittersViewProps) => {
  const optionsSorted = useMemo(() => options.sort((a, b) => a.card_emiter_id - b.card_emiter_id), [options]);
  return (
    <Grid container direction="column" spacing={2}>
      {mapper.map((row) => (
        <Grid item container direction="row" spacing={2}>
          {row.keys.map((key) => {
            const emiter = optionsSorted.find((emitter) => emitter[labelToFilter].toString() === key.toString());
            if (!emiter) return <></>;
            return (
              <Grid item>
                <Box display="flex" key={emiter.card_emiter_id}>
                  <PaymentCard value={emiter[valueKey].toString()} image={emiter[labelKey].toString()} />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      ))}
    </Grid>
  );
};
