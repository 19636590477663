import { useCallback } from 'react'
import { Analytics } from 'src/platform/analytics';
import { ConfigValues } from 'src/app/models';

export const useAnalyticsPromotion = () => {
  
  const callAnalytics = useCallback((item: ConfigValues)=>{
    if(item?.redirectTo?.includes('promotions')) Analytics.trackSelectPromotion(item)
  }, [])

  return callAnalytics;
}