import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { FileUpload } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'fileUpload';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'key',
      labelKey: 'message',
    },
    withSelectedChips: {
      idKey: 'key',
      labelKey: 'message',
    },
  },
};

const optionsToUse = !businessOptions.FileUpload
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.FileUpload,
      hoc: { ...defaultOptions.hoc, ...businessOptions.FileUpload.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<FileUpload>(DAO_NAME, optionsToUse);

// hooks
export const useFileUploadFetchById = fetchById;
export const useFileUploadLibbyCall = libbyCall;
export const useFileUploadDAO = libbyDAO;
export const useFileUploadLibbyFetch = libbyFetch;

// components/hoc
export const FileUploadAutocomplete = entityAutocomplete;
export const FileUploadCall = renderPropCall;
export const FileUploadById = renderPropFetchById;
export const FileUploadSelect = simpleSelect;
export const FileUploadAccordionSelector = accordionSelector;
export const FileUploadSelectedChips = selectedChips;

// context
// ByIdContext
export const useFileUploadByIdContext = useByIdContext;
export const FileUploadByIdProvider = ByIdProvider;
export const FileUploadByIdContext = ByIdContext;
