import React from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@material-ui/core';
import { CARD_EMITER } from '../../../../../../app/models';
import { ICheckoutAll } from '../../../initialValues';

export const TokenCardDecidir = ({ valuesForm }: { valuesForm: ICheckoutAll }) => (
  <>
    {valuesForm.tokenCardInfo.token !== '' ? (
      <Box key={valuesForm.tokenCardInfo.token} width="100%" paddingBottom={0} mb={0}>
        <Grid container direction="row" item xs={12}>
          <Grid item container alignItems="center" justify="flex-start" xs={12}>
            <Grid item container direction="column" spacing={1}>
              <Grid item container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Typography variant="body1" color="textPrimary">
                    {`${CARD_EMITER[valuesForm.tokenCardInfo.payment_method_id].type} - Terminada en ${valuesForm.tokenCardInfo.last_four_digits}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column">
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {`Tarjeta ${CARD_EMITER[valuesForm.tokenCardInfo.payment_method_id].emitter}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {`Vencimiento: ${moment(
                      `01/${valuesForm.tokenCardInfo.expiration_month}/${valuesForm.tokenCardInfo.expiration_year}`,
                      'DD/MM/YY',
                    ).format('MM/YYYY')}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <></>
    )}{' '}
  </>
);
