import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardActionArea, CardMedia, Grid, Typography, Divider, Backdrop } from '@material-ui/core';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';
import { Product } from 'src/app/models/business/Product';
import { useInfoPublicationPayment } from 'src/commons/hooks/useInfoPublicationPayment';
import { Loading } from 'src/commons';
import { usePublication } from 'src/app/business';
import { Analytics } from 'src/platform/analytics';
import { productCardStyles as useStyles } from '../../styles';
import { getDiscountPublication, useOnScreen, validateFreeShipping } from '../../../../utils';
import { ProductCardContent } from './ProductCardContent';
import { HighLightMultiple } from '../HighLightPromo/HightLigthMultiple';

export interface ProductCardProps {
  listName: string | undefined;
  data: Product;
  enableStock: boolean;
  loading?: boolean;
  isFavorite: boolean;
  onAddFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tracking: boolean | undefined;
}

export const ProductCard = ({
  listName,
  data,
  enableStock = true,
  loading,
  isFavorite,
  onAddFavorite,
  onRemoveFavorite,
  tracking,
}: ProductCardProps) => {
  const classes = useStyles();
  const [onHover, setOnHover] = useState(false);
  const { mainAmount, mainPrice, alterPrice } = useInfoPublicationPayment(data);

  const discount = getDiscountPublication(mainPrice[mainAmount].toString(), parseInt(data.old_price, 10));
  const onMousesOver: React.MouseEventHandler<HTMLDivElement> = () => {
    setOnHover(true);
  };

  const onMousesOut: React.MouseEventHandler<HTMLDivElement> = () => {
    setOnHover(false);
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useOnScreen(ref, { rootMargin: `-${408 / 2}px -${262 / 2}px` });
  const isVisible = entry?.isIntersecting;
  const [isTracked, setIsTracked] = useState(false);
  const { data: publication, working } = usePublication(data.productId.toString());

  useEffect(() => {
    if (!isTracked && isVisible && !working && tracking && publication && listName) {
      setIsTracked(true);
      Analytics.trackViewItemList({ publication: publication[0], listName });
    }
  }, [isTracked, isVisible, publication, working, tracking, listName]);


  return (
    <Card
      ref={ref}
      className={classes.container}
      onMouseOver={onMousesOver}
      onMouseOut={onMousesOut}
      id={data.productId.toString()}
    >
      <CardActionArea>
        <Grid className={classes.containerHover}>
          <CardMedia component="img" image={data.images[0]} title={data.name} height={230} />
          <Backdrop className={classes.backdrop} open={onHover}>
            <Box m={1}>
              <Grid container justify="center">
                <Typography component="div" align="center" className={classes.titleText} variant="body2" color="primary">
                  <Box component="p" color="white">
                    {data.name}
                  </Box>
                </Typography>
              </Grid>
            </Box>
          </Backdrop>
          <HighLightMultiple highlights={data.highlights || []} productId={data.productId} />
        </Grid>

        <Grid container direction="row" justify="space-between" alignItems="center" style={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <Box mb={0.5} display="flex" width="100%">
            <Grid item xs>
              <Box minHeight="24px" pt={1}>
                <Divider variant="fullWidth" />
              </Box>
            </Grid>

            {!loading && enableStock && validateFreeShipping(mainPrice[mainAmount].toString()) && data.shipment_category_id !== 2 && (
              <Grid xs item container direction="row" wrap="nowrap" alignContent="center" justify="flex-end">
                <Grid item>
                  <Box mr={1} ml={1}>
                    <LocalShippingOutlined color="primary" fontSize="small" />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mr={1}>
                    <Typography variant="body2" color="primary" noWrap>
                      Envío Gratis
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <ProductCardContent
            data={data}
            discount={discount}
            mainAmount={mainAmount}
            mainPrice={mainPrice}
            alterPrice={alterPrice}
            isFavorite={isFavorite}
            onAddFavorite={onAddFavorite}
            onRemoveFavorite={onRemoveFavorite}
            enableStock={enableStock}
          />
        )}
      </CardActionArea>
    </Card>
  );
};
