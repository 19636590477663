import React from 'react';
import { Grid, Box, Tooltip } from '@material-ui/core';
import theme from 'src/theme';

interface ProductVariantCardImgProps {
  image: string;
  border?: boolean;
  width?: string;
  widthImg?: string;
  nameId: string;
  mobile?: boolean;
  onClick: ()=> void;
  isSelected: boolean;
}

export const ProductVariantCardImg = ({ image, width = '60px', widthImg = '50px', mobile = true, onClick, isSelected, nameId }: ProductVariantCardImgProps) => (
  <Box
    position="relative"
    width={width}
    height="45px"
    borderRadius={2}
    justifyContent="center"
    alignContent="center"
    border="1px solid"
    borderColor={isSelected ? '#010101' : "other.stroke"}
    display="flex"
    color="text.primary"
    bgcolor={theme.palette.common.white}
    margin={mobile ? '3px' : '0px'}
    style={{cursor: !isSelected ? 'pointer' : 'default'}}
    onClick={onClick}
  >
    <Tooltip title={nameId}>
    <Box width={widthImg} justifyContent="center" alignContent="center" height="45px" display="grid">
      <Grid component="img" src={image} />
    </Box>
    </Tooltip>
  </Box>
);