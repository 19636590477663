import React from 'react';
import { Grid, useMediaQuery, Theme, Box, Button, Typography, Card } from '@material-ui/core';
import { useScrollToTopOnMount } from 'src/commons/hooks/useScrollToTopUnMount';
import { CommonFormInput, FormInputData } from 'src/app/views';
import { useHistory } from 'react-router';
import { FormDatePicker } from '../../../../../../../../lib/templates/components';
import { FormUserInfo } from '../../../types';
import { useUserInfoFormContext } from '../../../hooks';

const formInput: FormInputData[] = [
  {
    variant: 'standard',
    name: 'name',
    label: 'Nombre',
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
    required: true,
    id: 'Name',
    autoComplete: 'name',
  },
  {
    variant: 'standard',
    name: 'lastName',
    label: 'Apellido',
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
    required: true,
    id: 'lastName',
    autoComplete: 'family-name',
  },
  {
    variant: 'standard',
    name: 'document',
    label: 'DNI',
    lg: 6,
    md: 6,
    sm: 6,
    xs: 12,
    required: true,
    id: 'personalInfoDocument',
    autoComplete: 'on',
  },
  {
    variant: 'standard',
    name: 'phone.areaCode',
    label: 'Cód. de Área',
    lg: 2,
    md: 2,
    sm: 2,
    xs: 4,
    required: true,
    id: 'phoneAreaCode',
    autoComplete: 'tel-area-code',
  },
  {
    variant: 'standard',
    name: 'phone.number',
    label: 'Teléfono',
    lg: 4,
    md: 4,
    sm: 4,
    xs: 8,
    required: true,
    id: 'phoneNumber',
    autoComplete: 'tel-local',
  },
];

export const FormContent = () => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();
  const { form, values, errors, handleSubmit, initialValues } = useUserInfoFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item lg={8} md={8} xs={12}>
        {!isDownSm && (
          <Box my={1} mt={5}>
            <Typography variant="h6" color="textPrimary">
              Modificar datos personales
            </Typography>
          </Box>
        )}

        <Box justifyItems="center" component={Card} borderRadius={8} boxShadow={3} display="content">
          <Box padding={isDownSm ? 2 : 3}>
            <Grid container direction="row" spacing={3}>
              {formInput.map((input) => (
                <CommonFormInput<FormUserInfo>
                  key={input.name}
                  form={form}
                  name={input.name}
                  label={input.label}
                  variant={input.variant}
                  lg={input.lg}
                  md={input.md}
                  sm={input.sm}
                  xs={input.xs}
                  id={input.id}
                  autoComplete={input.autoComplete}
                />
              ))}

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormDatePicker
                  form={form}
                  fullWidth
                  disableFuture
                  openTo="year"
                  name="dateOfBirty"
                  views={['year', 'month', 'date']}
                  format="DD/MM/YYYY"
                  label={<Box color="text.disabled">Fecha de nacimiento</Box>}
                  inputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} />

              <Grid item container xs={12} justify="flex-end">
                <Box mr={2}>
                  <Button style={{ textTransform: 'none' }} size="large" variant="text" color="primary" onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                </Box>

                <Grid item>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit()}
                    disabled={Object.keys(errors as any).length > 0 || JSON.stringify(values) === JSON.stringify(initialValues)}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
