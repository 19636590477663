import { getPricePublication } from 'src/context/CartContext/utils';
import {
  AddToCartParams,
  PurchaseEventParams,
  StartCheckoutEventParams,
  VisitPublicationEventParams,
  ViewItemListEventParams,
  SelectPromotionEventParams,
} from '../interfaces';
import { searchSkuPublication } from '../../../utils';

export const googleAddToCart = (params: AddToCartParams) => ({
  value: Number(getPricePublication(params?.publication) || 0) * (params.quantity || 1),
  currency: 'ARS',
  items: [
    {
      id: searchSkuPublication({ featuresPublication: params.publication.attributes.features || [] }),
      name: params.publication.product,
      quantity: params.quantity,
      price: Number(getPricePublication(params?.publication) || 0) * (params.quantity || 1),
      category: params.publication.attributes.features?.find((item) => item.name === 'Categories')?.features[0].name,
      brand: params.publication.attributes.features
        ?.find((item) => item.name === 'Características Generales')
        ?.features?.find((item) => item.name === 'Marca')?.value,
    },
  ],
});

export const googlePurchase = (params: PurchaseEventParams) => ({
  transaction_id: params.transactionId,
  value: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1) * Number(getPricePublication(item.publication) || 0), 0),
  currency: 'ARS',
  items: params.cart.items.map((item) => ({
    id: searchSkuPublication({ featuresPublication: item.publication.attributes.features || [] }),
    name: item.publication.product,
    quantity: item.quantity,
    price: Number(getPricePublication(item.publication) || 0) * (item.quantity || 1),
    category: item.publication.attributes.features?.find((item) => item.name === 'Categories')?.features[0].name,
    brand: item.publication.attributes.features
      ?.find((item) => item.name === 'Características Generales')
      ?.features?.find((item) => item.name === 'Marca')?.value,
  })),
});

export const googleStartCheckout = (params: StartCheckoutEventParams) => ({
  currency: 'ARS',
  value: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1) * Number(getPricePublication(item.publication) || 0), 0),
  items: params.cart.items.map((item) => ({
    id: searchSkuPublication({ featuresPublication: item.publication.attributes.features || [] }),
    name: item.publication.product,
    quantity: item.quantity,
    price: Number(getPricePublication(item.publication) || 0) * (item.quantity || 1),
    category: item.publication.attributes.features?.find((item) => item.name === 'Categories')?.features[0].name,
    brand: item.publication.attributes.features
      ?.find((item) => item.name === 'Características Generales')
      ?.features?.find((item) => item.name === 'Marca')?.value,
  })),
  shipping_tier: params.deliveryType,
  payment_type: params.paymentMethod?.card.name,
});

export const googleViewContent = (params: VisitPublicationEventParams) => ({
  items: [
    {
      id: searchSkuPublication({ featuresPublication: params.publication.attributes.features || [] }),
      name: params.publication.product,
      quantity: 1,
      price: getPricePublication(params.publication),
      category: params.publication.attributes.features?.find((item) => item.name === 'Categories')?.features[0].name,
      brand: params.publication.attributes.features
        ?.find((item) => item.name === 'Características Generales')
        ?.features?.find((item) => item.name === 'Marca')?.value,
    },
  ],
});

export const googleViewItemList = (params: ViewItemListEventParams) => ({
  items: [
    {
      id: searchSkuPublication({ featuresPublication: params.publication.attributes.features || [] }),
      name: params.publication.product,
      quantity: 1,
      price: getPricePublication(params.publication),
      category: params.publication.attributes.features?.find((item) => item.name === 'Categories')?.features[0].name,
      brand: params.publication.attributes.features
        ?.find((item) => item.name === 'Características Generales')
        ?.features?.find((item) => item.name === 'Marca')?.value,
      list_name: params.listName,
    },
  ],
});

export const googleSelectPromotion = (params: SelectPromotionEventParams) => ({
  promotions: [
    {
      promotion_id: params.redirectTo?.includes('promotions') ? params.redirectTo?.split('/')[params.redirectTo?.split('/').length - 1] : '',
      promotion_name: params.imagen?.normal ? params.imagen?.normal?.split('/')[params.imagen.normal.split('/').length - 1] : '',
      creative_slot: 'Main Banner',
      location_id: 'top',
    },
  ],
});
