import { useMemo, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { orderBy } from 'lodash';
import { City, State } from '../../../../../../app/models';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { useLocationLibbyCall, useLocationDAO } from '../../../../../../app/business';
import { useDebounce } from '../../../../../../commons';
import { checkoutAllValues, deliveryInitialValue } from '../../../initialValues';

interface IGenerateValue {
  cities: City[];
  search: string;
  provinces: State[];
  loading: boolean;
  loadingProvinces: boolean;
  onInputChangeCity: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const useGenerateDecidirValues = (): IGenerateValue => {
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { form, values } = useCheckoutFormContext();

  const valuesAll = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const [search, setSearch] = useState(valuesAll.summaryAddress.city?.name || '');
  const searchDebunce = useDebounce(search, 600);

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  const locationDAO = useLocationDAO();
  const { data = [], working } = useLocationLibbyCall<State[]>({ methodName: 'getStates' });
  const filteredData = data.filter((province) => province.name !== 'default');
  const selectedProvince = useMemo(() => valuesAll.summaryAddress.province?.id !== '', [valuesAll.summaryAddress.province?.id]);

  useEffect(() => {
    if (
      (valuesAll?.summaryAddress?.city?.name?.length > 0 && valuesAll?.summaryAddress?.city?.name !== search) ||
      valuesAll?.summaryAddress?.city === null
    ) {
      const copyValuesForm = { ...values, summaryAddress: valuesAll.summaryAddress };
      copyValuesForm.summaryAddress.city = deliveryInitialValue.city;
      form.reset(copyValuesForm);
    }
  }, [search, values, form, valuesAll]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== valuesAll.summaryAddress.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(valuesAll.summaryAddress.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, valuesAll.summaryAddress.province, searchDebunce, valuesAll.summaryAddress.city]);

  return { cities, search, provinces: orderBy(filteredData, 'name'), loading, loadingProvinces: working, onInputChangeCity };
};
