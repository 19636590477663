import { useMemo } from 'react';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { IFieldComponent } from '../../FieldComponent';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { State, City } from '../../../../../../app/models';
import { useGenerateValues } from './useGenerateValues';
import { validateZipCode } from '../../../validators';
import { checkoutAllValues } from '../../../initialValues';
import { DocentType, optionsDocentType } from '../../../constants';

export const useFields = () => {
  const { form, values } = useCheckoutFormContext();
  const { cities, provinces, loading, search, loadingProvinces, onInputChangeCity } = useGenerateValues();

  const valuesForm = useMemo(
    () => ({
      ...checkoutAllValues,
      ...values,
    }),
    [values],
  );

  const selectedProvince = useMemo(() => valuesForm.applicantInfo.province?.id !== '', [valuesForm.applicantInfo.province?.id]);

  const data: IFieldComponent[] = [
    {
      key: 'useDeliveryAddress',
      xs: 6,
      component: FORM_FIELD_TYPE.CHECKBOX,
      componentProps: {
        form,
        label: 'La dirección es la misma que la de envío',
        name: 'useDeliveryAddress',
      },
    },
    {
      key: 'applicantInfo.docentType',
      lg: 5,
      md: 6,
      sm: 6,
      xs: 12,
      component: FORM_FIELD_TYPE.SELECT,
      componentProps: {
        form,
        loading: loadingProvinces,
        fullWidth: true,
        label: 'Provincia',
        name: 'applicantInfo.docentType',
        content: optionsDocentType,
        valueKey: 'id',
        labelKey: 'name',
        helperText: 'El valor ingresado no es valido',
        inputLabelProps: { shrink: true },
        optionToValue: (value: string, options: DocentType[]) => options?.find((item) => item.id.toString() === value.toString()),
        valueToOption: (value: DocentType, options: DocentType[]) => options.find((option) => option.id === value.id)?.id.toString(),
      },
    },
    {
      key: 'applicantInfo.province',
      lg: 5,
      md: 6,
      sm: 6,
      xs: 12,
      component: FORM_FIELD_TYPE.SELECT,
      componentProps: {
        form,
        loading: loadingProvinces,
        fullWidth: true,
        label: 'Provincia',
        name: 'applicantInfo.province',
        content: provinces,
        valueKey: 'id',
        labelKey: 'name',
        helperText: 'El campo Provincia es requerido',
        inputLabelProps: { shrink: true },
        optionToValue: (value: string, options: State[]) => options?.find((province) => province.id === value),
        valueToOption: (value: State, options: State[]) => options.find((option: State) => option.id === value.id)?.id || search,
      },
    },
    {
      key: 'applicantInfo.city',
      lg: 5,
      md: 6,
      sm: 6,
      xs: 12,
      component: FORM_FIELD_TYPE.AUTOCOMPLETE_FETCH,
      componentProps: {
        disabled: !selectedProvince,
        name: 'applicantInfo.city',
        form,
        label: 'Ciudad',
        options: cities,
        optionLabelKey: 'name',
        optionToValue: (option: City) => option,
        valueToOption: (value: City, options: City[]) =>
          search?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && search?.length > 0 && options.length > 0
            ? options.find((option: City) => option.id === value.id)?.name
            : search,
        helperText: 'El campo Ciudad es requerido',
        getOptionSelected: (option: City, value: City) => option.id === value?.id,
        onInputChange: onInputChangeCity,
        inputLabelProps: { shrink: true },
        loading,
        enabledOpenAutocomplete: search.length > 1,
      },
    },
    {
      key: 'applicantInfo.zipCode',
      lg: 2,
      md: 6,
      xs: 4,
      component: FORM_FIELD_TYPE.INPUT,
      componentProps: {
        variant: 'standard',
        name: `applicantInfo.zipCode`,
        label: 'Cód. postal',
        required: true,
        id: 'applicantInfo.zipCode',
        autoComplete: 'postal-code',
        validator: validateZipCode,
        form,
      },
    },
    {
      key: 'applicantInfo.street',
      lg: 6,
      md: 5,
      xs: 8,
      component: FORM_FIELD_TYPE.INPUT,
      componentProps: {
        variant: 'standard',
        name: `applicantInfo.street`,
        label: 'Calle',
        required: true,
        id: 'applicantInfo.street',
        autoComplete: 'street-address',
        form,
      },
    },
    {
      key: 'applicantInfo.streetNumber',
      lg: 2,
      md: 2,
      xs: 4,
      component: FORM_FIELD_TYPE.INPUT,
      componentProps: {
        form,
        variant: 'standard',
        name: `applicantInfo.streetNumber`,
        label: 'Altura',
        lg: 2,
        md: 2,
        xs: 4,
        required: true,
        id: 'applicantInfo',
      },
    },
    {
      key: 'applicantInfo.floor',
      lg: 2,
      md: 2,
      xs: 4,
      component: FORM_FIELD_TYPE.INPUT,
      componentProps: {
        form,
        variant: 'standard',
        name: `applicantInfo.floor`,
        label: 'Piso',
        required: true,
        id: 'applicantInfoFloor',
      },
    },
    {
      key: 'applicantInfo.apartament',
      lg: 2,
      md: 2,
      xs: 4,
      component: FORM_FIELD_TYPE.INPUT,
      componentProps: {
        form,
        variant: 'standard',
        name: `applicantInfo.apartament`,
        label: 'Dpto.',
        required: true,
        id: 'applicantInfo',
      },
    },
    {
      key: 'applicantInfo.additionalInfo',
      xs: 6,
      component: FORM_FIELD_TYPE.INPUT,
      componentProps: {
        form,
        variant: 'standard',
        name: `applicantInfo.additionalInfo`,
        label: 'Información adicional',
        id: 'applicantInfo',
      },
    },
    {
      key: 'applicantInfo.img_dni_front',
      lg: 6,
      md: 6,
      sm: 6,
      xs: 12,
      component: FORM_FIELD_TYPE.UPLOAD_FILE,
      componentProps: {
        form,
        accept: 'image/png,image/jpeg,application/pdf,application/msword',
        name: `applicantInfo.img_dni_front`,
        label: 'DNI frente',
        id: 'Img_dni_front',
      },
    },
    {
      key: 'applicantInfo.img_dni_back',
      lg: 6,
      md: 6,
      sm: 6,
      xs: 12,
      component: FORM_FIELD_TYPE.UPLOAD_FILE,
      componentProps: {
        form,
        accept: 'image/png,image/jpeg,application/pdf,application/msword',
        name: `applicantInfo.img_dni_back`,
        label: 'DNI atras',
        id: 'img_dni_back',
      },
    },
    {
      key: 'applicantInfo.img_paycheck',
      lg: 6,
      md: 6,
      sm: 6,
      xs: 12,
      component: FORM_FIELD_TYPE.UPLOAD_FILE,
      componentProps: {
        form,
        accept: 'image/png,image/jpeg,application/pdf,application/msword',
        name: `applicantInfo.img_paycheck`,
        label: 'Recibo de sueldo',
        id: 'img_paycheck',
      },
    },
    {
      key: 'applicantInfo.img_service_certificate',
      lg: 6,
      md: 6,
      sm: 6,
      xs: 12,
      component: FORM_FIELD_TYPE.UPLOAD_FILE,
      componentProps: {
        form,
        accept: 'image/png,image/jpeg,application/pdf,application/msword',
        name: `applicantInfo.img_service_certificate`,
        label: 'Servicio',
        id: 'img_service_certificate',
      },
    },
    {
      key: 'applicantInfo.img_certificate',
      lg: 6,
      md: 6,
      sm: 6,
      xs: 12,
      component: FORM_FIELD_TYPE.UPLOAD_FILE,
      componentProps: {
        form,
        accept: 'image/png,image/jpeg,application/pdf,application/msword',
        name: `applicantInfo.img_service_certificate`,
        label: 'Certificado de trabajo',
        id: 'img_certificate',
      },
    },
  ];
  return { fields: data.filter((field) => field.hidden) };
};
