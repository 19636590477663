import { TemplateProperty } from '../../../../../lib/templates';

export const cbuTemplate: TemplateProperty = {
  name: 'cbu',
  type: { name: 'string' },
  validators: [
    { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Completa solo con números.' },
    { name: 'length', signature: [22], errorMessage: 'CBU invalido.' },
    { name: 'empty', errorMessage: 'Completa este dato.' },
  ],
  label: 'CBU',
};
