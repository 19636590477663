import { TemplateProperty } from '../../../../lib/templates';

export const paymentMethodTemplate: TemplateProperty = {
  name: 'paymentMethod',
  type: {
    name: 'object',
    kind: [
      {
        name: 'emitter',
        type: {
          name: 'object',
          kind: [
            { name: 'card_emiter_id', type: { name: 'number' } },
            { name: 'name', type: { name: 'string' } },
            { name: 'image_url', type: { name: 'string' } },
          ],
        },
      },
      {
        name: 'card',
        type: {
          name: 'object',
          kind: [
            { name: 'card_id', type: { name: 'number' } },
            { name: 'name', type: { name: 'string' } },
            { name: 'emiter', type: { name: 'number' } },
            { name: 'type', type: { name: 'string' } },
            { name: 'card_image_url', type: { name: 'string' } },
            { name: 'emiter_image_url', type: { name: 'string' } },
            { name: 'match', type: { name: 'array', kind: 'string' } },
          ],
        },
      },
      {
        name: 'payment',
        type: {
          name: 'object',
          kind: [
            { name: 'reference', type: { name: 'string' }, optional: true },
            { name: 'type', type: { name: 'string' } },
            { name: 'installments', type: { name: 'number' } },
            { name: 'original_amount', type: { name: 'number' } },
            { name: 'amount', type: { name: 'number' } },
            { name: 'metadata', type: { name: 'any' }, optional: true },
          ],
        },
      },
    ],
  },
};
