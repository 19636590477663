import { EcommerceDAO } from './EcommerceDAO';
import { Cart, CartItem } from '../../../app/models';

export class CartDAO extends EcommerceDAO<Cart> {
  constructor() {
    super('cart', 'cartId');
  }

  async getByOwner(owner: string): Promise<Cart[]> {
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/cart/${owner}`);
    return response.json();
  }

  async updateCart(cart: Cart): Promise<void> {
    const { items, owner, state_id, email, send } = cart;
    const itemsFix: CartItem[] = items.map(({ quantity, publication }) => ({
      quantity,
      publication,
    }));
    await this.fetchData(`/cart`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ owner, items: itemsFix, state_id, email, send }),
    });
  }
}
