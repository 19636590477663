import { useEffect, useMemo, useState } from 'react';
import { MultiStepFormSteps } from '../../../../lib/MultiStepForm/types';
import {
  deliveryInfoTemplate,
  paymentMethodTemplate,
  loginTemplate,
  applicantInfoTemplate,
  makePersonalTemplate,
  guarantorInfoTemplate,
  identityValidationTemplate,
  makeCardInfoTemplate,
} from '../templates';
import {
  paymentInitialValue,
  loginInitialValue,
  cardInfoInitialValue,
  applicantInitialValue,
  guarantorInitialValue,
  deliveryInitialValue,
  personalInfoInitialValue,
  equifaxInitialValue,
} from '../initialValues';
import { LoginForm } from '../components/Login';
import { DeliveryInfoForm } from '../components/DeliveryInfoForm';
import { PersonalInfoForm } from '../components/PersonalInfoForm';
import { PaymentMethodInfoForm } from '../components/PaymentMethodInfoForm';
import { GuarantorInfoForm } from '../components/GuarantorInfoForm';
import { ApplicantInfoForm } from '../components/ApplicantInfoForm';
import { CardInfoForm } from '../components/CardInfoForm';
import { LOGIN_ENABLED } from '../../../../config';
import { useRolesContext } from '../../../../context';
import { Buyer, BuyerData } from '../../../../app/models';
import { useBuyerDAO } from '../../../../app/business';
import { EquifaxQuestionary } from '../components/IdentityValidationForm';
import { transformerDeliveryInfo, transformerPersonalInfo } from '../utils/transformers';

export const useStepsCheckout = ({ isCapresca }: { isCapresca: boolean }) => {
  const [working, setWorking] = useState(false);
  const [delivery, setDelivery] = useState(deliveryInitialValue);
  const [personal, setPersonal] = useState(personalInfoInitialValue);
  const { userInfo } = useRolesContext();
  const buyerDAO = useBuyerDAO();

  useEffect(() => {
    if (!userInfo.id) return;
    setWorking(true);
    (async () => {
      const info: BuyerData = await buyerDAO.getBuyerInfo();
      if (info) {
        const { addresses, ...rest } = info;
        const buyerInfo: Buyer = rest;
        const newInfo = transformerPersonalInfo(buyerInfo);
        const isSelected = addresses?.filter((address) => address.is_selected);
        if (isSelected[0]) {
          const newInfo = transformerDeliveryInfo(isSelected[0]);
          setDelivery(newInfo);
        }
        setPersonal(newInfo);
      }
      setWorking(false);
    })();
  }, [userInfo.id, buyerDAO]);

  const steps = useMemo<MultiStepFormSteps[]>(() => {
    const makeSteps: MultiStepFormSteps[] = [
      {
        id: 0,
        title: 'Ingresa a tu cuenta',
        subTitle: '',
        hidden: !LOGIN_ENABLED || !!userInfo.id,
        preStep: true,
        template: loginTemplate,
        initialValue: loginInitialValue,
        render: LoginForm,
      },
      {
        id: 1,
        title: 'Datos del comprador',
        subTitle: '',
        template: makePersonalTemplate({ isCapresca }),
        initialValue: personal,
        render: PersonalInfoForm,
      },
      {
        // EQUIFAX: Si el comprador supera cierto montó se le presentara un questionario
        // de tipo multiple-choice para validar su identidad
        id: 2,
        title: 'Cuestionario de verificación',
        subTitle: '',
        template: identityValidationTemplate,
        initialValue: equifaxInitialValue,
        render: EquifaxQuestionary,
      },
      {
        id: 3,
        title: 'Información de envío',
        subTitle: '',
        template: deliveryInfoTemplate,
        initialValue: delivery,
        render: DeliveryInfoForm,
      },
      {
        id: 4,
        title: 'Medio de pago',
        subTitle: '',
        template: paymentMethodTemplate,
        initialValue: paymentInitialValue,
        render: PaymentMethodInfoForm,
      },
      {
        id: 5,
        title: 'Datos del solicitante',
        subTitle: '',
        hidden: true,
        template: applicantInfoTemplate,
        initialValue: applicantInitialValue,
        render: ApplicantInfoForm,
      },
      {
        // GARANTE aparece si es docente y el typo es distinto de titular y cuando es alumno y es capresca
        id: 6,
        title: 'Datos del garante',
        subTitle: '',
        hidden: true,
        template: guarantorInfoTemplate,
        initialValue: guarantorInitialValue,
        render: GuarantorInfoForm,
      },
      {
        id: 7,
        title: 'Finaliza tu compra',
        subTitle: '',
        hidden: false,
        template: makeCardInfoTemplate({ isCapresca }),
        initialValue: cardInfoInitialValue,
        render: CardInfoForm,
      },
    ];
    return makeSteps;
  }, [userInfo.id, isCapresca, personal, delivery]);

  return { steps, working };
};
