import { Coupon } from 'src/app';
import { EcommerceDAO } from './EcommerceDAO';

export class CouponDAO extends EcommerceDAO<Coupon> {
  constructor() {
    super('coupon', 'couponId');
  }

  async sendToValidate(name: string, skus: string[], card_id: number): Promise<void> {
    const response = await this.fetchData(`/coupon/name`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ name, skus, card_id }),
    });
    return response.json();
  }
}
