import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { State } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'location';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idLocation',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idLocation',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Location
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Location,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Location.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<State>(DAO_NAME, optionsToUse);

// hooks
export const useLocationFetchById = fetchById;
export const useLocationLibbyCall = libbyCall;
export const useLocationDAO = libbyDAO;
export const useLocationLibbyFetch = libbyFetch;

// components/hoc
export const LocationAutocomplete = entityAutocomplete;
export const LocationCall = renderPropCall;
export const LocationById = renderPropFetchById;
export const LocationSelect = simpleSelect;
export const LocationAccordionSelector = accordionSelector;
export const LocationSelectedChips = selectedChips;

// context
// ByIdContext
export const useLocationByIdContext = useByIdContext;
export const LocationByIdProvider = ByIdProvider;
export const LocationByIdContext = ByIdContext;
// GeneralContext
// export const useLocationContext = useGeneralContext;
// export const LocationProvider = GeneralProvider;
// export const LocationContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
