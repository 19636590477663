import React, { useCallback } from 'react';
import { TextField, Box, InputLabelProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'react-final-form-hooks';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';

type OptionLabel = (option: any) => JSX.Element;
type TransformValue = (value: any) => any;

// FIXME: type form props and autoComplete
export interface FormAutocompleteProps {
  name: string;
  label: React.ReactNode;
  form: any;
  multiple?: boolean;
  autoCompleteProps?: any;
  options: any[];
  optionLabelKey: string | OptionLabel;
  transformValue?: TransformValue;
  getOptionSelected?: any;
  valueToOption?: (value: any, options: any) => any;
  optionToValue?: (option: any) => any;
  placeholder?: string;
  positionLabel?: 'inner' | 'outer';
  variant?: 'standard' | 'outlined' | 'filled';
  inputLabelProps?: Partial<InputLabelProps>;
  loading?: boolean;
  loadingText?: React.ReactNode;
  freeSolo?: boolean;
}

export const FormAutocomplete = ({
  name,
  form,
  label,
  options,
  multiple = false,
  optionLabelKey,
  autoCompleteProps,
  getOptionSelected,
  valueToOption,
  optionToValue,
  placeholder,
  positionLabel = 'inner',
  variant,
  inputLabelProps,
  loading = false,
  loadingText,
  freeSolo = false,
}: FormAutocompleteProps): JSX.Element => {
  const field = useField(name, form);
  const getOptionLabel = useCallback(
    (option: any) => {
      if (typeof optionLabelKey === 'string') {
        return option[optionLabelKey] || '';
      }
      return optionLabelKey(option);
    },
    [optionLabelKey],
  );
  return (
    <div>
      {positionLabel === 'outer' && <Box mb="8px">{label}</Box>}
      <Autocomplete
        fullWidth
        freeSolo={freeSolo}
        loadingText={loadingText}
        loading={loading}
        InputLabelProps={inputLabelProps}
        //  classes={{ root: classes.root }}
        multiple={multiple}
        options={options}
        onChange={(event, newValue: any) => {
          if (Array.isArray(newValue)) {
            field.input.onChange(optionToValue ? newValue.map(optionToValue) : newValue);
          } else {
            field.input.onChange(optionToValue ? optionToValue(newValue) : newValue);
          }
        }}
        value={field.input.value ? (valueToOption ? valueToOption(field.input.value, options) : field.input.value) : multiple ? [] : null}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={inputLabelProps}
            label={positionLabel === 'inner' && label}
            variant={variant}
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            placeholder={placeholder}
            {...getErrorAndHelperTextNoTranslation(field)}
          />
        )}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        {...(autoCompleteProps || {})}
      />
    </div>
  );
};
