import React, { useCallback } from 'react';
import { useFormContext, FormInput, FormContextProvider } from 'src/lib/templates';
import { Box, Card, Grid, Button, useMediaQuery, Theme, Container, Typography } from '@material-ui/core';
import { useAccountDAO, useReloginDAO, useConfirmationDAO } from 'src/app/business';
import { useHistory } from 'react-router';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Joi from 'joi';
import { useSnackbar } from 'notistack';
import { AppBreadcrumbsApp } from 'src/app/views';
import { useBreadcrumbs } from 'src/commons';
import { checkSession, SessionChangeEmitter } from 'src/platform/libby/session';
import { FieldValidator } from 'final-form';

interface EditEmailValue {
  email: string;
  re_email: string;
}
const initialValues: EditEmailValue = { email: '', re_email: '' };

export const EditEmail = () => {
  const history = useHistory();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isBw768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));
  const accountDAO = useAccountDAO();
  const reloginDAO = useReloginDAO();
  const confirmationDAO = useConfirmationDAO();
  const { enqueueSnackbar } = useSnackbar();

  const links = useBreadcrumbs({
    links: isDownSm
      ? [
          { label: 'Seguridad', url: '/dashboard' },
          { label: 'Cambiar correo electrónico', url: '/dashboard/configuration/security' },
          { label: 'Mi cuenta', url: '/dashboard/configuration/security' },
        ]
      : [
          { label: 'Mi cuenta', url: '/dashboard' },
          { label: 'Securidad', url: '/dashboard/configuration/security' },
          { label: 'Cambiar correo electrónico', url: '/dashboard/configuration/security/email' },
        ],
  });

  const validateEmail: FieldValidator<string> = useCallback(
    async (value: string) => {
      try {
        const { isValid } = await confirmationDAO.verifyEmail(value);
        if (!isValid) return 'Este email ya ah sido usado.';
      } catch (error) {
        console.log(error);
      }
    },
    [confirmationDAO],
  );

  const Form = () => {
    const { form, errors, handleSubmit } = useFormContext<EditEmailValue>();
    return (
      <Box component={Card} width="100%" borderRadius={8} boxShadow={3} display="content" position="relative">
        <Box padding={isDownSm ? 2 : 3} width="100%">
          <Grid container direction="row" spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormInput
                variant="standard"
                inputLabelProps={{
                  shrink: true,
                }}
                label={
                  <Box color="text.disabled" minWidth="104px">
                    Ingresá tu nuevo correo
                  </Box>
                }
                type="email"
                name="email"
                form={form}
                size="small"
                id="email-1"
                autoComplete="email"
                validator={validateEmail}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormInput
                variant="standard"
                inputLabelProps={{
                  shrink: true,
                }}
                label={
                  <Box color="text.disabled" minWidth="104px">
                    Repite tu correo
                  </Box>
                }
                type="email"
                name="re_email"
                form={form}
                size="small"
                id="re_email-1"
                autoComplete="email"
              />
            </Grid>

            <Grid item xs={12} />

            <Grid item container xs={12} justify="flex-end">
              <Box mr={2}>
                <Button style={{ textTransform: 'none' }} size="large" variant="text" color="primary" onClick={() => history.goBack()}>
                  Cancelar
                </Button>
              </Box>

              <Grid item>
                <Button
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={Object.keys(errors as any).length > 0}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const onSubmit = useCallback(
    async (values: EditEmailValue) => {
      try {
        await accountDAO.updateAccess({ newEmail: values.email, newPassword: '12345678', email: values.email });
        await reloginDAO.reload();
        const { isLogged, user } = await checkSession();
        SessionChangeEmitter.emitChange({ isLogged, user });
        enqueueSnackbar('Modificaste tu correo electrónico', { variant: 'success' });
        history.push('/dashboard/configuration/security');
      } catch (error) {
        enqueueSnackbar('No se pudo modificar el correo.', { variant: 'error' });
      }
    },
    [accountDAO, reloginDAO, history, enqueueSnackbar],
  );

  return (
    <Container maxWidth="lg">
      <Box px={isDownSm ? 0 : '10%'} mt={isDownSm ? 1 : 3}>
        {isDownSm && (
          <AppBreadcrumbsApp
            links={links}
            iconSeparator={<Box component="div">/</Box>}
            iconGoBack={<ChevronLeftIcon color="primary" />}
            invert
            msgReturn=" "
            noMargin
          />
        )}
        <Grid container item spacing={isDownSm ? 0 : 2} xs={12} sm={isBw768 ? 8 : 12} md={8}>
          {!isDownSm && (
            <Grid item>
              <Typography variant="h5" color="textPrimary">
                Cambiar correo electrónico
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} />

          <Grid item container lg={7} md={7} sm={isBw768 ? 7 : 12} xs={12}>
            <FormContextProvider
              initialValues={initialValues}
              onSubmit={onSubmit}
              template={{
                name: 'Update email',
                template: [
                  {
                    name: 'email',
                    type: { name: 'string' },
                    validators: [
                      { name: 'email', signature: [{ tlds: { allow: false } }] },
                      { name: 'empty', errorMessage: 'Completa este dato.' },
                    ],
                  },
                  {
                    name: 're_email',
                    type: { name: 'string' },
                    validators: [
                      { name: 'email', signature: [{ tlds: { allow: false } }] },
                      { name: 'empty', errorMessage: 'Completa este dato.' },
                      { name: 'valid', signature: [Joi.ref('email')] },
                    ],
                    options: { messages: { 'any.only': 'El email no coincide.' } },
                  },
                ],
              }}
            >
              <Form />
            </FormContextProvider>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
