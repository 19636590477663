/* eslint-disable @typescript-eslint/ban-ts-comment */
import Joi, { AnySchema, ObjectSchema, SchemaMap } from 'joi';
import { TemplateProperty, TemplatePropertyType, TemplatePropertyValidator } from './types';
import { customValidatorFunctions } from './customValidatorFunctions';

export type CustomValidator = {
  property: TemplateProperty;
  validator: TemplatePropertyValidator;
};

export const getJoiPropertyType = async (type: TemplatePropertyType) => {
  // Types
  if (type.name === 'string') {
    return Joi.string();
  }
  if (type.name === 'boolean') {
    return Joi.boolean();
  }
  if (type.name === 'date') {
    // TODO: Hay chequear bien los valores porque puede haber problemas con el formato de las fechas ya que el Joi transforma las fechas
    return Joi.date();
  }
  if (type.name === 'number') {
    return Joi.number();
  }
  if (type.name === 'array') {
    let value = Joi.array();
    if (type.kind) {
      if (typeof type.kind === 'string') {
        value = value.items(await getJoiPropertyType({ name: type.kind }));
      } else if (!Array.isArray(type.kind)) {
        value = value.items(await getJoiPropertyType(type.kind));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        value = value.items(await getJoiSchema(type.kind));
      }
    }
    return value;
  }
  if (type.name === 'object') {
    if (typeof type.kind === 'string' || !Array.isArray(type.kind)) {
      throw new Error('an object property type must have a TemplateProperty[] in kind property');
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return getJoiSchema(type.kind);
  }
  // TODO: define default value type
  return Joi.any();
};

export const getJoiSchema = async (template: TemplateProperty[]): Promise<ObjectSchema> => {
  const customValidators: CustomValidator[] = [];
  const joiSchema: SchemaMap = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const property of template) {
    // eslint-disable-next-line no-await-in-loop
    let joiParam: AnySchema = await getJoiPropertyType(property.type);
    // Validators
    if (property.validators?.length) {
      property.validators.forEach((validator) => {
        // this is for regular joi methods
        // @ts-ignore
        if (joiParam[validator.name]) {
          const normalizedParams = validator.signature || [];
          if (validator.name === 'pattern' && normalizedParams?.[0]) {
            const regex = new RegExp(normalizedParams[0]);
            // @ts-ignore
            joiParam = joiParam[validator.name](regex);
          } else {
            // @ts-ignore
            joiParam = joiParam[validator.name](...normalizedParams);
          }
        } else {
          customValidators.push({ property, validator });
          if (customValidatorFunctions[validator.name]) {
            joiParam = joiParam.custom(customValidatorFunctions[validator.name], validator.name);
          }
        }
      });
    }
    // handling string empty optional
    if (property.type.name === 'string' && property.optional) {
      joiParam = joiParam.allow('');
    }
    // Optional
    if (property.optional) {
      joiParam = joiParam.optional();
    } else {
      joiParam = joiParam.required();
    }
    // Options
    if (property.options) {
      joiParam = joiParam.options(property.options);
    }
    // Label property if exists
    joiParam = joiParam.label(property.label || property.name);
    // TODO: finish custom validators
    joiSchema[property.name] = joiParam;
  }
  return Joi.object(joiSchema);
};
