import React, { useMemo, useState } from 'react';
import { MenuCollection, MenuDivider, MenuItem, Metadata } from '../../commons/ui/menu';
import { Context } from './RouterContext';
import { MenuRoutes } from './MenuRoutes';

const isMenuItem = (menu: MenuItem | MenuDivider): menu is MenuItem => (menu as MenuDivider).divider === undefined;

export const createRouter = (config: MenuCollection) => {
  const menuItems: MenuItem[] = config.filter(isMenuItem);
  const RoutesWrapper = React.memo(() => <MenuRoutes config={menuItems} />);

  const RouterProvider = React.memo(({ children }: { children: React.ReactNode }) => {
    const [metadata, setMetadata] = useState<Metadata>({});
    const value = useMemo(
      () => ({
        config: menuItems,
        metadata,
        setMetadata,
      }),
      [metadata],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  });

  return {
    Routes: RoutesWrapper,
    RouterProvider,
  };
};
