import React, { useCallback } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Icons } from '../../icon';
import { MenuItem } from '../MenuItem';

export type MenuGroupItemProps = {
  menuItem: MenuItem;
  open: boolean;
  onClick: (menu: MenuItem) => void;
};

// TODO: hacer que puedas mandarle un icono custom
export const MenuGroupItem = ({ menuItem, open, onClick }: MenuGroupItemProps) => {
  const onButtonClick = useCallback(() => onClick(menuItem), [menuItem, onClick]);
  return (
    <ListItem button onClick={onButtonClick}>
      <Icons.IconByName name={menuItem.icon} />
      <ListItemText primary={menuItem.text} />
      {menuItem.children.length ? open ? <ExpandLess /> : <ExpandMore /> : null}
    </ListItem>
  );
};
