import React from 'react';
import { Grid, Typography, Divider, Link, Box } from '@material-ui/core';
import { orderBy } from 'lodash';
import { IMAGE_BASE_URL } from 'src/config';
import { ConfigValuesImages } from 'src/app/models';

type linkType = {
  name: string;
  url: string;
};

interface PromotionsLinkProps {
  title: string;
  children?: JSX.Element | JSX.Element[];
  link: linkType;
  onClick?: () => void;
}

const PromotionsLink = ({ title, children, link, onClick = () => {} }: PromotionsLinkProps) => (
  <Grid item xs={12}>
    {children}
    <Box p={2}>
      <Typography variant="h6">{title}</Typography>
      <Link href={link.url} component="button" variant="body2" onClick={onClick}>
        {link.name}
      </Link>
    </Box>
    <Divider />
  </Grid>
);

interface ProductDetailsPromotionsProps {
  targets?: Array<string>;
  paymentImages: ConfigValuesImages;
  onClickGuarantees: () => void;
  onClickPolitics: () => void;
}
export const ProductDetailsPromotions = ({ paymentImages, onClickGuarantees, onClickPolitics }: ProductDetailsPromotionsProps) => (
  <Grid container xs={12}>
    <Grid item xs={12}>
      <Box mt={2}>
        <Grid container justify="space-around" alignItems="center">
          {orderBy(paymentImages.images, 'relevance', 'asc').map((image) => (
            <Grid component="img" height="30px" src={`${IMAGE_BASE_URL}/${image.url}`} />
          ))}
        </Grid>
      </Box>
    </Grid>
    <PromotionsLink
      title="Política de devoluciones"
      link={{
        name: 'Ver política de devoluciones',
        url: '/',
      }}
      onClick={onClickPolitics}
    />
    <PromotionsLink
      title="Garantía"
      link={{
        name: 'Más información sobre la garantía ',
        url: '/',
      }}
      onClick={onClickGuarantees}
    />
  </Grid>
);
