import React from 'react';
import { Typography, Box } from '@material-ui/core';

interface TermsAndConditionsLabelProps {
  onOpenModals: () => void;
}

export const TermsAndConditionsLabel = ({ onOpenModals }: TermsAndConditionsLabelProps) => (
  <Typography variant="body2" color="inherit">
    <span>Acepto los </span>
    <Box color="primary.main" display="inline" onClick={onOpenModals}>
      términos y condiciones
    </Box>
    <span>. Autorizo el uso de mis datos y recibir mails.</span>
  </Typography>
);
