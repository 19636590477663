import React from 'react';
import { IconButton, Grid, TextField, InputAdornment, useTheme } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import { quantityButtonStyles as useStyles } from '../styles/quantityButtonStyles';

interface QuantityButtonProps {
  onAdd: () => void;
  onRemove: () => void;
  quantity: number;
  stock?: number;
  enableStock: boolean;
  productId: string | number;
}

export const QuantityButton = ({ quantity, stock, onAdd, onRemove, enableStock, productId }: QuantityButtonProps) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid container justify="center">
      <TextField
        variant="outlined"
        size="small"
        value={quantity}
        disabled={!enableStock}
        InputProps={{
          classes,
          readOnly: true,
          style: { color: theme.palette.primary.main },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton disabled={quantity === 1 || !enableStock} size="small" aria-label="menus" onClick={onRemove} id={`${productId}-remove`}>
                <RemoveOutlinedIcon className={classes.icon} color="primary" />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={quantity >= (stock || 1) || !enableStock} size="small" aria-label="more" onClick={onAdd} id={`${productId}-add`}>
                <AddOutlinedIcon className={classes.icon} color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};
