import { useState, useMemo } from 'react';
import { useCouponDAO } from 'src/app/business/Payment/CouponBusinessGenerated';
import { useCheckoutFormContext } from 'src/screens/Common/Checkout/hooks/CheckoutFormContext';
import { useCartContext } from 'src/context';
import { checkoutAllValues } from 'src/screens/Common/Checkout/initialValues';

interface CouponInfo {
  discount: number;
  checked: boolean;
  result: string[];
  amountCoupon: number;
}

export const useCouponSubmit = () => {
  const [coupon, setCoupon] = useState('');
  const [error, setError] = useState({
    err: false,
    msg: '',
  });
  const [infoCoupon, setInfoCoupon] = useState<CouponInfo>();
  const couponDao = useCouponDAO();

  const { form, values } = useCheckoutFormContext();
  const { cartState } = useCartContext();
  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const selectedPaymentMethod = Number(valuesForm.paymentMethod.card.card_id);

  const SKUS = cartState.items.map(
    (item) =>
      item.publication.attributes.features
        ?.find((feature) => feature.name === 'Características Generales')
        ?.features.find((_item) => _item.name === 'Modelo')?.value,
  );

  const handleInputChange = (e: any) => {
    setCoupon(e.target.value);
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();

    if (coupon.length) {
      couponDao.sendToValidate(String(coupon), SKUS, selectedPaymentMethod).then((response: CouponInfo) => {
        if (response)
          setError({
            ...error,
            err: response.checked,
            msg: response.checked ? 'El cupón fue agregado correctamente!.' : 'El código del cupón es incorrecto.',
          });
        setInfoCoupon(response);

        const sku = cartState.items.find(
          (publication) =>
            publication.publication.attributes.features
              ?.find((feature) => feature.name === 'Características Generales')
              ?.features.find((_item) => _item.name === 'Modelo')?.value === response.result[1],
        );
        const skuDiscount = sku?.publication.price[0].price;

        let couponAmount: number;

        switch (response.result[0]) {
          case 'total$':
            couponAmount = response.discount;
            break;
          case 'sku%':
            couponAmount = (response.discount * +skuDiscount!) / 100;
            break;
          case 'total%':
            couponAmount = (cartState.totals.totalPrice * response.discount) / 100;
            break;
          default:
            break;
        }

        const dataCoupon = {
          name: coupon,
          discount: response.discount,
          checked: response.checked,
          response: response.result,
          amountCoupon: !response.checked ? 0 : Math.round(couponAmount!),
        };
        form.change('coupon', dataCoupon);
        setCoupon('');
      });
    } else {
      setError({
        ...error,
        err: true,
        msg: 'El código del cupón es incorrecto.',
      });
    }
  };

  return { handleInputChange, handleSubmit, coupon, error, infoCoupon };
};
