import { makeStyles } from '@material-ui/core';

export const quantityButtonStyles = makeStyles(() => ({
  root: {
    width: 80,
    height: 31,
  },
  icon: {
    height: 10,
    width: 10,
  },
  input: { textAlign: 'center' },
  adornedEnd: {
    paddingLeft: 4,
  },
  adornedStart: {
    paddingRight: 4,
  },
}));
