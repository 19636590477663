import React from 'react';
import { Grid, Card, useMediaQuery, useTheme, Box, Divider } from '@material-ui/core';
import { Publication as PublicationModels} from 'src/app/models';
import { VerticalCarousel } from 'src/commons/ui/view/Banner';
import { imageCarouselType } from 'src/commons';
import {CatalogDescriptionView, CatalogEspecification} from './components';
import {CatalogInfoPanel} from "../CatalogInfoPanel";

interface ProductDetailsViewProps {
  product: PublicationModels;
  imagesCarousel: imageCarouselType[];
}

export const CatalogDetailsView = ({
  product,
  imagesCarousel,
}: ProductDetailsViewProps) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));


  const carouselImagesArray = imagesCarousel.map((image) => ({
    introline: image.alt,
    isImage: true,
    content: { image: image.img_url, copy: image.alt },
  }));

  return (
    <Grid container direction="row" xs={12}>
      <Grid item xs={12}>
        <Card>
          <Box p={3}>
            <Grid container justify="center" xs={12} direction="row" spacing={1}>
              <Grid item >
                <Grid item>
                  <CatalogInfoPanel
                      data={product}
                  />
                  <Divider style={{margin:10}}/>
                </Grid>

              </Grid>
              <Grid item direction="row" >
                <Box pr={isSm ? 0 : 1}>
                    <Box pb={4}>
                      <Grid item xs={12}>
                        <VerticalCarousel leadingText="" data={carouselImagesArray} />
                      </Grid>
                    </Box>
                  <Grid item>
                    <Box mb={1}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xl={12}>
                    <Grid container direction="column">
                      <CatalogDescriptionView data={product} />
                      <CatalogEspecification data={product} />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};
