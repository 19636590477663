import React, { useCallback, useState } from 'react';
import { useConfirmationDAO } from 'src/app/business';
import { CheckCodeRegisterFormView } from 'src/app/views';
import { VerifyCode } from 'src/app/models';
import { useSnackbar } from 'notistack';
import { FieldValidator } from 'final-form';
import { useRegisterFormContext } from '../../hooks';

export const CheckCodeRegisterForm = () => {
  const { form, errors, values, handleSubmit } = useRegisterFormContext();
  const confirmationDAO = useConfirmationDAO();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const sendVerificationCode = useCallback(async () => {
    try {
      await confirmationDAO.sendCode({ email: values.accountInfo.email });
    } catch (error) {
      console.log('Error al enviar el codigo');
    }
  }, [confirmationDAO, values.accountInfo.email]);

  const validateCheckCode: FieldValidator<string> = useCallback(
    async (value: string, allValues: any, meta) => {
      let result: VerifyCode = { isValid: false, verification_status_id: 2 };
      if (meta?.pristine) return;
      try {
        result = await confirmationDAO.verifyCode(allValues.accountInfo.email, value);
      } catch (error) {
        console.log('Error verificando el codigo');
      }
      if (!result.isValid) {
        return 'Código incorrecto.';
      }
    },
    [confirmationDAO],
  );

  const reSendCode = useCallback(async () => {
    setLoading(true);
    await sendVerificationCode();
    enqueueSnackbar('Código reenviado, recordá verificar tu casilla de no deseados.', {
      variant: 'info',
    });
    setLoading(false);
  }, [sendVerificationCode, enqueueSnackbar]);

  return (
    <CheckCodeRegisterFormView
      form={form}
      loading={loading}
      disableNext={Object.keys(errors as any).length !== 0}
      reSendCode={reSendCode}
      validateCheckCode={validateCheckCode}
      onSubmit={handleSubmit}
    />
  );
};
