import { useState, useEffect, useCallback } from 'react';
import { PagePagination } from 'src/app/models';
import { useGeneralContext } from 'src/context';
import _orderBy from 'lodash/orderBy';

const initialPagination = {
  perPage: 5,
  currentPage: 1,
  allCount: 0,
};

interface UsePaginationProps<T> {
  items: T[];
  orderBy: keyof T;
  direction: 'asc' | 'desc';
}

export const usePagination = <T = any>({ items, orderBy, direction = 'asc' }: UsePaginationProps<T>) => {
  const [itemsList, setItemsList] = useState<T[]>([]);
  const [list, setList] = useState<T[]>([]);
  const { generalState, dispatch } = useGeneralContext();
  const [pagination, setPagination] = useState<PagePagination>({ ...initialPagination, currentPage: generalState.paginate.page });

  useEffect(() => {
    if (items?.length) {
      setItemsList(items);
    } else if (itemsList.length > 0) {
      setItemsList([]);
    }
  }, [items, itemsList]);

  useEffect(() => {
    const result = _orderBy(itemsList, orderBy, direction);
    const updatedPagination = { ...pagination, allCount: result.length };
    const indexTo = updatedPagination.currentPage * updatedPagination.perPage;
    const indexFrom = indexTo - updatedPagination.perPage;
    setList([...result].slice(indexFrom, indexTo));
    if (pagination.allCount !== updatedPagination.allCount) setPagination(updatedPagination);
  }, [itemsList, orderBy, direction, pagination]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, currentPage: generalState.paginate.page }));
  }, [generalState.paginate.page]);

  const onChangePagination = useCallback(
    (page: number) => {
      setPagination((prev: PagePagination) => ({ ...prev, currentPage: page }));
      dispatch.setPaginate({ ...generalState.paginate, page });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [generalState.paginate, dispatch],
  );

  return { list, pagination, onChangePagination };
};
