import React, { useContext } from 'react';
import { ProfileContext as ProfileContextType } from './types';

// @ts-ignore
export const ProfileContext = React.createContext<ProfileContextType>({
  profile: '',
  setProfile: () => {},
});

export const useProfileContext = () => useContext<ProfileContextType>(ProfileContext);
