import { MenuItem } from 'src/commons';
import { routes } from './routes';

export const profilesMenu: MenuItem = {
  key: 'privateProfile',
  basePath: '/profile',
  enabled: true,
  icon: 'List',
  text: 'Mi cuenta',
  children: routes,
};
