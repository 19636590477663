import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';

export const StatelessTable = () => (
  <Grid container>
    <Grid item xs={12}>
      <Box marginTop="30%" marginBottom="30%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography align="center" variant="subtitle1">
          Introducí una dirección de referencia
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: '1rem', textAlign: 'center' }}>
          Te recomendaremos puntos de retiro según la distancia y horarios disponibles
        </Typography>
      </Box>
    </Grid>
  </Grid>
);
