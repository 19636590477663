import { useCallback } from 'react';
import { useFileUploadDAO } from '../../../../../../app/business';
import { FileUpload } from '../../../../../../app/models';

export interface ImagesUrl {
  img_dni_back: string;
  img_paycheck: string;
  img_dni_front: string;
  img_service_certificate: string;
  img_certificate: string;
}

export const useProcesingFile = () => {
  const fileUploadDAO = useFileUploadDAO();

  const getImagesUrl = useCallback(
    async (files: Array<{ item: Partial<File>; name: string }>) => {
      try {
        const images: Array<{ item: Partial<File>; name: string }> = files;
        const trasformImages: Array<{ [k: string]: string }> = await Promise.all(
          images.map(async (image) => {
            const result: FileUpload[] = await fileUploadDAO.uploadFile(image.item);
            return { [image.name]: result?.[0]?.message || '' };
          }),
        );
        const transfomr = trasformImages.reduce((accum, item) => {
          accum = { ...accum, ...item };
          return accum;
        }, {} as ImagesUrl);
        return transfomr;
      } catch (error) {
        console.log('error', error);
        throw new Error(error.message);
      }
    },
    [fileUploadDAO],
  );

  return { getImagesUrl };
};
