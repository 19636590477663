import React, { useState, useCallback } from 'react';
import { Grid, Box, Typography, Button, Dialog, DialogTitle, DialogContent, IconButton, DialogContentText } from '@material-ui/core';
import { GenericFilterData, FilterList } from 'src/commons/ui/view/GenericFilter';
import CloseIcon from '@material-ui/icons/Close';

interface FilterListViewProps extends GenericFilterData {
  onAddFilter: (value: FilterList) => void;
}

export const FilterListView = ({ title, items, onAddFilter }: FilterListViewProps) => {
  const [openModal, setOpenModal] = useState(false);
  const onHandleCloseModal = useCallback(() => setOpenModal(false), []);
  const onOpenModals = useCallback(() => setOpenModal(true), []);

  const onSelectItem = useCallback(
    (item: FilterList) => {
      onHandleCloseModal();
      onAddFilter(item);
    },
    [onHandleCloseModal, onAddFilter],
  );

  const maxRenderLevelOne = [...items].slice(0, 3);

  const newToShowMore = items.length > 4 || !!items.find((_item) => (_item.subItems || []).length > 2);

  return (
    <Box component="div" width="100%" borderBottom="1px solid #DADBE4" pb={1}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box px="20px" my={1}>
            {maxRenderLevelOne.map((item) => {
              const maxRenderLevelTwo = [...(item.subItems || [])]?.splice(0, 2);
              return (
                <Grid key={item.name}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {item.name}
                  </Typography>
                  {maxRenderLevelTwo?.map((_item) => (
                    <Typography
                      key={_item.name.toString()}
                      style={{ cursor: 'pointer' }}
                      onClick={() => onAddFilter(_item)}
                      variant="body1"
                      color="textSecondary"
                    >
                      {_item.name}
                    </Typography>
                  ))}
                </Grid>
              );
            })}
          </Box>
        </Grid>
        {newToShowMore && (
          <Button size="small" variant="text" style={{ textTransform: 'none' }} onClick={onOpenModals}>
            <Typography variant="subtitle2" color="primary">
              ver todos
            </Typography>
          </Button>
        )}
      </Grid>
      <Dialog open={openModal} aria-labelledby="draggable-dialog-title" onClose={onHandleCloseModal} fullWidth maxWidth="md">
        <DialogTitle style={{ padding: '24px 48px 0px 48px' }}>
          <Box borderBottom="1px solid #DADBE4" flexDirection="row" display="flex" width="100%" justifyContent="space-between" alignItems="center">
            <Box display="flex" height="100%" alignItems="center">
              <Typography variant="h5">{title}</Typography>
            </Box>
            <Box onClick={onHandleCloseModal}>
              <IconButton edge="end">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent style={{ padding: '8px 48px 24px 48px' }}>
          <DialogContentText>
            <Grid container direction="row">
              {[...items].map((item) => (
                <Grid key={item.name} item xs={6}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {item.name}
                  </Typography>
                  <Box pt={1} pb={2}>
                    {item.subItems?.map((_item) => (
                      <Typography key={_item.name} style={{ cursor: 'pointer' }} onClick={() => onSelectItem(_item)} variant="body1" color="primary">
                        {_item.name}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
