import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useMemo } from 'react';

export const useIsPromotionPath = (): boolean => {
  const location = useLocation();
  const parsed = useMemo(() => parse(location.search), [location.search]);
  const isPromo = location.pathname.includes('promotions');
  return !parsed.viewControls && isPromo;
};
