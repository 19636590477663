import { MenuItem } from '../../../commons/ui/menu';
import { categoriesMenu } from './categories';
import { cartMenu } from './cart';
import { productsMenu } from './products';
import { searchMenu } from './search';
import { aboutMenu } from './about';
import { faqsMenu } from './faqs';
import { userPolicyMenu } from './politicas/userPolicy';
import { privacyPolicyMenu } from './politicas/privacyPolicy';
import { comoComprarMenu } from './comoComprar';
import { promotionsMenu } from './promotions';
import { regretMenu } from './regret';
import { registerMenu } from './register';
import { dashboardMenu } from './dashboard';

export const routes: MenuItem[] = [
  categoriesMenu,
  cartMenu,
  productsMenu,
  searchMenu,
  aboutMenu,
  faqsMenu,
  userPolicyMenu,
  privacyPolicyMenu,
  comoComprarMenu,
  promotionsMenu,
  regretMenu,
  registerMenu,
  dashboardMenu,
];
