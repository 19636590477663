/* eslint-disable */
import React from 'react';
import {Maintenance} from '../../../../assets/images'

interface MaintenancePageProps {
  width?: string | number;
  height?: string | number;
}

export const MaintenancePage = ({ width = 490, height = 347 }: MaintenancePageProps) => (
   <img src={Maintenance} width={width} height={height} alt="Sitio en matenimiento" />
);
