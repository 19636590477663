import { routes } from './routes';
import { MenuItem } from '../../../../../commons/ui/menu';

export const userPolicyMenu: MenuItem = {
  key: 'publicUserPolicy',
  basePath: '/devoluciones',
  enabled: true,
  icon: 'List',
  text: 'Devoluciones',
  children: routes,
  default: true,
};
