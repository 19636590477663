import { makeStyles } from '@material-ui/core';

export const appBreadcrumbsStyles = makeStyles((theme) => ({
  breadcrumbs: { width: '100%', marginTop: 17 },
  breadcrumbsLi: { margin: 0 },
  lastLink: {
    /*   fontStyle: 'normal', */
    color: theme.palette.primary.main,
    /*  fontWeight: 'normal',
    fontSize: 13, */
    textDecoration: 'none',
  },
  link: {
    /*  textTransform: 'none', */
    color: theme.palette.text.secondary,
    /*   fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 13, */
    textDecoration: 'none',
  },
}));
