import { useEffect, useState } from 'react';

export const useLoadingHook = (variables: any) => {
  const [variable, setVariable] = useState<boolean>(false);

  useEffect(() => {
    setVariable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...variables]);

  useEffect(() => {
    setVariable(false);
  }, [variable]);

  return { variable };
};
