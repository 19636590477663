import { TemplateProperty } from '../../../../lib/templates';

export const loginTemplate: TemplateProperty = {
  name: 'loginInfo',
  type: {
    name: 'object',
    kind: [
      {
        name: 'email',
        type: { name: 'string' },
        label: 'Correo electrónico',
        validators: [
          { name: 'email', signature: [{ tlds: { allow: false } }] },
          { name: 'empty', errorMessage: 'Completa este dato' },
        ],
      },
      {
        name: 'password',
        type: { name: 'string' },
        label: 'Clave',
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
          { name: 'empty', errorMessage: 'Completa este dato' },
        ],
      },
    ],
  },
};
