import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { Zone } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'zone';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'zone_id',
      labelKey: 'name',
    },
    withSelectedChips: {
      idKey: 'zone_id',
      labelKey: 'name',
    },
  },
};

const optionsToUse = !businessOptions.Zone
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Zone,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Zone.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Zone>(DAO_NAME, optionsToUse);

// hooks
export const useZoneFetchById = fetchById;
export const useZoneLibbyCall = libbyCall;
export const useZoneDAO = libbyDAO;
export const useZoneLibbyFetch = libbyFetch;

// components/hoc
export const ZoneAutocomplete = entityAutocomplete;
export const ZoneCall = renderPropCall;
export const ZoneById = renderPropFetchById;
export const ZoneSelect = simpleSelect;
export const ZoneAccordionSelector = accordionSelector;
export const ZoneSelectedChips = selectedChips;

// context
// ByIdContext
export const useZoneByIdContext = useByIdContext;
export const ZoneByIdProvider = ByIdProvider;
export const ZoneByIdContext = ByIdContext;
// GeneralContext
// export const useZoneContext = useGeneralContext;
// export const ZoneProvider = GeneralProvider;
// export const ZoneContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
