/* eslint-disable class-methods-use-this */
const manager = {
  getItem: (key: string) => localStorage.getItem(key),
  setItem: (key: string, value: string) => localStorage.setItem(key, value),
  removeItem: (key: string) => localStorage.removeItem(key),
};

const COOKIE_APP = 'confirmation_time';

export class ConfirmationTimeManager {
  async retrieve(): Promise<string> {
    try {
      const cookie = await manager.getItem(COOKIE_APP);
      return cookie || '';
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  clean(): void | boolean {
    try {
      return manager.removeItem(COOKIE_APP);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return false;
    }
  }

  persist(cookie: string): void | boolean {
    try {
      return manager.setItem(COOKIE_APP, cookie);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      return false;
    }
  }

  static create(): ConfirmationTimeManager {
    return new ConfirmationTimeManager();
  }
}

export const confirmationTimeManager = ConfirmationTimeManager.create();
