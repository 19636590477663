import { TemplateProperty } from 'src/lib/templates';

export const couponTemplate: TemplateProperty[] = [
  {
    name: 'name',
    type: { name: 'string' },
    optional: true,
  },
  {
    name: 'discount',
    type: { name: 'number' },
    optional: true,
  },
  {
    name: 'response',
    type: { name: 'array', kind: 'string' },
    optional: true,
  },
  {
    name: 'checked',
    type: { name: 'boolean' },
    optional: true,
  },
  {
    name: 'amountCoupon',
    type: { name: 'number' },
    optional: true,
  },
];
