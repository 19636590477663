import { MenuItem } from '../../../../../commons/ui/menu';
import { Politicas } from '../../../../../screens/Public/Politicas';

export const routes: MenuItem[] = [
  {
    key: 'publicUserPolicyMain',
    basePath: '/',
    target: {
      component: Politicas,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Política de Devolucion',
    children: [],
    default: true,
  },
];
