import { useMemo, useEffect } from 'react';
import { useField } from 'react-final-form-hooks';
import moment from 'moment';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { IFieldComponent } from '../../FieldComponent';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { State, City } from '../../../../../../app/models';
import { checkoutAllValues } from '../../../initialValues';
import { useGenerateDecidirValues } from './useGenerateDecidirValues';
import { validateZipCode, useValidatorCardNumber, useValidatorCardHolder, validateExpCardDate } from '../validators';
import { CardExpireMaskInput } from '../../../../../../commons';
import { useBreakpointsState } from '../../../../../../app/hooks/useMobile';
import { useRolesContext } from '../../../../../../context';
import { useCardInfoTokenLogic } from './useCardInfoTokenLogic';
import { TokenCardDecidir } from '../components/TokenCardDecidir';

export const useFieldsDecidir = () => {
  const { form, values } = useCheckoutFormContext();
  const { useMakeUp } = useBreakpointsState();
  const { userInfo } = useRolesContext();
  const cardInfoField = useField('cardInfo', form);
  useCardInfoTokenLogic();
  const { provinces, cities, loading, loadingProvinces, search, onInputChangeCity } = useGenerateDecidirValues();

  const isUp768 = useMakeUp(768);

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const hiddenToken = useMemo(() => !userInfo.id || valuesForm.tokenCardInfo.token === '', [userInfo, valuesForm.tokenCardInfo.token]);

  const { validatorCardHolder } = useValidatorCardHolder();
  const { validatorCardNumber } = useValidatorCardNumber();

  useEffect(() => {
    if (!userInfo.id || valuesForm.tokenCardInfo.token === '') return;
    if (valuesForm.cardInfo.cardSecurityCode === '' || valuesForm.cardInfo.cardHolder !== '') return;

    const toSave = {
      ...valuesForm.cardInfo,
      cardHolder: valuesForm.tokenCardInfo.card_holder.name.toString(),
      cardExpiration: moment(`01/${valuesForm.tokenCardInfo.expiration_month}/${valuesForm.tokenCardInfo.expiration_year}`).format('MM/YY'),
      cardNumber: `${valuesForm.tokenCardInfo.bin}00000${valuesForm.tokenCardInfo.last_four_digits}`,
    };
    cardInfoField.input.onChange(toSave);
  }, [valuesForm, userInfo.id, cardInfoField]);

  useEffect(() => {
    if (valuesForm?.delivered !== 'delivered') form.change('useDeliveryAddress', false);
  }, [form, valuesForm]);

  const data = useMemo<IFieldComponent[]>(
    () => [
      {
        xs: 12,
        hidden: hiddenToken,
        component: FORM_FIELD_TYPE.CUSTOMIZED,
        componentProps: {
          component: TokenCardDecidir,
          componentProps: { valuesForm },
        },
      },
      {
        key: 'cardInfo.cardHolder',
        xs: 12,
        hidden: !hiddenToken,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `cardInfo.cardHolder`,
          label: 'Nombre del titular de la tarjeta',
          required: true,
          id: 'cardInfo.cardHolder',
          autoComplete: 'cc-name',
          form,
          inputLabelProps: { shrink: true },
          validator: validatorCardHolder,
        },
      },
      {
        hidden: !hiddenToken,
        key: 'cardInfo.cardNumber',
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `cardInfo.cardNumber`,
          label: 'Número de tarjeta',
          required: true,
          id: 'cardInfo.cardNumber',
          autoComplete: 'cc-number',
          form,
          inputLabelProps: { shrink: true },
          validator: validatorCardNumber,
        },
      },
      {
        hidden: !hiddenToken,
        lg: 2,
        md: 2,
        sm: isUp768 ? 2 : 4,
        xs: 5,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          label: 'Vencimiento',
          name: 'cardInfo.cardExpiration',
          form,
          variant: 'standard',
          id: 'cardInfo.cardExpiration',
          inputLabelProps: { shrink: true },
          autoComplete: 'cc-exp',
          placeholder: 'mm/yy',
          inputBaseProps: { inputComponent: CardExpireMaskInput },
          validator: validateExpCardDate,
        },
      },
      {
        key: 'cardInfo.cardSecurityCode',
        lg: 3,
        md: 4,
        sm: isUp768 ? 4 : 6,
        xs: 7,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `cardInfo.cardSecurityCode`,
          label: 'Código de seguridad',
          required: true,
          id: 'cardInfo.cardSecurityCode',
          autoComplete: 'cc-csc',
          inputLabelProps: { shrink: true },
          type: 'password',
          form,
        },
      },
      {
        xs: 12,
        title: 'Domicilio de resumen de su tarjeta.',
        titleProp: { mb: 2, mt: 2 },
        component: FORM_FIELD_TYPE.EMPTY,
      },
      {
        hidden: valuesForm.delivered !== 'delivered',
        xs: 12,
        component: FORM_FIELD_TYPE.CHECKBOX,
        componentProps: {
          name: 'useDeliveryAddress',
          id: 'useDeliveryAddress',
          label: 'Utilizar la misma dirección que para el envío.',
          form,
        },
      },
      {
        hidden: valuesForm.useDeliveryAddress,
        key: 'summaryAddress.province',
        lg: 5,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.SELECT,
        componentProps: {
          form,
          loading: loadingProvinces,
          fullWidth: true,
          label: 'Provincia',
          name: 'summaryAddress.province',
          content: provinces,
          valueKey: 'id',
          labelKey: 'name',
          helperText: 'El campo Provincia es requerido',
          inputLabelProps: { shrink: true },
          optionToValue: (value: string, options: State[]) => options?.find((province) => province.id === value),
          valueToOption: (value: State, options: State[]) => options.find((option: State) => option.id === value.id)?.id || search,
        },
      },
      {
        hidden: valuesForm.useDeliveryAddress,
        key: 'summaryAddress.city',
        lg: 5,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.AUTOCOMPLETE_FETCH,
        componentProps: {
          disabled: valuesForm.summaryAddress.province.name === '',
          name: 'summaryAddress.city',
          form,
          label: 'Ciudad',
          options: cities,
          optionLabelKey: 'name',
          optionToValue: (option: City) => option,
          valueToOption: (value: City, options: City[]) =>
            search?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && search?.length > 0 && options.length > 0
              ? options.find((option: City) => option.id === value.id)?.name
              : search,
          helperText: 'El campo Ciudad es requerido',
          getOptionSelected: (option: City, value: City) => option.id === value?.id,
          onInputChange: onInputChangeCity,
          inputLabelProps: { shrink: true },
          loading,
          enabledOpenAutocomplete: search.length > 1,
        },
      },
      {
        hidden: valuesForm.useDeliveryAddress,
        key: 'summaryAddress.zipCode',
        lg: 2,
        md: 4,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `summaryAddress.zipCode`,
          label: 'Cód. postal',
          required: true,
          id: 'deliveryInfoZipCode',
          autoComplete: 'postal-code',
          validator: validateZipCode,
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: valuesForm.useDeliveryAddress,
        key: 'summaryAddress.street',
        lg: 6,
        md: 8,
        xs: 8,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `summaryAddress.street`,
          label: 'Calle',
          required: true,
          id: 'deliveryInfoStreet',
          autoComplete: 'street-address',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: valuesForm.useDeliveryAddress,
        key: 'summaryAddress.streetNumber',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `summaryAddress.streetNumber`,
          label: 'Altura',
          lg: 2,
          md: 2,
          xs: 4,
          required: true,
          id: 'deliveryInfoStreetNumber',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: valuesForm.useDeliveryAddress,
        key: 'summaryAddress.floor',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `summaryAddress.floor`,
          label: 'Piso',
          required: true,
          id: 'deliveryInfoFloor',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: valuesForm.useDeliveryAddress,
        key: 'summaryAddress.apartament',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `summaryAddress.apartament`,
          label: 'Dpto.',
          required: true,
          id: 'deliveryInfoApartment',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: valuesForm.useDeliveryAddress,
        key: 'summaryAddress.additionalInfo',
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `summaryAddress.additionalInfo`,
          label: 'Información adicional',
          id: 'deliveryInfoAditionalInfo',
          inputLabelProps: { shrink: true },
        },
      },
    ],
    [
      cities,
      form,
      isUp768,
      loadingProvinces,
      loading,
      search,
      provinces,
      valuesForm,
      hiddenToken,
      onInputChangeCity,
      validatorCardHolder,
      validatorCardNumber,
    ],
  );
  return { fields: data.filter((field) => !field.hidden) };
};
