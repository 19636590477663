import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { PurchaseItem } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'purchases';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'purchase_id',
      labelKey: 'name',
    },
    withSelectedChips: {
      idKey: 'purchase_id',
      labelKey: 'name',
    },
  },
};

const optionsToUse = !businessOptions.Purchases
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Purchases,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Purchases.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<PurchaseItem>(DAO_NAME, optionsToUse);

// hooks
export const usePurchasesFetchById = fetchById;
export const usePurchasesLibbyCall = libbyCall;
export const usePurchasesDAO = libbyDAO;
export const usePurchasesLibbyFetch = libbyFetch;

// components/hoc
export const PurchasesAutocomplete = entityAutocomplete;
export const PurchasesCall = renderPropCall;
export const PurchasesById = renderPropFetchById;
export const PurchasesSelect = simpleSelect;
export const PurchasesAccordionSelector = accordionSelector;
export const PurchasesSelectedChips = selectedChips;

// context
// ByIdContext
export const usePurchasesByIdContext = useByIdContext;
export const PurchasesByIdProvider = ByIdProvider;
export const PurchasesByIdContext = ByIdContext;
// GeneralContext
// export const usePurchasesContext = useGeneralContext;
// export const PurchasesProvider = GeneralProvider;
// export const PurchasesContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
