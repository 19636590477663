import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useBuyerAddressInfoDAO } from 'src/app/business';
import { FormContextProvider } from 'src/lib/templates/context';
import { BuyerAddressInfo } from '../../../../../../../app/models';
import { FormContent } from './components';
import { addressesInfoTemplate } from '../../template';
import { InitialValueDefinition } from '../../types';

export interface MainFormProps {
  initialValue: InitialValueDefinition;
  raw?: BuyerAddressInfo;
}

export const MainForm = ({ initialValue, raw }: MainFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const buyerAddressInfoDAO = useBuyerAddressInfoDAO();

  const onSubmit = useCallback(
    async (values: InitialValueDefinition) => {
      try {
        const transformed: BuyerAddressInfo = {
          street: values.street,
          number: Number(values.streetNumber),
          floor: values.floor,
          department: values.apartament,
          city: values.city,
          state: values.province,
          zip: values.zipCode,
          comments: values.additionalInfo,
          is_selected: values.is_selected,
        };

        const toSave: BuyerAddressInfo = raw ? { ...raw, ...transformed } : transformed;
        await buyerAddressInfoDAO.save(toSave);
        enqueueSnackbar(raw ? 'Domicilio modificado correctamente' : 'Nuevo domicilio registrado correctamente', { variant: 'success' });
        if (raw ? !raw.is_selected && values.is_selected : values.is_selected) {
          enqueueSnackbar(`El domicilio de ${values.street} ${values.streetNumber} fue agregado como domicilio de envío.`, { variant: 'success' });
        }
        history.goBack();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('No se pudo completar la operacion.', { variant: 'error' });
      }
    },
    [raw, buyerAddressInfoDAO, enqueueSnackbar, history],
  );
  return (
    <FormContextProvider initialValues={initialValue} onSubmit={onSubmit} template={addressesInfoTemplate}>
      <FormContent title={raw ? 'Modificar domicilio' : 'Agregar domicilio'} initialValue={initialValue} />
    </FormContextProvider>
  );
};
