import React, { useCallback, useMemo } from 'react';
import { Theme } from '@material-ui/core/styles';
import { Stepper, useMediaQuery, Grid, Step, StepButton, StepLabel, Typography, Box } from '@material-ui/core';
import { useMultiStepFormContext } from '../../../../../lib/MultiStepForm';

export const StepperFlow = () => {
  const { steps, preSteps, activeStep, completedSteps, /* allSteps */ setActiveStep } = useMultiStepFormContext();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const isStepComplete = useCallback(
    (step: number) => {
      const plus = preSteps.length ? 1 : 0;
      return completedSteps.has(step + plus);
    },
    [completedSteps, preSteps],
  );
  const handleStep = useCallback(
    (step: number) => () => {
      const plus = preSteps.length ? 1 : 0;
      setActiveStep(step + plus);
    },
    [setActiveStep, preSteps],
  );

  const _steps = useMemo(() => steps.filter((step) => !step.preStep), [steps]);

  const currentStep = useMemo(() => (steps[activeStep].preStep ? -1 : preSteps.length ? activeStep - 1 : activeStep), [steps, preSteps, activeStep]);

  return (
    <Grid>
      <Stepper activeStep={currentStep} nonLinear orientation="horizontal">
        {_steps.map((step, index) => (
          <Step key={step.title} completed={isStepComplete(index)}>
            <StepButton onClick={handleStep(index)} disabled={!isStepComplete(index)} completed={isStepComplete(index)}>
              <StepLabel>
                {!isDownSm && (
                  <Typography variant="body1" color="textPrimary">
                    <Box component="span" fontWeight={currentStep === index ? 'bold' : ''}>
                      {step.title}
                    </Box>
                  </Typography>
                )}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};
