import { useCallback } from 'react';
import { DECIDIR_METRIX_URL, DECIDIR_ORG_ID, DECIDIR_MERCHANT_ID } from '../../../../../../config';

export const useMountFingerPrintScriptCallback = () =>
  useCallback((id?: string) => {
    if (id) {
      const elem = document.getElementById('fingenprintscript');
      if (elem) {
        elem.remove();
      }
      const div = document.createElement('div');
      div.id = 'fingenprintscript';
      const script = document.createElement('script');
      script.src = `${DECIDIR_METRIX_URL}?org_id=${DECIDIR_ORG_ID}&session_id=${DECIDIR_MERCHANT_ID}${id}`;
      script.setAttribute('type', 'text/javascript');
      div.appendChild(script);
      document.head.appendChild(div);
    }
  }, []);
