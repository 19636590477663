import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { ecommerceConfig } from '../../../config/ecommerce';

export const DisclaimerIBB = () => (
  <Grid item container direction="row" spacing={3}>
    <Grid item xs={12} sm={12} lg={8}>
      <Typography variant="caption" color="textSecondary">
        Somos agente de percepción de IIBB en CABA y Prov. de Bs. As. Si estás exento de este impuesto, envianos los comprobantes a {}
        <Link href={`mailto:${ecommerceConfig.general.contactUrl}`}>{ecommerceConfig.general.contactUrl}</Link>
      </Typography>
    </Grid>
  </Grid>
);
