import { makeStyles } from '@material-ui/core';

export const videoComponentStyle = makeStyles((theme) => ({
    hidden: {
        display: 'none'
    },
    videoRoot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px'
    },
    specificationsInnerRoot: {
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
    },
    specificationsRoot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px'
    },
    specificationsRootSm: {
        width: '100%',
        marginTop: '30px'
    },
    specification: {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
    },
    specificationSm: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    specificationXs: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginRight: '10px',
        marginLeft: '10px'
    },
    specificationSmRoot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    innerSpecifications: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    innerSpecificationsSm: {
        display: 'flex',
        justifyContent: 'center',
    },
    dividerV: {
        width: '2px',
        backgroundColor: '#BCBCBC',
        marginRight: '10px'
    },
    dividerH: {
        width: '100%',
        backgroundColor: '#BCBCBC',
        height: '0.5px',
        margin: '10px'
    },
    video: {
        cursor: 'pointer'
    },
    muteIconRoot: {
        position: 'relative',
        top: '30px',
        left: '10px',
        width: '50%',
        borderRadius: '10px',
        zIndex: 2,
        height: '20px',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            top: '24px',
            left: '5px'
        },
    },
    muteIcon: {
        backgroundColor: 'rgba(0, 0, 0, 0.41)',
        color: 'rgba(230, 230, 230)',
        borderRadius: '50%',
        padding: '2px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px'
        },
    },
}));