import { TemplateProperty } from '../../../../lib/templates';

export const identityValidationTemplate: TemplateProperty = {
  name: 'equifaxForm',
  type: {
    name: 'object',
    kind: [
      {
        name: 'request',
        type: {
          name: 'array',
          kind: [
            { name: 'id', type: { name: 'number' } },
            { name: 'selectedOptionId', type: { name: 'number' } },
          ],
        },
      },
      { name: 'validClient', type: { name: 'boolean' }, validators: [{ name: 'valid', signature: [true] }] },
      {
        name: 'userToValidate',
        type: {
          name: 'object',
          kind: [
            {
              name: 'name',
              type: { name: 'string' },
            },
            {
              name: 'lastName',
              type: { name: 'string' },
            },
            {
              name: 'document',
              type: { name: 'string' },
            },
            {
              name: 'gender',
              type: { name: 'string' },
            },
          ],
        },
      },
      {
        name: 'isPerson',
        type: { name: 'boolean' },
      },
    ],
  },
};
