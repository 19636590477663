
export const bannerStyles = (background,width,fontColor) =>
    ({
            containerColumn: {
                display:'flex',
                flexFlow:'row wrap',
                justifyContent:'center',
                alignItems:'center',
                // height:'80%',
                minHeight:'400px',
                margin:0,
                // border: '1px solid yellow',
                flex: '0 1 auto',
            },
            containerRow:{
                padding: '20px',
                display:'flex',
                justifyContent:'center',
                alignItems:'flex-end',
                width:'100%',
                height:'100%',
                minHeight:'400px',
                margin:0,
                // border: '1px solid red',
                backgroundColor: background,
                flexWrap: 'wrap',
                paddingLeft: '10px',
                columnGap: '10px',
            },
            boxLeft:{
                display: 'flex',
                flexDirection: 'column',
                alignItems: width > 970 ? 'flex-start' : 'center',
                justifyContent: width > 970 ? 'flex-start' : 'center',
                width: '100%',
                maxWidth: '500px',
                minWidth: '500px',
                height: '100%',
                margin: 0,
                // border: '1px solid black',
            },
            boxRight:{
                display: 'flex',
                flexDirection: 'column',
                alignItems: width > 970 ? 'flex-end' : 'center',
                justifyContent: width > 970 ? 'flex-end' : 'center',
                width: '100%',
                minWidth: '400px',
                height: '100%',
                // border: '1px solid red',
            },
            boxCenter:{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                margin: 0,
                //  border: '1px solid red',
            },
            background: {
                width: 'inherit',
                height: 'inherit',
            },
            top: {
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'inherit',
                height: '10%',
                maxHeight:'170px',
                minHeight:'100px'
                // border: '1px solid green'
            },
            formContainer: {
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                paddingLeft:'25px',
                paddingRight:'45px',
                backgroundColor:'#FFFFFF',
                width:'80%',
                paddingBottom: '25px',
                paddingTop: '25px',
                // maxHeight:'400px',
                // minHeight:'250px',
                borderRadius:'15px',
                boxShadow: '0px 4px 4px 0px #00000040'
                // border: '1px solid green'
            },
            container: {
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '15px',
                width: '300px'
            },
            textField: {
                margin:'5px',
                width: '100%'
            },
            text: {
                fontSize: '20px',
                lineHeight: '1.2',
                fontWeight: 600,
                color: fontColor,
            }
        }
    )
