import React from 'react';
import { Grid, ListItem, ListItemText, Link, ListItemIcon, Typography, useMediaQuery, Theme, Box, makeStyles, GridSize } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { ConfigValuesImages } from 'src/app/models';
import { orderBy } from 'lodash';
import { IMAGE_BASE_URL } from 'src/config';

export interface ListBannerFooter {
  icon?: JSX.Element;
  title: string;
  url?: string;
  linkTitle?: string;
  onClick?: () => void;
}

export interface BannerFooterProps {
  list: Array<ListBannerFooter>;
  paymentImages: ConfigValuesImages;
}

const useStyle = makeStyles(() => ({
  ancho: {
    height: '100%',
  }
}));

const GridMapper: { [k: number]: GridSize } = {
  2: 6,
  3: 4,
  4: 3,
};

export const BannerFooter = ({ list, paymentImages }: BannerFooterProps) => {
  const classes = useStyle();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isAlmostPhoneScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const max = list.length;

  return (
    <Grid container alignItems="center" item spacing={1}>
      <Grid item xs={12} sm={12} md={3} lg={3} container justify="space-around" alignItems="center">
        {orderBy(paymentImages.images, 'relevance', 'asc').map((image) => (
          <Grid component="img" width="auto" height="30px" src={`${IMAGE_BASE_URL}/${image.url}`} />
        ))}
      </Grid>
      <Grid item container xs={12} sm={12} md={9} lg={9} justify="space-around" alignItems="center" className={classes.ancho}>
        {!isDownSm &&
          list.map((item, index) => (
            <>
              <Grid key={item.url} item container xs={GridMapper[max]} className={classes.ancho} alignContent="center" justify="center">
                <Box paddingLeft={1} borderLeft={index > 0 ? '1px solid #E0E0E0' : ''} height="80%" width="100%" alignContent="center">
                  <ListItem disableGutters dense className={classes.ancho}>
                    {item.icon && <ListItemIcon style={isAlmostPhoneScreen ? { minWidth: 'auto' } : {}}>{item.icon}</ListItemIcon>}
                    <ListItemText
                      primary={
                        <Typography variant={!isAlmostPhoneScreen ? 'body1' : 'body2'} color="textPrimary">
                          {ReactHtmlParser(item.title)}
                        </Typography>
                      }
                      secondary={
                        item.linkTitle && (
                          <Link href={item.url} variant="body2" onClick={item.onClick}>
                            {item.linkTitle}
                          </Link>
                        )
                      }
                    />
                  </ListItem>
                </Box>
              </Grid>
            </>
          ))}
      </Grid>
    </Grid>
  );
};
