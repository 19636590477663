import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Button, makeStyles, Box, Typography } from '@material-ui/core';
import { AnyObject, Loading } from '../../../../../../commons';
import { useFormContext } from '../../../../../../lib/templates';
import { TransactionCompleted } from '../interfaces';

interface EquifaxSubmitProps {
  fail: boolean;
  submited: boolean;
  handleSubmitApproved: () => Promise<void>;
  approved: TransactionCompleted;
  working: boolean;
}

export const EquifaxSubmit = ({ submited, handleSubmitApproved, working }: EquifaxSubmitProps) => {
  const buttonStyles = makeStyles((theme) => ({
    buttonStyles: {
      width: '160px',
      height: '38px',
      display: 'inline-block',
      [theme.breakpoints.down(500)]: {
        width: '100px',
      },
    },
    buttonLoading: {
      width: '160px',
      height: '38px',
      display: 'inline-block',
      [theme.breakpoints.down(500)]: {
        width: '100px',
      },
      backgroundColor: '#F4F4F4',
      position: 'relative',
    },
    TypographyLight: {
      fontSize: '.9rem',
    },
    Loader: {
      width: '38px',
      height: '38px',
      position: 'absolute',
      top: 0,
      left: 60,
      [theme.breakpoints.down(500)]: {
        left: 30,
      },
    },
  }));

  const styles = buttonStyles();
  const [disabled, setDisabled] = useState<boolean>(true);
  const { values } = useFormContext<AnyObject>();
  // const score = approved?.payload?.score;

  const completedForm = useMemo(() => values.equifaxForm?.request || [], [values.equifaxForm?.request]);

  const checkForm: boolean = !completedForm.includes(undefined) && completedForm.length === 4;

  useEffect(() => {
    setDisabled(checkForm);
  }, [checkForm]);

  return (
    <Grid>
      <Box display="flex" alignContent="center" justifyContent="center" flexDirection="column">
        <Typography className={styles.TypographyLight}>
          {!disabled || submited ? 'Para continuar, respondé todas las preguntas.' : 'Envia el formulario para que podamos confirmar tu compra.'}
        </Typography>
        <Button
          disabled={!disabled || submited}
          className={!working ? styles.buttonStyles : styles.buttonLoading}
          onClick={handleSubmitApproved}
          type="submit"
          variant="contained"
          color="primary"
        >
          {working ? <Loading className={styles.Loader} /> : 'Enviar'}
        </Button>
      </Box>
    </Grid>
  );
};
