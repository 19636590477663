import { useMemo, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { orderBy } from 'lodash';
import { City, State, BuyerAddressInfo } from '../../../../../../app/models';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { useLocationLibbyCall, useLocationDAO, useBuyerAddressDAO } from '../../../../../../app/business';
import { useDebounce } from '../../../../../../commons';
import { checkoutAllValues, deliveryInitialValue } from '../../../initialValues';
import { useRolesContext } from '../../../../../../context';
import { transformerDeliveryInfo } from '../../../utils/transformers';

export const useGenerateValues = () => {
  const { form, values } = useCheckoutFormContext();
  const [cities, setCities] = useState<City[]>([]);
  const [addresses, setAddresses] = useState<BuyerAddressInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const locationDAO = useLocationDAO();
  const { userInfo } = useRolesContext();
  const buyerAddressDAO = useBuyerAddressDAO();
  const { data = [], working } = useLocationLibbyCall<State[]>({ methodName: 'getStates' });
  const filteredData = data.filter((province) => province.name !== 'default');
  const valuesAll = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const [search, setSearch] = useState(valuesAll.deliveryInfo.city?.name || '');
  const searchDebunce = useDebounce(search, 600);

  const selectedProvince = useMemo(() => valuesAll.deliveryInfo.province?.id !== '', [valuesAll.deliveryInfo.province?.id]);

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  useEffect(() => {
    if (valuesAll.deliveryInfo?.city === null) {
      const copyValuesForm = { ...values, deliveryInfo: valuesAll.deliveryInfo };
      copyValuesForm.deliveryInfo.city = deliveryInitialValue.city;
      form.reset(copyValuesForm);
    }
  }, [search, values, form, valuesAll]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== valuesAll.deliveryInfo.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(valuesAll.deliveryInfo.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, valuesAll.deliveryInfo.province, searchDebunce, valuesAll.deliveryInfo.city]);

  const getAddresses = useCallback(async () => {
    setLoadingAddress(true);
    await buyerAddressDAO.getAllAddress().then((addresses: BuyerAddressInfo[]) => {
      if (addresses.length) {
        // * Filter store pickup address default
        setAddresses(
          orderBy(
            addresses.filter((item) => item.zip !== '0000'),
            'is_selected',
            'asc',
          ),
        );
      }
      setLoadingAddress(false);
    });
  }, [buyerAddressDAO]);

  useEffect(() => {
    if (!userInfo.id) return;
    getAddresses();
  }, [userInfo.id, getAddresses]);

  const recall = useCallback(async () => {
    getAddresses();
    const selected = addresses.find((item) => item.is_selected);
    if (selected) {
      (form as any).change('deliveryInfo', transformerDeliveryInfo(selected));
    }
  }, [form, addresses, getAddresses]);

  return {
    cities,
    search,
    provinces: orderBy(filteredData, 'name'),
    loading,
    loadingProvinces: working,
    addresses,
    loadingAddress,
    onInputChangeCity,
    recall,
  };
};
