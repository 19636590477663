import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Link,
  Divider,
  ListItemIcon,
  Typography,
  useMediaQuery,
  Theme,
  Box,
  Card,
  Paper,
} from '@material-ui/core';
import { ListBannerFooter } from 'src/commons/ui/view/BannerFooter';

export interface InfoPromosProps {
  list: Array<ListBannerFooter>;
}

export const InfoPromos = ({ list }: InfoPromosProps) => {
  const max = list.length;
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return isDownSm ? (
    <></>
  ) : (
    <Box borderRadius={8} pb={isDownSm ? '5vw' : '3vw'}>
      <Card>
        <Paper elevation={1}>
          <Box p={1}>
            <Grid container xs={12} justify="space-around">
              {list.map((item, index) => (
                <>
                  <Grid key={item.url} item>
                    <List>
                      <ListItem disableGutters dense>
                        {item.icon && <ListItemIcon style={isDownSm ? { minWidth: 30 } : {}}>{item.icon}</ListItemIcon>}
                        <ListItemText
                          primary={
                            <Typography variant="body1" color="textPrimary">
                              {item.title}
                            </Typography>
                          }
                          secondary={
                            item.linkTitle && (
                              <Link href={item.url} variant="body2" onClick={item.onClick}>
                                {item.linkTitle}
                              </Link>
                            )
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  {index < max - 1 && <Divider key={item.title} style={{ margin: 0 }} orientation="vertical" flexItem variant="middle" />}
                </>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Card>
    </Box>
  );
};
