import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery, Theme } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

interface ReactCarouselProps {
  arrayComponentsToBeRender: JSX.Element[];
  autoPlay?: boolean;
  interval?: number;
  forceNumberRender?: number;
}

export function ReactCarousel({ arrayComponentsToBeRender, autoPlay = false, interval = 150, forceNumberRender = 0 }: ReactCarouselProps) {
  const isBetweenSmMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md')); // 2
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down(890)); // 2
  const isPhoneScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down(605)); // 1

  // TODO: Hacer que la cantidad de elementos que se renderice sea por el size del component y no hardcode.
  const itemsToBeRendered = isPhoneScreen ? 1 : isDownSm ? 2 : isBetweenSmMd ? 3 : 4;
  const renderNumberComparation = forceNumberRender > 0 ? forceNumberRender : itemsToBeRendered;
  const [carouselItems, setCarouselItems] = useState<JSX.Element[]>([]);
  const makeCarouselItems = useCallback(() => {
    let carouselItem: JSX.Element[] = [];
    let allCarouselItems: JSX.Element[] = [];
    if (arrayComponentsToBeRender.length) {
      arrayComponentsToBeRender.forEach((item) => {
        carouselItem = [...carouselItem, item];
        if (carouselItem.length >= renderNumberComparation) {
          allCarouselItems = [
            ...allCarouselItems,
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>{carouselItem.map((i: JSX.Element) => i)}</div>,
          ];
          carouselItem = [];
        }
      });
    }
    setCarouselItems(allCarouselItems);
  }, [renderNumberComparation, setCarouselItems, arrayComponentsToBeRender]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => makeCarouselItems(), [window.innerWidth, makeCarouselItems]);

  return (
    <Carousel autoPlay={autoPlay} swipe stopAutoPlayOnHover animation="slide" interval={interval} fullHeightHover>
      {carouselItems}
    </Carousel>
  );
}
