import { TemplateProperty } from 'src/lib/templates';

export const retirementPointsTemplate: TemplateProperty[] = [
  {
    name: 'shipment_hop_options_id',
    type: { name: 'string' },
    optional: false,
  },
  {
    name: 'andreani_id',
    type: { name: 'number' },
    optional: true,
  },
  {
    name: 'code',
    type: { name: 'string' },
    optional: true,
  },
  {
    name: 'number',
    type: { name: 'string' },
    optional: true,
  },
  {
    name: 'description',
    type: { name: 'string' },
    optional: true,
  },
  {
    name: 'canal',
    type: { name: 'string' },
    optional: true,
  },
  {
    name: 'shipment_hop_direction',
    type: {
      name: 'object',
      kind: [
        {
          name: 'shipment_hop_direction_id',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'street',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'number',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'province',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'city',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'region',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'country',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'zip',
          type: { name: 'string' },
          optional: true,
        },
      ],
    },
    optional: true,
  },
  {
    name: 'coordinates',
    type: {
      name: 'object',
      kind: [
        {
          name: 'latitud',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'longitud',
          type: { name: 'string' },
          optional: true,
        },
      ],
    },
    optional: true,
  },
  {
    name: 'service_hours',
    type: { name: 'string' },
    optional: true,
  },
  {
    name: 'additional_information',
    type: {
      name: 'object',
      kind: [
        {
          name: 'seHaceAtencionAlCliente',
          type: { name: 'boolean' },
          optional: true,
        },
        {
          name: 'tipo',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'admiteEnvios',
          type: { name: 'boolean' },
          optional: true,
        },
        {
          name: 'entregaEnvios',
          type: { name: 'boolean' },
          optional: true,
        },
        {
          name: 'conBuzonInteligente',
          type: { name: 'boolean' },
          optional: true,
        },
        {
          name: 'sucursalAbastecedora',
          type: {
            name: 'object',
            kind: [
              {
                name: 'id',
                type: { name: 'number' },
                optional: true,
              },
              {
                name: 'codigo',
                type: { name: 'number' },
                optional: true,
              },
              {
                name: 'descripcion',
                type: { name: 'string' },
                optional: true,
              },
            ],
          },
          optional: true,
        },
      ],
    },
    optional: true,
  },
  {
    name: 'phone_numbers',
    type: { name: 'any', kind: 'any' },
    optional: true,
  },
  {
    name: 'accepted_zip',
    type: { name: 'array', kind: 'string' },
    optional: true,
  },
  {
    name: 'active',
    type: { name: 'boolean' },
    optional: true,
  },
  {
    name: 'straightLine',
    type: { name: 'number' },
    optional: true,
  },
  {
    name: 'googleDistance',
    type: {
      name: 'object',
      kind: [
        {
          name: 'text',
          type: { name: 'string' },
          optional: true,
        },
        {
          name: 'value',
          type: { name: 'number' },
          optional: true,
        },
      ],
    },
  },
];
