import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Container } from '@material-ui/core';
import { useSkeletonLibbyCall } from 'src/app/business';
import { Skeleton, SkeletonComponent, SkeletonComponents, SKELETON_TYPE } from 'src/app/models/Site';
import { SKELETON_IDS } from 'src/app/skeletons';
import { orderBy } from 'lodash';
import { Loading } from '../../../../commons/components/Loading';
import { ecommerceConfig } from '../../../../config/ecommerce';

interface Banner {
  component: JSX.Element,
  rendered: boolean
}

export const HomeView = () => {
  const { data, working } = useSkeletonLibbyCall<Skeleton>({ methodName: 'getSkeleton', params: [SKELETON_IDS.HOME] });
  const [banner, setBanner] = useState<Banner>({ component: <></>, rendered: false });
  
  return (
    <>
      <Helmet>
        <title>{ecommerceConfig.general.title}</title>
        <meta name="description" content={ecommerceConfig.general.description} />
      </Helmet>

      {
        banner.component
      }

      <Container maxWidth='lg'>
        <Grid container direction="column">
          {working ? (
            <Loading />
          ) : data && !Array.isArray(data) ? (
            orderBy(data.skeleton_components, 'relevance', 'asc').map((skeleton: SkeletonComponent) => {
              const Component = SkeletonComponents[SKELETON_TYPE[skeleton.config_values.component_key]];

              if(skeleton.name === 'Main Banner'){
                if(banner.rendered)
                  return <></>
                setBanner({ 
                  component: <Component data={skeleton.config_values} /> ,
                  rendered: true
                });
              }

              return Component ? <Component data={skeleton.config_values} /> : <></>;
            })
          ) : null}
        </Grid>
      </Container>
    </>
  );
};
