/* eslint max-len: ["error", { "ignorePattern": "^\\s*var\\s.+=\\s*require\\s*\\(", "code": 1000 }] */
import React from 'react';

// export const ShortLogo = () =>
//   <svg width="140" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 675.87 72.94">
//       <defs>
//           <style>
//               {`.cls-1{fill:#07d3ed;}.cls-2{fill:#0b006d;}`}
//           </style>
//       </defs>
//       <g id="Capa_2" data-name="Capa 2">
//           <g id="Capa_1-2" data-name="Capa 1">
//               <path className="cls-1"
//                     d="M42.5,2.25V57.87h-9V52.7A14.47,14.47,0,0,1,27.77,57a19.32,19.32,0,0,1-7.53,1.43A21.06,21.06,0,0,1,9.86,55.85a18.27,18.27,0,0,1-7.24-7.24A21.61,21.61,0,0,1,0,37.86,21.42,21.42,0,0,1,2.62,27.14a18.36,18.36,0,0,1,7.24-7.2,21.06,21.06,0,0,1,10.38-2.55,19,19,0,0,1,7.27,1.35,14.62,14.62,0,0,1,5.62,4.05V2.25ZM27.44,48.84a11.07,11.07,0,0,0,4.27-4.39,13.38,13.38,0,0,0,1.57-6.59,13.42,13.42,0,0,0-1.57-6.6,11.14,11.14,0,0,0-4.27-4.39,12.11,12.11,0,0,0-6.07-1.53,12.12,12.12,0,0,0-6.08,1.53A11.14,11.14,0,0,0,11,31.26a13.42,13.42,0,0,0-1.57,6.6A13.38,13.38,0,0,0,11,44.45a11.07,11.07,0,0,0,4.27,4.39,12.13,12.13,0,0,0,6.08,1.54A12.12,12.12,0,0,0,27.44,48.84Z" />
//               <path className="cls-1"
//                     d="M91.53,40.86H60.12a10.89,10.89,0,0,0,4.31,7,14.11,14.11,0,0,0,8.66,2.59A14.59,14.59,0,0,0,84,46.1l5,5.77a16.92,16.92,0,0,1-6.82,4.88,24.84,24.84,0,0,1-9.29,1.65,24.7,24.7,0,0,1-11.62-2.63,18.83,18.83,0,0,1-7.76-7.31,20.57,20.57,0,0,1-2.74-10.6,21,21,0,0,1,2.66-10.54A18.86,18.86,0,0,1,60.8,20a21.49,21.49,0,0,1,10.64-2.63A20.94,20.94,0,0,1,81.9,20a18.29,18.29,0,0,1,7.19,7.27,22,22,0,0,1,2.59,10.83C91.68,38.73,91.63,39.66,91.53,40.86ZM63.83,27.55a11.18,11.18,0,0,0-3.78,7.08H82.76a11,11,0,0,0-3.67-7,11.18,11.18,0,0,0-7.65-2.7A11.33,11.33,0,0,0,63.83,27.55Z" />
//               <path className="cls-2"
//                     d="M124,55.7a11.06,11.06,0,0,1-4,2,18.06,18.06,0,0,1-5,.68q-6.58,0-10.19-3.45T101.2,44.9V25.64H94.6v-7.5h6.6V9h9.37v9.14h10.72v7.5H110.57v19A6.32,6.32,0,0,0,112,49.14a5.34,5.34,0,0,0,4.12,1.54A8.24,8.24,0,0,0,121.37,49Z" />
//               <path className="cls-2"
//                     d="M167.09,40.86H135.68a10.87,10.87,0,0,0,4.32,7,14.08,14.08,0,0,0,8.65,2.59,14.59,14.59,0,0,0,10.87-4.35l5,5.77a17,17,0,0,1-6.83,4.88,27,27,0,0,1-20.91-1,18.83,18.83,0,0,1-7.76-7.31,20.67,20.67,0,0,1-2.74-10.6A20.93,20.93,0,0,1,129,27.32a18.77,18.77,0,0,1,7.38-7.3A21.55,21.55,0,0,1,147,17.39,20.94,20.94,0,0,1,157.46,20a18.31,18.31,0,0,1,7.2,7.27,22.07,22.07,0,0,1,2.59,10.83C167.25,38.73,167.19,39.66,167.09,40.86ZM139.4,27.55a11.15,11.15,0,0,0-3.79,7.08h22.71a11,11,0,0,0-3.67-7,11.18,11.18,0,0,0-7.65-2.7A11.31,11.31,0,0,0,139.4,27.55Z" />
//               <path className="cls-2"
//                     d="M182.69,55.77A19.16,19.16,0,0,1,175,48.46a20.4,20.4,0,0,1-2.78-10.6A20.22,20.22,0,0,1,175,27.29,19.28,19.28,0,0,1,182.65,20a23.4,23.4,0,0,1,11.21-2.63,21.59,21.59,0,0,1,10.38,2.4,15.59,15.59,0,0,1,6.71,6.9l-7.2,4.19a11.5,11.5,0,0,0-4.31-4.16,12.59,12.59,0,0,0-14.35,2,12.23,12.23,0,0,0-3.45,9.11,12.26,12.26,0,0,0,3.41,9.1,12.61,12.61,0,0,0,14.39,2,11.42,11.42,0,0,0,4.31-4.16L211,49A16.25,16.25,0,0,1,204.17,56a21.26,21.26,0,0,1-10.31,2.44A23.46,23.46,0,0,1,182.69,55.77Z" />
//               <path className="cls-2"
//                     d="M253.15,21.81q4.57,4.43,4.58,13.12V57.87h-9.37V36.13c0-3.5-.83-6.13-2.48-7.91a9.17,9.17,0,0,0-7-2.66,10.84,10.84,0,0,0-8.17,3.11c-2,2.08-3,5.06-3,9V57.87h-9.38v-40h8.92V23a14.73,14.73,0,0,1,5.85-4.19A21.05,21.05,0,0,1,241,17.39Q248.59,17.39,253.15,21.81Z" />
//               <path className="cls-2"
//                     d="M276.32,55.77a19.06,19.06,0,0,1-7.61-7.31A20.57,20.57,0,0,1,266,37.86a20.38,20.38,0,0,1,2.74-10.57A19.1,19.1,0,0,1,276.32,20a24.23,24.23,0,0,1,22,0,19.16,19.16,0,0,1,7.61,7.27,20.48,20.48,0,0,1,2.74,10.57,20.67,20.67,0,0,1-2.74,10.6,19.13,19.13,0,0,1-7.61,7.31,24.23,24.23,0,0,1-22,0Zm19.49-8.84a12.42,12.42,0,0,0,3.37-9.07,12.39,12.39,0,0,0-3.37-9.07,11.45,11.45,0,0,0-8.55-3.45,11.33,11.33,0,0,0-8.51,3.45,12.49,12.49,0,0,0-3.33,9.07,12.52,12.52,0,0,0,3.33,9.07,11.33,11.33,0,0,0,8.51,3.45A11.45,11.45,0,0,0,295.81,46.93Z" />
//               <path className="cls-2" d="M316.87,2.25h9.37V57.87h-9.37Z" />
//               <path className="cls-2"
//                     d="M344.83,55.77a19.13,19.13,0,0,1-7.61-7.31,20.66,20.66,0,0,1-2.73-10.6,20.47,20.47,0,0,1,2.73-10.57A19.16,19.16,0,0,1,344.83,20a24.26,24.26,0,0,1,22,0,19.14,19.14,0,0,1,7.6,7.27,20.38,20.38,0,0,1,2.74,10.57,20.57,20.57,0,0,1-2.74,10.6,19.1,19.1,0,0,1-7.6,7.31,24.26,24.26,0,0,1-22,0Zm19.49-8.84a12.43,12.43,0,0,0,3.38-9.07,12.4,12.4,0,0,0-3.38-9.07,11.45,11.45,0,0,0-8.54-3.45,11.32,11.32,0,0,0-8.51,3.45,12.45,12.45,0,0,0-3.34,9.07,12.49,12.49,0,0,0,3.34,9.07,11.32,11.32,0,0,0,8.51,3.45A11.45,11.45,0,0,0,364.32,46.93Z" />
//               <path className="cls-2"
//                     d="M425.12,17.84v34q0,21.14-21.59,21.14a40.28,40.28,0,0,1-10.95-1.46A23.26,23.26,0,0,1,384,67.24l4.2-7a20.06,20.06,0,0,0,6.63,3.48A25.81,25.81,0,0,0,403,65q6.6,0,9.67-3t3.08-9.15v-2.1a15.4,15.4,0,0,1-5.85,4,20.59,20.59,0,0,1-7.57,1.35A21.76,21.76,0,0,1,392,53.64a18.33,18.33,0,0,1-7.27-6.86,19.19,19.19,0,0,1-2.66-10.12,19,19,0,0,1,2.66-10.09A18.13,18.13,0,0,1,392,19.79a22,22,0,0,1,10.31-2.4,21.14,21.14,0,0,1,7.91,1.43,14.6,14.6,0,0,1,6,4.34V17.84Zm-12.63,27.1a10.81,10.81,0,0,0,3.41-8.28,10.6,10.6,0,0,0-3.41-8.17,13.8,13.8,0,0,0-17.55,0,10.6,10.6,0,0,0-3.41,8.17,10.81,10.81,0,0,0,3.41,8.28,13.66,13.66,0,0,0,17.55,0Z" />
//               <path className="cls-2"
//                     d="M437.11,9.63a5.25,5.25,0,0,1-1.72-4,5.27,5.27,0,0,1,1.72-4A6,6,0,0,1,441.38,0a6.18,6.18,0,0,1,4.28,1.54,4.94,4.94,0,0,1,1.72,3.86,5.64,5.64,0,0,1-1.69,4.16,5.84,5.84,0,0,1-4.31,1.68A6,6,0,0,1,437.11,9.63Zm-.45,8.21H446v40h-9.37Z" />
//               <path className="cls-2"
//                     d="M486.4,21.63q4.68,4.23,4.68,12.78V57.87h-8.84V53a10.89,10.89,0,0,1-4.91,4,19.32,19.32,0,0,1-7.69,1.39,18.92,18.92,0,0,1-7.87-1.54,12.18,12.18,0,0,1-5.21-4.27,10.9,10.9,0,0,1-1.83-6.19,10.59,10.59,0,0,1,4-8.66q4-3.26,12.63-3.26h10.34v-.6a8.17,8.17,0,0,0-2.51-6.44q-2.51-2.25-7.46-2.25a21.47,21.47,0,0,0-6.63,1,16.24,16.24,0,0,0-5.51,2.92l-3.67-6.82a22.63,22.63,0,0,1,7.57-3.67,33.67,33.67,0,0,1,9.37-1.28Q481.71,17.39,486.4,21.63ZM477.85,50a8.72,8.72,0,0,0,3.86-4.61V40.71H472Q464,40.71,464,46a4.78,4.78,0,0,0,2,4,9.27,9.27,0,0,0,5.62,1.5A12,12,0,0,0,477.85,50Z" />
//               <path className="cls-2" d="M501,56.63a6.22,6.22,0,1,1,4.35,1.77A6,6,0,0,1,501,56.63Z" />
//               <path className="cls-2"
//                     d="M526.32,55.77a19.25,19.25,0,0,1-7.73-7.31,20.49,20.49,0,0,1-2.77-10.6,20.31,20.31,0,0,1,2.77-10.57A19.31,19.31,0,0,1,526.28,20a23.4,23.4,0,0,1,11.21-2.63,21.61,21.61,0,0,1,10.38,2.4,15.59,15.59,0,0,1,6.71,6.9l-7.2,4.19a11.57,11.57,0,0,0-4.31-4.16,12.61,12.61,0,0,0-14.36,2,12.26,12.26,0,0,0-3.44,9.11,12.26,12.26,0,0,0,3.41,9.1,12.61,12.61,0,0,0,14.39,2,11.5,11.5,0,0,0,4.31-4.16l7.2,4.2A16.22,16.22,0,0,1,547.79,56a21.19,21.19,0,0,1-10.3,2.44A23.43,23.43,0,0,1,526.32,55.77Z" />
//               <path className="cls-2"
//                     d="M568.37,55.77a19.06,19.06,0,0,1-7.61-7.31A20.66,20.66,0,0,1,558,37.86a20.47,20.47,0,0,1,2.73-10.57A19.1,19.1,0,0,1,568.37,20a24.24,24.24,0,0,1,22,0,19.1,19.1,0,0,1,7.61,7.27,20.38,20.38,0,0,1,2.74,10.57,20.57,20.57,0,0,1-2.74,10.6,19.06,19.06,0,0,1-7.61,7.31,24.24,24.24,0,0,1-22,0Zm19.49-8.84a12.42,12.42,0,0,0,3.37-9.07,12.39,12.39,0,0,0-3.37-9.07,11.45,11.45,0,0,0-8.54-3.45,11.32,11.32,0,0,0-8.51,3.45,12.45,12.45,0,0,0-3.34,9.07,12.49,12.49,0,0,0,3.34,9.07,11.32,11.32,0,0,0,8.51,3.45A11.45,11.45,0,0,0,587.86,46.93Z" />
//               <path className="cls-2"
//                     d="M671.45,21.78q4.41,4.38,4.42,13.15V57.87H666.5V36.13c0-3.5-.78-6.13-2.33-7.91a8.39,8.39,0,0,0-6.67-2.66,9.78,9.78,0,0,0-7.57,3.11q-2.85,3.12-2.85,8.89V57.87h-9.37V36.13c0-3.5-.77-6.13-2.32-7.91a8.4,8.4,0,0,0-6.67-2.66,9.8,9.8,0,0,0-7.61,3.08c-1.88,2.05-2.81,5-2.81,8.92V57.87h-9.37v-40h8.92v5.1a14.55,14.55,0,0,1,5.62-4.12A19.08,19.08,0,0,1,631,17.39a18.08,18.08,0,0,1,8,1.69,12.94,12.94,0,0,1,5.51,5,16.72,16.72,0,0,1,6.37-4.91,20.76,20.76,0,0,1,8.62-1.76Q667,17.39,671.45,21.78Z" />
//           </g>
//       </g>
//   </svg>

export const ShortLogo = () => (
  <svg
    width="140"
    height="30"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 491.1 72.9"
    enableBackground="new 0 0 491.1 72.9"
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`.st0{fill:#07D3ED;}
	.st1{fill:#0B006D;}`}
    </style>
    <g id="Capa_2_00000083797798362304826840000005233352452460399238_">
      <g id="Capa_1-2">
        <path
          className="st0"
          d="M42.5,2.2v55.6h-9v-5.2C32,54.6,30,56.1,27.8,57c-2.4,1-4.9,1.5-7.5,1.4c-3.6,0-7.2-0.8-10.4-2.6
			c-3.1-1.7-5.6-4.2-7.2-7.2c-1.8-3.3-2.7-7-2.6-10.8c-0.1-3.7,0.8-7.4,2.6-10.7c1.7-3,4.2-5.5,7.2-7.2c3.2-1.7,6.8-2.6,10.4-2.5
			c2.5,0,5,0.4,7.3,1.4c2.2,0.9,4.1,2.3,5.6,4.1V2.2H42.5z M27.4,48.8c1.8-1,3.3-2.6,4.3-4.4c1.1-2,1.6-4.3,1.6-6.6
			c0.1-2.3-0.5-4.6-1.6-6.6c-1-1.8-2.5-3.4-4.3-4.4c-1.9-1-3.9-1.6-6.1-1.5c-2.1,0-4.2,0.5-6.1,1.5c-1.8,1-3.3,2.6-4.3,4.4
			c-1.1,2-1.6,4.3-1.6,6.6c-0.1,2.3,0.5,4.6,1.6,6.6c1,1.8,2.5,3.4,4.3,4.4c1.9,1,4,1.6,6.1,1.5C23.5,50.4,25.6,49.9,27.4,48.8z"
        />
        <path
          className="st0"
          d="M91.5,40.9H60.1c0.5,2.8,2,5.3,4.3,7c2.5,1.8,5.6,2.7,8.7,2.6c4.1,0.1,8-1.4,10.9-4.4l5,5.8
			c-1.8,2.2-4.2,3.9-6.8,4.9c-3,1.1-6.1,1.7-9.3,1.7c-4,0.1-8-0.8-11.6-2.6c-3.2-1.6-5.9-4.2-7.8-7.3c-1.9-3.2-2.8-6.9-2.7-10.6
			c-0.1-3.7,0.9-7.3,2.7-10.5c1.7-3.1,4.3-5.6,7.4-7.3c3.3-1.8,6.9-2.7,10.6-2.6c3.7-0.1,7.3,0.9,10.5,2.6c3,1.7,5.5,4.2,7.2,7.3
			c1.8,3.3,2.7,7.1,2.6,10.8C91.7,38.7,91.6,39.7,91.5,40.9z M63.8,27.5c-2.1,1.8-3.4,4.3-3.8,7.1h22.7c-0.3-2.7-1.6-5.2-3.7-7
			c-2.1-1.8-4.9-2.8-7.7-2.7C68.7,24.8,66,25.8,63.8,27.5z"
        />
        <path
          className="st1"
          d="M124,55.7c-1.2,0.9-2.5,1.6-4,2c-1.6,0.5-3.3,0.7-5,0.7c-4.4,0-7.8-1.2-10.2-3.5s-3.6-5.6-3.6-10V25.6h-6.6
			v-7.5h6.6V9h9.4v9.1h10.7v7.5h-10.7v19c-0.1,1.6,0.4,3.2,1.4,4.5c1.1,1.1,2.6,1.6,4.1,1.5c1.9,0.1,3.7-0.5,5.2-1.7L124,55.7z"
        />
        <path
          className="st1"
          d="M167.1,40.9h-31.4c0.5,2.8,2,5.3,4.3,7c2.5,1.8,5.6,2.7,8.6,2.6c4.1,0.1,8-1.4,10.9-4.3l5,5.8
			c-1.8,2.2-4.2,3.8-6.8,4.9c-6.8,2.5-14.4,2.1-20.9-1c-3.2-1.6-5.9-4.2-7.8-7.3c-1.8-3.2-2.8-6.9-2.7-10.6c0-3.7,0.9-7.3,2.7-10.5
			c1.7-3.1,4.3-5.6,7.4-7.3c3.3-1.8,6.9-2.7,10.6-2.6c3.7-0.1,7.3,0.8,10.5,2.6c3,1.7,5.5,4.2,7.2,7.3c1.8,3.3,2.7,7.1,2.6,10.8
			C167.2,38.7,167.2,39.7,167.1,40.9z M139.4,27.5c-2.1,1.8-3.4,4.3-3.8,7.1h22.7c-0.3-2.7-1.6-5.2-3.7-7c-2.1-1.8-4.9-2.8-7.6-2.7
			C144.2,24.8,141.5,25.8,139.4,27.5z"
        />
        <path
          className="st1"
          d="M182.7,55.8c-3.2-1.7-5.9-4.2-7.7-7.3c-1.9-3.2-2.8-6.9-2.8-10.6c-0.1-3.7,0.9-7.4,2.8-10.6
			c1.8-3.1,4.5-5.6,7.6-7.3c3.5-1.8,7.3-2.7,11.2-2.6c3.6-0.1,7.2,0.7,10.4,2.4c2.9,1.5,5.3,3.9,6.7,6.9l-7.2,4.2
			c-1-1.7-2.5-3.2-4.3-4.2c-4.7-2.4-10.5-1.6-14.4,2c-2.4,2.4-3.6,5.7-3.4,9.1c-0.2,3.4,1.1,6.7,3.4,9.1c3.9,3.6,9.7,4.4,14.4,2
			c1.8-1,3.3-2.4,4.3-4.2L211,49c-1.5,3-3.9,5.4-6.8,7c-3.2,1.7-6.7,2.5-10.3,2.4C190,58.5,186.1,57.6,182.7,55.8z"
        />
        <path
          className="st1"
          d="M253.1,21.8c3,3,4.6,7.3,4.6,13.1v22.9h-9.4V36.1c0-3.5-0.8-6.1-2.5-7.9c-1.8-1.8-4.4-2.8-7-2.7
			c-3-0.2-6,1-8.2,3.1c-2,2.1-3,5.1-3,9v20.2h-9.4v-40h8.9V23c1.6-1.9,3.6-3.3,5.9-4.2c2.5-1,5.2-1.5,7.9-1.4
			C246.1,17.4,250.1,18.9,253.1,21.8z"
        />
        <path
          className="st1"
          d="M276.3,55.8c-3.2-1.7-5.8-4.2-7.6-7.3c-1.8-3.2-2.8-6.9-2.7-10.6c-0.1-3.7,0.9-7.4,2.7-10.6
			c1.8-3.1,4.4-5.6,7.6-7.3c6.9-3.5,15.1-3.5,22,0c3.2,1.7,5.8,4.2,7.6,7.3c1.9,3.2,2.8,6.9,2.7,10.6c0.1,3.7-0.9,7.4-2.7,10.6
			c-1.8,3.1-4.4,5.6-7.6,7.3C291.4,59.3,283.2,59.3,276.3,55.8L276.3,55.8z M295.8,46.9c2.3-2.4,3.5-5.7,3.4-9.1
			c0.2-3.4-1.1-6.6-3.4-9.1c-2.2-2.3-5.3-3.5-8.5-3.5c-3.2-0.1-6.3,1.2-8.5,3.5c-2.3,2.5-3.5,5.7-3.3,9.1c-0.1,3.3,1.1,6.6,3.3,9.1
			c2.2,2.3,5.3,3.6,8.5,3.5C290.5,50.5,293.6,49.2,295.8,46.9z"
        />
        <path className="st1" d="M316.9,2.2h9.4v55.6h-9.4V2.2z" />
        <path
          className="st1"
          d="M344.8,55.8c-3.2-1.7-5.8-4.2-7.6-7.3c-1.8-3.2-2.8-6.9-2.7-10.6c-0.1-3.7,0.9-7.4,2.7-10.6
			c1.8-3.1,4.4-5.6,7.6-7.3c6.9-3.5,15.1-3.5,22,0c3.2,1.7,5.8,4.2,7.6,7.3c1.9,3.2,2.8,6.9,2.7,10.6c0.1,3.7-0.9,7.4-2.7,10.6
			c-1.8,3.1-4.4,5.6-7.6,7.3C359.9,59.3,351.7,59.3,344.8,55.8L344.8,55.8z M364.3,46.9c2.3-2.4,3.5-5.7,3.4-9.1
			c0.1-3.4-1.1-6.6-3.4-9.1c-2.2-2.3-5.3-3.5-8.5-3.5c-3.2-0.1-6.3,1.1-8.5,3.5c-2.3,2.5-3.5,5.7-3.3,9.1c-0.1,3.3,1.1,6.6,3.3,9.1
			c2.2,2.3,5.3,3.6,8.5,3.5C359,50.5,362.1,49.2,364.3,46.9L364.3,46.9z"
        />
        <path
          className="st1"
          d="M425.1,17.8v34c0,14.1-7.2,21.1-21.6,21.1c-3.7,0-7.4-0.5-11-1.5c-3.1-0.8-6-2.3-8.6-4.3l4.2-7
			c2,1.6,4.2,2.7,6.6,3.5c2.6,0.9,5.4,1.3,8.2,1.3c4.4,0,7.6-1,9.7-3c2-2,3.1-5,3.1-9.2v-2.1c-1.6,1.8-3.6,3.1-5.9,4
			c-2.4,0.9-5,1.4-7.6,1.3c-3.6,0-7.1-0.8-10.3-2.5c-3-1.6-5.5-3.9-7.3-6.9c-1.8-3.1-2.7-6.6-2.7-10.1c-0.1-3.5,0.8-7,2.7-10.1
			c1.7-2.9,4.3-5.2,7.3-6.8c3.2-1.6,6.7-2.5,10.3-2.4c2.7,0,5.4,0.5,7.9,1.4c2.3,0.9,4.4,2.4,6,4.3v-5.3H425.1z M412.5,44.9
			c2.3-2.1,3.5-5.2,3.4-8.3c0.1-3.1-1.1-6.1-3.4-8.2c-5.1-4.2-12.5-4.2-17.5,0c-2.3,2.1-3.5,5.1-3.4,8.2c-0.1,3.1,1.1,6.1,3.4,8.3
			C400,49.2,407.4,49.2,412.5,44.9z"
        />
        <path
          className="st1"
          d="M437.1,9.6c-1.1-1-1.8-2.5-1.7-4c0-1.5,0.6-3,1.7-4c1.2-1.1,2.7-1.7,4.3-1.6c1.6,0,3.1,0.5,4.3,1.5
			c1.1,1,1.8,2.4,1.7,3.9c0,1.6-0.6,3.1-1.7,4.2c-1.1,1.1-2.7,1.7-4.3,1.7C439.8,11.3,438.3,10.7,437.1,9.6z M436.7,17.8h9.3v40
			h-9.4L436.7,17.8z"
        />
        <path
          className="st1"
          d="M486.4,21.6c3.1,2.8,4.7,7.1,4.7,12.8v23.5h-8.8V53c-1.2,1.8-2.9,3.2-4.9,4c-2.4,1-5.1,1.5-7.7,1.4
			c-2.7,0.1-5.4-0.5-7.9-1.5c-2.1-0.9-3.9-2.4-5.2-4.3c-1.2-1.8-1.9-4-1.8-6.2c-0.1-3.4,1.4-6.6,4-8.7c2.7-2.2,6.9-3.3,12.6-3.3
			h10.3v-0.6c0.2-2.4-0.8-4.8-2.5-6.4c-1.7-1.5-4.2-2.2-7.5-2.2c-2.2,0-4.5,0.3-6.6,1c-2,0.6-3.9,1.6-5.5,2.9l-3.7-6.8
			c2.3-1.7,4.8-2.9,7.6-3.7c3-0.9,6.2-1.3,9.4-1.3C478.8,17.4,483.3,18.8,486.4,21.6z M477.9,50c1.8-1,3.2-2.7,3.9-4.6v-4.7H472
			c-5.3,0-8,1.8-8,5.3c0,1.6,0.7,3.1,2,4c1.7,1.1,3.6,1.6,5.6,1.5C473.8,51.6,475.9,51.1,477.9,50z"
        />
      </g>
    </g>
  </svg>
);
