import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AnyObject } from 'src/commons/types';
import { AppTheme } from '../../../theme';

export interface SelectedChipsProps<T> {
  items: T[];
  labelKey: keyof T;
  idKey: keyof T;
  onChange?: (newItems: T[]) => void;
  onItemDelete?: (item: T) => void;
  customStyles?: { chip?: AnyObject; container?: AnyObject };
}

// TODO: add performance if needed
export function SelectedChips<T>({ items, idKey, labelKey, customStyles, onChange, onItemDelete }: SelectedChipsProps<T>) {
  const { chip, container } = { chip: {}, container: {}, ...(customStyles || {}) };
  const useStyle = makeStyles<AppTheme>(() => ({
    chip: {
      marginBottom: 10,
      maxWidth: 257,
      ...chip,
    },
    container: {
      padding: 16,
      ...container,
    },
  }));
  const classes = useStyle();

  return items.length > 0 ? (
    <div className={classes.container}>
      {items.map((item) => {
        const label = typeof item[idKey] === 'string' ? `${item[idKey]}: ${item[labelKey]}` : item[labelKey];

        return (
          <Tooltip title={label}>
            <Chip
              key={`${item[idKey]}`}
              className={classes.chip}
              label={label}
              onDelete={() => {
                if (onChange) {
                  const newItems = items.filter((_item) => _item[idKey] !== item[idKey]);
                  onChange(newItems);
                }
                if (onItemDelete) {
                  onItemDelete(item);
                }
              }}
            />
          </Tooltip>
        );
      })}
    </div>
  ) : (
    <></>
  );
}
