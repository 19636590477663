import { routes } from './routes';
import { MenuCollection } from '../../../commons/ui/menu';

export const privateMenu: MenuCollection = [
  {
    key: 'private',
    basePath: '/',
    enabled: true,
    icon: 'List',
    text: 'Private',
    children: routes,
    default: true,
  },
];
