import React, { useMemo, useCallback, useState } from 'react';
import { orderBy } from 'lodash';
import { Box, Grid, Card, Typography, useMediaQuery, Theme, Button } from '@material-ui/core';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { BuyerAddressInfo } from 'src/app/models';
import { OptionsModal, OptionsModalOption } from 'src/commons';
import { useHistory } from 'react-router';
import { useBuyerAddressInfoDAO, useConfirmationDAO } from 'src/app/business';
import { useSnackbar } from 'notistack';
import confirmDialog from 'src/commons/services/confirmDialog';
import { withStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import moment from 'moment';
import { useRolesContext } from 'src/context';
import { confirmationTimeManager } from 'src/utils';
import { getRemovalAddressContent } from './components';
import { getRemovalTitle } from '../../common/RemovalModalTitle';
import { getTextComponent } from '../../common';

interface AddressViewProps {
  addresses: BuyerAddressInfo[];
  recall: () => void;
}

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export const AddressView = ({ addresses }: AddressViewProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const confirmationDAO = useConfirmationDAO();
  const { userInfo } = useRolesContext();
  const history = useHistory();
  const [allAddress, setAllAddress] = useState(addresses);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const buyerAddressInfoDAO = useBuyerAddressInfoDAO();

  const handleUpdatePersonalInfo = useCallback(
    async (data: BuyerAddressInfo, callback: (data: BuyerAddressInfo) => void) => {
      const nowTime = moment();
      // INFO: verifica cada 24 horas, tras la ultima verificacion
      const time = await confirmationTimeManager.retrieve();
      if (!moment(time).isValid() || moment(time).diff(nowTime) < 0) {
        await confirmationDAO.sendCode({ email: userInfo.email });
        history.push({
          pathname: '/dashboard/confirm',
          state: {
            goBack: '/dashboard/profile',
            goNext: '/dashboard/profile',
          },
        });
      } else {
        callback(data);
      }
    },
    [history, userInfo.email, confirmationDAO],
  );

  const handleToUseDeliveryAddress = useCallback(
    async (data: BuyerAddressInfo) => {
      try {
        await buyerAddressInfoDAO.save({ ...data, is_selected: true });
        setAllAddress((old) =>
          old.map((item) => {
            if (item.buyer_address_id === data.buyer_address_id) {
              return { ...item, is_selected: true };
            }
            return { ...item, is_selected: false };
          }),
        );
        enqueueSnackbar(`El domicilio de ${data.street} ${data.number} fue agregado como domicilio de envío.`, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('No se pudo modificar correctamente.', { variant: 'error', persist: true });
      }
    },
    [buyerAddressInfoDAO, enqueueSnackbar],
  );

  const onUndoAction = useCallback(
    async (item: BuyerAddressInfo) => {
      try {
        closeSnackbar();
        await buyerAddressInfoDAO.save({ ...item, is_deleted: false });
        setAllAddress((old) => [...old, item]);
      } catch (error) {
        enqueueSnackbar(`No se pudo recuperar la direccion eliminada.`, {
          variant: 'error',
        });
      }
    },
    [buyerAddressInfoDAO, closeSnackbar, enqueueSnackbar],
  );

  const handleDeleteAddress = useCallback(
    async (data: BuyerAddressInfo) => {
      try {
        const confirm = await confirmDialog.show({
          title: getRemovalTitle('Estas por eliminar el siguiente domicilio.'),
          content: getRemovalAddressContent(data),
          confirmText: 'ELIMINAR',
          cancelText: 'Cancelar',
          buttonConfirm: ColorButton,
        });
        if (confirm) {
          await buyerAddressInfoDAO.remove(data.buyer_address_id);
          setAllAddress((old) => old.filter((item) => item.buyer_address_id !== data.buyer_address_id));
          enqueueSnackbar(`Eliminaste el domicilio de "${data.street} ${data.number}"`, {
            variant: 'error',
            action: (
              <Button color="inherit" size="small" onClick={() => onUndoAction(data)}>
                Deshacer
              </Button>
            ),
          });
        }
      } catch (error) {
        console.log('ERROR', error);
      }
    },
    [buyerAddressInfoDAO, enqueueSnackbar, onUndoAction],
  );

  const optionsMenu = useMemo<OptionsModalOption<BuyerAddressInfo>[]>(
    () => [
      {
        label: getTextComponent('Modificar', 'text.secondary'),
        onClick: (data) => handleUpdatePersonalInfo(data, (data) => history.push(`/dashboard/profile/address/${data.buyer_address_id}`)),
        hide: false,
        disable: false,
        id: 1,
      },
      {
        label: getTextComponent('Usar para envío', 'text.secondary'),
        onClick: (data) => handleUpdatePersonalInfo(data, handleToUseDeliveryAddress),
        hide: (data) => data.is_selected,
        disable: false,
        id: 2,
      },
      {
        label: getTextComponent('Eliminar', 'error.dark'),
        onClick: (data) => handleUpdatePersonalInfo(data, handleDeleteAddress),
        hide: (data) => data.is_selected,
        disable: false,
        id: 3,
      },
    ],
    [history, handleToUseDeliveryAddress, handleDeleteAddress, handleUpdatePersonalInfo],
  );

  return (
    <Box
      justifyItems="center"
      width="100%"
      padding={isDownSm ? 2 : 3}
      component={Card}
      minHeight={149}
      borderRadius={8}
      boxShadow={3}
      display="content"
    >
      {allAddress.length === 0 ? (
        <Grid container justify="center" alignItems="center">
          <Typography variant="body1">No tenés una dirección de envío establecida, agrega una</Typography>
        </Grid>
      ) : (
        orderBy(allAddress, 'is_selected', 'desc')
          .filter((item) => item.zip !== '0000')
          .map((address, index) => (
            <Box
              key={address.buyer_address_id}
              width="100%"
              paddingBottom={index + 1 !== allAddress.length ? 3 : 0}
              mb={index + 1 !== allAddress.length ? 3 : 0}
              borderBottom={index + 1 !== allAddress.length ? '1px solid #DADBE4' : ''}
            >
              <Box display="flex" flexDirection="row" width="100%">
                <Grid item xs={11} container direction="column" spacing={1}>
                  <Grid item container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="body1" color="textPrimary">
                        {`${address.street} ${address.number}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {address.is_selected && (
                        <Box
                          display="flex"
                          bgcolor="primary.border"
                          borderRadius="4px"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          padding="2px 8px"
                        >
                          <LocalShippingOutlinedIcon color="primary" />
                          <Typography variant="caption" color="primary">
                            Envío
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        {`${address.state.name} / ${address.city.name} , ${address.zip}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        {`Piso: ${address.floor} / Dpto: ${address.department}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="textSecondary">
                        {address.comments}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} container alignItems="center" justify="flex-end">
                  <OptionsModal key={address.buyer_address_id} options={optionsMenu} data={address} color="default" />
                </Grid>
              </Box>
            </Box>
          ))
      )}
    </Box>
  );
};
