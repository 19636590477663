import { Product } from 'src/screens/Public/Product';
import { MenuItem } from '../../../../commons/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'publicProductsMain',
    basePath: '/',
    target: {
      component: Product,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Products',
    children: [],
    default: true,
  },
];
