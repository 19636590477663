import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const loginMenu: MenuItem = {
  key: 'publicLogin',
  basePath: '/login',
  enabled: true,
  icon: 'List',
  text: 'Login',
  children: routes,
};
