import React from 'react';

interface approvedCheckProps {
  width: string | number;
  height: string | number;
  color: string;
}

export const ApprovedCheck = ({ width = 34, height = 34, color = '#8B04BC' }: approvedCheckProps) => (
  <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M17 0.375C7.81914 0.375 0.375 7.81914 0.375 17C0.375 26.1809 7.81914 33.625 17 33.625C26.1809 33.625 33.625 26.1809 33.625 17C33.625 7.81914 26.1809 0.375 17 0.375ZM24.1807 11.5709L16.3654 22.4068C16.2562 22.5593 16.1122 22.6835 15.9454 22.7692C15.7786 22.8549 15.5937 22.8996 15.4062 22.8996C15.2186 22.8996 15.0338 22.8549 14.8669 22.7692C14.7001 22.6835 14.5561 22.5593 14.4469 22.4068L9.81934 15.9943C9.67832 15.7977 9.81934 15.523 10.0605 15.523H11.801C12.1795 15.523 12.5395 15.7049 12.7621 16.0166L15.4043 19.683L21.2379 11.5932C21.4605 11.2852 21.8168 11.0996 22.199 11.0996H23.9395C24.1807 11.0996 24.3217 11.3742 24.1807 11.5709Z"
      fill={color}
    />
  </svg>
);
