import { Cart } from 'src/screens/Public/Cart';
import { ApprovedPaymentView } from 'src/app/views/components/ApprovedPaymentView';
import { Checkout } from 'src/screens/Common';
import { FailurePaymentView } from 'src/app/views/components/FailurePaymentView';
import { MenuItem } from '../../../../commons/ui/menu';
import { PendingPaymentView } from '../../../../app/views';

export const routes: MenuItem[] = [
  {
    key: 'publicCartMainFailure',
    basePath: '/checkout/failure',
    target: {
      component: FailurePaymentView,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Cart item checkout',
    children: [],
  },
  {
    key: 'publicCartMainConfirm',
    basePath: '/checkout/confirm/:payment_id',
    target: {
      component: ApprovedPaymentView,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Cart item checkout',
    children: [],
  },
  {
    key: 'publicCartMainPending',
    basePath: '/checkout/pending',
    target: {
      component: PendingPaymentView,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Cart item checkout',
    children: [],
  },
  {
    key: 'publicCartMain',
    basePath: '/checkout',
    target: {
      component: Checkout,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Cart item checkout',
    children: [],
  },
  {
    key: 'publicCartMain',
    basePath: '/',
    target: {
      component: Cart,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Cart',
    children: [],
    default: true,
  },
];
