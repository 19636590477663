import { TemplateProperty } from '../../../../../lib/templates';

// * Contiene los templates comunes para el address
export const addressTemplate: TemplateProperty[] = [
  {
    name: 'buyer_address_id',
    type: { name: 'string' },
    label: '',
    optional: true,
  },
  {
    name: 'province',
    type: {
      name: 'object',
      kind: [
        { name: 'id', type: { name: 'string' } },
        { name: 'name', type: { name: 'string' } },
        { name: 'matches', type: { name: 'array', kind: 'string' } },
      ],
    },
    label: 'Provincia',
  },
  {
    name: 'city',
    type: {
      name: 'object',
      kind: [
        { name: 'id', type: { name: 'string' } },
        { name: 'state_id', type: { name: 'number' } },
        { name: 'zip', type: { name: 'array', kind: 'number' } },
        { name: 'name', type: { name: 'string' } },
        { name: 'matches', type: { name: 'array', kind: 'string' } },
      ],
    },
    label: 'Ciudad',
  },
  {
    name: 'street',
    type: { name: 'string' },
    label: 'Calle',
    validators: [
      { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
      { name: 'max', signature: [45] },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
  {
    name: 'streetNumber',
    type: { name: 'string' },
    label: 'Altura',
    validators: [
      { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Completa con solo números' },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
  {
    name: 'floor',
    type: { name: 'string' },
    label: 'Piso',
    optional: true,
    validators: [
      { name: 'pattern', signature: ['^[A-Za-z]{0,2}[0-9]*$'] },
      { name: 'max', signature: [5] },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
  {
    name: 'apartament',
    type: { name: 'string' },
    label: 'Apartamento',
    optional: true,
    validators: [
      { name: 'pattern', signature: ['^[A-Za-z0-9]+$'] },
      { name: 'max', signature: [5] },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
  {
    name: 'zipCode',
    type: { name: 'string' },
    label: 'Código postal',
    validators: [
      { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Completa con solo números' },
      { name: 'max', signature: [6], errorMessage: 'Código postal invalido' },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
];
