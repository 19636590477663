import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { LocalShipping } from '@material-ui/icons';
import { ShipmentData } from 'src/screens/Public/CalculateShipments/types';
import { productInfoPanelStyles as useStyles } from '../../../styles';

interface PayShipmentProps {
  shipmentData: ShipmentData;
  calculateShipment: () => void;
}

export const PayShipmentView = ({ shipmentData, calculateShipment }: PayShipmentProps) => {
  const classes = useStyles();
  return (
    <Box mt={2} alignItems="center" display="flex">
      <Grid container>
        <Box pr={1}>
          <LocalShipping className={shipmentData.deliveryDate ? classes.localShippingPay : classes.localShipping} />
        </Box>
        {shipmentData.deliveryDate ? (
          <Box>
            <Typography variant="body2" color="textSecondary">
              Legará en las próximas
              <Typography display="inline" variant="body1" color="textPrimary">
                {` ${shipmentData.deliveryDate} `}
              </Typography>
              por
              <Typography display="inline" variant="body1" color="textPrimary">
                {` $${shipmentData.shipmentAddress.deliveryCost}`}
              </Typography>
            </Typography>
            <Button color="primary" className={classes.buttonShipment} onClick={calculateShipment}>
              Ver datos de entrega
            </Button>
          </Box>
        ) : (
          <Box>
            <Typography variant="body2">
              <Box color="success.dark">Este producto tiene envío a todo el país</Box>
            </Typography>
            <Button color="primary" className={classes.buttonShipment} onClick={calculateShipment}>
              Calcular envío
            </Button>
          </Box>
        )}
      </Grid>
    </Box>
  );
};
