import { useMemo } from 'react';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { IFieldComponent } from '../../FieldComponent';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { State, City } from '../../../../../../app/models';
import { useGenerateValues } from './useGenerateValues';
import { validateZipCode } from '../validators';
import { checkoutAllValues } from '../../../initialValues';
import { EMITTERS } from '../../../../../../app/const/Emitters';
import { useEmittersContext } from '../../../../../../context/EmittersContext/EmittersContextProvider';
import { optionsGender } from '../../../constants';

export const useFields = () => {
  const { form, values } = useCheckoutFormContext();
  const { emitters } = useEmittersContext();
  const { cities, provinces, loading, search, loadingProvinces, onInputChangeCity } = useGenerateValues();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const selectedProvince = useMemo(() => valuesForm.guarantorInfo.province?.id !== '', [valuesForm.guarantorInfo.province?.id]);
  const isCapresca = !!emitters.find((emiter) => emiter.card_emiter_id.toString() === EMITTERS.CATAMARCA_CREDITS.toString());

  const data: IFieldComponent[] = useMemo(
    () => [
      {
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'guarantorInfo.name',
          label: 'Nombre',
          required: true,
          id: 'guarantorInfoName',
          autoComplete: 'name',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'guarantorInfo.lastName',
          label: 'Apellido',
          required: true,
          id: 'guarantorInfoLastName',
          autoComplete: 'family-name',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'guarantorInfo.document',
          label: isCapresca ? 'CUIL' : 'DNI',
          required: true,
          id: 'guarantorInfoDocument',
          autoComplete: 'on',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 12,
        component: FORM_FIELD_TYPE.DATE_PICKER,
        componentProps: {
          fullWidth: true,
          disableFuture: true,
          openTo: 'year',
          name: 'guarantorInfo.dateOfBirty',
          label: 'Fecha de nacimiento',
          views: ['year', 'month', 'date'],
          format: 'DD/MM/YYYY',
          inputLabelProps: { shrink: true },
          required: true,
          id: 'guarantorInfoDateOfBirty',
          form,
        },
      },
      {
        component: FORM_FIELD_TYPE.RADIO_GROUP,
        componentProps: {
          row: true,
          formlabel: 'Género',
          marginTop: '10px',
          name: 'guarantorInfo.gender',
          id: 'gender',
          labelKey: 'label',
          options: optionsGender,
          form,
        },
      },
      {
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'guarantorInfo.email',
          label: 'E-mail',
          required: true,
          id: 'guarantorInfoEmail',
          autoComplete: 'email',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        lg: 2,
        md: 2,
        sm: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'guarantorInfo.phone.areaCode',
          label: 'Cód. de Área',
          required: true,
          id: 'guarantorInfoAreaCode',
          autoComplete: 'tel-area-code',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        lg: 4,
        md: 4,
        sm: 4,
        xs: 8,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'guarantorInfo.phone.number',
          label: 'Teléfono',
          required: true,
          id: 'guarantorInfoPhoneNumber',
          autoComplete: 'tel-local',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        lg: 4,
        md: 4,
        sm: 4,
        xs: 8,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'guarantorInfo.cbu',
          label: 'CBU',
          required: true,
          id: 'guarantorInfoCBU',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        xs: 6,
        component: FORM_FIELD_TYPE.EMPTY,
      },
      {
        key: 'guarantorInfo.province',
        lg: 5,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.SELECT,
        componentProps: {
          form,
          loading: loadingProvinces,
          fullWidth: true,
          label: 'Provincia',
          name: 'guarantorInfo.province',
          content: provinces,
          valueKey: 'id',
          labelKey: 'name',
          helperText: 'El campo Provincia es requerido',
          inputLabelProps: { shrink: true },
          optionToValue: (value: string, options: State[]) => options?.find((province) => province.id === value),
          valueToOption: (value: State, options: State[]) => options.find((option: State) => option.id === value.id)?.id || search,
        },
      },
      {
        key: 'guarantorInfo.city',
        lg: 5,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.AUTOCOMPLETE_FETCH,
        componentProps: {
          disabled: !selectedProvince,
          name: 'guarantorInfo.city',
          form,
          label: 'Ciudad',
          options: cities,
          optionLabelKey: 'name',
          optionToValue: (option: City) => option,
          valueToOption: (value: City, options: City[]) =>
            search?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && search?.length > 0 && options.length > 0
              ? options.find((option: City) => option.id === value.id)?.name
              : search,
          helperText: 'El campo Ciudad es requerido',
          getOptionSelected: (option: City, value: City) => option.id === value?.id,
          onInputChange: onInputChangeCity,
          inputLabelProps: { shrink: true },
          loading,
          enabledOpenAutocomplete: search.length > 1,
        },
      },
      {
        key: 'guarantorInfo.zipCode',
        lg: 2,
        md: 6,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `guarantorInfo.zipCode`,
          label: 'Cód. postal',
          required: true,
          id: 'guarantorInfoZipCode',
          autoComplete: 'postal-code',
          validator: validateZipCode,
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'guarantorInfo.street',
        lg: 6,
        md: 5,
        xs: 8,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `guarantorInfo.street`,
          label: 'Calle',
          required: true,
          id: 'guarantorInfoStreet',
          autoComplete: 'street-address',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'guarantorInfo.streetNumber',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `guarantorInfo.streetNumber`,
          label: 'Altura',
          lg: 2,
          md: 2,
          xs: 4,
          required: true,
          id: 'guarantorInfoStreetNumber',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'guarantorInfo.floor',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `guarantorInfo.floor`,
          label: 'Piso',
          required: true,
          id: 'guarantorInfoFloor',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'guarantorInfo.apartament',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `guarantorInfo.apartament`,
          label: 'Dpto.',
          required: true,
          id: 'guarantorInfoApartament',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'guarantorInfo.img_dni_front',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.UPLOAD_FILE,
        componentProps: {
          form,
          accept: 'image/png,image/jpeg,application/pdf,application/msword',
          name: `guarantorInfo.img_dni_front`,
          label: 'DNI frente',
          id: 'Img_dni_front',
        },
      },
      {
        key: 'guarantorInfo.img_dni_back',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.UPLOAD_FILE,
        componentProps: {
          form,
          accept: 'image/png,image/jpeg,application/pdf,application/msword',
          name: `guarantorInfo.img_dni_back`,
          label: 'DNI atras',
          id: 'img_dni_back',
        },
      },
      {
        key: 'guarantorInfo.img_paycheck',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.UPLOAD_FILE,
        componentProps: {
          form,
          accept: 'image/png,image/jpeg,application/pdf,application/msword',
          name: `guarantorInfo.img_paycheck`,
          label: 'Recibo de sueldo',
          id: 'img_paycheck',
        },
      },
      {
        key: 'guarantorInfo.img_service_certificate',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        component: FORM_FIELD_TYPE.UPLOAD_FILE,
        componentProps: {
          form,
          accept: 'image/png,image/jpeg,application/pdf,application/msword',
          name: `guarantorInfo.img_service_certificate`,
          label: 'Servicio',
          id: 'img_service_certificate',
        },
      },
    ],
    [cities, form, isCapresca, loading, loadingProvinces, onInputChangeCity, provinces, search, selectedProvince],
  );
  return { fields: data.filter((field) => !field.hidden) };
};
