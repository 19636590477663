import { Template } from 'src/lib/templates';
import { accountInfoTemplate } from './accountInfoTemplate';

export const registerTemplate: Template = {
  name: 'Register Form',
  template: [
    {
      name: 'termsAndConditions',
      type: { name: 'boolean' },
      label: 'Términos y condiciones',
      validators: [
        {
          name: 'valid',
          signature: [true],
        },
      ],
    },
    accountInfoTemplate,
  ],
};
