import { TemplateProperty } from '../../../../lib/templates';
import { personalTemplate, cuilTemplate, termsAndConditionTemplate } from './common';

export const personalInfoCaprescaTemplate: TemplateProperty = {
  name: 'personalInfo',
  type: {
    name: 'object',
    kind: [...personalTemplate, cuilTemplate, termsAndConditionTemplate],
  },
};
