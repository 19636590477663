import React from 'react';
import { Grid, Typography, Box, Card } from '@material-ui/core';
import { BuyerAddressInfo } from 'src/app/models';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';

export const getRemovalAddressContent = (address: BuyerAddressInfo) => (
  <Box width="100%" justifyItems="center" padding={2} component={Card} minHeight={149} borderRadius={8} boxShadow={3} display="content">
    <Box component="span" display="flex" flexDirection="row" width="100%">
      <Grid item xs={12} container direction="column" spacing={1}>
        <Grid item container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              {`${address.street} ${address.number}`}
            </Typography>
          </Grid>
          <Grid item>
            {address.is_selected && (
              <Box
                display="flex"
                bgcolor="primary.border"
                borderRadius="4px"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding="2px 8px"
              >
                <LocalShippingOutlinedIcon color="primary" />
                <Typography variant="caption" color="primary">
                  Envío
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {`${address.state.name} / ${address.city.name} , ${address.zip}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {`Piso: ${address.floor} / Dpto: ${address.department}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {address.comments}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Box>
);
