import { Box, Button, Grid, TextField } from '@material-ui/core';
import { Autocomplete, LoadScript } from '@react-google-maps/api';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { stylesRetirementPoints as useStyles } from './styles';
import { TablePoints } from './components/TablePoints';
import { useHOP } from './hooks';
import { GOOGLE_MAPS_API_KEY } from '../../../../../config';
import { HOP, HOPPoints } from '../../../../../app';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';
import { useSavingHOPPoints } from './hooks/useSavingHOPPoints';
import { findingPlace } from '../../utils';

export const RetirementPoints = () => {
  const classes = useStyles();
  const { useMakeDown } = useBreakpointsState();
  const sm = useMakeDown('sm');
  const { points, loading, handlePoints, getLocation, address, location } = useHOP();
  const { savingHOPData, values, form } = useSavingHOPPoints();
  const savedPoints = values.retirementPoints?.retirementPoints;
  const direction: string = values.retirementPoints?.address;
  const [data, setData] = useState<HOP[]>(savedPoints?.length > 0 ? savedPoints : points);
  const [explicitAddress, setExplicitAddress] = useState('');
  const [deleteAddress, setDeleteAddress] = useState(false);
  const [coords, setCoords] = useState<HOPPoints>();
  const [coordsExist, setCoordsExist] = useState<boolean>(true);
  const addressFormated = useMemo(() => address?.results[0]?.formatted_address, [address]);
  const userAddress = addressFormated || explicitAddress;
  const restrictions = {
    country: 'AR',
  };
  const textInput: any = useRef();
  const autocomplete: any = useRef();

  // I had to do this because when you get the address with the button the label doesnt get active leading to a messy input.
  const activeLabel =
    textInput.current?.value !== ''
      ? {
          shrink: true,
        }
      : {};

  const showAllPoints = () => {
    if (location) {
      handlePoints(location, true);
    } else {
      handlePoints(coords, true);
    }
  };

  if (addressFormated && !deleteAddress) {
    textInput.current.value = addressFormated;
  }

  const cleanInput = () => {
    setDeleteAddress(true);
    textInput.current.value = '';
  };

  useEffect(() => {
    if (points?.length > 0) {
      setData(points);
    }
  }, [points]);

  const resetForm = () => {
    if (!direction) return;
    form.change('retirementPoints', undefined);
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Box marginBottom="2rem" width="100%">
        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
          <Box width="100%" display="flex" flexDirection={sm ? 'column' : 'row'} justifyContent="space-between">
            <Autocomplete
              className={sm ? classes.containerMobile : classes.containerDesktop}
              restrictions={restrictions}
              ref={autocomplete}
              onPlaceChanged={() => {
                const place = autocomplete.current;
                const placeInfo = findingPlace(place.state.autocomplete.gm_accessors_.place, 'place');
                const latitude = placeInfo.place.geometry?.location?.lat();
                const longitude = placeInfo.place.geometry?.location?.lng();
                const directionGoogleAPI: string = placeInfo.formattedPrediction;
                setCoords({ lat: latitude, lng: longitude });
                setCoordsExist(!!latitude);
                handlePoints({ lat: latitude, lng: longitude });
                setExplicitAddress(directionGoogleAPI);
              }}
            >
              <TextField
                className={sm ? classes.inputUbicationMobile : classes.inputUbicationDesktop}
                variant="standard"
                required
                label="dirección de referencia"
                inputRef={textInput}
                onClick={() => cleanInput()}
                defaultValue={direction || userAddress}
                onChange={resetForm}
                InputLabelProps={activeLabel}
              />
            </Autocomplete>
            <Button
              className={sm ? classes.buttonMobile : classes.buttonDesktop}
              variant="contained"
              color="primary"
              onClick={() => {
                getLocation();
                setDeleteAddress(false);
                resetForm();
              }}
            >
              Obtener mi ubicación
            </Button>
          </Box>
        </LoadScript>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" alignItems="center">
        <TablePoints rows={data} loading={loading} savingHOPData={savingHOPData} address={userAddress} coordsExist={coordsExist} />
        {data.length > 0 && (
          <Button className={classes.buttonMore} variant="contained" color="primary" onClick={() => showAllPoints()}>
            Ver más
          </Button>
        )}
      </Box>
    </Grid>
  );
};
