import React from 'react';
import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { IMAGE_BASE_URL } from 'src/config';
import { IMAGE_POSITION } from 'src/app/const/ImgPosition';
import { productDetailsInfoStyles } from '../../styles';


interface ProductDetailsInfoType {
  title: string;
  description: string;
  image: string;
  icon: {name: string, isValid: string};
  imgPosition: IMAGE_POSITION;
  sku: string;
}

export const ProductDetailsInfo = ({ title, description, image, icon, imgPosition, sku }: ProductDetailsInfoType) => {
  const classes = productDetailsInfoStyles();
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isDown380 = useMediaQuery<Theme>((theme) => theme.breakpoints.down(380));
  const isUp815 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(815));
  const columns = imgPosition === IMAGE_POSITION.LEFT || imgPosition === IMAGE_POSITION.RIGHT ? 6 : 12;

  return (
    <>
      <Grid direction="row" className={isSm ? classes.rootCenter : imgPosition === IMAGE_POSITION.CENTER ? classes.rootCenter : classes.root}>
        {imgPosition === IMAGE_POSITION.LEFT && (
          <>
            <Grid xs={12} md={columns} className={isSm ? classes.isImgLeft : ''}>
              <img className={classes.ImgJustifyEnd} alt={title} src={`${IMAGE_BASE_URL}/f_auto/Products/${sku}/${image}-img.png`} />
            </Grid>
          </>
        )}
        <Grid
          xs={12}
          md={columns}
          className={
            isSm
              ? isDown380
                ? classes.InfoRootXs
                : classes.InfoRootRight
              : imgPosition === IMAGE_POSITION.CENTER
              ? classes.infoRootCenter
              : imgPosition === IMAGE_POSITION.LEFT
              ? classes.InfoRootLeft
              : classes.InfoRootRight
          }
        >
          <Grid className={imgPosition === IMAGE_POSITION.LEFT && isUp815 ? classes.innerRoot90 : isSm ? classes.innerRoot100Sm : classes.innerRoot100}>
            <Grid
              className={
                isSm
                  ? classes.iconRoot
                  : imgPosition === IMAGE_POSITION.CENTER
                  ? classes.iconRootCenter
                  : imgPosition === IMAGE_POSITION.LEFT
                  ? classes.iconRoot
                  : classes.iconRoot
              }
            >
              {icon.isValid === 'true' &&
                <img className={classes.icon} alt="icon" src={`${IMAGE_BASE_URL}/f_auto/Products/${sku}/${icon.name}-icon.png`} />
              }
            </Grid>
            <Typography
              variant={isSm ? 'h5' : 'h2'}
              color="textPrimary"
              className={
                isSm
                  ? classes.titleRoot
                  : imgPosition === IMAGE_POSITION.CENTER
                  ? classes.titleRootCenter
                  : imgPosition === IMAGE_POSITION.LEFT
                  ? classes.titleRootRigth
                  : classes.titleRootLeft
              }
            >
              {title}
            </Typography>
            <Grid
              className={
                isSm
                  ? classes.descriptionSm
                  : imgPosition === IMAGE_POSITION.CENTER
                  ? classes.descriptionRootCenter
                  : imgPosition === IMAGE_POSITION.LEFT
                  ? classes.descriptionRootLeft
                  : classes.descriptionRootRight
              }
            >
              <Typography
                color="textSecondary"
                variant="body1"
                className={
                  isSm
                    ? isDown380
                      ? classes.descriptionXs
                      : classes.descriptionSm
                    : imgPosition === IMAGE_POSITION.CENTER
                    ? classes.descriptionCenter
                    : classes.description
                }
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={columns} className={isSm ? '' : imgPosition === IMAGE_POSITION.LEFT ? classes.hidden : ''}>
          <img
            className={
              imgPosition === IMAGE_POSITION.CENTER ? classes.imgCenter : imgPosition === IMAGE_POSITION.LEFT ? classes.imgRight : classes.imgLeft
            }
            alt={title}
            src={`${IMAGE_BASE_URL}/f_auto/Products/${sku}/${image}-img.jpg`}
          />
        </Grid>
      </Grid>
    </>
  );
};

