import { makeStyles } from '@material-ui/core';
import { APP_ECOMMERCE } from 'src/config';

export const labelRadioGroupStyles = makeStyles(() => ({
  radioGroupLabel: {
    height: APP_ECOMMERCE === 'detecnologia' ? '10px' : '12px',
    position: 'absolute',
    display: 'inline-block',
    fontSize: APP_ECOMMERCE === 'detecnologia' ? '10px' : '12px',
  },
}));
