import { FacebookLogin } from 'src/screens/Public/Facebook/FacebookLogin';
import { MenuItem } from '../../../../commons/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'publicFacebookCallbackMain',
    basePath: '/login',
    target: {
      component: FacebookLogin,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Facebook login',
    children: [],
    default: true,
  },
];
