import { EcommerceDAO } from './EcommerceDAO';
import { FileUpload } from '../../../app/models';

export class FileUploadDAO extends EcommerceDAO<FileUpload> {
  constructor() {
    super('fileUpload', 'fileUploadId');
  }

  async uploadFile(file: File): Promise<FileUpload[]> {
    const formData = new FormData();
    formData.append('file', file);
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/file/upload`, { method: 'post', body: formData });
    return response.json();
  }

  async uploadMultipleFile(files: File[]): Promise<FileUpload[]> {
    const formData = new FormData();
    files.forEach((item) => formData.append('file', item));
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/file/upload`, { method: 'post', body: formData });
    return response.json();
  }
}
