import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTemplateContext, useFormContext, Template } from 'src/lib/templates';
import { AnyObject, StepFormRecord } from 'src/commons';

export const useStepTemplateLogic = (activeStep: number, completedSteps: Set<number>, steps: StepFormRecord[]) => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  /* eslint-disable */
  const { form, values, errors } = useFormContext<AnyObject>();
  const { setTemplate } = useTemplateContext();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const ObjectSteps = useMemo(
    () =>
      steps.reduce<{ [k: number]: StepFormRecord }>((accum, step, index) => {
        accum[index] = step;
        return accum;
      }, {}),
    [steps],
  );

  const cleanerForm = useCallback((template: Template) => {
    let changeTemplate = { ...template };

    if (template.template.find((property) => property.name === template.name)) {
      changeTemplate = {
        ...template,
        template: template.template.filter((property) => property.name !== template.name),
      };
      form.change(template.name, undefined);
    }
    // TODO : ver como blanquear extras
    // form.change('useDeliveryAddress', true);

    return changeTemplate;
  }, []);

  const setterTemplate = useCallback(
    (step: StepFormRecord, extra?: (template: Template) => Template) => {
      if (currentStep > activeStep && !completedSteps.has(currentStep)) {
        setTemplate((template) => {
          let newTemplate = { ...template };
          if (!template.template.find((property) => property.name === steps[currentStep].template.name)) {
            return newTemplate;
          }
          form.change(steps[currentStep].template.name, undefined);
          if (extra) newTemplate = { ...extra(newTemplate) };

          return {
            ...template,
            template: newTemplate.template.filter((property) => property.name !== steps[currentStep].template.name),
          };
        });
        setCurrentStep(activeStep);
      }
      setTemplate((template) => {
        if (template.template.find((property) => property.name === step.template.name)) {
          return template;
        }
        form.change(step.template.name, step.initialValue);
        return {
          ...template,
          template: [...template.template, step.template],
        };
      });
    },
    [activeStep, currentStep, completedSteps, form, setTemplate],
  );

  useEffect(() => {
    if (currentStep < activeStep) {
      setCurrentStep(activeStep);
    }
    const stepValue = ObjectSteps[activeStep];
    setterTemplate(stepValue, cleanerForm);
  }, [setTemplate, completedSteps, currentStep, activeStep, form, steps]);
};
