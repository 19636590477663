import { Grid, Box, Typography } from '@material-ui/core';
import React from 'react';
import { FailedCheck } from 'src/assets/images/svgs/FailedCheck';

export const HOPErrorMessage = () => (
  <Grid container>
    <Grid item xs={12}>
      <Box marginTop="30%" marginBottom="30%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <FailedCheck width="40px" height="40px" color="crimson" />
        <Typography color="error" variant="subtitle1" align="center" style={{ margin: '1em 0' }}>
          Lo sentimos,no existen puntos de retiro cerca de la ubicación ingresada.
        </Typography>
        <Typography color="error" variant="subtitle2">
          Intenta probar con otra dirección!
        </Typography>
      </Box>
    </Grid>
  </Grid>
);
