import { makeStyles } from '@material-ui/core/styles';

export const cartMenuStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    marginBottom: '25px',
    marginLeft: '20px',
    borderRadius: '50%',
    background: '#F4DCFD',
    width: '22px',
    height: '22px',
  },
  number: {
    marginLeft: '1px',
    marginTop: '3px',
    // fontFamily: 'Circular Std',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#50106B',
  },
}));
