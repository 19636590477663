import React, { useMemo } from 'react';
import { usePublicationByZone } from './usePublicationByZone';
import { LibbyCallReturn } from '../../../lib/libby/hooks';
import { Publication } from '../../models';
import { useZoneContext } from '../Zone';

export interface PublicationByZoneProps {
  children: (response: LibbyCallReturn<Publication[]>, title: string) => JSX.Element | JSX.Element[];
  zone: string;
}

export const PublicationByZone = ({ children, zone }: PublicationByZoneProps) => {
  const response = usePublicationByZone(zone);
  const { data } = useZoneContext();

  const _zone = useMemo(() => data.find((z) => z.zone_id === zone), [zone, data]);

  return <>{children(response, _zone?.name || '')}</>;
};
