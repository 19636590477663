import { TemplateProperty } from '../../../../lib/templates';
import { personalTemplate, dniTemplate, termsAndConditionTemplate, cuilTemplate } from './common';

export const personalInfoTemplate: TemplateProperty = {
  name: 'personalInfo',
  type: {
    name: 'object',
    kind: [...personalTemplate, dniTemplate, termsAndConditionTemplate],
  },
};

export const makePersonalTemplate = ({ isCapresca }: any) => ({
  name: 'personalInfo',
  type: {
    name: 'object',
    kind: [...personalTemplate, isCapresca ? cuilTemplate : dniTemplate, termsAndConditionTemplate],
  },
});
