import { useCallback } from 'react';
import { SessionChangeEmitter, userGuest, validateUser } from 'src/platform/libby/session';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { LoginStatusResponse, SingIn, OAuthProvider } from 'src/app/models';
import { useAccountDAO } from 'src/app/business';

interface SendLoginProps {
  redirectOnSuccess?: string;
  msgOnSuccess?: string;
  redirectOnFailure?: string;
}

export const useLogin = (props?: SendLoginProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const accounDAO = useAccountDAO();

  const { redirectOnSuccess = '', msgOnSuccess = '', redirectOnFailure = '' } = props || {};

  const singIn = useCallback(
    async (values: SingIn) => {
      let Session: LoginStatusResponse = { isGuest: false, user: userGuest };
      try {
        const to_loging: SingIn = {
          provider: 'user',
          token_id: values.token_id,
          email: values.email,
          password: values.password,
          username: values.email,
        };
        switch (values.provider) {
          case OAuthProvider.GOOGLE:
            to_loging.provider = OAuthProvider.GOOGLE;
            to_loging.token_id = values.token_id;
            break;
          case OAuthProvider.FACEBOOK:
            to_loging.provider = OAuthProvider.FACEBOOK;
            to_loging.token_id = values.token_id;
            break;
          default:
            break;
        }
        if ((values.provider === OAuthProvider.GOOGLE || values.provider === OAuthProvider.FACEBOOK) && !to_loging.token_id) return;
        Session = await accounDAO.login(to_loging);
        if (Session.isGuest || !validateUser(Session.user)) {
          enqueueSnackbar('Usuario o clave incorrectos', {
            variant: 'error',
          });
          if (redirectOnFailure !== '') history.push(redirectOnFailure || '/');
        } else {
          enqueueSnackbar(msgOnSuccess || `Bienvenido ${Session?.user?.name || ''}`, {
            variant: 'success',
          });
          history.push(redirectOnSuccess || '/');
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Usuario o clave incorrectos', {
          variant: 'error',
        });
        if (redirectOnFailure !== '') history.push(redirectOnFailure || '/');
      } finally {
        const { user } = Session;
        const isLogged: boolean = !!user && user.isGuest === false;
        SessionChangeEmitter.emitChange({ isLogged, user });
      }
    },
    [history, accounDAO, redirectOnSuccess, msgOnSuccess, redirectOnFailure, enqueueSnackbar],
  );

  return { singIn };
};
