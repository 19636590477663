import React, { useMemo, createContext, useContext } from 'react';
import { useConfigurationLibbyCall } from 'src/app/business';
import { Configuration } from 'src/app/models';

export interface ConfigurationContextValue {
  configurations: Array<Configuration>;
}

const ConfigurationsContext = createContext<ConfigurationContextValue>({ configurations: [] });

interface ConfigurationsContextProviderProps {
  children: JSX.Element;
}

export const ConfigurationsContextProvider = ({ children }: ConfigurationsContextProviderProps) => {
  const { data: configurations = [] } = useConfigurationLibbyCall<Configuration[]>({
    methodName: 'getData',
  });

  const contextValue = useMemo(() => ({ configurations }), [configurations]);

  return <ConfigurationsContext.Provider value={contextValue}>{children}</ConfigurationsContext.Provider>;
};

export const useConfigurationsContext = () => useContext(ConfigurationsContext);
