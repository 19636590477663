import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Loading } from '../../../../../../commons';
import { useCartContext, useGeneralContext } from '../../../../../../context';
import { useMercadopagoPayment, useMountMercadopagoPaymentCallback } from '../hooks';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { usePaymentDAO } from '../../../../../../app/business';
import { CartItem, Payment } from '../../../../../../app/models';
import { Decidir } from '../../../../../../app/services';
import { createOrder, createPaymentMercadoPago } from '../../../utils';
import { checkoutAllValues } from '../../../initialValues';
import { useMultiStepFormContext } from '../../../../../../lib/MultiStepForm';

export const CardInfoFormMercadoPago = () => {
  const { values } = useCheckoutFormContext();
  const { cartState } = useCartContext();
  const { dispatch } = useGeneralContext();
  const paymentDAO = usePaymentDAO();
  const [requesting, setRequesting] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState('');
  const { setActiveStep } = useMultiStepFormContext();
  const mountMercadopago = useMountMercadopagoPaymentCallback();
  const triggerMercadopago = useMercadopagoPayment();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  useEffect(() => {
    if (valuesForm && cartState && !requesting) {
      setRequesting(true);
      (async () => {
        const { items, owner, lastUpdate = new Date(), state_id } = cartState;
        const itemsFix = items.map<CartItem>(({ quantity, publication }) => ({
          quantity,
          publication,
        }));
        let ipAddress = '';
        try {
          ipAddress = await Decidir.getIp();
        } catch (error) {
          console.log('Error opteniendo la ip');
        }
        const order = await createOrder(
          { items: itemsFix, owner, lastUpdate, state_id, email: valuesForm.personalInfo.email, send: false },
          valuesForm,
          valuesForm.equifaxForm.validClient,
        );
        const paymentBody = createPaymentMercadoPago(order, valuesForm.paymentMethod.payment, { ip_address: ipAddress, 
          payment_method: { 
          card_id: valuesForm.paymentMethod.card.card_id,
          card_name:valuesForm.paymentMethod.card.name,
          card_emiter_id:valuesForm.paymentMethod.emitter.card_emiter_id,
          emitter_name: valuesForm.paymentMethod.emitter.name,
          installments: valuesForm.paymentMethod.payment.installments

          }  }, values.coupon);

        const resultPayment: Payment = await paymentDAO.paymentMercadoPago(paymentBody);

        if (resultPayment?.metadata?.mercadopago?.id) {
          mountMercadopago(resultPayment?.metadata?.mercadopago?.id);
          triggerMercadopago();
          setTimeout(() => {
            setShowButton(true);
            setError('');
          }, 2000);
        } else if (resultPayment?.metadata?.result === 'NO_STOCK') {
          setError('Algun producto del carrito no tiene stock, por favor revise su orden');
          setShowButton(true);
        } else {
          console.log(resultPayment?.metadata);
          setError('Ocurrió un problema. Por favor pongase en contacto con nosotros');
          setShowButton(true);
        }
      })();
    }
  }, [cartState, mountMercadopago, paymentDAO, requesting, triggerMercadopago, values.coupon, valuesForm]);

  const onPay = useCallback(() => {
    if (dispatch.setIsBlocking) dispatch.setIsBlocking(false);
    triggerMercadopago();
  }, [triggerMercadopago, dispatch]);

  const onBackStep = useCallback(() => setActiveStep((oldStep) => oldStep - 1), [setActiveStep]);

  return (
    <Grid container direction="column" spacing={1}>
      {!!error && (
        <Grid item>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      {showButton ? (
        <Grid item>
          <Grid container spacing={1} justify="center">
            <Grid item>
              <Button variant="outlined" onClick={onBackStep}>
                Volver
              </Button>
            </Grid>
            {!error && (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={onPay}>
                  Intentar Pagar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </Grid>
  );
};
