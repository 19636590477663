import { useCallback, useEffect, useState } from 'react';
import { Decidir } from '../../../../app/services';
import { usePaymentDAO } from '../../../../app/business';
import { useConfigurationsContext } from '../../../../context/Configuration';
import { CONFIGURATION_ID } from '../../../../app/models';
import { useTemplateContext } from '../../../../lib/templates';

export const useFormLogicReCaptcha = () => {
  const paymentDAO = usePaymentDAO();
  const { configurations } = useConfigurationsContext();
  const { setTemplate } = useTemplateContext();
  const [showReCAPTCHA, setShowReCAPTCHA] = useState<boolean>(false);

  const validationIpAddres = useCallback(async () => {
    try {
      const ipAddress = await Decidir.getIp();
      const result: boolean = await paymentDAO.paymentValidationIpAddress(ipAddress);
      return result;
    } catch (error) {
      console.log('Error al optener ip');
      return false;
    }
  }, [paymentDAO]);

  const isEnabled = useCallback(() => {
    const value = configurations.find((item) => item.general_config_id.toString() === CONFIGURATION_ID.RECAPTCHA.toString())?.value;
    return { enable: false, interval: 0, ...value };
  }, [configurations]);

  const onHandleVerification = useCallback(async () => {
    const { enable, interval } = isEnabled();
    if (!enable) return;
    if (interval !== 0) {
      if (!(await validationIpAddres())) setShowReCAPTCHA(true);
    } else {
      setShowReCAPTCHA(true);
    }
  }, [isEnabled, validationIpAddres]);

  useEffect(() => {
    onHandleVerification();
  }, [onHandleVerification]);

  useEffect(() => {
    if (!showReCAPTCHA) return;
    setTemplate((template) => {
      if (!template.template.find((property) => property.name === 'recaptcha')) {
        return template;
      }
      const [temp] = template.template.filter((property) => property.name === 'recaptcha');
      const filtered = template.template.filter((property) => property.name !== 'recaptcha');
      return { ...template, template: [...filtered, { ...temp, optional: false }] };
    });
  }, [showReCAPTCHA, setTemplate]);

  return { showReCAPTCHA };
};
