import { useEffect, useState } from 'react';
import { getPricePublicationCart } from '../../../../context/CartContext/utils';
import { checkoutAllValues } from '../initialValues';
import { Shipment, ShipmentData } from '../../../../app/models';
import { useCheckoutFormContext } from './CheckoutFormContext';
import { useShipmentDAO } from '../../../../app/business';
import { useCartContext } from '../../../../context';
import { useTemplateContext } from '../../../../lib/templates';
import { deliveryInfoTemplate } from '../templates';

export interface UbicationInfo {
  city_id: string;
  enabled: boolean;
  list: number[];
  matches: string[];
  name: string;
  state_id: string;
  zip: number;
}

export const useShipmentCostLogic = () => {
  const { form, values } = useCheckoutFormContext();
  const { cartState } = useCartContext();
  const shipmentDAO = useShipmentDAO();
  const { setTemplate } = useTemplateContext();

  const valuesForm = {
    ...checkoutAllValues,
    ...values,
  };
  const [province_id, setProvince_id] = useState('');
  const hop_province = valuesForm.retirementPoints?.selectedPoint?.shipment_hop_direction?.city;
  useEffect(() => {
    (async () => {
      if (valuesForm.delivered === 'HOP' && hop_province) {
        const resp: UbicationInfo = await shipmentDAO.getProvinceId(hop_province);
        const { state_id, matches, city_id, zip, name } = resp;
        setProvince_id(state_id);
        form.change('ubicationInfo', { id: city_id, state_id: +state_id, name, matches, zip });
      } else if (valuesForm.delivered === 'delivered') {
        setProvince_id(valuesForm.deliveryInfo.province.id);
      } else {
        setProvince_id('');
      }
      if (province_id !== '') {
        const totals = cartState.items.reduce<number>((accum, item) => {
          const price = getPricePublicationCart(item.publication, valuesForm.paymentMethod.payment);
          accum += +price * item.quantity;
          return accum;
        }, 0);
        const shipmentData: ShipmentData[] = cartState.items.map(({ quantity, publication }) => ({
          quantity,
          shipment_category_id: publication.shipment_category_id,
        }));
        const dataCost: Shipment = await shipmentDAO.calculate(province_id, totals, shipmentData);
        form.change('shipment_cost', dataCost.cost);
      }
    })();
  }, [
    form,
    valuesForm.paymentMethod.payment,
    shipmentDAO,
    cartState.items,
    valuesForm.deliveryInfo.province.id,
    hop_province,
    province_id,
    valuesForm.delivered,
  ]);

  useEffect(() => {
    (async () => {
      if (province_id === '') {
        form.change('shipment_cost', 0);
        setTemplate((template) => {
          if (!template.template.find((property) => property.name === deliveryInfoTemplate.name)) {
            form.change('shipment_cost', 0);
          }
          return template;
        });
      }
    })();
  }, [province_id, form, setTemplate, valuesForm.shipment_cost]);
};
