/* eslint-disable */
import React, { useCallback, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { AnyObject } from '../../../commons/types';
import { makeEntityLibbyCall } from '../hooks';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 5,
    },
  },
}));

export type OptionLabelKeyFunction = <T>(option: T) => string | JSX.Element;

export interface WithEntityAutocompleteProps<T> {
  autoCompleteProps?: any;
  multiple?: boolean;
  value: undefined | T | T[];
  onChange: (newValue: T | T[]) => void;
  filter?: AnyObject;
  textFieldProps?: AnyObject;
  optionLabelKey?: string | OptionLabelKeyFunction;
}

export interface WithEntityAutocompleteOptions<T> {
  optionLabelKey: string | OptionLabelKeyFunction;
}

type WithEntityAutocompleteReturn = <T>(
  daoName: string,
  options: WithEntityAutocompleteOptions<T>,
) => (props: WithEntityAutocompleteProps<T>) => JSX.Element;

const dummyFilter = {};
// TODO: add loading
export const withEntityAutocomplete: WithEntityAutocompleteReturn = (daoName: string, { optionLabelKey }) => {
  const useHook = makeEntityLibbyCall(daoName);

  return ({
    multiple = false,
    autoCompleteProps,
    value,
    onChange,
    filter = dummyFilter,
    textFieldProps = dummyFilter,
    optionLabelKey: optionLabelKeyProps,
  }) => {
    const optionLabelKeyToUse = optionLabelKeyProps || optionLabelKey;
    const classes = useStyles();

    // TODO: improve this
    const {
      working,
      data: options,
      recall,
    } = useHook({
      methodName: 'fetch',
      params: [],
      noAutoCall: true,
    });
    useEffect(() => {
      if (recall) {
        const params = { filter, limit: 20000 };
        recall(params);
      }
    }, [filter, recall]);

    const onChangeAutoComplete = useCallback(
      (event, newValue) => {
        onChange(newValue);
      },
      [onChange],
    );

    const getOptionLabel = useCallback(
      (option) => (typeof optionLabelKeyToUse !== 'string' ? optionLabelKeyToUse(option) : get(option, optionLabelKeyToUse)),
      [optionLabelKeyToUse],
    );
    return (
      <>
        {!working && options && (
          <Autocomplete
            classes={classes}
            multiple={multiple}
            options={options}
            onChange={onChangeAutoComplete}
            value={value || (multiple ? [] : undefined)}
            renderInput={(params) => <TextField {...params} variant="outlined" {...textFieldProps} />}
            getOptionLabel={getOptionLabel}
            {...(autoCompleteProps || {})}
          />
        )}
      </>
    );
  };
};
