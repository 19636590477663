import { IFieldComponent } from '../../FieldComponent';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';

export const useLoginFields = () => {
  const { form } = useCheckoutFormContext();
  const data: IFieldComponent[] = [
    {
      xs: 12,
      component: FORM_FIELD_TYPE.INPUT,
      componentProps: {
        variant: 'standard',
        name: `loginInfo.email`,
        label: 'Email',
        required: true,
        id: 'loginInfoEmail',
        autoComplete: 'email',
        type: 'email',
        inputLabelProps: { shrink: true },
        form,
      },
    },
    {
      xs: 12,
      component: FORM_FIELD_TYPE.INPUT,
      componentProps: {
        variant: 'standard',
        name: `loginInfo.password`,
        label: 'Clave',
        required: true,
        id: 'loginInfoPassword',
        autoComplete: 'password',
        inputLabelProps: { shrink: true },
        type: 'password',
        form,
      },
    },
  ];
  return { fields: data };
};
