import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { Target } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'targetpromotion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTarget',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTarget',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Target
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Target,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Target.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Target>(DAO_NAME, optionsToUse);

// hooks
export const useTargetFetchById = fetchById;
export const useTargetLibbyCall = libbyCall;
export const useTargetDAO = libbyDAO;
export const useTargetLibbyFetch = libbyFetch;

// components/hoc
export const TargetAutocomplete = entityAutocomplete;
export const TargetCall = renderPropCall;
export const TargetById = renderPropFetchById;
export const TargetSelect = simpleSelect;
export const TargetAccordionSelector = accordionSelector;
export const TargetSelectedChips = selectedChips;

// context
// ByIdContext
export const useTargetByIdContext = useByIdContext;
export const TargetByIdProvider = ByIdProvider;
export const TargetByIdContext = ByIdContext;
// GeneralContext
// export const useTargetContext = useGeneralContext;
// export const TargetProvider = GeneralProvider;
// export const TargetContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
