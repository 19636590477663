import React, { FunctionComponent, useMemo } from 'react';
import { Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TabBarContext, useTabBarSetValue, useTabBarValue } from 'src/commons/components/TabBar';
import { grey, primary } from 'src/theme/colors';
import { Loading } from 'src/commons';
import { usePermissionContext } from 'src/lib/permission';

const tabBarStyles = makeStyles(() => ({
  container: {
    boxShadow: 'none',
    borderBottom: '1px solid lightGrey',
  },
  activeTab: {
    height: '10px',
    color: primary.lightBlue,
    textTransform: 'none',
    fontSize: 14,
  },
  defaultTab: {
    height: '10px',
    color: grey.medium,
    textTransform: 'none',
    fontSize: 14,
  },
  indicator: {
    backgroundColor: primary.lightBlue,
  },
  link: {
    textDecoration: 'none',
  },
  loading: {
    position: 'fixed',
    background: 'rgba(0, 0, 0, .15)',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 999,
  },
}));

export interface Content {
  id?: number;
  title: string;
  component: React.ReactNode;
}

interface NavBarProps {
  content: Array<Content>;
  loading?: boolean;
  customStyles?: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ children, index, value }) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
    {value === index && <Grid>{children}</Grid>}
  </div>
);

const TabBarComp: FunctionComponent<NavBarProps> = ({ content, loading, customStyles = {} }) => {
  const { activeRules } = usePermissionContext();
  const setTabBarValue = useTabBarSetValue();
  const { tabIndex } = useTabBarValue();
  const handleChange = (_: React.ChangeEvent<any>, newValue: number) => {
    setTabBarValue(newValue);
  };
  const classes = tabBarStyles();
  const getStyle = (index: number) => (index === tabIndex ? classes.activeTab : classes.defaultTab);

  const filteredContent = useMemo(
    () =>
      content.filter((item) => {
        if (!item.id) return true;

        return !activeRules.tabBars.some((tab) => tab.id === item.id);
      }),
    [activeRules.tabBars, content],
  );

  return (
    <div>
      <div className={customStyles}>
        {loading && <Loading className={classes.loading} />}
        <Paper className={classes.container}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            classes={{ indicator: classes.indicator }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="auto-scroll-tabs"
          >
            {filteredContent.map((item, index) => {
              const typography = (
                <Typography>
                  <span className={getStyle(index)}>{item.title}</span>
                </Typography>
              );
              return <Tab key={`tab-${item.id}`} label={typography} />;
            })}
          </Tabs>
        </Paper>
        {filteredContent.map((item, index) => (
          <TabPanel value={tabIndex} index={index} key={item.id}>
            {item.component}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export const TabBar: FunctionComponent<NavBarProps> = ({ content, loading }) => (
  <TabBarContext>
    <TabBarComp loading={loading} content={content} />
  </TabBarContext>
);
