import { EcommerceLogo } from '../../../app/models/general/ecommerceLogo';
import { MainLogo } from './components/MainLogo';
import { ShortLogo } from './components/ShortLogo';
import { NotFoundPage } from './components/NotFoundPage';
import { EmpyCart } from './components/EmpyCart';
import { ApprovedPayment } from './components/ApprovedPayment';
import { FailurePayment } from './components/failurePayment';

export const ecommerceLogo: EcommerceLogo = {
  mainLogo: MainLogo,
  shortLogo: ShortLogo,
  notFoundPage: NotFoundPage,
  empyCart: EmpyCart,
  approvedPayment: ApprovedPayment,
  failurePayment: FailurePayment
};
