import React, { useMemo } from 'react';
import { BannerInputView } from 'src/commons/ui/view/BannerInput';
import { SkeletonComponentProps, SOURCE } from 'src/app/models/Site';
import { Loading } from 'src/commons';

interface DataInput {
  title: string;
}

export const SkeletonSubcription = ({ data }: SkeletonComponentProps) => {
  const { data: publication, working } = SOURCE.PUBLICATION(data.source_id);

  const dataInput = useMemo(() => publication?.[0]?.config_values as DataInput, [publication]);

  return working ? <Loading /> : <BannerInputView title={dataInput?.title || ''} />;
};
