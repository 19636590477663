import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { createOrder, createPaymentDecidir } from '../utils';
import { CartItem, ResponseDecidir, Payment, StatusPayment, PAYMENT_STATUS, ValidPayment } from '../../../../app/models';
import { useCartContext } from '../../../../context';
import { usePaymentDAO } from '../../../../app/business';
import { Decidir, TokenBody, TokenByTokenCard } from '../../../../app/services';
import { checkoutAllValues, ICheckoutAll } from '../initialValues';
import { useCheckoutFormContext } from './CheckoutFormContext';

let nCall = 0;

export const usePaymentProcessing = () => {
  const { cartState } = useCartContext();
  const paymentDAO = usePaymentDAO();
  const { values: valuesForm } = useCheckoutFormContext();
  const _valuesForm = useMemo(() => ({ ...checkoutAllValues, ...valuesForm }), [valuesForm]);

  const checkStatusPayment = useCallback(
    async (id: string, cb: (status: StatusPayment) => void) => {
      const _status: StatusPayment = await paymentDAO.checkPayment(id);
      if (_status.payment_state_id !== PAYMENT_STATUS.PENDING || nCall === 3) {
        nCall = 0;
        cb(_status);
      } else {
        nCall += 1;
        setTimeout(checkStatusPayment, 10000, id, cb);
      }
    },
    [paymentDAO],
  );

  const getPayment = useCallback(
    async (values: ICheckoutAll, deviceId: string): Promise<ValidPayment> => {
      try {
        const {
          deliveryInfo,
          useDeliveryAddress,
          cardInfo: { cardExpiration, cardHolder, cardNumber, cardSecurityCode },
          personalInfo: { document, dateOfBirty, email },
          isResponsable,
          paymentMethod: { payment, emitter, card },
          responsableInscripto = { cuit: '' },
          summaryAddress,
          tokenCardInfo,
          coupon,
        } = values;
        const { cuit } = responsableInscripto;

        const holder_door_number = useDeliveryAddress ? deliveryInfo?.streetNumber : summaryAddress?.streetNumber;

        /*     const wholeDecimal = Math.round(Number(spliceText(moment(cardExpiration, 'MM/YY').month().toString() || '', 2, 0, '.')) || 0);
        const parseDecimal = wholeDecimal > 0 ? (wholeDecimal < 10 ? wholeDecimal.toString().padStart(2, '0') : wholeDecimal.toString()) : ''; */

        const { items, owner, lastUpdate = new Date(), state_id } = cartState;
        const itemsFix: CartItem[] = items.map(({ quantity, publication }) => ({
          quantity,
          publication,
        }));
        const order = await createOrder(
          { items: itemsFix, owner, lastUpdate, state_id, email, send: false },
          values,
          _valuesForm.equifaxForm.validClient,
        );

        const ipAddress = await Decidir.getIp();

        const paymentValidBody = createPaymentDecidir(
          order,
          payment,
          {
            bin: cardNumber.slice(0, 6),
            emiter: emitter.card_emiter_id,
            ip_address: ipAddress,
          } as any,
          coupon,
        );

        const resultPaymentValid = await paymentDAO.paymentValidDecidir(paymentValidBody);

        if (!resultPaymentValid.valid) {
          return resultPaymentValid;
        }

        const body: TokenBody = {
          card_number: cardNumber,
          card_expiration_month: cardExpiration.split('/')[0],
          card_expiration_year: cardExpiration.split('/')[1],
          security_code: cardSecurityCode,
          card_holder_name: cardHolder,
          card_holder_door_number: Number(holder_door_number) || 0,
          card_holder_birthday: moment(dateOfBirty).format('DDMMYYYY'),
          card_holder_identification: {
            type: isResponsable === 'true' ? 'cuit' : 'dni',
            number: isResponsable === 'true' ? cuit : document,
          },
          fraud_detection: {
            device_unique_identifier: deviceId,
          },
          ip_address: ipAddress,
        };

        const reqBody: TokenByTokenCard = {
          token: tokenCardInfo?.token || '',
          fraud_detection: {
            device_unique_identifier: deviceId,
          },
          ip_address: ipAddress,
        };

        const result: ResponseDecidir =
          tokenCardInfo?.token && tokenCardInfo?.token !== '' ? await Decidir.getTokenByTokenCard(reqBody) : await Decidir.getToken(body);

        const paymentBody = createPaymentDecidir(order, payment, result, coupon);

        paymentBody.metadata.ip_address = ipAddress;
        paymentBody.metadata.device_unique_identifier = deviceId;
        paymentBody.metadata.emiter = emitter.card_emiter_id;
        paymentBody.metadata.payment_method = {
        card_emiter_id: emitter.card_emiter_id,
        card_id: card.card_id,
        card_name: card.name,
        emitter_name: emitter.name,
        installments: payment.installments
        }
        

        const resultPayment: Payment = await paymentDAO.paymentDecidir(paymentBody);

        return resultPayment;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    [cartState, _valuesForm.equifaxForm.validClient, paymentDAO],
  );

  return { getPayment, checkStatusPayment };
};
