import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const productsMenu: MenuItem = {
  key: 'privateProducts',
  basePath: '/product/:publicationId',
  enabled: true,
  icon: 'List',
  text: 'Product',
  children: routes,
};
