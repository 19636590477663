import { FilterList, FILTER_TYPE } from 'src/commons/ui/view/GenericFilter';

export const fixedNameCategory = (items: FilterList[]) =>
  items.map<FilterList>((item) => {
    const splitedName = item.name.split('.');
    if (item.type === FILTER_TYPE.CATEGORY && splitedName.length > 1) {
      return { ...item, name: splitedName[1] };
    }
    return item;
  });
