import { RegisterSuccess } from 'src/screens/Public/Register/components/RegisterSuccess';
import { MenuItem } from '../../../../commons/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'publicRegisterSuccessMain',
    basePath: '/success',
    target: {
      component: RegisterSuccess,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Registro exitoso',
    children: [],
    default: true,
  },
];
