export const optionsInvoice = [
  { id: 'false', label: 'Consumidor final' },
  { id: 'true', label: 'Responsable inscripto' },
];

export const optionsGender = [
  { id: 'F', label: 'F' },
  { id: 'M', label: 'M' },
];

// Mantener el mismo nombre por comodidad acá y en las configuraciones de la base de datos.
export const optionsDelivery = [
  { id: 'delivered', label: 'Envio a domicilio' },
  { id: 'store_pickup', label: 'Retiro en casa central' },
  { id: 'HOP', label: 'Puntos de retiro' },
];

export interface DocentType {
  id: number;
  name: string;
}

export const optionsDocentType: Array<DocentType> = [
  {
    id: 1,
    name: 'Titular',
  },
  {
    id: 2,
    name: 'Interino',
  },
  {
    id: 3,
    name: 'Provisional',
  },
  {
    id: 4,
    name: 'Suplente',
  },
];

export enum CARD_PERSONAL_CREDIT {
  DOCENTES = 9,
  ALUMNOS,
}
