import { Promotions } from 'src/screens/Public/Promotions';
import { MenuItem } from '../../../../commons/ui/menu';
import { CyberWeek, HotsaleWeek } from '../../../../screens';

export const routes: MenuItem[] = [
  {
    key: 'publicPromotionsMain',
    basePath: '/',
    target: {
      component: Promotions,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions',
    children: [],
    default: true,
  },
  {
    key: 'publicPromotionsMain',
    basePath: '/cyber',
    target: {
      component: CyberWeek,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions',
    children: [],
    default: true,
  },
  {
    key: 'hotsalePromotion',
    basePath: '/hotsale',
    target: {
      component: HotsaleWeek,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Promotions',
    children: [],
    default: true,
  },
];
