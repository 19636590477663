import React from 'react';
import { useHistory } from 'react-router';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { ecommerceConfig } from '../../../../config/ecommerce';
import { NotFoundPageStyles as useStyles } from '../../styles';

export const NotFoundPageView = () => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();

  const { notFoundPage: NotFoundPage } = ecommerceConfig.ecommerceLogo

  const handleBackHome = () => {
    history.push('/');
  };

  return (
    <Grid container justify="center" className={classes.gridContainer}>
      <Box width={isDownSm ? '95%' : '80%'}>
        <Box textAlign="center">
          <ErrorOutlineOutlinedIcon className={classes.exclamationIcon} />
        </Box>
        <Typography className={classes.titleText} variant="h3" component="h3" align="center">
          Ocurrio un error!
        </Typography>
        <Box width="65%" margin="0 auto 60px auto">
          <Typography variant="body1" color="textSecondary" align="center">
            Parece que la página que estás buscando no existe. Puedes{' '}
            <span className={classes.linkStyle} onClick={() => handleBackHome()} aria-hidden="true">
              volver al inicio
            </span>{' '}
            o comunicate con nuesto{' '}
            <a className={classes.linkStyle} href="mailto:contacto@novatechstore.com.ar">
              servicio de soporte
            </a>{' '}
            para poder ayudarte.
          </Typography>
        </Box>
        <Grid container justify="center">
          <NotFoundPage height={isDownSm ? '260' : '347'} width={isDownSm ? '367' : '490'} />
        </Grid>
      </Box>
    </Grid>
  );
};
