import React, { useCallback, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Grid, Button } from '@material-ui/core';
import { useBuyerAddressInfoDAO } from '../../../../../../../app/business';
import { BuyerAddressInfo } from '../../../../../../../app/models';
import { FormContextProvider, useFormContext } from '../../../../../../../lib/templates';
import { addressesInfoTemplate } from '../../../../../../Private/Profile/component/AddressesInfoForm/template';
import { useBreakpointsState } from '../../../../../../../app/hooks/useMobile';
import { FieldComponent } from '../../../FieldComponent';
import { useAddressManagerFields } from './hooks/useAddressManageFields';
import { IDeliveryManage } from './types';

const FormContent = ({ onCancel }: { onCancel: () => void }) => {
  const { values, errors, initialValues, handleSubmit } = useFormContext<IDeliveryManage>();
  const { fields } = useAddressManagerFields();
  const { useMakeDown, useMakeUp } = useBreakpointsState();
  const isDownSm = useMakeDown('sm');
  const isUp768 = useMakeUp(768);

  const isDiableSave = useCallback(
    () => Object.keys(errors as any).length > 0 || JSON.stringify(initialValues) === JSON.stringify(values),
    [initialValues, values, errors],
  );

  return (
    <Box padding={isDownSm ? 1 : 3}>
      <Grid container direction="row" spacing={3}>
        {fields.map((field) => (
          <FieldComponent {...field} />
        ))}

        <Grid item container justify={isDownSm ? (isUp768 ? 'flex-end' : 'space-between') : 'flex-end'} xs={12}>
          <Grid item>
            <Button size={isDownSm ? (isUp768 ? 'large' : 'small') : 'large'} variant="text" color="primary" onClick={() => onCancel()}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Box pl={2}>
              <Button
                disabled={isDiableSave()}
                size={isDownSm ? (isUp768 ? 'large' : 'small') : 'large'}
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Guardar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export interface AddressManagerProps {
  initialValue: IDeliveryManage;
  raw?: BuyerAddressInfo;
  recall: () => Promise<void>;
  onCancel: () => void;
}

export const AddressManager = ({ initialValue, raw, recall, onCancel }: AddressManagerProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const buyerAddressInfoDAO = useBuyerAddressInfoDAO();

  const _initialvalue = useRef(initialValue);

  const onSubmit = useCallback(
    async (values: IDeliveryManage) => {
      try {
        const transformed: BuyerAddressInfo = {
          street: values.street,
          number: Number(values.streetNumber),
          floor: values.floor,
          department: values.apartament,
          city: values.city,
          state: values.province,
          zip: values.zipCode,
          comments: values.additionalInfo,
          is_selected: values.is_selected,
        };

        const toSave: BuyerAddressInfo = raw ? { ...raw, ...transformed } : transformed;

        await buyerAddressInfoDAO.save(toSave);
        enqueueSnackbar(raw ? 'Domicilio modificado correctamente' : 'Nuevo domicilio registrado correctamente', { variant: 'success' });
        if (raw ? !raw.is_selected && values.is_selected : values.is_selected) {
          enqueueSnackbar(`El domicilio de ${values.street} ${values.streetNumber} fue agregado como domicilio de envío.`, { variant: 'success' });
        }
        recall();
        onCancel();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('No se pudo completar la operacion.', { variant: 'error' });
      }
    },
    [raw, buyerAddressInfoDAO, enqueueSnackbar, onCancel, recall],
  );
  return (
    <FormContextProvider initialValues={_initialvalue.current} onSubmit={onSubmit} template={addressesInfoTemplate}>
      <FormContent onCancel={onCancel} />
    </FormContextProvider>
  );
};
