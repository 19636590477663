import { AnyObject } from 'final-form';
import { useFormContext } from 'src/lib/templates';
import { IHOP } from '../../../initialValues';

export const useSavingHOPPoints = () => {
  const { form, values } = useFormContext<AnyObject>();

  const savingHOPData = (data: IHOP) => {
    const { retirementPoints, selectedPoint, approved, address } = data;
    form.change('retirementPoints', { retirementPoints, selectedPoint, approved, address });
  };

  return { savingHOPData, values, form };
};
