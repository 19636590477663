import React, { ChangeEvent } from 'react';
import { Grid, Tabs, Tab, Box } from '@material-ui/core';
import { CategoryPromo } from 'src/app/models';

interface FilterTapsProps {
  categories: CategoryPromo[];
  category: CategoryPromo;
  onChange: (value: CategoryPromo) => void;
}

const a11yProps = (index: number) => ({
  id: `filter-tab-${index}`,
  'aria-controls': `filter-tabpanel-${index}`,
});

export const FilterTabs = ({ categories, category, onChange }: FilterTapsProps) => {
  const onSelectCategory = (event: ChangeEvent<{}>, value: number) => {
    const newValue = categories.find((_category) => _category.value === value);
    if (!newValue) return;
    onChange(newValue);
  };

  return (
    <Box pb={2}>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Tabs indicatorColor="primary" textColor="primary" value={category.value} onChange={onSelectCategory} aria-label="filter tabs categories">
            {categories.map((category) => (
              <Tab key={category.id} label={category.name} {...a11yProps(category.value)} style={{ minWidth: 50 }} />
            ))}
          </Tabs>
        </Grid>
      </Grid>
    </Box>
  );
};
