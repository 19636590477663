import { MenuItem } from '../../../../commons/ui/menu';
import {Catalog} from "../../../../screens/Public/Catalog";

export const routes: MenuItem[] = [
  {
    key: 'publicProductsMain',
    basePath: '/',
    target: {
      component: Catalog,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Products',
    children: [],
    default: true,
  },
];
