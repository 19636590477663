import React from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { useCheckoutFormContext } from '../../hooks/CheckoutFormContext';
import { FieldComponent } from '../FieldComponent';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';
import { useFields } from './hooks/useFields';

export const GuarantorInfoForm = () => {
  const { errors, handleSubmit } = useCheckoutFormContext();
  const { sm } = useBreakpointsState();
  const { fields } = useFields();

  return (
    <Box padding={sm ? 2 : 3}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={sm && '1px solid'}>
            <Typography variant="h5" color="textPrimary">
              Datos del garante
            </Typography>
          </Box>
        </Grid>

        {fields.map((field) => (
          <FieldComponent {...field} />
        ))}

        {sm && (
          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={Object.keys(errors as any).length > 0}
              size="large"
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              Continuar
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
