import { BaseAnalytics } from '../../lib/analytics';
import { FacebookPixelApi } from './FacebookPixelApi';
import { GoogleAnalytic } from './GoogleAnalytic';
import { FacebookPixelNav } from './FacebookPixelNav';
import {
  AddToCartParams,
  FacebookTrackEvent,
  PurchaseEventParams,
  RemoveFromCartParams,
  StartCheckoutEventParams,
  TrackEvent,
  ViewItemListEventParams,
  VisitPublicationEventParams,
  SelectPromotionEventParams
} from './interfaces';
import {
  facebookAddToCart,
  facebookPurchase,
  facebookStartCheckout,
  facebookViewContent,
  googleAddToCart,
  googlePurchase,
  googleSelectPromotion,
  googleStartCheckout,
  googleViewContent,
} from './utils';

class Analytics extends BaseAnalytics {
  TRACK_EVENT = TrackEvent;

  initialize(gaID: any) {
    FacebookPixelNav.initialize();
    GoogleAnalytic.initialize(gaID);
  }

  // emit helpers
  trackAddToCart(params: AddToCartParams) {
    this.track(this.TRACK_EVENT.ADD_TO_CART, params);
  }

  trackRemoveFromCart(params: RemoveFromCartParams) {
    this.track(this.TRACK_EVENT.REMOVE_FROM_CART, params);
  }

  trackPurchaseApproved(params: PurchaseEventParams) {
    this.track(this.TRACK_EVENT.PURCHASE_APPROVED, params);
  }

  trackPurchasePending(params: PurchaseEventParams) {
    this.track(this.TRACK_EVENT.PURCHASE_PENDING, params);
  }

  trackPurchaseFailed(params: PurchaseEventParams) {
    this.track(this.TRACK_EVENT.PURCHASE_FAILED, params);
  }

  trackStartCheckout(params: StartCheckoutEventParams) {
    this.track(this.TRACK_EVENT.START_CHECKOUT, params);
  }

  trackPersonalInfo(params: StartCheckoutEventParams) {
    this.track(this.TRACK_EVENT.PERSONAL_INFO, params);
  }

  trackEquifaxVerify(params: StartCheckoutEventParams) {
    this.track(this.TRACK_EVENT.EQUIFAX_VERIFY, params);
  }

  trackDeliveryInfo(params: StartCheckoutEventParams) {
    this.track(this.TRACK_EVENT.DELIVERY_INFO, params);
  }

  trackPaymentMethodInfo(params: StartCheckoutEventParams) {
    this.track(this.TRACK_EVENT.PAYMENT_METHOD, params);
  }

  trackApplicantInfo(params: StartCheckoutEventParams) {
    this.track(this.TRACK_EVENT.APPLICANT_INFO, params);
  }

  trackGuarantorInfo(params: StartCheckoutEventParams) {
    this.track(this.TRACK_EVENT.GAURANTOR_INFO, params);
  }

  trackVisitPublication(params: VisitPublicationEventParams) {
    this.track(this.TRACK_EVENT.VISIT_PUBLICATION, params);
  }
  
  trackViewItemList(params: ViewItemListEventParams) {
    this.track(this.TRACK_EVENT.VIEW_ITEM_LIST, params);
  }

  trackSelectPromotion(params: SelectPromotionEventParams) {
    this.track(this.TRACK_EVENT.SELECT_PROMOTION, params);
  }

  register() {
    if (process.env.REACT_APP_ANALYTICS === 'true') {
      this.TRACK_EVENT = TrackEvent;
      this.addEvent(this.TRACK_EVENT.ADD_TO_CART, this.addToCart);
      this.addEvent(this.TRACK_EVENT.REMOVE_FROM_CART, this.removeFromCart);
      this.addEvent(this.TRACK_EVENT.PURCHASE_APPROVED, this.purchaseApproved);
      this.addEvent(this.TRACK_EVENT.PURCHASE_PENDING, this.purchasePending);
      this.addEvent(this.TRACK_EVENT.PURCHASE_FAILED, this.purchaseFailed);
      this.addEvent(this.TRACK_EVENT.START_CHECKOUT, this.startCheckout);
      this.addEvent(this.TRACK_EVENT.PERSONAL_INFO, this.personalInfo);
      this.addEvent(this.TRACK_EVENT.EQUIFAX_VERIFY, this.equifaxVerify);
      this.addEvent(this.TRACK_EVENT.DELIVERY_INFO, this.deliveryInfo);
      this.addEvent(this.TRACK_EVENT.PAYMENT_METHOD, this.paymentMethodInfo);
      this.addEvent(this.TRACK_EVENT.APPLICANT_INFO, this.applicantInfo);
      this.addEvent(this.TRACK_EVENT.GAURANTOR_INFO, this.guarantorInfo);
      this.addEvent(this.TRACK_EVENT.VISIT_PUBLICATION, this.visitPublication);
      this.addEvent(this.TRACK_EVENT.VIEW_ITEM_LIST, this.viewItemList);
      this.addEvent(this.TRACK_EVENT.SELECT_PROMOTION, this.selectPromotion);
    }
  }

  private addToCart(params: AddToCartParams) {
    const facebookParams = facebookAddToCart(params);
    const googleParams = googleAddToCart(params);

    FacebookPixelNav.addToCartTrack(facebookParams);
    FacebookPixelApi.addToCartTrack(facebookParams);
    // @ts-ignore
    window.gtag('event', 'add_to_cart', googleParams);
  }

  private removeFromCart(params: RemoveFromCartParams) {
    const facebookParams = facebookAddToCart(params);
    const googleParams = googleAddToCart(params);

    FacebookPixelNav.track(FacebookTrackEvent.REMOVE_FROM_CART, facebookParams);
    FacebookPixelApi.removeToCartTrack(facebookParams);
    // @ts-ignore
    window.gtag('event', 'remove_from_cart', googleParams);
  }

  private purchaseApproved(params: PurchaseEventParams) {
    const facebookParams = facebookPurchase(params, 'Compra aprobada');
    const googleParams = googlePurchase(params);

    FacebookPixelNav.purchaseTrack(facebookParams);
    FacebookPixelApi.purchaseTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'purchase', googleParams);
  }

  private purchasePending(params: PurchaseEventParams) {
    const facebookParams = facebookPurchase(params, 'Compra aprobada con pago pendiente');
    const googleParams = googlePurchase(params);

    FacebookPixelNav.track(FacebookTrackEvent.PURCHASE_PENDING, facebookParams);
    FacebookPixelApi.purchasePendingTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'purchase_pending', googleParams);
  }

  private purchaseFailed(params: PurchaseEventParams) {
    const facebookParams = facebookPurchase(params, 'La Compra Falló');
    const googleParams = googlePurchase(params);

    FacebookPixelNav.track(FacebookTrackEvent.PURCHASE_FAILED, facebookParams);
    FacebookPixelApi.purchaseFailedTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'purchase_failed', googleParams);
  }

  private startCheckout(params: StartCheckoutEventParams) {
    const facebookParams = facebookStartCheckout(params);
    const googleParams = googleStartCheckout(params);

    FacebookPixelNav.checkOutTrack(facebookParams);
    FacebookPixelApi.checkOutTrack(facebookParams);
    // @ts-ignore
    window.gtag('event', 'begin_checkout', googleParams);
  }

  private personalInfo(params: StartCheckoutEventParams) {
    const facebookParams = facebookStartCheckout(params);
    const googleParams = googleStartCheckout(params);

    FacebookPixelNav.track(FacebookTrackEvent.PERSONAL_INFO, facebookParams);
    FacebookPixelApi.PersonalInfoTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'add_personal_info', googleParams);
  }

  private equifaxVerify(params: StartCheckoutEventParams) {
    const facebookParams = facebookStartCheckout(params);
    const googleParams = googleStartCheckout(params);

    FacebookPixelNav.track(FacebookTrackEvent.EQUIFAX_VERIFY, facebookParams);
    FacebookPixelApi.EquifaxVerifyTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'equifax_verify', googleParams);
  }

  private deliveryInfo(params: StartCheckoutEventParams) {
    const facebookParams = facebookStartCheckout(params);
    const googleParams = googleStartCheckout(params);

    FacebookPixelNav.track(FacebookTrackEvent.DELIVERY_INFO, facebookParams);
    FacebookPixelApi.DeliveryInfoTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'add_shipping_info', googleParams);
  }

  private paymentMethodInfo(params: StartCheckoutEventParams) {
    const facebookParams = facebookStartCheckout(params);
    const googleParams = googleStartCheckout(params);

    FacebookPixelNav.track(FacebookTrackEvent.PAYMENT_METHOD, facebookParams);
    FacebookPixelApi.PaymentMethodInfoTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'add_payment_info', googleParams);
  }

  private applicantInfo(params: StartCheckoutEventParams) {
    const facebookParams = facebookStartCheckout(params);
    const googleParams = googleStartCheckout(params);

    FacebookPixelNav.track(FacebookTrackEvent.APPLICANT_INFO, facebookParams);
    FacebookPixelApi.ApplicantInfoTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'applicant_info', googleParams);
  }

  private guarantorInfo(params: StartCheckoutEventParams) {
    const facebookParams = facebookStartCheckout(params);
    const googleParams = googleStartCheckout(params);

    FacebookPixelNav.track(FacebookTrackEvent.GAURANTOR_INFO, facebookParams);
    FacebookPixelApi.GuarantorInfoTrack(facebookParams, params.userData);
    // @ts-ignore
    window.gtag('event', 'guarantor_info', googleParams);
  }

  private visitPublication(params: VisitPublicationEventParams) {
    const facebookParams = facebookViewContent(params);
    const googleParams = googleViewContent(params);

    FacebookPixelNav.viewContent(facebookParams);
    FacebookPixelApi.viewContent(facebookParams);
    // @ts-ignore
    window.gtag('event', 'view_item', googleParams);
  }

  private viewItemList(params: ViewItemListEventParams) {
    const googleParams = googleViewContent(params);

    // @ts-ignore
    window.gtag('event', 'view_item_list', googleParams);
  }

  private selectPromotion(params: SelectPromotionEventParams) {
    const googleParams = googleSelectPromotion(params);

    // @ts-ignore
    window.gtag('event', 'select_promotion', googleParams);
  }
}

const instance = new Analytics();
export { instance as Analytics };
