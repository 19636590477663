import React, { ChangeEvent, FunctionComponent } from 'react';
import { useField } from 'react-final-form-hooks';
import { RadioGroup, Box, FormHelperText } from '@material-ui/core';
import { Loading } from 'src/commons';
import { getErrorAndHelperTextNoTranslation } from 'src/utils/materialHelpers';

export interface FormRadioGroupCustomProps<T = any> {
  name: string;
  form: any;
  row?: boolean;
  options: Array<any>;
  loading?: boolean;
  valueKey?: string;
  labelKey?: string;
  customHelperText?: string;
  component: React.ComponentType<T> | FunctionComponent<T>;
  componentProps?: T;
  optionToValue?: (value: any, options: any) => any;
  valueToOption?: (value: any, options: any) => any;
}

export const FormRadioGroupCustom = ({
  name,
  form,
  row,
  options,
  loading,
  valueKey = 'id',
  labelKey = 'description',
  customHelperText,
  component: Comp,
  componentProps = {},
  optionToValue,
  valueToOption,
}: FormRadioGroupCustomProps): JSX.Element => {
  const field = useField(name, form);

  const { error, helperText } = getErrorAndHelperTextNoTranslation(field);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <RadioGroup
          row={row}
          onFocus={field.input.onFocus}
          onBlur={field.input.onBlur}
          value={field.input.value ? (valueToOption ? valueToOption(field.input.value, options) : field.input.value) : ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            field.input.onChange(optionToValue ? optionToValue(newValue, options) : newValue);
          }}
        >
          <Box flexDirection="column" width="100%">
            <Comp {...componentProps} valueKey={valueKey} labelKey={labelKey} options={options} />
          </Box>
          {error && <FormHelperText error={error}>{!customHelperText ? helperText : customHelperText}</FormHelperText>}
        </RadioGroup>
      )}
    </>
  );
};
