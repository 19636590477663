import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const facebookMenu: MenuItem = {
  key: 'publicFacebook',
  basePath: '/facebook',
  enabled: true,
  icon: 'List',
  text: 'Facebook',
  children: routes,
};
