export const validateZipCode = async (value: any, allValues: any) => {
  if (value === '') {
    return 'Completa este dato.';
  }
  const regex = new RegExp('^[0-9]+$');
  if (!regex.test(value)) {
    return 'Solo podés ingresar números.';
  }
  if (value.length > 6) {
    return 'Código postal inválido.';
  }
  if (!allValues.summaryAddress.city?.zip?.includes(Number(value))) {
    return 'El código postal no coincide.';
  }
};
