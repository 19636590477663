import { useEffect, useMemo, useState, useCallback } from 'react';
import { checkoutAllValues, docentInitialValue, alumnInitialValue } from '../initialValues';
import { AnyObject } from '../../../../commons';
import { useFormContext } from '../../../../lib/templates';
import { useMultiStepFormContext } from '../../../../lib/MultiStepForm';
import { useEmittersContext } from '../../../../context/EmittersContext/EmittersContextProvider';
import { APP_ECOMMERCE } from '../../../../config';
import { EMITTERS } from '../../../../app/const/Emitters';
import { docentInfoTemplate, alumnInfoTemplate } from '../templates';
import { DocenteInfoForm } from '../components/DocenteInfoForm';
import { AlumnInfoForm } from '../components/AlumnInfoForm';
import { CARD_PERSONAL_CREDIT, optionsDocentType } from '../constants';

export const usePersonalCreditLogic = () => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { values } = useFormContext<AnyObject>();
  const [modify, setModify] = useState<boolean>(false);
  const { allSteps, setSteps } = useMultiStepFormContext();
  const { emitters } = useEmittersContext();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const evalApplicantTemp = allSteps.find((item) => item.title === 'Datos del solicitante');

  const validStepper = useCallback(
    (newStepper) => {
      if (JSON.stringify(newStepper) !== JSON.stringify(allSteps)) {
        setSteps(newStepper);
      }
    },
    [setSteps, allSteps],
  );

  useEffect(() => {
    if (APP_ECOMMERCE !== 'detecnologia') return;
    if (emitters.length === 0) {
      setModify(false);
      return;
    }

    if (evalApplicantTemp && emitters.find((item) => item.card_emiter_id === EMITTERS.CATAMARCA_CREDITS) && evalApplicantTemp?.hidden && !modify) {
      const newSteper = allSteps.map((item) => {
        if (item.title === 'Datos del solicitante') return { ...item, hidden: false };
        return item;
      });
      validStepper(newSteper);
    } else if (
      evalApplicantTemp &&
      !evalApplicantTemp.hidden &&
      valuesForm.paymentMethod.emitter.card_emiter_id !== EMITTERS.CATAMARCA_CREDITS &&
      !modify
    ) {
      const newSteper = allSteps.map((item) => {
        if (item.title === 'Datos del solicitante') return { ...item, hidden: true };
        return item;
      });
      validStepper(newSteper);
    }
    setModify(true);
  }, [valuesForm.paymentMethod.emitter.card_emiter_id, emitters, evalApplicantTemp, modify, allSteps, validStepper]);

  useEffect(() => {
    if (APP_ECOMMERCE !== 'detecnologia') return;
    if (valuesForm.paymentMethod.emitter.card_emiter_id !== EMITTERS.CATAMARCA_CREDITS) return;
    if (
      evalApplicantTemp &&
      valuesForm.paymentMethod.card.card_id.toString() === CARD_PERSONAL_CREDIT.DOCENTES.toString() &&
      (valuesForm.docentInfo.docentType.id === optionsDocentType[0].id ||
        valuesForm.docentInfo.docentType.id.toString() === '' ||
        valuesForm.docentInfo.docentType.id === 0)
    ) {
      const oldStep = [...allSteps];
      const newSteps = oldStep.map((item) => {
        if (item.title === 'Datos del solicitante')
          return { ...item, hidden: false, template: docentInfoTemplate, initialValue: docentInitialValue, render: DocenteInfoForm };
        if (item.title === 'Datos del garante') return { ...item, hidden: true };
        return item;
      });
      validStepper(newSteps);
    } else if (evalApplicantTemp && valuesForm.paymentMethod.card.card_id.toString() === CARD_PERSONAL_CREDIT.ALUMNOS.toString()) {
      const oldStep = [...allSteps];
      const newSteps = oldStep.map((item) => {
        if (item.title === 'Datos del solicitante')
          return { ...item, hidden: false, template: alumnInfoTemplate, initialValue: alumnInitialValue, render: AlumnInfoForm };
        if (item.title === 'Datos del garante') return { ...item, hidden: false };
        return item;
      });
      validStepper(newSteps);
    }

    if (
      valuesForm.docentInfo.docentType.id !== 0 &&
      valuesForm.docentInfo.docentType.id !== 1 &&
      valuesForm.docentInfo.docentType.id.toString() !== ''
    ) {
      const oldStep = [...allSteps];
      const newSteps = oldStep.map((step) => {
        if (step.title === 'Datos del garante') {
          return { ...step, hidden: false };
        }
        if (step.title === 'Datos del solicitante') {
          return { ...step, hidden: false };
        }
        return step;
      });
      validStepper(newSteps);
    }
  }, [
    allSteps,
    valuesForm.paymentMethod.card.card_id,
    valuesForm.paymentMethod.emitter.card_emiter_id,
    valuesForm.docentInfo.docentType,
    evalApplicantTemp,
    validStepper,
  ]);
};
