export enum ZONE {
  BANNER_PRINCIPAL = '19ed055a-4d66-4845-ba8e-d85d683eb3de',
  OFERTAS_PARA_VOS = '13d79f51-cfaf-4fa8-8c60-5c49c19b224d',
  TE_PUEDE_INTERESAR = 'a7e887e3-c20c-4be8-9513-fff7c0ba68cd',
  BANNER_SECUNDARIO = '514b0196-c46c-496d-9ae0-e82dd212832c',
  NOTEBOOK_BUSCAS = '1df857cb-18e8-46b7-b399-ea581ca4bb72',
  OFERTAS_IMPERDIBLES = '13d79f51-cfaf-4fa8-8c60-5c49c19b224d',
  PC_PARA_VOS = 'a7e887e3-c20c-4be8-9513-fff7c0ba68cd',
  ALL = '@ALL@',
  REGALO_PARA_MAMA = '52c58e90-4789-463b-8dc2-d56b73ec8db1',
  TIMER_PROMOTION = '36cc1686-ab71-434b-8b50-654f99b241cf',
}
