import React from 'react';
import { Box, Grid, Typography, Container, useMediaQuery, Theme, FormControl, FormHelperText, InputLabel, Input, Button } from '@material-ui/core';
import { useEmailSubscription } from 'src/commons/';
import { appFooterStyles as useStyles } from 'src/app/views/styles';

export const AppFooterSubscription = () => {
  const { handleInputChange, handleSubmit, email, error } = useEmailSubscription();
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down(769));

  return (
    <Container maxWidth="lg" className={classes.containerSubscription}>
      <Box
        display="flex"
        justifyContent={isDownSm ? 'center' : 'space-between'}
        alignItems="center"
        flexDirection={isDownSm ? 'column' : 'row'}
        height="100%"
      >
        <Grid>
          <Typography variant={isDownSm ? 'h5' : 'h6'}>¡Enterate de todas las novedades!</Typography>
          {isDownSm ? null : (
            <Typography variant="body2" color="textSecondary">
              Recibí nuestras promociones exclusivas.
            </Typography>
          )}
        </Grid>
        <form onSubmit={(e: any) => handleSubmit(e)} className={classes.containerForm}>
          <FormControl required className={classes.inputStyleFooter}>
            <InputLabel shrink htmlFor="email">Dejá tu correo aquí</InputLabel>
            <Input id="email" value={email} onChange={(e: any) => handleInputChange(e)} />
            <FormHelperText className={error.err ? classes.errorMsg : ''}>
              {error.err ? error.msg : error.msg.length ? error.msg : null}
            </FormHelperText>
          </FormControl>
          <Button type="submit" variant="contained" color="primary" className={classes.buttonStyles}>
            Suscribirme
          </Button>
        </form>
      </Box>
    </Container>
  );
};
