import { PurchaseItem } from 'src/app/models/Purchases';
import { EcommerceDAO } from './EcommerceDAO';

export class PurchasesDAO extends EcommerceDAO<PurchaseItem> {
  constructor() {
    super('purchases', 'purchases_id');
  }

  async getAllPurchases(): Promise<any> {
    const response = await this.fetchData(`/order/purchased`);
    return response.json();
  }

  async getPurchasesById(order_id: string): Promise<any> {
    const response = await this.fetchData(`/order/purchased/${order_id}`);
    return response.json();
  }
}
