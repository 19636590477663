import { TemplateProperty } from '../../../../../lib/templates';

export const cuilTemplate: TemplateProperty = {
  name: 'document',
  type: { name: 'string' },
  label: 'CUIL',
  validators: [
    { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Completa solo con números' },
    { name: 'min', signature: [10], errorMessage: 'CUIL invalido' },
    { name: 'max', signature: [11], errorMessage: 'CUIL invalido' },
    { name: 'empty', errorMessage: 'Completa este dato.' },
  ],
};
