import { Configuration, Security /* Notifications */ } from 'src/screens';
import { MenuItem } from 'src/commons';
import { EditEmail } from 'src/screens/Private/Configuration/Security/components/EditEmail';
import { EditPassword } from 'src/screens/Private/Configuration/Security/components/EditPassword';

export const routes: MenuItem[] = [
  /*   {
    key: 'privateNotificationMain',
    basePath: '/notifications',
    target: {
      component: Notifications,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Notificaciones',
    children: [],
    default: true,
  }, */
  {
    key: 'privateSecurityEmailMain',
    basePath: '/security/email',
    target: {
      component: EditEmail,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Seguridad Email',
    children: [],
    default: true,
  },
  {
    key: 'privateSecurityEmailMain',
    basePath: '/security/password',
    target: {
      component: EditPassword,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Seguridad Password',
    children: [],
    default: true,
  },
  {
    key: 'privateSecurityMain',
    basePath: '/security',
    target: {
      component: Security,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Seguridad',
    children: [],
    default: true,
  },
  {
    key: 'privateConfigurationMain',
    basePath: '/',
    target: {
      component: Configuration,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Profile',
    children: [],
    default: true,
  },
];
