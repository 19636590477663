import React, { FunctionComponent } from 'react';
import { makeStyles, useMediaQuery, Theme, IconButton } from '@material-ui/core';
import { AppFooter } from 'src/app/views';
import { useLocation } from 'react-router';
import { useConfigurationsContext } from 'src/context/Configuration';
import { MenuCollection } from '../../ui/menu';
import { AppHeader } from '../../../app/views/components/AppHeader';
import { ecommerceConfig } from '../../../config/ecommerce';
import { SideMenu } from '../SideMenu';
import { GenericIcon } from '../GenericIcon';
import {APP_ECOMMERCE} from "../../../config";

const ecommerce = APP_ECOMMERCE;

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  mainContainer: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    minHeight: 800,
    paddingTop: theme.spacing(0.125),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paperProps: { position: 'absolute', height: '100%', marginTop: '-6px', marginBottom: '-30px' },
  modalProps: { position: 'absolute' },
  btnWhatsapp: { position: 'fixed', bottom: '2%', right: '2%' }
}));

interface MainLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}



export const MainLayout: FunctionComponent<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const { general } = ecommerceConfig;
  const location = useLocation();
  const { configurations } = useConfigurationsContext();
  const whatsappBtnConfig = configurations.find((config)=> config.name === 'whatsapp_button');

  const footerItems = [
    {
      title: 'Contacto',
      items: [{ description: general.address }, { description: general.contactUrl, url: `mailto:${general.contactUrl}` }],
    },
    {
      title: 'Más información',
      items: [
        { description: 'Nosotros', url: '/nosotros' },
        { description: 'Cómo comprar', url: '/como-comprar' },
        {
          description: 'Política de devoluciones',
          url: '/devoluciones',
        },
        {
          description: 'Políticas de privacidad',
          url: '/politicas-de-privacidad',
        },
        {
          description: 'Preguntas frecuentes',
          url: '/preguntas-frecuentes',
        },
        {
          description: 'Cancelar Compra',
          url: '/cancelar-compra',
        },
      ],
    },
    {
      title: 'Seguinos en las redes',
      items: [
        {
          description: 'Facebook',
          url: general.facebookUrl,
          blank: '_blank',
        },
        {
          description: 'Instagram',
          url: general.instagramUrl,
          blank: '_blank',
        },
      ],
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  ecommerce === 'novatech' && footerItems[1].items.push({ description: 'Base y condiciones Quantum', url: '/terminos-y-condiciones-quantum', blank: '_blank'});
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  ecommerce === 'enova' && footerItems[1].items.push({ description: 'Base y condiciones sorteo', url: '/terminos-y-condiciones-sorteo', blank: '_blank'});


  return (
      <div className={classes.container}>
        <AppHeader />

        <main className={classes.mainContainer} style={{ marginTop: isDownSm? '70px' : '120px'}}>
          <>
            {!isDownSm && location.pathname.includes('/dashboard') && (
                <SideMenu
                    modalsProps={{
                      container: document.getElementById('drawer-container'),
                      className: classes.modalProps,
                    }}
                    paperProps={{ className: classes.paperProps }}
                />
            )}
            {children}
          </>
        </main>
        {!location.pathname.includes('catalog') && (
            <>
              <AppFooter/>
              {
                  whatsappBtnConfig?.value.enable &&
                  <IconButton className={classes.btnWhatsapp} onClick={()=> window.open(`https://api.whatsapp.com/send?phone=${general.whatsapp}`)}>
                    <GenericIcon
                        image='https://res.cloudinary.com/phinx-lab/image/upload/v1677684940/Assets/whastapp-icon.png'
                        width='60px'
                        height='60px'
                    />
                  </IconButton>
              }
            </>
        )}

      </div>
  );
};
