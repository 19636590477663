import React from 'react';
import { Grid, Typography, Box, Divider} from '@material-ui/core';
import { PaymentExtraDataModel } from 'src/app/models/Payment';
import { productInfoPanelStyles as useStyles } from '../../styles';

interface ProductInfoPanelProps {
  data: any;
}

interface BestPromotionsMsgProps {
  promotion: PaymentExtraDataModel;
}

export const BestPromotionsMsg = ({ promotion }: BestPromotionsMsgProps) => (
  <Box>
    {promotion.installments}
    {` ${promotion.installments > 1 ? 'cuotas' : 'pago'} `}
    de
    <Box display="inline" fontWeight="fontWeightBold" color="success.dark" px={1}>
      {promotion.amountTotal}
    </Box>
  </Box>
);

export const CatalogInfoPanel = ({
  data,
}: ProductInfoPanelProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Box pt={2}>
        <Grid>
          <Box component="text" color="primary.dark">
            <Typography variant="h5" className={classes.normalFontWeight} id={`${data.product}-name`}>
              {data.product}
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Divider />
    </Grid>
  );
};
