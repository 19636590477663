import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
  Divider,
  Button,
  IconButton,
} from '@material-ui/core';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';
import { useInfoPublicationPayment } from 'src/commons/hooks/useInfoPublicationPayment';
import { useLocation } from 'react-router';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { PriceView } from 'src/commons/components/PriceView';
import { useBreakpointsState } from 'src/app/hooks/useMobile';
import { usePublication } from 'src/app/business';
import { Analytics } from 'src/platform/analytics';
import { productCardRowStyles as useStyles } from '../../styles';
import { formatPriceNotDecimal, getDiscountPublication, useOnScreen, validateFreeShipping } from '../../../../utils';
import { CardDiscount } from '../CardDiscount/CardDiscount';
import { Product } from '../../../models/business/Product';
// import { HighLightPromo } from '../HighLightPromo';
import { FavoriteButtonView } from '../FavoriteButtonView';
import ProductWithOutStock from './ProductWithOutStock';

export interface ProductCardRowProps {
  listName: string | undefined;
  data: Product;
  enableStock: boolean;
  isFavorite: boolean;
  onAddFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tracking: boolean | undefined;
}

export const ProductCardRow = ({
  listName,
  data,
  enableStock,
  isFavorite,
  onRemoveFavorite,
  onAddFavorite,
  tracking,
}: ProductCardRowProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const { xs } = useBreakpointsState();
  const classes = useStyles({ xs, isDownSm, lineCount: 2, lineHeight: 1.2, bgColor: 'white' });
  const { mainPrice, mainAmount, alterPrice } = useInfoPublicationPayment(data);
  const location = useLocation();

  const discount = getDiscountPublication(mainPrice[mainAmount].toString(), parseInt(data.old_price, 10));

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useOnScreen(ref, { rootMargin: `-${408 / 2}px -${262 / 2}px` });
  const isVisible = entry?.isIntersecting;
  const [isTracked, setIsTracked] = useState(false);
  const { data: publication, working } = usePublication(data.productId.toString());

  useEffect(() => {
    if (!isTracked && isVisible && !working && tracking && publication && listName) {
      setIsTracked(true);
      Analytics.trackViewItemList({ publication: publication[0], listName });
    }
  }, [isTracked, isVisible, publication, working, tracking, listName]);

  return (
    <Card className={classes.container}>
      <CardActionArea id={data.productId.toString()}>
        <Grid container direction="row" alignItems={!isDownSm ? 'flex-start' : 'center'}>
          <Grid item xs={4}>
            {isDownSm ? (
              <Box width={xs ? '130px' : 'max(120px, 80%)'} height={xs ? '130px' : 'max(120px, 80%)'}>
                <Grid component="img" src={data.images[0]} />
              </Box>
            ) : (
              <CardMedia className={classes.cover} component="img" image={data.images[0]} title={data.name} height={230} />
            )}
          </Grid>
          <Grid item xs={8} container justify="flex-start">
            <CardContent className={classes.cardContainer}>
              {enableStock && validateFreeShipping(mainPrice[mainAmount].toString()) && data.shipment_category_id !== 2 && (
                <Grid item container direction="row" alignItems="center" justify="flex-start">
                  <Grid className={classes.actualPrice} item>
                    <Box mr={1}>
                      <LocalShippingOutlined className={classes.localShipmentIcon} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography color="primary" variant="body2">
                      Envío Gratis
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Box mb={1}>
                <Typography
                  className={classes.blockWithText}
                  variant={isDownSm ? 'body1' : 'h5'}
                  color="textSecondary"
                  id={`${data.productId.toString()}-name`}
                >
                  {data.name}
                </Typography>
              </Box>

              <Grid container style={{ paddingRight: '11px' }}>
                <FavoriteButtonView
                  isFavorite={isFavorite}
                  hidden={location.pathname.includes('/dashboard/favorites')}
                  fontSize={isDownSm ? 'small' : 'large'}
                  top={isDownSm ? 0 : '10%'}
                  right={10}
                  onAddFavorite={onAddFavorite}
                  onRemoveFavorite={onRemoveFavorite}
                />
                {enableStock ? (
                  <Box>
                    <Box color="text.secondary" mb={0.6} display="flex">
                      {!!parseInt(data.old_price, 10) && (
                        <Typography className={classes.oldPrice} variant={isDownSm ? 'body2' : 'body2'} id={`${data.productId.toString()}-old-price`}>
                          {`$${formatPriceNotDecimal(parseInt(data.old_price, 10))}`}
                        </Typography>
                      )}
                      {discount > 0 && (
                        <CardDiscount isAbsolute={false} semiBorderRadius={false} discount={discount} padding="2px 6px" productId={data.productId} />
                      )}
                    </Box>

                    <Typography className={classes.actualPrice} variant="h5" color="textPrimary" id={`${data.productId.toString()}-price`}>
                      {`$${formatPriceNotDecimal(mainPrice[mainAmount])}`}
                    </Typography>

                    {/* <HighLightPromo xs = {xs} isAbsolute={!isDownSm} semiBorderRadius={false} highlights={data.highlights || []} productId={data.productId} /> */}

                    <Box mt={0.5} width="auto" display={isDownSm ? 'flex' : 'inline-grid'}>
                      <Grid className={classes.actualPrice} item container xs={12} zeroMinWidth>
                        <PriceView
                          xs
                          variant="body2"
                          key={`${alterPrice.amountTotal}-best-promos`}
                          id={`${data.productId.toString()}-best-promos`}
                          color="primary"
                          beforeText={xs ? `${alterPrice.installments} x` : `${alterPrice.installments} ${alterPrice.typePayment}  de`}
                          afterText={`${alterPrice.discount > 0 ? `(${alterPrice.discount}% OFF)` : ''}${
                            alterPrice.installments > 1 && alterPrice.interest === 0 ? 'sin interés' : ''
                          }`}
                          withDecimals
                          price={alterPrice.amountTotal}
                          afterProps={{ pl: 1.7 }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={1} zIndex={10000} hidden={isDownSm || !location.pathname.includes('/dashboard/favorites')}>
                      <Divider />
                      <Button className={classes.favoriteDeleteButton} size="small" variant="text" color="primary" onClick={onRemoveFavorite}>
                        Eliminar
                      </Button>
                    </Box>

                    <Box
                      zIndex={10000}
                      hidden={!isDownSm || !location.pathname.includes('/dashboard/favorites')}
                      position="absolute"
                      top="40%"
                      right={0}
                    >
                      <IconButton onClick={onRemoveFavorite}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (
                  <ProductWithOutStock />
                )}
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};
