import React from 'react';
import { Container } from '@material-ui/core';
import { NotFoundPageView } from 'src/app/views'

export const NotFoundPage = () => (
    <Container>
      <NotFoundPageView />
    </Container>
  );

