import React from 'react';
import { Box, Grid, Typography, Container, Paper, Divider } from '@material-ui/core';
import { OptionListProps } from 'src/commons/ui/view/OptionList/OptionList';
import { appFooterStyles as useStyles } from 'src/app/views/styles';
import { useSkeletonLibbyCall } from 'src/app/business';
import { SKELETON_IDS } from 'src/app/skeletons';
import { initialContentState, Skeleton } from 'src/app/models';
import DOMPurify from 'dompurify';
import caceLogo from '../../../../assets/images/CACE originales 3.png';
import dataFiscal from '../../../../assets/images/DATAWEB 2.svg';
import { AppFooterSubscription } from './components';

export interface AppFooterProps {
  title: string;
  email: string;
  onChangeEmail: () => void;
  onSubcriber: () => void;
  optionLists: Array<OptionListProps>;
}

const HtmlInectableElement = ({ html }: { html: string }) => <div dangerouslySetInnerHTML={{ __html: html }} />;

export const AppFooter = () => {
  const classes = useStyles();
  const { data: skeleton = initialContentState } = useSkeletonLibbyCall<Skeleton>({ methodName: 'getSkeleton', params: [SKELETON_IDS.FOOTER] });

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Box mt="-5px">
        <Paper elevation={3} square>
          <AppFooterSubscription />
          <Divider component="div" />

          {
            skeleton && skeleton.skeleton_components[0] && 
            <HtmlInectableElement html={DOMPurify.sanitize(skeleton.skeleton_components[0].url)}/>
          }

          <Grid className={classes.bottom} container>
            <Container maxWidth="lg">
              <Grid container direction="row" justify="space-between" alignItems="center" className={classes.fullHeight}>
                <Grid>
                  <Typography variant="body2" color="textPrimary">
                    Copyright © Enova Store 2021{' '}
                    <Typography variant="body2" color="textSecondary">
                      Built with JetCommerce by{' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.phinxlab.com/">
                        Phinxlab
                      </a>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid spacing={1} direction="row" item container className={classes.autoWidth} alignItems="center">
                  <Grid>
                    <img height={65} src={caceLogo} alt="cace logo" />
                  </Grid>
                  <Grid>
                    <a href="http://qr.afip.gob.ar/?qr=b0Lr-aQ_n6jfULRC_e-HnA,,">
                      <img src={dataFiscal} alt="fiscal data qr" />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Paper>
      </Box>
    </Container >
  );
};

