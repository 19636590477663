import { API_URL } from 'src/config';
import { LibbyFetchDAO } from './LibbyFetchDAO';
import { TokenManager, TOKEN_APP } from '../TokenManager';
/* eslint-disable */
// TODO: cambiar la implementacion para hackear la RESTDAO
export abstract class EcommerceDAO<T> extends LibbyFetchDAO {
  private tokenManager = new TokenManager();
  // TODO handlear el error
  async fetchData(path: string, options?: RequestInit) {
    const headerToken = {
      [TOKEN_APP]: ((await this.tokenManager.retrieve()) as string) || '',
    };
    const res = await fetch(`${API_URL}${path}`, {
      ...options,
      headers: { ...options?.headers, ...headerToken },
    });
    const justRPCError = res.headers.get('x-justrpc-error');

    if (justRPCError === 'Session has expired') {
      const { pathname, href } = window.location;
      const base = pathname === '/' ? href : href.replace(pathname, '');
      window.location.href = base;
    }
    return res;
  }
  // #_cache: T[] | null = null;
  //
  // protected loadingCache = false;
  //
  // protected set cache(data: T[]) {
  //   this._cache = data;
  // }
  //
  // protected get cache() {
  //   return this._cache;
  // }
  //
  // constructor(name: string, pk: string) {
  //   super(name, pk);
  //
  //   this.init();
  // }
  //
  // async init() {
  //   this.loadingCache = true;
  //   this.cache = await this.initializeCache();
  //   this.loadingCache = false;
  // }
  //
  // getData() {
  //
  // }
  //
  // abstract initializeCache(): Promise<T[]> | T[];
}
