import React, { useMemo, useCallback } from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';
import { FieldComponent } from '../FieldComponent';
import { useCheckoutFormContext } from '../../hooks/CheckoutFormContext';
import { useLoginFields } from './hooks';
import { useLogin } from '../../../../Public/Login/hooks';
import { loginInitialValue } from '../../initialValues';
import { useMultiStepFormContext } from '../../../../../lib/MultiStepForm';
import { useTemplateContext } from '../../../../../lib/templates';
import { loginTemplate } from '../../templates';

export const LoginForm = () => {
  const { useMakeDown } = useBreakpointsState();
  const { form, values, errors } = useCheckoutFormContext();
  const { fields } = useLoginFields();
  const location = useLocation();
  const { setActiveStep } = useMultiStepFormContext();
  const { setTemplate } = useTemplateContext();
  const { singIn } = useLogin({ redirectOnSuccess: location.pathname, msgOnSuccess: 'Bienvenido! continua con tu compra' });

  const isDownMd = useMakeDown('md');
  const isDownSm = useMakeDown('sm');

  const valuesForm = useMemo(() => ({ loginInfo: loginInitialValue, ...values }), [values]);

  const handleSingIn = useCallback(async () => {
    await singIn({ email: valuesForm.loginInfo.email, password: valuesForm.loginInfo.password });
  }, [valuesForm, singIn]);

  const nextStep = useCallback(() => {
    setTemplate((template) => {
      let changeTemplate = template;
      if (template.template.find((property) => property.name === loginTemplate.name)) {
        changeTemplate = {
          ...template,
          template: template.template.filter((property) => property.name !== loginTemplate.name),
        };
        form.change(loginTemplate.name as any, undefined);
      }
      return changeTemplate;
    });
    setActiveStep((prev) => prev + 1);
  }, [form, setTemplate, setActiveStep]);

  return (
    <Box pl={isDownMd ? 0 : 4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5" color="textPrimary">
              Ingresa a tu cuenta
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Typography variant="body1" color="textSecondary">
              Para continuar con tu compra te pedimos{' '}
              <Box display="inline" fontWeight="bold">
                ingresar a tu cuenta o registrarte
              </Box>
              . También podés{' '}
              <Box display="inline" color="primary.main">
                <Button color="primary" variant="outlined" size="small" onClick={nextStep} style={{ textDecoration: 'none', cursor: 'pointer' }}>
                  continuar como invitado
                </Button>
              </Box>{' '}
              (al terminar tu compra tendrás la opción de crear cuenta).
            </Typography>
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} sm={10} xs={12}>
          <Box bgcolor="white" borderRadius={8} boxShadow={3} display="content">
            <Box padding={isDownSm ? 2 : 3}>
              <Grid container direction="row" spacing={3}>
                {fields.map((input: any) => (
                  <FieldComponent xs={input.xs} component={input.component} componentProps={input.componentProps} />
                ))}
                <Grid item xs={12} container justify="flex-end" alignItems="center">
                  <Link to="/login/recovery" style={{ textDecoration: 'none' }}>
                    <Typography variant="body2">
                      <Box color="primary.main">Registrarse</Box>
                    </Typography>
                  </Link>

                  <Box ml={2} display="flex">
                    <Button
                      disabled={Object.keys(errors as any).length !== 0}
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={handleSingIn}
                    >
                      Ingresar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
