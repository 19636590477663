import React from 'react';
import MaskedInput from 'react-text-mask';
import { InputBaseComponentProps } from '@material-ui/core';

export const CardExpireMaskInput = ({ inputRef, ...rest }: InputBaseComponentProps) => (
  <MaskedInput
    {...rest}
    ref={(ref) => inputRef(ref ? ref.inputElement : null)}
    mask={[/[0-9]/, /[0-9]/, '/', /\d/, /\d/]}
    placeholderChar={'\u2000'}
    type="text"
  />
);
