import palette from '../palette';

type MuiOutlinedInputType = { [k: string]: any };

const MuiOutlinedInput: MuiOutlinedInputType = {
  root: {
    '&:hover:not($disabled)': {
      backgroundColor: palette.background.default,
    },
  },
};

export default MuiOutlinedInput;
