import React, { useEffect, useReducer, useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { hotsaleBanners } from '../../../assets/images';
import { INITIAL_STATE, reducer } from './reducer';
import { API_URL, APP_ECOMMERCE } from '../../../config';
import { isEmail } from '../../../utils';
import { bannerStyles } from './styles';

const background = APP_ECOMMERCE === 'enova' ? '#371e55' : '#223092';

export const HotsaleWeek = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [width, setWidth] = useState(window.innerWidth);

  const handleSubmit = async () => {
    if (isEmail(state.email)) {
      const header = new Headers();
      header.append('Content-Type', 'application/json');
      let response = await fetch(`${API_URL}/subscription/new/3`, {
        headers: header,
        method: 'POST',
        body: JSON.stringify(state),
      });
      response = await response.json();
      if (response) {
        alert('Correo guardado');
        dispatch({ type: 'name', payload: '' });
        dispatch({ type: 'email', payload: '' });
      } else {
        alert('El correo ya existe');
        dispatch({ type: 'name', payload: '' });
        dispatch({ type: 'email', payload: '' });
      }
    } else {
      alert('El mail no es valido');
    }
  };

  const stylesBanner = bannerStyles(background, width);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });

    return () =>
      window.removeEventListener('resize', () => {
        setWidth(window.innerWidth);
      });
  }, []);

  return (
    <div style={stylesBanner.containerRow}>
      <div style={stylesBanner.containerColumn}>
        <div style={{ ...stylesBanner.boxRight, ...(width < 450 ? { marginBottom: '10px' } : { marginRight: '10px' }) }}>
          <img src={APP_ECOMMERCE === 'enova' ? hotsaleBanners[0] : hotsaleBanners[1]} style={{ borderRadius: '5px' }} alt="banner" />
        </div>
        <div style={{ ...stylesBanner.boxLeft, marginLeft: '10px' }}>
          <div style={stylesBanner.container}>
            <Typography style={stylesBanner.text}>Suscribite y conocé las ofertas antes que nadie</Typography>
          </div>
          <div style={stylesBanner.formContainer}>
            <form>
              <TextField
                value={state?.name}
                variant="standard"
                onChange={(e) => dispatch({ type: 'name', payload: e.target.value })}
                label="Nombre *"
                InputLabelProps={{ style: { fontSize: 15 }, shrink: true }}
                style={stylesBanner.textField}
              />
              <TextField
                value={state?.email}
                onChange={(e) => dispatch({ type: 'email', payload: e.target.value })}
                label="Dejá tu correo aquí *"
                InputLabelProps={{ style: { fontSize: 15 }, shrink: true }}
                style={stylesBanner.textField}
              />
              <Button onClick={() => handleSubmit()} type="button" variant="contained" color="primary" style={stylesBanner.textField}>
                suscribirme
              </Button>
            </form>
          </div>
          <Typography style={{ ...stylesBanner.text, fontSize: '8px', marginTop: '10px', marginLeft: width < 900 ? '10px' : '5px', width: '73%', fontWeight: 400 }}>
            Mediante el presente dejo constancia que he sido debidamente informado respecto de la finalidad para la cual han sido recabados mis datos
            personales y quiénes pueden ser sus destinatarios, como así también del derecho que me asiste a tener acceso a los mismos, como así a
            peticionar su rectificación y/o supresión, todo ello conforme a lo normado en la Ley Nacional N° 25326 (Protección de Datos Personales) y
            su Decreto Reglamentario N° 1558/01.
          </Typography>
        </div>
      </div>
    </div>
  );
};
