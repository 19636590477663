import React, { FunctionComponent } from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { ecommerceConfig } from 'src/config/ecommerce';

const useStyles = makeStyles({
  success: { backgroundColor: ecommerceConfig.ecommercePalette.materialUi.primary.dark },
  error: { backgroundColor: 'red' },
  warning: { backgroundColor: 'yellow' },
  info: { backgroundColor: ecommerceConfig.ecommercePalette.materialUi.info.main },
});

export const ToastProvider: FunctionComponent<any> = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
