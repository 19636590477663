import { ConfigValues, Publication } from '../../../app/models';
import { CartState } from '../../../context/CartContext/types';
import { IPaymentInfo, IPersonalInfo } from '../../../screens/Common/Checkout/initialValues';

export enum TrackEvent {
  ADD_TO_CART = 'AddToCart',
  REMOVE_FROM_CART = 'RemoveFromCart',
  PURCHASE_APPROVED = 'PurchaseApproved',
  PURCHASE_PENDING = 'PurchasePending',
  PURCHASE_FAILED = 'PurchaseFailed',
  START_CHECKOUT = 'StartCheckout',
  PERSONAL_INFO = 'PersonalInfo',
  EQUIFAX_VERIFY = 'EquifaxVerify',
  DELIVERY_INFO = 'DeliveryInfo',
  PAYMENT_METHOD = 'PaymentMethodInfo',
  APPLICANT_INFO = 'ApplicantInfo',
  GAURANTOR_INFO = 'GuarantorInfo',
  VISIT_PUBLICATION = 'VisitPublication',
  VIEW_ITEM_LIST = 'ViewItemList',
  SELECT_PROMOTION = 'SelectPromotion',
}

export interface AddToCartParams {
  publication: Publication;
  quantity: number;
}

export interface RemoveFromCartParams {
  publication: Publication;
  quantity: number;
}

export interface PurchaseEventParams {
  publication?: Publication;
  cart: CartState;
  transactionId?: string;
  userData?: IPersonalInfo | null;
}

export interface StartCheckoutEventParams {
  cart: CartState;
  userData?: IPersonalInfo | null;
  paymentMethod?: IPaymentInfo;
  deliveryType?: string;
}

export interface VisitPublicationEventParams {
  publication: Publication;
}

export interface ViewItemListEventParams {
  publication: Publication;
  listName: string;
}

export interface SelectPromotionEventParams extends ConfigValues {}
