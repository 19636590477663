/* eslint max-len: ["error", { "ignorePattern": "^\\s*var\\s.+=\\s*require\\s*\\(", "code": 1000 }] */
import React from 'react';

export const ShortLogo = () =>
  <svg width="83" height="18" viewBox="0 0 83 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9908 17.1987V14.4467H6.65692C3.82523 14.4467 3.42755 12.733 3.42755 10.2852H12.1487C13.8472 10.2852 14.471 9.06132 14.471 7.22619C14.471 2.02643 11.497 0.799683 7.24879 0.799683C2.15203 0.799683 0 2.63481 0 9.05989C0 14.2596 0.849903 17.2001 5.66337 17.2001L13.9908 17.1987ZM3.42755 7.53324C3.42755 4.77983 4.41977 3.55594 7.25145 3.55594C9.23323 3.55594 11.0461 4.4428 11.0461 6.58497C11.0461 7.04482 10.961 7.53324 10.3092 7.53324H3.42755Z"
      fill="black"
      fillOpacity="0.87"
    />
    <path
      d="M18.3301 17.2002H21.6137V5.2593C21.6137 4.67775 21.8939 4.38651 22.4543 4.38561H25.4503C27.6911 4.38561 28.5593 5.60851 28.5593 7.93881V17.2002H31.8363V7.03522C31.8363 2.95889 29.4275 1.76453 26.0676 1.76453H20.8519C19.1716 1.76453 18.3314 2.63822 18.3314 4.38561V17.2002H18.3301Z"
      fill="black"
      fillOpacity="0.87"
    />
    <path
      d="M42.4483 0.799683C37.2516 0.799683 34.7305 2.61473 34.7305 8.97022C34.7305 15.3837 37.2465 17.2001 42.4483 17.2001C47.6502 17.2001 50.1662 15.3851 50.1662 8.97022C50.161 2.61473 47.645 0.799683 42.4483 0.799683ZM38.0453 8.97022C38.0453 5.03621 39.1657 3.52367 42.4483 3.52367C45.731 3.52367 46.8514 5.03621 46.8514 8.97022C46.8514 12.9042 45.731 14.4832 42.4483 14.4832C39.1657 14.4832 38.0453 12.9085 38.0453 8.97022Z"
      fill="black"
      fillOpacity="0.87"
    />
    <path
      d="M54.8373 1.76453H51.1309L57.3106 15.8464C57.9214 17.2002 58.903 17.2002 59.8214 17.2002C60.7397 17.2002 61.7198 17.2002 62.3019 15.8464L68.496 1.76453H64.7896L60.2568 13.0822C60.1648 13.3121 60.0125 13.6872 59.8257 13.6872C59.6388 13.6872 59.4894 13.3121 59.3945 13.0822L54.8373 1.76453Z"
      fill="black"
      fillOpacity="0.87"
    />
    <path
      d="M77.8838 1.76453H70.9054V4.38561H76.7227C78.1046 4.38561 78.767 5.14245 78.767 6.59906V7.58961H72.1431C69.6559 7.58961 68.4961 9.48238 68.4961 12.3956C68.4961 15.5982 69.6559 17.2002 72.1431 17.2002H79.5164C81.1737 17.2002 82.0023 16.3265 82.0023 14.5792V6.30692C81.9984 3.6872 80.6451 1.76453 77.8838 1.76453ZM78.767 13.7041C78.767 14.2866 78.4913 14.5783 77.9397 14.5792H73.3809C72.2769 14.5792 71.834 13.8509 71.834 12.3942C71.834 10.9376 72.2769 10.2093 73.3809 10.2093H78.767V13.7041Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>