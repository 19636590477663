import React, { useMemo } from 'react';
import { Zone } from 'src/app/models';
import { ZoneContext, ZoneContextValue } from './ZoneContext';
import { useZoneLibbyCall } from './ZoneBusinessGenerated';

export interface ZoneProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const ZoneProvider = ({ children }: ZoneProviderProps) => {
  const { working, data } = useZoneLibbyCall<Zone[]>({ methodName: 'getAll' });

  const value = useMemo<ZoneContextValue>(
    () => ({
      data: data || [],
      working,
    }),
    [data, working],
  );
  return <ZoneContext.Provider value={value}>{children}</ZoneContext.Provider>;
};
