import React, { useCallback } from 'react';
import { uniqBy } from 'lodash';
import { Grid, Typography, ListItem, Box, Divider } from '@material-ui/core';
import { PriceRange } from 'src/app/models/PriceRange';
// import { GenericIcon } from 'src/commons/components/GenericIcon';
import { productFiltersStyles as useStyles } from '../../../../app/views/styles';

export interface ListMenu {
  value: string | PriceRange;
  name: string;
  url: string;
  icon?: string | null;
  relevance?: number | string;
  in_header?: boolean;
}

export interface GenericMenuProps {
  items: Array<ListMenu>;
  onAddFilter: (value: ListMenu) => void;
  onSubMenu: (values: ListMenu[]) => void;
}

export const GenericMenu = ({ items, onAddFilter, onSubMenu }: GenericMenuProps) => {
  const classes = useStyles();
  const getItems = useCallback(() => {
    const singleItems: JSX.Element[] = [];

    items.forEach((item: ListMenu) => {
      const splitedName = item.name.split('.');
      const parentName = [...splitedName];
      parentName.pop();

      const searchParent = uniqBy(items, (item2) => item2).filter(({ name }) => name === parentName.join('.'));

      if (splitedName.length === 1 || searchParent.length >= 0) {
        const search = uniqBy(items, (item2) => item2).filter(({ name }) => name.includes(`${item.name}.`));

        singleItems.push(
          <ListItem disableGutters key={`${item.value}${item.name}`} button>
            <Box display="flex" px={2} alignItems="center" justifyContent="space-between" width="100%">
              <Box
                width="100%"
                onClick={() => {
                  onAddFilter(item);
                }}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                {/* <Box paddingRight=".6rem">{item.icon &&  <GenericIcon width="16px" height="16px" image={item.icon} />}</Box> */}
                {splitedName.length > 1 ? (
                  <Typography className={classes.boxSubCategory} variant="subtitle1" color="textSecondary">
                    {splitedName[splitedName.length - 1]}
                  </Typography>
                ) : (
                  <Typography className={classes.boxCategory} variant="subtitle1" color="textSecondary">
                    {splitedName}
                  </Typography>
                )}
              </Box>

              {search.length > 0 && (
                <Box
                  pl={1}
                  onClick={() => {
                    onSubMenu(search);
                  }}
                />
              )}
            </Box>
          </ListItem>,
        );
      }
    });

    return [...singleItems];
  }, [classes.boxCategory, classes.boxSubCategory, items, onAddFilter, onSubMenu]);

  return (
    <Grid container direction="column">
      <Grid item>
        {getItems().map((element) => (
          <>
            {element}
            {element.key === 'Todos los productos' && <Divider />}
          </>
        ))}
      </Grid>
    </Grid>
  );
};
