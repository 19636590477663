import { EcommerceGeneral } from '../../../app/models/general/ecommerceGeneral';

export const general: EcommerceGeneral = {
  nameMp: 'Novastore',
  address: 'Uspallata 2776, Parque Patricios',
  contactUrl: 'contacto@novatechstore.com.ar',
  facebookUrl: 'https://www.facebook.com/NovatechStoreArg',
  instagramUrl: 'https://www.instagram.com/novatechstore',
  title: 'Novatech Store',
  description: 'Tienda online especializada en tecnología pensada para vos',
  whatsapp: '5491151414869'
};
