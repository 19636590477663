import React from 'react';
import { Grid, Box, IconButton, Typography, GridJustification } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { LOGIN_ENABLED } from 'src/config';

interface FavoriteButtonViewProps {
  isFavorite: boolean;
  hidden?: boolean;
  top?: string | number;
  right?: string | number;
  position?: 'relative' | 'absolute';
  fontSize?: 'small' | 'inherit' | 'default' | 'large';
  countFavorites?: number;
  label?: React.ReactNode;
  justify?: GridJustification;
  onAddFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRemoveFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const FavoriteButtonView = ({
  isFavorite,
  fontSize = 'default',
  hidden,
  top,
  right,
  position = 'absolute',
  countFavorites,
  label,
  justify = 'flex-end',
  onAddFavorite,
  onRemoveFavorite,
}: FavoriteButtonViewProps) =>
  LOGIN_ENABLED ? (
    <Box width="100%" display={hidden ? 'none' : 'flex'} hidden={hidden} zIndex={1000} position={position} top={top} right={right}>
      <Grid container justify={justify} alignItems="center">
        {countFavorites !== undefined && (
          <Box mr={1} color="#737373">
            <Typography variant="subtitle2">{countFavorites}</Typography>
          </Box>
        )}
        <IconButton size="small" onClick={isFavorite ? onRemoveFavorite : onAddFavorite}>
          {isFavorite ? <FavoriteIcon color="primary" fontSize={fontSize} /> : <FavoriteBorderIcon color="primary" fontSize={fontSize} />}
        </IconButton>
        {label && label}
      </Grid>
    </Box>
  ) : (
    <></>
  );
