import { useMemo, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { City, State } from '../../../../../../app/models';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { useLocationLibbyCall, useLocationDAO } from '../../../../../../app/business';
import { useDebounce } from '../../../../../../commons';
import { PROVINCES } from '../../../../../../app/const';
import { checkoutAllValues, docentInitialValue } from '../../../initialValues';

export const useGenerateValues = () => {
  const { form, values } = useCheckoutFormContext();
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const locationDAO = useLocationDAO();
  const { data = [], working } = useLocationLibbyCall<State[]>({ methodName: 'getStates' });

  const valuesAll = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const [search, setSearch] = useState(valuesAll.docentInfo?.city?.name || '');
  const searchDebunce = useDebounce(search, 600);

  const selectedProvince = useMemo(() => valuesAll.docentInfo?.province?.id !== '', [valuesAll.docentInfo.province?.id]);

  const provinces = useMemo(() => {
    let provincesFiltered: State[] = data?.filter((item) => item.id.toString() === PROVINCES.CATAMARCA.toString()) || [];
    if (valuesAll.useDeliveryAddress) {
      provincesFiltered = [...provincesFiltered, valuesAll.deliveryInfo.province];
    }
    return provincesFiltered;
  }, [data, valuesAll.useDeliveryAddress, valuesAll.deliveryInfo.province]);

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  useEffect(() => {
    if ((valuesAll.docentInfo?.city?.name?.length > 0 && valuesAll.docentInfo?.city?.name !== search) || valuesAll.docentInfo?.city === null) {
      const copyValuesForm = { ...values, docentInfo: valuesAll.docentInfo };
      copyValuesForm.docentInfo.city = docentInitialValue.city;
      form.reset(copyValuesForm);
    }
  }, [search, values, form, valuesAll]);

  useEffect(() => {
    if (valuesAll.useDeliveryAddress && search !== valuesAll.deliveryInfo?.city?.name) {
      setSearch(valuesAll.deliveryInfo?.city?.name);
    } else if (!valuesAll.useDeliveryAddress && search === valuesAll?.deliveryInfo?.city?.name) {
      setSearch('');
    }
  }, [valuesAll.useDeliveryAddress, valuesAll.deliveryInfo?.city?.name, search]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== valuesAll.docentInfo?.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(valuesAll.docentInfo?.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, valuesAll.docentInfo?.province, searchDebunce, valuesAll.docentInfo?.city]);

  return { cities, search, provinces, loading, loadingProvinces: working, onInputChangeCity };
};
