import React, { useState, useEffect, useCallback } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container, Grid, Typography, Box } from '@material-ui/core';
import parse from 'react-html-parser';
import { useSkeletonDAO } from 'src/app/business/Skeleton';
import { Skeleton, initialContentState } from '../../../app/models';

interface Data {
  question: string;
  answer: string;
}

const initialJsonState = {
  header: '',
  data: new Array<Data>(),
};

export const Faqs = () => {
  const [content, setContent] = useState<Skeleton>(initialContentState);
  const [json, setJson] = useState(initialJsonState);
  const skeletonDAO = useSkeletonDAO();

  const getJson = useCallback(async () => {
    if (content.skeleton_components.length) setJson(JSON.parse(content.skeleton_components[0].url));
  }, [content.skeleton_components]);

  const getContent = useCallback(async () => {
    const res = await skeletonDAO.getSkeleton('6');
    setContent(res);
    getJson();
  }, [getJson, skeletonDAO]);

  useEffect(() => {
    if (!json.data.length) getContent();
  }, [getContent, json]);

  return (
    <div>
      {json ? parse(json.header) : null}
      <Container>
        <Box mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              {json
                ? json.data.map((item: Data) => (
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="subtitle1" color="textPrimary">
                          {item.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>{parse(item.answer)}</AccordionDetails>
                    </Accordion>
                  ))
                : null}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};
