import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { StepFormRecord } from 'src/commons';
import ReactHtmlParser from 'react-html-parser';

export interface RecoveryViewProps {
  steps: StepFormRecord[];
  activeStep: number;
  email: string;
}

export const RecoveryView = ({ steps, activeStep, email }: RecoveryViewProps) => {
  const Comp = steps[activeStep].render;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              {steps[activeStep].title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Typography variant="body1" color="textSecondary">
              {ReactHtmlParser(steps[activeStep].subTitle.replace('$1', email))}
            </Typography>
          </Grid>
        </Grid>

        <Comp />
      </Grid>
    </>
  );
};
