import { BankNoInterest, BankOptions } from '../interfaces';

export const filterInterestRate = (map: Map<number, { bankOptions: BankOptions[]; bankNoInterest: BankNoInterest[] }>) => {
  const installments = Array.from(map.keys());

  installments.forEach((installment) => {
    const data = map.get(installment);
    if (data) {
      const { bankOptions } = data;
      let bestInterestRate = Infinity;
      const newBankOptions = bankOptions.reduce((acc: any, curr: any) => {
        if (curr.interest < bestInterestRate) {
          acc = [curr];
          bestInterestRate = curr.interest;
        } else if (curr.interest === bestInterestRate) {
          acc.push(curr);
        }
        return acc;
      }, []);
      map.set(installment, { ...data, bankOptions: newBankOptions });
      return newBankOptions;
    }
  });
};
