import { LibbyCallReturn, makeEntityLibbyCall } from '../hooks';

export interface WithRenderPropCallProps<T> {
  methodName: string;
  params?: any[];
  aspect?: string;
  children: (result: LibbyCallReturn<T>) => JSX.Element;
}

export const withRenderPropCall = (daoName: string) => {
  const useHook = makeEntityLibbyCall(daoName);
  return <T = any>({ methodName, params = [], aspect, children }: WithRenderPropCallProps<T>) => {
    const result = useHook<T>({ methodName, params, aspect });
    return children(result);
  };
};
