import ReactPixel from 'react-facebook-pixel';

export interface EventParams {
  content_name: string;
  content_ids: Array<any>;
}
export interface PurchaseEventParams {
  currency: string;
  value: number;
}
export interface CheckOutEventParams {
  date: any;
  url: any;
  content_name: string;
  content_ids: Array<any>;
}

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class FacebookPixel {
  initialize() {
    ReactPixel.init('769594007067633', undefined, options);
  }

  track(event: string, { content_name, content_ids }: EventParams) {
    ReactPixel.trackCustom(event, { content_name, content_ids });
  }

  purchaseTrack(event: string, { currency, value }: PurchaseEventParams) {
    ReactPixel.track(event, { currency, value });
  }

  checkOutTrack(event: string, { date, url, content_name, content_ids }: CheckOutEventParams) {
    ReactPixel.track(event, { date, url, content_name, content_ids });
  }
}

const i: FacebookPixel = new FacebookPixel();
export { i as FacebookPixel };
