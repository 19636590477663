import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { BuyerAddressInfo } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'buyer_address';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'buyer_address_id',
      labelKey: 'name',
    },
    withSelectedChips: {
      idKey: 'buyer_address_id',
      labelKey: 'name',
    },
  },
};

const optionsToUse = !businessOptions.BuyerAddressInfo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.BuyerAddressInfo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.BuyerAddressInfo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<BuyerAddressInfo>(DAO_NAME, optionsToUse);

// hooks
export const useBuyerAddressInfoFetchById = fetchById;
export const useBuyerAddressInfoLibbyCall = libbyCall;
export const useBuyerAddressInfoDAO = libbyDAO;
export const useBuyerAddressInfoLibbyFetch = libbyFetch;

// components/hoc
export const BuyerAddressInfoAutocomplete = entityAutocomplete;
export const BuyerAddressInfoCall = renderPropCall;
export const BuyerAddressInfoById = renderPropFetchById;
export const BuyerAddressInfoSelect = simpleSelect;
export const BuyerAddressInfoAccordionSelector = accordionSelector;
export const BuyerAddressInfoSelectedChips = selectedChips;

// context
// ByIdContext
export const useBuyerAddressInfoByIdContext = useByIdContext;
export const BuyerAddressInfoByIdProvider = ByIdProvider;
export const BuyerAddressInfoByIdContext = ByIdContext;
// GeneralContext
// export const useBuyerAddressInfoContext = useGeneralContext;
// export const BuyerAddressInfoProvider = GeneralProvider;
// export const BuyerAddressInfoContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
