export type Confirmation = {
  confirmation_type_id?: string;
  email: string;
};

export const CONFIRMATION_STATUS = {
  SUCCESS: 1,
  FAILED: 2,
};

export interface VerificationCode {
  code_id?: number;
  code: string;
  send_to: string;
  expiration: string;
  created_at?: string;
  verification_status_id: number;
}

export enum VERIFICATION_STATUS {
  QUEUED = 1,
  SENT,
  FAILED,
  VERIFIED,
  EXPIRED,
  CANCELED,
}

export interface VerifyCode {
  isValid: boolean;
  verification_status_id: number;
}
