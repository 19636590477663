import React, { FunctionComponent, ReactNode } from 'react';
import { KeyboardDatePicker as MuiDatePicker, DatePickerView } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { InputLabelProps, InputProps } from '@material-ui/core';

interface DatePickerProps {
  value: ParsableDate;
  format?: string;
  label: ReactNode;
  error?: boolean;
  helperText?: string;
  clearable?: boolean;
  openTo?: DatePickerView;
  placeholder?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  views?: DatePickerView[];
  inputLabelProps?: InputLabelProps;
  inputProps?: InputProps;
  fullWidth?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  onChange: (date: MaterialUiPickersDate) => void;
  InputAdornmentProps?: { position: 'start' | 'end' | undefined };
}

export const DatePicker: FunctionComponent<DatePickerProps> = ({
  value,
  error,
  format = 'DD/MM/yyyy',
  onChange,
  clearable,
  helperText,
  placeholder = 'dd/mm/aaaa',
  disablePast,
  openTo,
  disableFuture,
  views,
  label,
  inputLabelProps,
  inputProps,
  variant,
  fullWidth,
  InputAdornmentProps,
}) => <MuiDatePicker
  disableFuture={disableFuture}
  fullWidth={fullWidth}
  error={error}
  value={value}
  okLabel="Aceptar"
  clearLabel="Limpiar"
  cancelLabel="Cancelar"
  format={format}
  openTo={openTo}
  views={views}
  onChange={onChange}
  clearable={clearable}
  helperText={helperText}
  disablePast={disablePast}
  placeholder={placeholder}
  InputLabelProps={inputLabelProps}
  InputProps={inputProps}
  inputVariant={variant}
  label={label}
  InputAdornmentProps={InputAdornmentProps}
/>


