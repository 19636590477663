import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  padding: {
    paddingLeft: 16,
    paddingRight: 16,
    '@media (min-width: 600px)': {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
});

/* eslint-disable */
export type PaddingProps<T = any> = {
  children: JSX.Element | JSX.Element[];
};

export const Padding: FunctionComponent<PaddingProps> = ({ children }) => {
  const classes = useStyles();
  return <Grid className={classes.padding}>{children}</Grid>;
};
