import React, {  useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid } from '@material-ui/core';
import { CategoriesView } from 'src/app/views';
import { useParams } from 'react-router';
import { sortOptions, SEARCH_TYPES, useSearch, useFavoriteManage } from 'src/commons';
import { usePublicationByZone } from 'src/app/business/PublicationByZone/usePublicationByZone';
import { useCategory } from 'src/app/business/Category/useCategory';
import { ZONE } from 'src/app/const';
import { useGeneralContext } from 'src/context';
import { useScrollToTopOnMount } from 'src/commons/hooks/useScrollToTopUnMount';
import { useZoneContext } from 'src/app/business';
import { Publication } from '../../../app/models/publication';
import { FeatureGroup } from '../../../app/models/FeatureGroup';
import { ecommerceConfig } from '../../../config/ecommerce';

const emptyArray: any[] = [];

const SearchRAW = () => {
  useScrollToTopOnMount();
  const params = useParams<Record<string, string>>();
  const { data } = useZoneContext();

  const isZone = useMemo(() => !!data.find((zone) => zone.zone_id === params.value), [data, params.value]);

  const paramsFetch = useMemo(() => (isZone ? params.value : ZONE.ALL), [params.value, isZone]);

  const { data: publications = [], working } = usePublicationByZone(paramsFetch);

  const { data: categories } = useCategory();
  const { dispatch } = useGeneralContext();

  const publicationsFixed = useMemo<Publication[]>(
    () =>
      publications
        ? publications.map((pub: Publication) => ({
            ...pub,
            attributes: {
              ...pub.attributes,
              features:
                pub.attributes?.features?.map((group: FeatureGroup) => ({
                  ...group,
                  features: group.features || [],
                })) || [],
            },
          }))
        : [],
    [publications],
  );

  const {
    list,
    pagination,
    onChangePagination,
    filterContent,
    onAddFilter,
    onRemoveFilter,
    filters,
    sortOpenModal,
    setSortOpenModal,
    setSortFilter,
    sortFilter,
    freeShipment,
    setFreeShipment,
  } = useSearch({
    searchType: SEARCH_TYPES.all,
    publications: publicationsFixed || emptyArray,
    categoriesSource: categories || emptyArray,
    byZone: isZone,
  });

  useEffect(() => {
    if (params.value && !isZone) {
      dispatch.setSearch(params.value);
    }
  }, [params.value, dispatch, isZone]);

  const { favorites, addToFavorite, removeFromFavorite } = useFavoriteManage();

  return (
    <>
      <Helmet>
        <title>
          {ecommerceConfig.general.title} - {params.value || 'Todos los productos'}
        </title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid>
          <Grid container>
            <Grid item style={{ width: '100%' }}>
              {!working && list ? (
                <CategoriesView
                  sortOptions={sortOptions}
                  sortOpenModal={sortOpenModal}
                  setSortOpenModal={setSortOpenModal}
                  setSortFilter={setSortFilter}
                  sortFilter={sortFilter}
                  products={list}
                  filterContent={filterContent}
                  selectedFilters={filters}
                  onAddFilter={onAddFilter}
                  onRemoveFilter={onRemoveFilter}
                  onChangePagination={onChangePagination}
                  pagination={pagination}
                  defaultSearchText={params.value && isZone ? 'Ofertas' : 'Todos los Productos'}
                  searchType={SEARCH_TYPES.all}
                  freeShipment={freeShipment}
                  setFreeShipment={setFreeShipment}
                  favorites={favorites}
                  onRemoveFavorite={(item) => removeFromFavorite(item.productId.toString())}
                  onAddFavorite={(item) => addToFavorite(item.productId.toString())}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export const Search = () => {
  const { working } = useZoneContext();
  return working ? null : <SearchRAW />;
};
