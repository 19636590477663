import { TemplateProperty } from 'src/lib/templates';

export const shipmentAddressTemplate: TemplateProperty = {
  name: 'shipmentAddress',
  type: {
    name: 'object',
    kind: [
      {
        name: 'province',
        type: {
          name: 'object',
          kind: [
            { name: 'id', type: { name: 'string' } },
            { name: 'name', type: { name: 'string' } },
            { name: 'matches', type: { name: 'array', kind: 'string' } },
          ],
        },
        label: 'Provincia',
      },
      {
        name: 'city',
        type: {
          name: 'object',
          kind: [
            { name: 'id', type: { name: 'string' } },
            { name: 'state_id', type: { name: 'number' } },
            { name: 'zip', type: { name: 'array', kind: 'number' } },
            { name: 'name', type: { name: 'string' } },
            { name: 'matches', type: { name: 'array', kind: 'string' } },
          ],
        },
        label: 'Ciudad',
      },
      {
        name: 'street',
        type: { name: 'string' },
        label: 'Calle',
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
          { name: 'max', signature: [25] },
        ],
      },
      {
        name: 'streetNumber',
        type: { name: 'string' },
        label: 'Altura',
        validators: [
          {
            name: 'pattern',
            signature: ['^[0-9]+$'],
          },
        ],
      },
      {
        name: 'zipCode',
        type: { name: 'string' },
        label: 'Código postal',
        optional: true,
        validators: [
          { name: 'pattern', signature: ['^[0-9]+$'] },
          { name: 'max', signature: [6] },
          { name: 'empty', errorMessage: 'Completa este dato.' },
        ],
      },
      {
        name: 'deliveryCost',
        type: { name: 'number' },
        label: 'Costo de envío',
        validators: [{ name: 'pattern', signature: ['^[0-9]+$'] }],
      },
    ],
  },
};
