import React, { useCallback } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useField } from 'react-final-form-hooks';
import { grey } from 'src/theme/colors';
import { MainButton } from '../../../../commons/components/MainButton';
import { FormMultiField, GetRender } from './components/FormMultiField';

const useStyles = makeStyles({
  label: {
    marginBottom: 8,
    fontSize: 13,
    fontWeight: 600,
    color: grey.medium,
  },
});
export interface FormMultiProps {
  name: string;
  label: string;
  form: any;
  getItemKey?: (value: any, index: number) => string;
  children: GetRender;
  addButtonText?: string;
  getNewElement: (values: any) => any | Promise<any>;
  renderAddButton?: (onClick: () => void) => JSX.Element;
}

// <TextField
//   key={`${item}${index}`}
//   value={item}
//   onChange={({ target: { value } }) => {
//     const newInput = [...objetivos.input.value];
//     newInput[index] = value;
//     objetivos.input.onChange(newInput);
//   }}
// />

export const FormMulti = ({
  name,
  form,
  label,
  getItemKey,
  children,
  addButtonText,
  getNewElement,
  renderAddButton,
}: FormMultiProps): JSX.Element => {
  const classes = useStyles();
  const field = useField(name, form);
  const onChange = useCallback(
    (value, index) => {
      const newValues = field.input.value ? [...field.input.value] : [];
      newValues[index] = value;
      field.input.onChange(newValues);
    },
    [field.input],
  );
  const onAddClick = useCallback(async () => {
    const newInput = [...field.input.value];
    const newElement = await getNewElement(newInput);
    newInput.push(newElement);
    field.input.onChange(newInput);
  }, [field.input, getNewElement]);
  const onRemoveItem = useCallback(
    (index) => {
      const newInput = field.input.value.filter((item: any, ind: number) => index !== ind);
      field.input.onChange(newInput);
    },
    [field.input],
  );
  return (
    <div>
      <Typography className={classes.label}>{label}</Typography>
      {!field.input.value
        ? null
        : field.input.value.map((item: any, index: number) => {
            const error = !field.meta.error ? null : field.meta.error[index];
            return (
              <FormMultiField
                key={getItemKey ? getItemKey(item, index) : `${index}`}
                value={item}
                onChange={onChange}
                index={index}
                getRender={children}
                onBlur={field.input.onBlur}
                onFocus={field.input.onFocus}
                onRemove={onRemoveItem}
                error={error}
              />
            );
          })}
      {renderAddButton ? (
        renderAddButton(onAddClick)
      ) : (
        <MainButton
          title={addButtonText}
          type="info"
          handleOnClick={onAddClick}
          customStyle={{
            color: grey.textPrimary,
            border: '1px solid',
            borderColor: grey.normal,
            marginTop: 5,
          }}
        />
      )}
    </div>
  );
};
