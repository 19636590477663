import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { City, Publication, Shipment, State } from 'src/app/models';
import { useLocationDAO, useLocationLibbyCall, useShipmentDAO } from 'src/app/business';
import { useField } from 'react-final-form-hooks';
import { orderBy } from 'lodash';
import { useDebounce } from 'src/commons';
import { useShipmentAddressFormContext } from '../../hooks/ShipmentAddressFormContext';
import { initialValue } from '../../initialValues';
import { ShipmentAddressView } from '../ShipmentAddressView';
import { ShipmentData, ShipmentInfo } from '../../types';

interface ShipmentAddressFormProps {
  shipmentData: ShipmentData;
  publication?: Publication;
  loadingShipment: boolean;
  quantity: number;
  onChangeAddress: () => void;
}

export const ShipmentAddressForm = ({ onChangeAddress, loadingShipment, shipmentData, publication, quantity }: ShipmentAddressFormProps) => {
  const { form, values, errors, handleSubmit } = useShipmentAddressFormContext();
  const [cities, setCities] = useState<City[]>([]);
  const [deliveryData, setDeliveryData] = useState<Shipment>({ cost: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState(values.shipmentAddress?.city?.name || '');
  const searchDebunce = useDebounce(search, 600);
  const locationDAO = useLocationDAO();
  const shiptemntDAO = useShipmentDAO();
  const field = useField('shipmentAddress.deliveryCost', form);

  const { data: provinceData, working } = useLocationLibbyCall<State[]>({ methodName: 'getStates' });

  const data = provinceData?.filter((province) => province.name !== 'default');

  const selectedProvince = useMemo(() => values?.shipmentAddress?.province?.id !== '', [values.shipmentAddress?.province?.id]);

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  useEffect(() => {
    if (!deliveryData.cost || values.shipmentAddress?.deliveryCost > 0) return;
    field.input.onChange(deliveryData.cost);
  }, [deliveryData.cost, field.input, values.shipmentAddress?.deliveryCost]);

  useEffect(() => {
    (async () => {
      if (values.shipmentAddress?.province?.id !== '') {
        const shipmentData: ShipmentInfo[] = [{ quantity, shipment_category_id: publication?.shipment_category_id || 1 }];
        const dataCost: Shipment = await shiptemntDAO.calculate(values.shipmentAddress?.province?.id, publication?.best[0].amount, shipmentData);
        setDeliveryData(dataCost);
      }
    })();
  }, [values.shipmentAddress?.province, publication?.best, quantity, publication?.shipment_category_id, shiptemntDAO, setDeliveryData]);

  useEffect(() => {
    if (
      (values?.shipmentAddress?.city?.name?.length > 0 && values?.shipmentAddress?.city?.name !== search) ||
      values?.shipmentAddress?.city === null
    ) {
      const copyValuesForm = { ...values };
      copyValuesForm.shipmentAddress.city = initialValue?.shipmentAddress?.city;
      form.reset(copyValuesForm);
    }
  }, [search, values?.shipmentAddress?.city, form, values]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== values?.shipmentAddress?.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(values?.shipmentAddress?.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, values?.shipmentAddress?.province, searchDebunce, values?.shipmentAddress?.city]);

  const validateZipCode = useCallback(async (value: any, allValues: any) => {
    const regex = new RegExp('^[0-9]+$');
    if (!regex.test(value)) {
      return 'Solo podés ingresar números.';
    }
    if (value.length > 6) {
      return 'Código postal inválido.';
    }
    if (value === '') {
      return 'Completa este dato.';
    }
    if (value && !allValues.shipmentAddress.city?.zip?.includes(Number(value))) {
      return 'El código postal no coincide.';
    }
  }, []);

  return (
    <ShipmentAddressView
      title="Calculá el costo del envío de tu producto, así como el tiempo aproximado para que llegue a tu domicilio."
      onInputChangeCity={onInputChangeCity}
      form={form}
      loading={working}
      loadingCities={loading}
      provinces={orderBy(data, 'name')}
      cities={cities}
      selectedProvince={selectedProvince}
      searchCityValue={search}
      onClickCalculate={handleSubmit}
      disableCalculate={Object.keys(errors as any).length !== 0}
      deliveryData={deliveryData}
      deliveryTime={shipmentData.deliveryDate}
      loadingShipment={loadingShipment}
      onChangeAddress={onChangeAddress}
      valuesAddress={values.shipmentAddress}
      validateZipCode={validateZipCode}
    />
  );
};
