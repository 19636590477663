import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, InputAdornment } from '@material-ui/core/';

interface MainInputTypesProps {
  value: string | number;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  onSubmit?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (value: React.FocusEvent<HTMLInputElement>) => void;
  placeholder: string;
  label?: string;
  icon?: JSX.Element;
  iconPossition?: 'start' | 'end';
  // maxLength?: number;
  type?: string;
  fullWidth?: boolean;
  rows?: number | string;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  customStyle?: any;
  customStyleLabel?: any;
  name?: string;
  size?: 'medium' | 'small' | undefined;
  customClass?: string;
  autoFocus?: boolean;
  id?: string;
}

export const MainInput = ({
  label,
  icon,
  iconPossition = 'start',
  fullWidth = false,
  rows = 0,
  type,
  multiline = false,
  handleChange,
  placeholder,
  required,
  disabled,
  error = false,
  helperText = '',
  value,
  customStyle,
  customStyleLabel,
  name,
  size,
  customClass,
  onKeyPress,
  onSubmit,
  onBlur,
  autoFocus,
  id,
}: MainInputTypesProps) => {
  const inputStyles = makeStyles(() => ({
    root: {
      ...customStyle,
      padding: '7px',
    },
    label: {
      ...customStyleLabel,
    },
  }));

  const classes = inputStyles();

  const iconAddornment = icon
    ? {
        [iconPossition === 'start' ? 'startAdornment' : 'endAdornment']: <InputAdornment position={iconPossition}>{icon}</InputAdornment>,
      }
    : {};

  return (
    <article style={{ width: '100%' }}>
      {label && (
        <Typography align="left" variant="subtitle2" className={classes.label}>
          {label}
        </Typography>
      )}
      <TextField
        name={name}
        InputProps={{
          disableUnderline: true,
          ...iconAddornment,
          className: [classes.root, customClass].join(' '),
          inputProps: { min: 0, id },
        }}
        error={error}
        rows={rows}
        type={type}
        fullWidth={fullWidth}
        multiline={multiline}
        helperText={error && helperText}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={(_value: React.ChangeEvent<HTMLInputElement>) => handleChange(_value)}
        onKeyPress={(_value: React.KeyboardEvent<HTMLInputElement>) => onKeyPress && onKeyPress(_value)}
        onBlur={onBlur}
        value={value}
        size={size}
        onSubmit={onSubmit}
        autoFocus={autoFocus}
      />
    </article>
  );
};
