import { routes } from './routes';
import { MenuItem } from '../../../../commons';

export const promotionsMenu: MenuItem = {
  key: 'publicPromotions',
  basePath: '/promotions/:id',
  enabled: true,
  icon: 'List',
  text: 'Promotions',
  children: routes,
};
