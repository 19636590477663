import { useMediaQuery, Theme } from '@material-ui/core';
import { useMemo } from 'react';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export const useBreakpointsState = () => {
  const xs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
  const sm = useMediaQuery<Theme>((theme) => theme.breakpoints.only('sm'));
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.only('md'));
  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.only('lg'));
  const xl = useMediaQuery<Theme>((theme) => theme.breakpoints.only('xl'));
  const useMakeUp = (value: Breakpoint | number) => useMediaQuery<Theme>((theme) => theme.breakpoints.up(value));
  const useMakeDown = (value: Breakpoint | number) => useMediaQuery<Theme>((theme) => theme.breakpoints.down(value));
  return useMemo(() => ({ xs, sm, md, lg, xl, useMakeUp, useMakeDown }), [xs, sm, md, lg, xl]);
};
