import { PUBLICATION_TYPE } from 'src/app/const';
import { EcommerceDAO } from './EcommerceDAO';
import { Publication } from '../../../app/models';
import { SHOW_PRODUCTS_WITH_OUT_STOCK } from '../../../config';

const PublicationExclude = [PUBLICATION_TYPE.BANNER, PUBLICATION_TYPE.TIMER, PUBLICATION_TYPE.IMAGES];

export class PublicationByZoneDAO extends EcommerceDAO<Publication> {
  constructor() {
    super('publication_by_zone', 'publicationId');
  }

  async getByZone(zone: string): Promise<Publication[]> {
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/publication/zone/${zone}`);
    let publications: Publication[] = await response.json();
    if (!SHOW_PRODUCTS_WITH_OUT_STOCK) {
      publications = publications.filter((publi) => PublicationExclude.includes(publi.type as PUBLICATION_TYPE) || publi.has_stock);
    }
    return publications;
  }

  async getNameByZone(zone: string) {
    const response = await this.fetchData(`/publication/zonename/${zone}`);
    const result = await response.text();

    return result;
  }
}
