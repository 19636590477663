import React, { useMemo } from 'react';
import { Grid, Typography, Box, makeStyles, useMediaQuery, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Product } from 'src/app/models/business/Product';
import { normalizePublications } from 'src/utils';
import { ScrollCarousel } from 'src/commons/ui/view/ScrollCarousel';
import { Publication, PublicationFavoriteInfo } from '../../../models';
import { ProductCardView } from '../ProductCard';

export interface ProductCarouselProps {
  publications: Publication[];
  title: string;
  zone: string;
  favorites: PublicationFavoriteInfo[];
  toLink?: (event: any) => void;
  onAddFavorite: (value: Product) => void;
  onRemoveFavorite: (value: Product) => void;
}
const useStyles = makeStyles(() => ({
  principalContainer: {
    marginBottom: ({ isDownSm }: { isDownSm: boolean }) => (isDownSm ? '5vw' : '3vw'),
  },
  linkVerOfertas: {
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    color: '#8C8C8C',
    textDecoration: 'none',
  },
}));

export const ProductCarousel = ({ publications, title, zone, favorites, toLink, onAddFavorite, onRemoveFavorite }: ProductCarouselProps) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm')); // 2
  const isDownMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md')); // 2
  const classes = useStyles({ isDownSm });
  const items = useMemo<Product[]>(() => normalizePublications(publications), [publications]);

  const arrayComponentsToBeRender = items.map((item) => (
    <Grid key={item.productId}>
      <ProductCardView
        listName={title}
        data={item}
        isFavorite={!!favorites.find((fav) => fav.publication.id === item.productId)}
        onAddFavorite={() => onAddFavorite(item)}
        onRemoveFavorite={() => onRemoveFavorite(item)}
        tracking
      />
    </Grid>
  ));
  return !items.length ? (
    <h1>NO HAY PUBLICACIONES EN &#34;{title}&#34;</h1>
  ) : (
    <Grid container direction="row" className={classes.principalContainer}>
      <Grid item container direction="row" alignItems="flex-end">
        <Box pr={1}>
          <Typography variant={isDownMd ? 'h5' : 'h4'} color="textPrimary">
            {title}
          </Typography>
        </Box>

        <Box mb="3px">
          {toLink ? (
            <Typography component="h6" variant="body2" onClick={toLink} style={{ cursor: 'pointer' }}>
              <Box component="p" color="action.cta" m={0}>
                Ver más
              </Box>
            </Typography>
          ) : (
            <Typography>
              <Link className={classes.linkVerOfertas} to={`/search/${zone}`}>
                Ver más ofertas
              </Link>
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ScrollCarousel data={arrayComponentsToBeRender} />
      </Grid>
    </Grid>
  );
};
