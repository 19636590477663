import { TemplateProperty } from '../../../../lib/templates';
import { addressTemplate } from './common';

export const docentInfoTemplate: TemplateProperty = {
  name: 'docentInfo',
  type: {
    name: 'object',
    kind: [
      ...addressTemplate.map((temp) => {
        if (temp.name === 'province') {
          return { ...temp, validators: [{ name: 'province', signature: [3] }] };
        }
        return temp;
      }),
      {
        name: 'docentType',
        type: {
          name: 'object',
          kind: [
            { name: 'id', type: { name: 'number' } },
            { name: 'name', type: { name: 'string' } },
          ],
        },
        label: 'Tipo de Docente',
      },
      {
        name: 'img_dni_front',
        type: { name: 'any' },
        label: 'DNI frente',
        validators: [
          { name: 'fileSize', signature: [40000] },
          { name: 'fileType', signature: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword'] },
        ],
      },
      {
        name: 'img_dni_back',
        type: { name: 'any' },
        label: 'DNI dorso',
        validators: [
          { name: 'fileSize', signature: [40000] },
          { name: 'fileType', signature: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword'] },
        ],
      },
      {
        name: 'img_certificate',
        type: { name: 'any' },
        label: 'Certificado',
        validators: [
          { name: 'fileSize', signature: [40000] },
          { name: 'fileType', signature: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword'] },
        ],
      },
      {
        name: 'img_paycheck',
        type: { name: 'any' },
        label: 'Recibo de sueldo',
        validators: [
          { name: 'fileSize', signature: [40000] },
          { name: 'fileType', signature: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword'] },
        ],
      },
    ],
  },
};
