import { Divider, Box, Typography, Button } from '@material-ui/core';
import React from 'react';
import { HOP, HOPPoints } from 'src/app';
import { ModalDialog } from 'src/commons';
// import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { Map } from './Map';

export interface MapModalProps {
  center: HOPPoints;
  open: boolean;
  handleClose: () => void;
  row: HOP;
  mobile: boolean;
}

export const MapModal = ({ center, open, handleClose, row, mobile }: MapModalProps) => {
  const { province, city, zip, street, number } = row.shipment_hop_direction;
  const coordinates = `${row.coordinates.latitud},${row.coordinates.longitud}`;
  const address = `${province}, ${city}, CP ${zip}, ${street} ${number}`;
  // const { form } = useCheckoutFormContext();

  const savingHOPData = (data: HOP) => {
    console.log(data);
    // form.change('retirement_points', data);
  };

  const limitChars = (title: string) => {
    if (title.length >= 25) {
      return `${title.slice(0, 25)}...`;
    }
    return title;
  };

  return (
    <ModalDialog center title="" open={open} onHandleClose={handleClose}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
        <Typography variant={mobile ? 'h6' : 'h4'} color="primary">
          {limitChars(row.description)}
        </Typography>
        <Divider variant="middle" style={{ background: '#000' }} />
        <Box margin="1rem 0">
          <Typography color="textPrimary">{address}</Typography>
          <Typography>{row.service_hours}</Typography>
          <Typography>{coordinates}</Typography>
        </Box>

        <Map mobile={mobile} center={center} />
        <Button
          style={{ margin: '1rem 0' }}
          variant="contained"
          color="primary"
          onClick={() => {
            handleClose();
            savingHOPData(row);
          }}
        >
          Seleccionar
        </Button>
      </Box>
    </ModalDialog>
  );
};
