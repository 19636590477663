// import { homeMenu } from './home';
import { MenuItem } from '../../../commons';
import { loginMenu } from './login';
import { categoriesMenu } from './categories';
import { productsMenu } from './products';
import { cartMenu } from './cart';
import { searchMenu } from './search';
import { aboutMenu } from './about';
import { faqsMenu } from './faqs';
import { userPolicyMenu } from './politicas/userPolicy';
import { privacyPolicyMenu } from '../Private/politicas/privacyPolicy';
import { comoComprarMenu } from './comoComprar';
import { promotionsMenu } from './promotions';
import { customRedirectsMenu } from './customredirects';
import { regretMenu } from './regret';
import { registerMenu } from './register';
import { facebookMenu } from './facebook';
import { TermsAndConditionsQuantumMenu } from './politicas/termsAndConditionsQuantum';
import { TermsAndConditionsLotteryMenu } from './politicas/termsAndConditionsLottery';
import { cyberMenu } from './cyber';
import { catalogMenu } from './catalog';
import { hotsaleMenu } from './hotsale';

// TODO: el home lo saco de aqui porque no lo estamos usanddo en el armador de rutas, la ruta del home la estamos declarando directo alla
export const routes: MenuItem[] = [
  // homeMenu,
  loginMenu,
  categoriesMenu,
  cartMenu,
  productsMenu,
  catalogMenu,
  searchMenu,
  aboutMenu,
  faqsMenu,
  userPolicyMenu,
  privacyPolicyMenu,
  TermsAndConditionsQuantumMenu,
  comoComprarMenu,
  promotionsMenu,
  customRedirectsMenu,
  regretMenu,
  registerMenu,
  facebookMenu,
  TermsAndConditionsLotteryMenu,
  cyberMenu,
  hotsaleMenu,
];
