import { useCallback, useMemo } from 'react';
import { usePublicationByZone } from './usePublicationByZone';
import { Publication } from '../../models';
import { LibbyCallReturn } from '../../../lib/libby/hooks';
import { useZoneContext } from '../Zone';

export const usePublicationZoneName = (zone_id: string): LibbyCallReturn<{ publications: Publication[]; name: string }> => {
  const { data: publications = [], working: wPublications, recall: recallPublication } = usePublicationByZone(zone_id);
  const { data, working } = useZoneContext();

  const _zone = useMemo(() => data.find((zone) => zone.zone_id === zone_id), [zone_id, data]);

  const recall = useCallback(
    async (...args: any[]) => {
      const publication = await recallPublication(args);
      return { publications: publication, name: _zone?.name || '' };
    },
    [recallPublication, _zone],
  );

  return { data: { publications, name: _zone?.name || '' }, working: wPublications || working, recall };
};
