import { TemplateProperty } from '../../../../lib/templates';
import { addressTemplate, retirementPointsTemplate } from './common';

export const deliveryInfoTemplate: TemplateProperty = {
  name: 'deliveryInfo',
  type: {
    name: 'object',
    kind: [
      ...addressTemplate,
      {
        name: 'additionalInfo',
        type: { name: 'string' },
        label: 'Información adicional',
        optional: true,
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
          { name: 'max', signature: [50] },
        ],
      },
    ],
  },
};
export const authorizeReceiverTemplate = ( isAuthorized : boolean = false) : TemplateProperty => ({
  name: 'authorizeReceiver',
  type: {
    name: 'object',
    kind: [
      {
        name: 'name',
        type: { name: 'string' },
        label: 'Nombre',
        optional: !isAuthorized,
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
          { name: 'max', signature: [50] },
        ],
      },
      {
        name: 'lastname',
        type: { name: 'string' },
        label: 'Apellido',
        optional: !isAuthorized,
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
          { name: 'max', signature: [50] },
        ],
      },
      {
        name: 'dni',
        type: { name: 'string' },
        label: 'DNI',
        optional: !isAuthorized,
        validators: [
          { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Completa con solo números' },
          { name: 'max', signature: [8] },
          { name: 'min', signature: [7] },
        ],
      },
      {
        optional: true,
        name: 'authorize',
        type: { name: 'boolean' },
      },
    ],
  },
})

export const hopTemplate: TemplateProperty = {
  name: 'retirementPoints',
  type: {
    name: 'object',
    kind: [
      {
        name: 'retirementPoints',
        type: {
          name: 'array',
          kind: [...retirementPointsTemplate],
        },
      },
      {
        name: 'selectedPoint',
        type: {
          name: 'object',
          kind: [...retirementPointsTemplate],
        },
      },
      {
        name: 'approved',
        type: { name: 'boolean' },
      },
      {
        name: 'address',
        type: { name: 'string' },
        optional: true,
      },
    ],
  },
};
