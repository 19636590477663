import { makeStyles} from '@material-ui/core';
import { ecommerceConfig } from 'src/config/ecommerce';


interface StyleProps {
  lineHeight: number;
  lineCount: number;
  bgColor: string;
  isDownSm: boolean;
  xs: boolean;
}




export const productCardRowStyles = makeStyles(() => ({
  container: {
    // display: 'flex',
    width: '100%',
    //  maxHeight: 230,
  },
  cover: { width: 265 },
  box: {
    overflow: 'hidden',
  },
  installmentsContainer: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
  },
  cardContainer: {
    width: '100%',
    padding: ({ isDownSm }: StyleProps) => (isDownSm ? '0px' : '16px'),
    marginLeft: ({ isDownSm }: StyleProps) => (isDownSm ? '16px' : '0px'),
    '&:last-child': {
      paddingBottom: ({ isDownSm }: StyleProps) => (isDownSm ? '8px' : '0px'),
      paddingTop: ({ isDownSm }: StyleProps) => (isDownSm ? '24px' : '0px')
    },
  },
  installmentsInnerContainer: {
    background: '#00D1F7',
    padding: '4px 12px',
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 5,
  },
  installmentsTitle: {
    color: 'white',
    fontWeight: 600,
  },
  installmentsSubtitle: {
    color: 'white',
    fontWeight: 600,
    fontSize: 11,
    marginTop: '-5px',
  },
  priceWithDiscountContainer: {
    height: 30,
    margin: '0px -4px 5px',
    paddingLeft: 10
  },
  priceText: {
    textDecoration: 'line-through',
    color: '#8492A6',
    fontSize: 12,
    fontWeight: 400,
    marginRight: 10
  },
  priceWithDiscountText: {
    color: '#3C4858',
    fontSize: 10,
    fontWeight: 400,
    marginRight:10
  },
  titleText: {
    minHeight: '50px',
  },
  actualPrice: {
    marginLeft:({xs}: StyleProps) => (xs ? 11 : 0),
  },
  oldPrice: {
    textDecoration: 'line-through',
    marginLeft:({xs}: StyleProps) => (xs ? 11 : 0),
    alignItems: 'center',
  },
  iconLocalShipping: { width: 15, height: 15 },
  blockWithText: {
    overflow: 'hidden',
    position: 'relative',
    lineHeight: ({ lineHeight }: StyleProps) => lineHeight,
    maxHeight: ({ lineHeight, lineCount }: StyleProps) => `${lineHeight * lineCount}em`,
    marginRight: 0, // -1em
    paddingRight: '.5em',
    marginLeft:({xs}: StyleProps) => (xs ? 11 : 0),
    fontSize: ({xs}: StyleProps) => (xs ? 14 : 16),
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      width: '.5em',// 1em
      height: '.5em',// 1em
      marginTop: '0.2em',
      background: ({ bgColor }: StyleProps) => bgColor,
    },
  },
  localShipmentIcon: {
    color: ecommerceConfig.ecommercePalette.materialUi.primary.main,
    fontSize: ({ isDownSm }: StyleProps) => (isDownSm ? 10 : 24),
  },
  favoriteDeleteButton: {
    textTransform: 'none',
    paddingLeft: 0,
    minWidth: 0,
  },
}));
