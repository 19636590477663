import React from 'react';
import { Grid, Box, FormControlLabel, Radio } from '@material-ui/core';
import theme from 'src/theme';

export interface PaymentCardProps {
  value: string;
  image: string;
  border?: boolean;
  width?: string;
  widthImg?: string;
  nameId?: string;
}

export const PaymentCard = ({ image, value, border = true, width = '130px', widthImg = '80px', nameId }: PaymentCardProps) => (
  <Box
    position="relative"
    width={width}
    height="60px"
    borderRadius={2}
    paddingLeft={1.3}
    border={border ? '1px solid' : 0}
    borderColor="other.stroke"
    display="flex"
    color="text.primary"
    bgcolor={theme.palette.common.white}
  >
    <FormControlLabel
      value={value}
      control={<Radio id={nameId || value} color="primary" />}
      label={
        <Box width={widthImg} justifyContent="center" alignContent="center" height="50px" display="grid">
          <Grid component="img" src={image} />
        </Box>
      }
    />
  </Box>
);
