import {MenuItem} from "../../../../../commons";
import {TermsAndConditionsLottery} from "../../../../../screens/Public/TermsAndConditionsLottery";

export const routes: MenuItem[] = [
  {
    key: 'publicTermsAndConditionsLotteryMain',
    basePath: '/',
    target: {
      component: TermsAndConditionsLottery,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Terminos y condiciones sorteo ',
    children: [],
    default: true,
  },
];
