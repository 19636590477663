import React, { useMemo, useCallback } from 'react';
import {
  Grid,
  Typography,
  Box,
  Card,
  Switch,
  useMediaQuery,
  IconButton,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  makeStyles,
  ListItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useGeneralContext } from 'src/context';
import { isZone } from 'src/screens/Public/Search/util';
import { GenericFilterData, FilterList, FILTER_TYPE } from 'src/commons/ui/view/GenericFilter';
import { Product } from 'src/app/models/business/Product';
import { GenericChipFilter } from 'src/commons/ui/view/GenericChipFilter';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SEARCH_TYPES } from 'src/commons';
import { fixedNameCategory } from 'src/utils';
import { categoriesViewStyles as useStyles, productFiltersStyles } from '../../../../styles';
import { FilterListView } from '../../../FilterListView';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 44,
    height: 22,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(22px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const acordionStyle = makeStyles(() => ({
  acordion: {
    '& .Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  acordionSummary: {
    margin: 0,
    '& .MuiAccordion-root.Mui-expanded': {
      height: '35px',
      alignItems: 'center',
    },
  },
  expanded: {
    minHeight: 0,
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  expandIcon: { '&.Mui-expanded': { transform: 'none' } },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      minHeight: '49px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 49,
    '&$expanded': {
      minHeight: 49,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: { padding: '8px' },
}))(MuiAccordionDetails);

interface CategoryFiltersProps {
  defaultSearchText?: string;
  pagination: { allCount: number };
  filterContent: Array<GenericFilterData>;
  selectedFilters: Array<FilterList>;
  searchType: SEARCH_TYPES;
  freeShipment: boolean;
  products: Product[];
  onAddFilter: (value: FilterList) => void;
  onRemoveFilter: (value: FilterList) => void;
  setFreeShipment: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CategoryFilters = ({
  defaultSearchText,
  filterContent,
  selectedFilters,
  searchType,
  freeShipment,
  pagination,
  products,
  onAddFilter,
  onRemoveFilter,
  setFreeShipment,
  setOpenModal,
}: CategoryFiltersProps) => {
  const isMdUp = useMediaQuery('(min-width:50em)');
  const classes = useStyles();
  const clasesAcoord = acordionStyle();
  const classesPr = productFiltersStyles();
  const { generalState } = useGeneralContext();

  const handleChange = useCallback(
    (event) => {
      setFreeShipment(event.target.checked);
    },
    [setFreeShipment],
  );

  const { markContetns, otherContents } = useMemo(() => {
    const result = filterContent
      .filter((filter) => {
        let show = !filter.hide;
        if (filter.type === FILTER_TYPE.MARK) {
          show = !selectedFilters.filter(({ type }) => type === FILTER_TYPE.MARK).length;
        }
        return show && filter.items.length > 0;
      })
      .filter(
        (item) =>
          item.type === FILTER_TYPE.MARK ||
          (searchType === SEARCH_TYPES.category ? item.type !== FILTER_TYPE.CATEGORY : item.type === FILTER_TYPE.CATEGORY),
      );
    const markContetns = result.filter((item) => item.type === FILTER_TYPE.MARK);
    const otherContents = result.filter((item) => item.type !== FILTER_TYPE.MARK);
    return { markContetns, otherContents };
  }, [filterContent, searchType, selectedFilters]);

  return isMdUp ? (
    <Grid container direction="row" spacing={3}>
      <Grid item className={classes.resultResume} container direction="column">
        <Typography variant="h6" style={!isMdUp ? { color: 'white' } : { color: '#000000de' }}>
          {generalState.search && !isZone(generalState.search) ? `Busqueda: ${generalState.search}` : defaultSearchText}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${products.length === 0 ? products.length : pagination.allCount} resultado${products.length > 1 ? 's' : ''}`}
        </Typography>
      </Grid>
      <Grid item>
        <GenericChipFilter
          labelKey="name"
          idKey="value"
          items={fixedNameCategory(selectedFilters)}
          onItemDelete={onRemoveFilter}
          customStyles={{ container: { padding: 0 }, chip: { backgroundColor: '#EEEEEE' } }}
        />
      </Grid>
      {pagination.allCount > 0 && (
        <Grid item container xs={12}>
          <Box component={Card} width="100%" height="54px" borderRadius={8} boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)">
            <Grid container alignContent="center" alignItems="center" style={{ padding: '16px 24px' }}>
              <Grid item xs={8}>
                <Typography variant="subtitle2" color="textSecondary">
                  Envío gratis
                </Typography>
              </Grid>
              <Grid item xs={4} container justify="flex-end">
                <AntSwitch checked={freeShipment} onChange={handleChange} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
      {markContetns.map((item) => (
        <Grid key={item.title} item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textPrimary">
                {item.title}
              </Typography>
            </Grid>
            <Grid item container direction="row" spacing={1}>
              {item.items.map((_item, index) => (
                <Grid key={_item.name} item>
                  <Box
                    key={`${_item.name + index}`}
                    onClick={() => onAddFilter(_item)}
                    component={Card}
                    style={{ cursor: 'pointer' }}
                    borderRadius={4}
                    height="38px"
                    padding="8px 16px 8px 16px"
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  >
                    <Typography variant="subtitle2" color="textSecondary">
                      {_item.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ))}
      {otherContents.map((item) =>
        searchType !== SEARCH_TYPES.category ? (
          <Grid key={item.title} item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="textPrimary">
                  {item.title}
                </Typography>
              </Grid>
              <Grid item container direction="column">
                {item.items.map((_item, index) => (
                  <Typography
                    key={`${_item.name + index}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onAddFilter(_item)}
                    variant="body1"
                    color="textSecondary"
                  >
                    {_item.name.split('.').length > 1 ? _item.name.split('.')[1] : _item.name}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box padding={1} key={item.title}>
            <FilterListView {...item} onAddFilter={onAddFilter} />
          </Box>
        ),
      )}
    </Grid>
  ) : (
    <Grid container direction="row">
      <Grid item container direction="row">
        <Box padding={2} width="100%" height="100%" bgcolor="primary.main" color="primary.contrastText">
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <IconButton color="inherit" onClick={() => setOpenModal(false)}>
                <ArrowBackIosIcon />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6">Filtrar por</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid container className={classesPr.container}>
        <Box bgcolor="white" width="100%">
          <Grid container direction="column">
            <Accordion variant="outlined">
              <AccordionSummary
                classes={{ expandIcon: clasesAcoord.expandIcon }}
                expandIcon={<AntSwitch checked={freeShipment} onChange={handleChange} />}
              >
                <Typography variant="h6" color="textSecondary">
                  Envío gratis
                </Typography>
              </AccordionSummary>
            </Accordion>

            {markContetns.map((item) => (
              <Accordion key={item.title} variant="outlined">
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                  <Typography variant="h6" color="textPrimary">
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {item.items.map((_item) => (
                      <ListItem disableGutters key={`${_item.value}${_item.name}`} button onClick={() => onAddFilter(_item)}>
                        <Box pl={1}>
                          <Typography variant="body1" color="primary">
                            {_item.name}
                          </Typography>
                        </Box>
                      </ListItem>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
            {otherContents.map((item) =>
              searchType !== SEARCH_TYPES.category ? (
                item.items.map((_item) => (
                  <Accordion variant="outlined">
                    <AccordionSummary onClick={() => onAddFilter(_item)}>
                      <Typography variant="h6" color="textPrimary">
                        {_item.name.split('.').length > 1 ? _item.name.split('.')[1] : _item.name}
                      </Typography>
                    </AccordionSummary>
                  </Accordion>
                ))
              ) : (
                <Accordion key={item.title} variant="outlined">
                  <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                    <Typography variant="h6" color="textPrimary">
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <Box px={1} my={1}>
                        {item.items.map((_item) => (
                          <Grid key={_item.name}>
                            <Typography variant="subtitle1" color="textSecondary">
                              {_item.name}
                            </Typography>
                            <Box pl={3}>
                              {_item.subItems?.map((__item) => (
                                <Typography
                                  key={__item.name.toString()}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => onAddFilter(__item)}
                                  variant="body1"
                                  color="primary"
                                >
                                  {__item.name}
                                </Typography>
                              ))}
                            </Box>
                          </Grid>
                        ))}
                      </Box>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ),
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
