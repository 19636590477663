import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pallet from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: pallet.background.defaultDark,
    height: 100,
    justifyContent: 'center',
    borderBottom: '1px solid gray',
  },
  menuContainer: {
    backgroundColor: pallet.background.defaultDark,
    height: 55,
  },
  action: {
    fontWeight: 'bold',
    color: 'white',
    marginTop: -9,
  },
}));

export const Header = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.container}>
        <Toolbar style={{ marginLeft: '15' }}>
          <img src="https://ster-ecommerce-img.s3.amazonaws.com/assets/images/novatech-logo.svg" alt="logo" />
        </Toolbar>
      </AppBar>
    </>
  );
};
