export const ecommerceTypography = {
  useNextVariants: true,
  fontFamily: 'Montserrat, sans-serif',
  h1: {
    fontWeight: 400,
    fontSize: '94px',
    lineHeight: '112.03px',
    letterSpacing: '-1.5px',
  },
  h2: {
    fontWeight: 400,
    fontSize: '72px',
    lineHeight: '72px',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontWeight: 400,
    fontSize: '45px',
    lineHeight: '56.02px',
  },
  h4: {
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '41.99px',
    letterSpacing: '0.25px',
  },
  h5: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32.02px',
  },
  h6: {
    fontWeight: 600,
    fontSize: '18px',
    letterSpacing: '0.15px',
    lineHeight: '32px',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '13px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '22px',
  },
  body1: {
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },
  body2: {
    fontWeight: 500,
    fontSize: '12px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
  },
  buttonLarge: {
    fontWeight: 700,
    fontSize: '15px',
    letterSpacing: '0.46px',
    lineHeight: '26px',
    textTransform: 'uppercase',
  },
  buttonMedium: {
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: '0.46px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  buttonSmall: {
    fontWeight: 700,
    fontSize: '13px',
    letterSpacing: '0.46px',
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  overline: {
    fontWeight: 600,
    fontSize: '12px',
    letterSpacing: '1px',
    lineHeight: '32px',
    textTransform: 'uppercase',
  },
  inputLabel: {
    fontWeight: 600,
    fontSize: '12px',
    letterSpacing: '0.15px',
    lineHeight: '12px',
  },
  helperText: {
    fontWeight: 400,
    fontSize: '11px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
  },
  inputText: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '22px',
  },
  tooltip: {
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '14px',
  },

  navbarIcon: {
    height: '32px',
    width: '32px',
    fontSize: '32px',
  },
};
