import { useMemo, useEffect } from 'react';
import { useFormContext } from 'src/lib/templates';
import { AnyObject } from 'src/commons';
import { EMITTERS } from 'src/app/const/Emitters';
import { deliveryInitialValue, checkoutAllValues } from '../initialValues';
import { docentInfoTemplate, alumnInfoTemplate } from '../templates';
import { applicantInfoTemplate } from '../templates/applicantTemplate';

export const TEMPLATE_MAP = {
  docentInfo: docentInfoTemplate,
  alumnInfo: alumnInfoTemplate,
  applicantInfo: applicantInfoTemplate,
};

export const useCreditAddressLogic = (nameForm: 'docentInfo' | 'alumnInfo' | 'applicantInfo') => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { form, values } = useFormContext<AnyObject>();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  useEffect(() => {
    if (valuesForm.paymentMethod.emitter.card_emiter_id.toString() !== EMITTERS.CATAMARCA_CREDITS.toString() || !valuesForm[nameForm]) return;
    const objectEval = {
      province: valuesForm.deliveryInfo.province,
      city: valuesForm.deliveryInfo.city,
      street: valuesForm.deliveryInfo.street,
      streetNumber: valuesForm.deliveryInfo.streetNumber,
      floor: valuesForm.deliveryInfo.floor,
      apartament: valuesForm.deliveryInfo.apartament,
      zipCode: valuesForm.deliveryInfo.zipCode,
    };
    const { province, city, street, streetNumber, floor, apartament, zipCode } = valuesForm[nameForm] || deliveryInitialValue;
    const validateInfo = JSON.stringify(objectEval) !== JSON.stringify({ province, city, street, streetNumber, floor, apartament, zipCode });
    if (valuesForm.useDeliveryAddress && validateInfo) {
      // aqui quito actualizo la info del address con la ingresada en la informacion de envio
      const copy = { ...objectEval };
      form.change(TEMPLATE_MAP[nameForm].name, { ...valuesForm[nameForm], ...copy });
    } else if (!valuesForm.useDeliveryAddress && !validateInfo) {
      // aqui quito blanqueo la info del address del template
      const { additionalInfo, ...rest } = deliveryInitialValue;
      form.change(TEMPLATE_MAP[nameForm].name, { ...valuesForm[nameForm], ...rest });
    }
  }, [form, valuesForm, nameForm]);
};
