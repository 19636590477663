import { TemplateProperty } from 'src/lib/templates';
import Joi from 'joi';

export const accountInfoTemplate: TemplateProperty = {
  name: 'accountInfo',
  type: {
    name: 'object',
    kind: [
      {
        name: 'name',
        type: { name: 'string' },
        label: 'Nombre',
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$'], errorMessage: 'Utilizá solo letras.' },
          { name: 'max', signature: [30] },
          { name: 'empty', errorMessage: 'Completa este dato' },
        ],
      },
      {
        name: 'lastname',
        type: { name: 'string' },
        label: 'Apellido',
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$'], errorMessage: 'Utilizá solo letras.' },
          { name: 'max', signature: [30] },
          { name: 'empty', errorMessage: 'Completa este dato' },
        ],
      },
      {
        name: 'email',
        type: { name: 'string' },
        label: 'Correo electrónico',
        validators: [
          { name: 'email', signature: [{ tlds: { allow: false } }], errorMessage: 'Utilizá un formato de correo nombre@ejemplo.com' },
          { name: 'empty', errorMessage: 'Completa este dato' },
        ],
      },
      {
        name: 'document',
        type: { name: 'string' },
        label: 'Documento',
        validators: [
          { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Solo podés ingresar números.' },
          { name: 'length', signature: [8], errorMessage: 'Documento invalido' },
          { name: 'empty', errorMessage: 'Completa este dato' },
        ],
      },
      {
        name: 'password',
        type: { name: 'string' },
        label: 'Clave',
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'], errorMessage: 'Verificá cumplir con los requisitos de contraseña.' },
          { name: 'min', signature: [6], errorMessage: 'Verificá cumplir con los requisitos de contraseña.' },
          { name: 'max', signature: [20], errorMessage: 'Verificá cumplir con los requisitos de contraseña.' },
          { name: 'empty', errorMessage: 'Completa este dato' },
        ],
        optional: false,
      },
      {
        name: 'confirmPassword',
        type: { name: 'string' },
        label: 'Confirmar clave',
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'], errorMessage: 'Verificá cumplir con los requisitos de contraseña.' },
          { name: 'empty', errorMessage: 'Completa este dato' },
          { name: 'min', signature: [6], errorMessage: 'Verificá cumplir con los requisitos de contraseña.' },
          { name: 'max', signature: [20], errorMessage: 'Verificá cumplir con los requisitos de contraseña.' },
          { name: 'valid', signature: [Joi.ref('password')] },
        ],
        options: { messages: { 'any.only': 'Ambas contraseñas deben ser iguales.' } },
      },
    ],
  },
};
