import { TemplateProperty } from '../../../../lib/templates';
import { deliveryInfoTemplate } from './deliveryInfoTemplate';

export const summaryAddressTemplate: TemplateProperty = {
  name: 'summaryAddress',
  type: {
    name: 'object',
    kind: deliveryInfoTemplate.type.kind,
  },
};
