import { Equifax } from 'src/app';
import { CompletedForm } from 'src/screens/Common/Checkout/components/IdentityValidationForm/interfaces';
import { EcommerceDAO } from './EcommerceDAO';

export class EquifaxDAO extends EcommerceDAO<Equifax> {
  constructor() {
    super('equifax', 'documentNumber');
  }

  async sendUserDataToGetQuestions(documentNumber: number, fullName: string, gender: string): Promise<void> {
    const response = await this.fetchData(`/equifax/api`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ documentNumber, fullName, gender }),
    });
    return response.json();
  }

  async sendForm(completedForm: CompletedForm): Promise<void> {
    const response = await this.fetchData(`/equifax/api/form`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(completedForm),
    });
    return response.json();
  }
}
