import React, { useMemo } from 'react';
import { couponFormStyles as useStyles } from 'src/app/views/styles/couponForm';
import { FailedCheck } from 'src/assets/images/svgs/FailedCheck';
import { Box, Typography, Link } from '@material-ui/core';
import { Error, ErrorObject } from '../interfaces';

const errorData: ErrorObject = {
  BLOCKED_USER: {
    h1: 'Usuario Bloqueado.',
    h2: 'Agotaste la cantidad de intentos para que podamos validar tu identidad.',
    contactus: 'Volvé a intentarlo pasadas 48 horas o comunícate con nosotros a',
    helpyou: 'para que podamos ayudarte .',
  },
  NOT_FOUND: {
    h1: 'Usuario no encontrado.',
    h2: 'No se pudo encontrar a una persona con los datos proporcionados.',
    contactus: 'Podés volver a intentarlo, pero tené en cuenta que 3 es el maximo de intentos por seguridad',
    helpyou: 'envíanos un mail para ayudarte en caso de que lo necesites .',
  },
  TKL_VALIDATION: {
    h1: 'Error de validación.',
    h2: 'No es posible validar la persona consultada porque tiene Bloqueos de TKL.',
    contactus: 'Menor de 16 años, consultar a operaciones (K&L) o volvé a intentarlo con otras credenciales.',
    helpyou: 'envíanos un mail para ayudarte en caso de que lo necesites.',
  },
  DNI_CONFLICT: {
    h1: 'Error de conflicto.',
    h2: 'Múltiples personas con el mismo documento.',
    contactus: 'Ingrese un nuevo nombre y apellido e intente nuevamente.',
    helpyou: 'envíanos un mail para ayudarte en caso de que lo necesites.',
  },
  API_ERROR: {
    h1: 'Error de conexión.',
    h2: 'Ha ocurrido un error al momento de validar los datos',
    contactus: 'Este es un error de conexión de la plataforma equifax, intenta nuevamente o',
    helpyou: 'envíanos un mail para ayudarte en caso de que lo necesites .',
  },
  DEFAULT: {
    h1: 'Error',
    h2: 'Ocurrió un error inesperado.',
    contactus: 'Volvé a intentarlo en unos minutos o comunícate con nosotros a',
    helpyou: 'para que podamos ayudarte .',
  },
};

export default function SelectSpecificEquifaxError({ error, link }: { error: Error; link: string }) {
  const classes = useStyles();
  const groupedError = useMemo(() => {
    switch (error?.code) {
      case 8:
      case 11:
      case 12:
      case 24:
      case 31:
      case 33:
      case 35:
      case 36:
      case 37:
      case 46:
      case 504:
      case 500:
        return 'API_ERROR';
      case 17:
        return 'BLOCKED_USER';
      case 7:
        return 'NOT_FOUND';
      case 18:
        return 'TKL_VALIDATION';
      case 29:
        return 'DNI_CONFLICT';
      default:
        return 'DEFAULT';
    }
  }, [error.code]);
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" paddingTop="25%" marginBottom="25%">
        <FailedCheck width="34" height="34" color="crimson" />
        <Typography className={classes.TypographyBold}>{errorData[groupedError].h1}</Typography>
        <Typography className={classes.TypographyLight}>{errorData[groupedError].h2}</Typography>
        <Typography className={classes.TypographyLight} align="center">
          {errorData[groupedError].contactus}{' '}
          <Link href={`mailto:${link}`} target="_blank" rel="noreferrer">
            {link}{' '}
          </Link>
          {errorData[groupedError].helpyou}
        </Typography>
      </Box>
    );
  

}
