import React, { useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { SingIn, OAuthProvider } from 'src/app/models';
import { FacebookButton, ResponseHandler } from 'src/commons/components/FacebookButton';
import { FACEBOOK_LOGIN_ENABLED, GOOGLE_LOGIN_ENABLED } from 'src/config';
import { FacebookApi } from '../../../services';
import { GoogleButton } from './GoogleButton';

interface SocialButtonProps {
  gsiButtonConfig?: Partial<GsiButtonConfiguration>
  handlerSocialSingIn: ({ provider }: SingIn) => void;
}

export const SocialButton = ({ gsiButtonConfig, handlerSocialSingIn }: SocialButtonProps) => {
  console.log("GLE", GOOGLE_LOGIN_ENABLED, "FLE", FACEBOOK_LOGIN_ENABLED)

  const handleGoogleSignIn = useCallback(
    (response: CredentialResponse) => {
      if (response.credential !== '') {
        const to_singin: SingIn = {
          token_id: response.credential,
          provider: OAuthProvider.GOOGLE,
        };
        handlerSocialSingIn(to_singin);
      }
    },
    [handlerSocialSingIn],
  );

  useEffect(() => {
    const func = FacebookApi.subscribe(async (response: ResponseHandler) => {
      if (response.authResponse) {
        handlerSocialSingIn({ provider: OAuthProvider.FACEBOOK, token_id: response.authResponse.accessToken });
      }
    });
    return () => func();
  }, [handlerSocialSingIn]);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={12} md={10} >
        {FACEBOOK_LOGIN_ENABLED && <FacebookButton />}
      </Grid>
      <Grid item xs={12} sm={12} md={10} >
        {GOOGLE_LOGIN_ENABLED && <GoogleButton handleGoogleSignIn={handleGoogleSignIn} gsiButtonConfig={gsiButtonConfig} />}
      </Grid>
    </Grid>
  );
};
