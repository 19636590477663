import { makeStyles } from '@material-ui/core/styles';
import pallet from '../../../theme/palette';

export const appHeaderStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: pallet.background.defaultDark,
    justifyContent: 'center',
    borderBottom: '1px solid gray',
  },
  logo: {
    cursor: 'pointer',
  },
  searchBar: {
    height: 30,
  },
  searchBarMobile: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  grow: {
    flexGrow: 1,
  },
  linkLogin: {
    textDecoration: 'none',
  },
  ampliedBox: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  icon: {
    marginLeft: '-20px',
  },
  logoEcommerce: {
    marginBottom: '5px',
  },
}));
