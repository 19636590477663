import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { formatPriceNotDecimal, getDiscountPublication, getInterest } from 'src/utils';
import { EMITTERS } from 'src/app/const/Emitters';
import { IPaymentInfo, ICardInfo, ICoupon } from '../../initialValues';

interface PaymentMethodViewProps {
  paymentInfo: IPaymentInfo;
  cardInfo: ICardInfo;
  couponData: ICoupon;
}

export const PaymentMethodView = ({ paymentInfo, cardInfo, couponData }: PaymentMethodViewProps) => {
  const { amountCoupon } = couponData;
  const cuotas = paymentInfo.payment?.installments > 1 ? 'cuotas' : 'cuota';
  const discount = getDiscountPublication(paymentInfo.payment?.amount?.toString(), paymentInfo.payment?.original_amount);
  const interest = discount <= 0 ? getInterest(paymentInfo.payment?.original_amount, paymentInfo?.payment?.amount) : 0;
  const isCatamarca = paymentInfo.emitter.card_emiter_id === EMITTERS.CATAMARCA_CREDITS;
  const priceView = paymentInfo.payment.reference === '19' ? 'original_amount' : 'amount';
  const textInterest = paymentInfo.payment?.installments > 1 ? (interest === 0 ? `sin interés` : interest !== 0 ? 'fijas' : '') : '';
  const target = isCatamarca
    ? `En ${paymentInfo.payment.installments} ${cuotas}: : Subtotal $${formatPriceNotDecimal(paymentInfo.payment?.[priceView] - amountCoupon)}`
    : `En ${paymentInfo.payment.installments} ${cuotas} ${textInterest}: Subtotal $${formatPriceNotDecimal(
        paymentInfo.payment?.amount - amountCoupon,
      )}`;
  return (
    <Grid container spacing={2}>
      {paymentInfo.payment.installments > 0 && (
        <Grid item container justify="flex-start" xs={12}>
          <Grid item xs={10}>
            <Typography variant="caption" color="textPrimary">
              {`Tarjeta n° XXXX-XXXX-XXXX-${cardInfo?.cardNumber.slice(-4) || 'XXXX'}`}
            </Typography>
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            <Box display="flex" width="51px" height="21px" border="1px solid" borderColor="other.stroke" borderRadius={8}>
              <Grid component="img" src={paymentInfo.emitter?.image_url} />
            </Box>
          </Grid>
          <Typography variant="caption" color="textPrimary">
            {target}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
