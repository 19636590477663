import React, { useState, useMemo } from 'react';
import { AnyObject } from 'src/commons';
import { JetGroups } from 'src/platform/libby/session';
import { RolesContext, RolesContextValue, UserRole, UserInfo } from './RolesContext';

const emptyRole: UserRole = {
  rol_id: '',
  rol_name: '',
};

const emptyUserRoles: UserRole[] = [];

const emptyUserInfo: UserInfo = {
  id: '',
  email: '',
  name: '',
  lastname: '',
  isGuest: true,
  nick: '',
  username: '',
  groups: new Array<JetGroups>(),
};

export const RolesContextProvider = ({ children }: AnyObject) => {
  const [selectedRole, setSelectedRole] = useState<UserRole>(emptyRole);
  const [userRoles, setUserRoles] = useState<UserRole[]>(emptyUserRoles);
  const [userInfo, setUserInfo] = useState<UserInfo>(emptyUserInfo);

  const value = useMemo<RolesContextValue>(
    () => ({
      selectedRole,
      setSelectedRole,
      deselectRole: () => setSelectedRole(emptyRole),
      userRoles,
      setUserRoles,
      userInfo,
      setUserInfo,
    }),
    [selectedRole, userRoles, userInfo],
  );
  return <RolesContext.Provider value={value}>{children}</RolesContext.Provider>;
};
