import React, { useState } from 'react';
import { Grid, MobileStepper, useMediaQuery, IconButton, Container } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { ConfigValues, ConfigValuesImages } from 'src/app/models';
import { Link } from 'react-router-dom';
import { bannerStyles as useStyles } from '../styles';
import { BannerFooter, ListBannerFooter } from '../BannerFooter';
import { IMAGE_BASE_URL } from '../../../../config';
import { useInterval, useAnalyticsPromotion } from './hooks';

export interface BannerImage {
  image_url: string;
  link_url?: string;
}

export interface BannerCarouselProps {
  items: Array<ConfigValues>;
  listItemFooter?: Array<ListBannerFooter>;
  paymentImages: ConfigValuesImages;
  transitionTime: number | undefined;
}

export const BannerCarousel = ({ items, listItemFooter, paymentImages, transitionTime = 5 }: BannerCarouselProps) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(0);
  const isDownSm = useMediaQuery<any>((theme) => theme.breakpoints.down('sm'));
  const callAnalytics = useAnalyticsPromotion();
  const maxSteps = items.length;
  const noStep = items.length === 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const resetSteps = () => {
    setActiveStep(0);
  };

  const resetInterval = useInterval(() => {
    if (activeStep === maxSteps - 1) {
      resetSteps();
    } else {
      handleNext();
    }
  }, transitionTime * 1000);

  const onClickNext = () => {
    resetInterval()
    handleNext()
  }

  const onClickBack = () => {
    resetInterval()
    handleBack()
  }

  return (
    <Grid container className={classes.principalContainer}>
      <Grid container justify="center" alignItems="center" className={classes.actionContainer}>
        <Grid className={classes.arrowBack}>
          <IconButton onClick={onClickBack} disabled={activeStep === 0} className={classes.buttonBack}>
            <NavigateBeforeIcon fontSize="large" className={classes.iconSize} />
          </IconButton>
        </Grid>
        <Grid className={classes.imgContainer}>
          <Link to={`${items[activeStep]?.redirectTo}`} className={items[activeStep]?.redirectTo ? undefined : classes.pointerLinks} onClick={() => callAnalytics(items[activeStep])}>
            <img
              width="100%"
              src={`${IMAGE_BASE_URL}${items[activeStep]?.imagen?.[isDownSm ? 'small' : 'normal']}`}
              alt="tecnologia, producto, banner"
            />
          </Link>
        </Grid>
        <Grid className={classes.arrowNext}>
          <IconButton onClick={onClickNext} disabled={activeStep === maxSteps - 1} className={classes.buttonNext}>
            <NavigateNextIcon fontSize="large" className={classes.iconSize} />
          </IconButton>
        </Grid>
        
      </Grid>
      <Container className={classes.bannerFooterContainer}>
        <Container className={classes.bannerFooter}>
          {!noStep && !isDownSm && (
            <MobileStepper
              variant="dots"
              classes={{ dots: classes.docsStepper }}
              hidden={isDownSm}
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={null}
              backButton={null}
            />
          )}
          {listItemFooter && <BannerFooter list={listItemFooter} paymentImages={paymentImages} />}
        </Container>
      </Container>
    </Grid>
  );
};
