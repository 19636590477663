import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const categoriesMenu: MenuItem = {
  key: 'publicCategories',
  basePath: '/categories/:id',
  enabled: true,
  icon: 'List',
  text: 'Categories',
  children: routes,
};
