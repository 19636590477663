import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { CardType } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'card';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCard',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCard',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Card
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Card,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Card.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<CardType>(DAO_NAME, optionsToUse);

// hooks
export const useCardFetchById = fetchById;
export const useCardLibbyCall = libbyCall;
export const useCardDAO = libbyDAO;
export const useCardLibbyFetch = libbyFetch;

// components/hoc
export const CardAutocomplete = entityAutocomplete;
export const CardCall = renderPropCall;
export const CardById = renderPropFetchById;
export const CardSelect = simpleSelect;
export const CardAccordionSelector = accordionSelector;
export const CardSelectedChips = selectedChips;

// context
// ByIdContext
export const useCardByIdContext = useByIdContext;
export const CardByIdProvider = ByIdProvider;
export const CardByIdContext = ByIdContext;
// GeneralContext
// export const useCardContext = useGeneralContext;
// export const CardProvider = GeneralProvider;
// export const CardContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
