/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Grid,
  MobileStepper,
  useMediaQuery,
  Box,
  Stepper,
  Step,
  Divider,
  IconButton,
  Card,
  Typography,
  makeStyles,
  Slide,
} from '@material-ui/core';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { PromotionItem as Product } from 'src/app/models';
import { ArrowScrollCarousel } from 'src/commons/ui/view/ScrollCarousel';
import { roles } from 'src/assets/images';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import { UserAvatar } from 'src/commons/components';
import { bannerStyles as useStyles } from '../styles';
import './styles.css';
import { BannerVerticalCard } from './BannerVerticalCard';
import {ImagesLightbox} from "../ImagesLightbox";

export interface BannerProps {
  product: Product;
}

export const Banner = ({ product }: BannerProps) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(0);
  const isDownSm = useMediaQuery<any>((theme) => theme.breakpoints.down('sm'));
  const maxSteps = product?.product?.images?.length || 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box position="relative" justifyItems="center">
      <Grid container justify="center" alignItems="center">
        <Grid component="img" className={classes.img} src={product.product.images[activeStep]} alt="imagenes de productos" />
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          classes={{ dots: classes.hiddeText }}
          className={classes.buttomStepper}
          nextButton={
            <Button size="large" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              <KeyboardArrowRight className={isDownSm ? classes.iconButtonSm : classes.iconButtonSm} />
            </Button>
          }
          backButton={
            <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft className={isDownSm ? classes.iconButtonSm : classes.iconButtonSm} />
            </Button>
          }
        />
      </Grid>
    </Box>
  );
};

interface VerticalBannerProps {
  activeStep: number;
  steps: Array<string>;
}
// TODO: refactor

export const VerticalBanner = ({ activeStep, steps }: VerticalBannerProps) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Stepper activeStep={activeStep} orientation="vertical" connector={<Divider />}>
        <Grid container spacing={3}>
          {steps.map((label, index) => (
            <Grid item>
              <Step key={label}>
                <Grid
                  component="img"
                  style={{
                    display: 'block',
                  }}
                  src={roles['2']}
                  alt="imagenes de productos"
                />
              </Step>
            </Grid>
          ))}
        </Grid>
      </Stepper>
    </Grid>
  );
};

const classItem = ['carousel-item'];

interface VerticalCarouselProps {
  data: Array<any>;
  leadingText: string;
}

// Version 3 vertical carousel infinite loops
export const VerticalCarousel = ({ data, leadingText }: VerticalCarouselProps) => {
  const [activeEnable, setActiveIndex] = useState(0);

  const activeEnableButton = data.length > 5;

  const activeIndex = useMemo(() => (activeEnableButton ? activeEnable : 0), [activeEnableButton, activeEnable]);

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(data.length / 2);

  // Usd to determine the height/spacing of each item
  const itemHeight = 75;

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight;

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex: number) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) {
      return 0;
    }

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight;
      }
      return -(data.length + activeIndex - itemIndex) * itemHeight;
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight;
      }
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };

  const handleClick = (direction: string) => {
    setActiveIndex((prevIndex) => {
      if (direction === 'next') {
        if (prevIndex + 1 > data.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return data.length - 1;
      }

      return prevIndex - 1;
    });
  };

  const [hover, setOnHover] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);
  const imagesLightbox = useMemo(() => data.map(item => item.content.image), [data]);
  return (
    <Box display="flex" flexDirection="row">
      <Box
        display="flex"
        flexDirection="column"
        width="15%"
        justifyItems="center"
        alignSelf="center"
        alignItems="center"
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        {activeEnableButton && (
          <Box my={1} height="60px">
            <ArrowScrollCarousel direction="up" onClick={() => handleClick('prev')} parentOnHover={hover} color="primary" />
          </Box>
        )}

        <Box width="100%" flex display="flex" justifyContent="center" alignItems="center">
          <Box display="flex" alignSelf="center" justifyContent="center" width="100%">
            <Box flex position="relative" maxHeight="360px" height="360px" width="100%" alignSelf="center" justifyContent="center" display="flex">
              {data.map((item: any, i: number) => (
                <BannerVerticalCard
                  placement={determinePlacement(i)}
                  maxItemHeight={Math.abs(determinePlacement(i) || 1) <= itemHeight * 2}
                  setActiveIndex={() => setActiveIndex(i)}
                  item={item}
                  activeCard={i === activeEnable}
                />
              ))}
            </Box>
          </Box>
        </Box>

        {activeEnableButton && (
          <Box my={1} height="50px">
            <ArrowScrollCarousel direction="down" onClick={() => handleClick('next')} parentOnHover={hover} color="primary" />
          </Box>
        )}
      </Box>
      <Box alignItems="center" width="100%" justifyContent="center" display="flex" position="relative" minHeight="505px">
        <Box display="flex" maxWidth="520px" minWidth="380px" width="100%" position="absolute" bgcolor="red">
          <UserAvatar
            img={data[activeEnable].content.image}
            variant="square"
            avatarWidth={600}
            avatarHeight={550}
            avatarMaxHeight="100%"
            avatarMaxWidth="100%"
            onClick={() => setOpenLightbox(true)}
          />
          {openLightbox && (
            <ImagesLightbox images={imagesLightbox} initialActive={activeEnable} onClose={() => setOpenLightbox(false)} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

// --------------------------------------------------------------------------------------------

const SLIDE_INFO = [
  { backgroundColor: '#ff7c7c', title: 'Slide 1' },
  { backgroundColor: '#ffb6b9', title: 'Slide 2' },
  { backgroundColor: '#8deaff', title: 'Slide 3' },
  { backgroundColor: '#ffe084', title: 'Slide 4' },
  { backgroundColor: '#d9d9d9', title: 'Slide 5' },
];

export const CoruselMultiple = () => {
  const [index, setIndex] = useState(0);
  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState<'down' | 'left' | 'right' | 'up' | undefined>('up');
  const content = SLIDE_INFO[index];
  const maxSlides = SLIDE_INFO.length;

  const onArrowClick = (direction: 'down' | 'left' | 'right' | 'up' | undefined) => {
    const increment = direction === 'left' ? -1 : 1;
    const newIndex = (index + increment + maxSlides) % maxSlides;

    const oppDirection = direction === 'left' ? 'right' : 'left';
    setSlideDirection(direction);
    setSlideIn(false);

    setTimeout(() => {
      setIndex(newIndex);
      setSlideDirection(oppDirection);
      setSlideIn(true);
    }, 500);
  };

  return (
    <Grid container>
      <Arrow direction="left" onClick={() => onArrowClick('left')} />
      <Slide in={slideIn} direction={slideDirection}>
        <Grid>
          <CarouselSlide content={content} />
        </Grid>
      </Slide>

      <Arrow direction="right" onClick={() => onArrowClick('right')} />
    </Grid>
  );
};
interface CarouselSlideProps {
  content: { backgroundColor: string; title: string };
}

const CarouselSlide = ({ content: { backgroundColor, title } }: CarouselSlideProps) => {
  const useCardStyles = makeStyles(() => ({
    card: {
      backgroundColor,
      borderRadius: 5,
      padding: '75px 50px',
      margin: '0px 25px',
      width: '500px',
      boxShadow: '20px 20px 20px black',
      display: 'flex',
      justifyContent: 'center',
    },
  }));

  const classes = useCardStyles();
  return (
    <Card className={classes.card}>
      <Typography>{title}</Typography>
    </Card>
  );
};

interface ArrowProps {
  direction: string;
  disabled?: boolean;
  onClick: () => void;
}
const Arrow = ({ direction, disabled, onClick }: ArrowProps) => {
  const icon =
    direction === 'left' ? (
      <NavigateBeforeRoundedIcon color="primary" fontSize="large" />
    ) : (
      <NavigateNextRoundedIcon color="primary" fontSize="large" />
    );
  return (
    <IconButton disabled={disabled} onClick={onClick} style={{ zIndex: 5 }}>
      {icon}
    </IconButton>
  );
};

// --------------------------------------------------------------------------------------------
// VERSION 3

interface CaroulselProps {
  children: JSX.Element[];
  show?: number;
}

export const Carousel = ({ children, show = 1 }: CaroulselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children.length);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);
  const [paginate, setPaginate] = useState<JSX.Element[]>([]);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }
    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;
    if (diff > 5 && currentIndex !== length - show) {
      onArrowClick('right');
    }
    if (diff < -5 && currentIndex !== 0) {
      onArrowClick('left');
    }
    setTouchPosition(null);
  };
  // Set the length to match current children from props
  useEffect(() => {
    setLength(children.length);
  }, [children]);

  useEffect(() => {
    const paginateChildren = children.slice(currentIndex, currentIndex + show);
    setPaginate(paginateChildren);
  }, [currentIndex, children, show]);

  const onArrowClick = (direction: 'left' | 'right') => {
    const increment = direction === 'left' ? -1 : 1;
    setCurrentIndex((prevState) => prevState + increment);
  };

  return (
    <Grid className="carousel-container" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Grid className="carousel-wrapper" style={{ display: 'flex', width: '100%', position: 'relative' }}>
        <Arrow direction="left" disabled={currentIndex === 0} onClick={() => onArrowClick('left')} />
        <Grid
          container
          className="carousel-content-wrapper"
          style={{ overflow: 'hidden', width: '100%', height: '100%' }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <Grid
            className="carousel-content"
            justify="space-around"
            style={{
              display: 'flex',
              transition: 'all 250ms linear',
              width: `${100}%`,
              // overflow: 'hidden',
              msOverflowStyle: 'none' /* hide scrollbar in IE and Edge */,
              scrollbarWidth: 'none' /* hide scrollbar in Firefox */,
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {children}
          </Grid>
        </Grid>
        <Arrow direction="right" disabled={currentIndex === length - show} onClick={() => onArrowClick('right')} />
      </Grid>
    </Grid>
  );
};

// *******************

/* ***********************************
1. If you want to add or remove items you will need to change a variable called $slide-count in the CSS *minimum 3 slides

2. if you want to change the dimensions of the slides you will need to edit the slideWidth variable here 👇 and the $slide-width variable in the CSS.
*********************************** */
const slideWidth = 30;

interface ContentItems {
  title: string;
  desc: string;
  image: string;
}

interface Items {
  player: ContentItems;
}

const _items: Array<Items> = [
  {
    player: {
      title: 'Efren Reyes',
      desc: 'Known as "The Magician", Efren Reyes is well regarded by many professionals as the greatest all around player of all time.',
      image: 'https://i.postimg.cc/RhYnBf5m/er-slider.jpg',
    },
  },
  {
    player: {
      title: "Ronnie O'Sullivan",
      desc: "Ronald Antonio O'Sullivan is a six-time world champion and is the most successful player in the history of snooker.",
      image: 'https://i.postimg.cc/qBGQNc37/ro-slider.jpg',
    },
  },
  {
    player: {
      title: 'Shane Van Boening',
      desc: 'The "South Dakota Kid" is hearing-impaired and uses a hearing aid, but it has not limited his ability.',
      image: 'https://i.postimg.cc/cHdMJQKG/svb-slider.jpg',
    },
  },
  {
    player: {
      title: 'Mike Sigel',
      desc: 'Mike Sigel or "Captain Hook" as many like to call him is an American professional pool player with over 108 tournament wins.',
      image: 'https://i.postimg.cc/C12h7nZn/ms-1.jpg',
    },
  },
  {
    player: {
      title: 'Willie Mosconi',
      desc: 'Nicknamed "Mr. Pocket Billiards," Willie Mosconi was among the first Billiard Congress of America Hall of Fame inductees.',
      image: 'https://i.postimg.cc/NfzMDVHP/willie-mosconi-slider.jpg',
    },
  },
];

interface CarouselSlideItem {
  pos: number;
  idx: number;
  activeIdx: number;
}

interface Item {
  styles: { transform: string; filter?: string; opacity?: number };
  player: ContentItems;
}

_items.push(..._items);

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position: number, idx: number) => {
  const item: Item = {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
    },
    player: _items[idx].player,
  };

  switch (position) {
    case _items.length - 1:
    case _items.length + 1:
      item.styles = { ...item.styles, filter: 'grayscale(1)' };
      break;
    case _items.length:
      break;
    default:
      item.styles = { ...item.styles, opacity: 0 };
      break;
  }

  return item;
};

const CarouselSlideItem = ({ pos, idx, activeIdx }: CarouselSlideItem) => {
  const item = createItem(pos, idx);

  return (
    <li
      className="carousel__slide-item"
      style={{
        ...item.styles,
      }}
    >
      <div className="carousel__slide-item-img-link">
        <img src={item.player.image} alt={item.player.title} />
      </div>
      <div className="carousel-slide-item__body">
        <h4>{item.player.title}</h4>
        <p>{item.player.desc}</p>
      </div>
    </li>
  );
};

const keys = Array.from(Array(_items.length).keys());

export const CarouselNew = () => {
  const [items, setItems] = React.useState(keys);
  const [isTicking, setIsTicking] = React.useState(false);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const bigLength = items.length;

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i + jump) % bigLength]);
      });
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
      });
    }
  };

  const handleDotClick = (idx: number) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  React.useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  React.useEffect(() => {
    setActiveIdx((_items.length - (items[0] % _items.length)) % _items.length); // prettier-ignore
  }, [items]);

  return (
    <div className="carousel__wrap">
      <div className="carousel__inner">
        <Button className="carousel__btn carousel__btn--prev" onClick={() => prevClick()}>
          <i className="carousel__btn-arrow carousel__btn-arrow--left" />
        </Button>
        <div className="carousel__container">
          <ul className="carousel__slide-list">
            {items.map((pos, i) => (
              <CarouselSlideItem key={i} idx={i} pos={pos} activeIdx={activeIdx} />
            ))}
          </ul>
        </div>
        <Button className="carousel__btn carousel__btn--next" onClick={() => nextClick()}>
          <i className="carousel__btn-arrow carousel__btn-arrow--right" />
        </Button>
        <div className="carousel__dots">
          {items.slice(0, _items.length).map((pos, i) => (
            <Button key={i} onClick={() => handleDotClick(i)} className={i === activeIdx ? 'dot active' : 'dot'} />
          ))}
        </div>
      </div>
    </div>
  );
};
