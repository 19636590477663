import React from 'react';
import { Grid, Box, Typography, Divider, IconButton } from '@material-ui/core';
import { ListMenu, GenericMenu } from 'src/commons/ui/view/GenericMenu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { productFiltersStyles as useStyles } from '../../styles/productFilters';

export interface MenuFilterProps {
  contents: Array<ListMenu>;
  onAddFilter: (value: ListMenu) => void;
  onRemoveFilter: (value: ListMenu) => void;
  onSubMenu: (value: ListMenu[]) => void;
  contentMenuAux: Array<ListMenu>[];
  onReturnContentMenu: () => void;
  onSingOut: () => void;
}

export const MenuDrawer = ({ contents, onAddFilter, onSubMenu, contentMenuAux, onReturnContentMenu }: MenuFilterProps) => {
  const classes = useStyles();

  return contents.length ? (
    <Grid container className={classes.container}>
      {!!contentMenuAux?.length && (
        <Box bgcolor="white" width="100%">
          <Typography variant="body1" color="primary">
            <Box py={1} onClick={onReturnContentMenu} display="flex" alignItems="center">
              <IconButton>
                <ArrowBackIcon color="primary" />
              </IconButton>
              <Box py={1} onClick={onReturnContentMenu} alignItems="center">
                {contentMenuAux?.length === 1
                  ? 'Menú principal'
                  : `Volver a ${contentMenuAux[contentMenuAux.length - 1][0].name.split('.')[contentMenuAux?.length]}`}
              </Box>
            </Box>
          </Typography>

          <Divider />
        </Box>
      )}

      <Box bgcolor="white" width="100%">
        <GenericMenu onAddFilter={onAddFilter} items={contents} onSubMenu={onSubMenu} />
      </Box>
    </Grid>
  ) : (
    <></>
  );
};
