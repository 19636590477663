import { TemplateProperty } from '../../../../lib/templates';

export const responsableTemplate: TemplateProperty = {
  name: 'responsableInscripto',
  type: {
    name: 'object',
    kind: [
      {
        name: 'business',
        type: { name: 'string' },
        label: 'Nombre de la empresa',
        validators: [
          { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
          { name: 'max', signature: [25] },
        ],
      },
      {
        name: 'cuit',
        type: { name: 'string' },
        label: 'CUIT',
        validators: [
          {
            name: 'pattern',
            signature: ['^[0-9]+$'],
          },
          { name: 'max', signature: [12] },
          { name: 'min', signature: [10] },
        ],
      },
    ],
  },
};
