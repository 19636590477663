import { makeStyles } from '@material-ui/core';

export const optionListStyles = makeStyles(() => ({
  title: {
    // fontFamily: 'Circular',
    fontSize: 20,
    lineHeight: '160%',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#333333',
    marginBottom: 10,
  },
  body: {
    // fontFamily: 'Circular',
    fontSize: 16,
    lineHeight: '175%',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#333333',
  },
}));
