
export const INITIAL_STATE = {
    name: '',
    email: ''
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'name':
            return {
                ...state,
                name: action.payload
            }
        case 'email':
            return {
                ...state,
                email: action.payload
            }
        default:
            return state;
    }
}