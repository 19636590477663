import React, { useCallback, useState } from 'react';
import { FormControlLabel, List, FormHelperText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useField } from 'react-final-form-hooks';
import { getErrorAndHelperTextNoTranslation } from 'src/utils/materialHelpers';
import { ModalDialog } from 'src/commons';

interface LabelProps {
  onOpenModals: () => void;
}

export interface FormDialogCheckboxTypesProps {
  name: string;
  form: any;
  label: (props: LabelProps) => React.ReactNode;
  dialogContent: () => JSX.Element;
}

export const FormDialogCheckbox = ({ name, form, label, dialogContent }: FormDialogCheckboxTypesProps): JSX.Element => {
  const field = useField(name, form);
  const [openModal, setOpenModal] = useState(false);
  const onHandleCloseModal = useCallback(() => setOpenModal(false), []);
  const onOpenModals = useCallback(() => setOpenModal(true), []);

  const onChange = useCallback(
    (value) => {
      field.input.onChange(value.target.checked);
    },
    [field.input],
  );

  const { error } = getErrorAndHelperTextNoTranslation(field);

  const Comp = dialogContent;

  return (
    <>
      <List key="list-item-one-checkbox" dense component="div" role="list" disablePadding>
        <FormControlLabel
          control={<Checkbox id={name} checked={field.input.value} onChange={(value) => onChange(value)} color="primary" />}
          label={label({ onOpenModals })}
        />
        <FormHelperText error={error}>{error && 'Debes aceptar los Términos y condiciones para continuar.'}</FormHelperText>
      </List>
      <ModalDialog title="Términos y Condiciones" open={openModal} onHandleClose={onHandleCloseModal}>
        <Comp />
      </ModalDialog>
    </>
  );
};
