import React, { useCallback } from 'react';
import { Grid, Container, Box } from '@material-ui/core';
import { MultiStepFormContextProvider } from 'src/lib/MultiStepForm';
import { registerInitialValue, accountInfoInitialValues, checkCodeInitialValue } from './initialValues';
import { registerTemplate, accountInfoTemplate, checkCodeTemplate } from './Templates';
import { AccountInfoForm } from './components/AccountInfoForm';
import { RegisterForm } from './components';
import { FormRegisterValues } from './types';
import { useSendRegister } from './hooks';
import { CheckCodeRegisterForm } from './components/CheckCodeRegisterForm';
import { MultiStepFormSteps } from '../../../lib/MultiStepForm/types';

export const steps: Array<MultiStepFormSteps> = [
  {
    id: 1,
    title: 'Completá tus datos',
    subTitle: 'Completa tu compra mucho más fácil y enterate de todos los descuentos antes que nadie creando un usuario en nuestro store.',
    hidden: false,
    template: accountInfoTemplate,
    initialValue: accountInfoInitialValues,
    render: AccountInfoForm,
  },
  {
    id: 2,
    title: 'Confirmación',
    subTitle:
      'Te enviamos un correo a <b>$1</b> con un código de 6 dígitos para confirmar tu identidad. Si no lo encontrás, verificá en tu casilla de no deseados.',
    hidden: false,
    template: checkCodeTemplate,
    initialValue: checkCodeInitialValue,
    render: CheckCodeRegisterForm,
  },
];

export const Register = () => {
  const { sendRegister } = useSendRegister();

  const onSubmit = useCallback(
    async (values: FormRegisterValues) => {
      try {
        await sendRegister(values);
      } catch (error) {
        console.log('error', error);
      }
    },
    [sendRegister],
  );

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Box mt={4}>
          <MultiStepFormContextProvider steps={steps} initialValues={registerInitialValue} template={registerTemplate} onSubmit={onSubmit}>
            <RegisterForm />
          </MultiStepFormContextProvider>
        </Box>
      </Grid>
    </Container>
  );
};
