import React, { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Grid, Typography, useMediaQuery, Box } from '@material-ui/core';
import { Publication } from 'src/app/models';
import typography from '../../../../../theme/typography';
import { productDescriptionStyles as useStyles } from '../../../styles';

interface ProductDescriptionViewProps {
  data: Publication;
}

export const  ProductDescriptionView = ({ data }: ProductDescriptionViewProps) => {
  const isSm = useMediaQuery<any>((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  const dataPublication = useMemo(() => {
    if (data) {
      const publicationData: Publication = { ...data };
      const {
        attributes: { features },
      } = publicationData;
      const newFeatures = features?.filter((feature) => feature.name !== 'Categories');
      publicationData.attributes.features = newFeatures;
      return publicationData;
    }
    return undefined;
  }, [data]);

  return (
    <Grid item container>
      {isSm && dataPublication.attributes?.description && (
        <Grid item xs={12}>
          <Box py={1}>
            <Typography variant={!isSm ? 'h4' : 'h5'}>
              <Box component="text" color="primary.dark" fontWeight="bold">
                Descripción
              </Box>
            </Typography>
          </Box>
        </Grid>
      )}

      <Typography variant="h6">
        <Box component="text" color="textPrimary" fontWeight="bold" py={2}>
          {dataPublication.attributes?.title || ''}
        </Box>
      </Typography>

      <Box color="textPrimary">
        <h2 style={typography.body1} className={classes.normalFontWeight}>
          {ReactHtmlParser(dataPublication.attributes?.description || '')}
        </h2>
      </Box>
    </Grid>
  );
};
