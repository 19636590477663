import { useEffect } from 'react';
import { useTemplateContext, useFormContext } from 'src/lib/templates';
import { AnyObject } from 'src/commons';

import { responsableTemplate } from '../templates/responsableTemplate';

export const useIsResponsableInscriptoLogic = () => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { form, values } = useFormContext<AnyObject>();
  const { setTemplate } = useTemplateContext();

  useEffect(() => {
    if (values.isResponsable !== 'true') {
      // aqui quito el template adicional del responsable inscripto si existe
      setTemplate((template) => {
        if (!template.template.find((property) => property.name === responsableTemplate.name)) {
          return template;
        }
        form.change(responsableTemplate.name, undefined);
        return {
          ...template,
          template: template.template.filter((property) => property.name !== responsableTemplate.name),
        };
      });
    } else {
      // aqui agrego el template adicional del responsable si no existe
      setTemplate((template) => {
        if (template.template.find((property) => property.name === responsableTemplate.name)) {
          return template;
        }
        form.change(responsableTemplate.name, { business: '' });
        return {
          ...template,
          template: [...template.template, responsableTemplate],
        };
      });
    }
  }, [setTemplate, values, form]);
};
