import React, { ChangeEvent, useMemo, useState, useCallback, useEffect } from 'react';
import { Grid, useMediaQuery, Theme, Box, Button, Typography, Card } from '@material-ui/core';
import { useScrollToTopOnMount } from 'src/commons/hooks/useScrollToTopUnMount';
import { CommonFormInput, FormInputData } from 'src/app/views';
import { State, City } from 'src/app/models';
import { useHistory } from 'react-router';
import { useDebounce } from 'src/commons';
import { useLocationDAO, useLocationLibbyCall } from 'src/app/business';
import { orderBy } from 'lodash';
import { FormSelect, FormAutocompleteFetch, FormCheckbox } from '../../../../../../../../lib/templates/components';
import { FormAddressInfo, InitialValueDefinition } from '../../../types';
import { useAddressInfoFormContext } from '../../../hooks';

export const FormContent = ({ title, initialValue }: { title: string; initialValue: InitialValueDefinition }) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const locationDAO = useLocationDAO();
  const { form, values, errors, handleSubmit } = useAddressInfoFormContext();
  const [search, setSearch] = useState(values.city?.name || '');
  const searchDebunce = useDebounce(search, 600);

  const { data, working } = useLocationLibbyCall<State[]>({ methodName: 'getStates' });

  const selectedProvince = useMemo(() => values?.province?.id !== '', [values?.province?.id]);

  const onInputChangeCity = useCallback((event: ChangeEvent<HTMLInputElement>, value: string) => setSearch(value), [setSearch]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== values?.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(values?.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, values?.province, searchDebunce, values?.city]);

  const validateZipCode = useCallback(async (value: any, allValues: any) => {
    if (value && !allValues.city?.zip?.includes(Number(value))) {
      return 'El codigo postal no coincide';
    }
  }, []);

  const formInput: FormInputData[] = useMemo(
    () => [
      {
        variant: 'standard',
        name: `zipCode`,
        label: 'Cód. postal',
        lg: 2,
        md: 6,
        xs: 4,
        required: true,
        id: 'zipCode',
        autoComplete: 'postal-code',
        validator: validateZipCode,
      },
      {
        variant: 'standard',
        name: `street`,
        label: 'Calle',
        lg: 6,
        md: 5,
        xs: 8,
        required: true,
        id: 'street',
        autoComplete: 'street-address',
      },
      {
        variant: 'standard',
        name: `streetNumber`,
        label: 'Altura',
        lg: 2,
        md: 2,
        xs: 4,
        required: true,
        id: 'streetNumber',
      },
      {
        variant: 'standard',
        name: `floor`,
        label: 'Piso',
        lg: 2,
        md: 2,
        xs: 4,
        id: 'floor',
      },
      {
        variant: 'standard',
        name: `apartament`,
        label: 'Dpto.',
        lg: 2,
        md: 2,
        xs: 4,
        id: 'apartament',
      },
      {
        variant: 'standard',
        name: `additionalInfo`,
        label: 'Información adicional',
        xs: 6,
        id: 'additionalInfo',
      },
    ],
    [validateZipCode],
  );

  return (
    <Grid container spacing={2}>
      <Grid item lg={8} md={8} xs={12}>
        {!isDownSm && (
          <Box my={1} mt={5}>
            <Typography variant="h6" color="textPrimary">
              {title}
            </Typography>
          </Box>
        )}

        <Box justifyItems="center" component={Card} borderRadius={8} boxShadow={3} display="content">
          <Box padding={isDownSm ? 2 : 3}>
            <Grid container direction="row" spacing={3}>
              <Grid item lg={5} md={6} sm={6} xs={12}>
                <FormSelect<State>
                  loading={working}
                  fullWidth
                  form={form}
                  label={<Box color="text.disabled">Provincia</Box>}
                  name="province"
                  content={orderBy(data, 'name')}
                  valueKey="id"
                  labelKey="name"
                  helperText="El campo Provincia es requerido"
                  inputLabelProps={{ shrink: true }}
                  optionToValue={(value, options) => options?.find((province) => province.id === value)}
                  valueToOption={(value, options) => options.find((option: State) => option.id === value.id)?.id || search}
                />
              </Grid>

              <Grid item lg={5} md={6} sm={6} xs={12}>
                <FormAutocompleteFetch
                  disabled={!selectedProvince}
                  name="city"
                  form={form}
                  label={<Box color="text.disabled">Ciudad</Box>}
                  options={cities}
                  optionLabelKey="name"
                  optionToValue={(option: City) => option}
                  valueToOption={(value: City, options: City[]) =>
                    search?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && search?.length > 0 && options.length > 0
                      ? options.find((option: City) => option.id === value.id)?.name
                      : search
                  }
                  helperText="El campo Ciudad es requerido"
                  getOptionSelected={(option: City, value: City) => option.id === value?.id}
                  onInputChange={onInputChangeCity}
                  inputLabelProps={{ shrink: true }}
                  loading={loading}
                  enabledOpenAutocomplete={search.length > 1}
                />
              </Grid>

              {formInput.map((input) => (
                <CommonFormInput<FormAddressInfo>
                  lg={input.lg}
                  md={input.md}
                  xs={input.xs}
                  name={input.name}
                  form={form}
                  variant={input.variant}
                  label={input.label}
                  error={input.error}
                  helperText={input.helperText}
                  id={input.id}
                  autoComplete={input.autoComplete}
                  validator={input.validator}
                />
              ))}

              <Grid item xs={12} />

              <Grid item xs={12}>
                <FormCheckbox
                  label={
                    <Typography variant="body2" color="inherit">
                      Usar este domicilio como domicilio de entrega
                    </Typography>
                  }
                  name="is_selected"
                  form={form}
                />
              </Grid>

              <Grid item container xs={12} justify="flex-end">
                <Box mr={2}>
                  <Button style={{ textTransform: 'none' }} size="large" variant="text" color="primary" onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                </Box>

                <Grid item>
                  <Button
                    type="submit"
                    onClick={() => handleSubmit()}
                    disabled={Object.keys(errors as any).length > 0 || JSON.stringify(values) === JSON.stringify(initialValue)}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
