import { Template } from 'src/lib/templates';

export const loginTemplate: Template = {
  name: 'Login Form',
  template: [
    {
      name: 'email',
      type: { name: 'string' },
      label: 'Correo electrónico',
      validators: [{ name: 'email', signature: [{ tlds: { allow: false } }] }],
    },
    {
      name: 'password',
      type: { name: 'string' },
      label: 'Clave',
      validators: [{ name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] }],
    },
  ],
};
