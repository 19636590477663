import { Equifax } from 'src/app/models';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'equifax';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEquifax',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEquifax',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Equifax
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Equifax,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Equifax.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Equifax>(DAO_NAME, optionsToUse);

// hooks
export const useEquifaxFetchById = fetchById;
export const useEquifaxLibbyCall = libbyCall;
export const useEquifaxDAO = libbyDAO;
export const useEquifaxLibbyFetch = libbyFetch;

// components/hoc
export const EquifaxAutocomplete = entityAutocomplete;
export const EquifaxCall = renderPropCall;
export const EquifaxById = renderPropFetchById;
export const EquifaxSelect = simpleSelect;
export const EquifaxAccordionSelector = accordionSelector;
export const EquifaxSelectedChips = selectedChips;

// context
// ByIdContext
export const useEquifaxByIdContext = useByIdContext;
export const EquifaxByIdProvider = ByIdProvider;
export const EquifaxByIdContext = ByIdContext;
