import React, { useCallback } from 'react';
import { Toolbar, Grid, Divider, IconButton, Box, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { MainInput, UserCard } from 'src/commons';
import { Link } from 'react-router-dom';
import { grey } from 'src/theme/colors';
import { appHeaderStyles as useStyles } from 'src/app/views/styles';
import { useRolesContext } from 'src/context';
import { LOGIN_ENABLED } from 'src/config';
import { CartMenu } from '../../CartMenu';
import { AppHeaderMenu } from './AppHeaderMenu';
import { AppHeaderWebProps } from './types';
import { ecommerceConfig } from '../../../../../config/ecommerce';
import { useIsPromotionPath } from '../../../../../utils';

export const AppHeaderWeb = ({
  contents,
  links,
  quantityItems,
  onCartClick,
  onOpenMenu,
  onOpenCategories,
  onSearhChange,
  onSearchClick,
  onSingOut,
  onLogoClick,
  searchValue,
}: AppHeaderWebProps) => {
  const classes = useStyles();
  const onEnterPress = useCallback(
    (ev) => {
      if (ev.key === 'Enter') {
        ev.preventDefault();
        setTimeout(() => {
          onSearchClick();
        }, 300);
      }
    },
    [onSearchClick],
  );

  const { mainLogo: MainLogo } = ecommerceConfig.ecommerceLogo;

  const isPromotionPath = useIsPromotionPath();

  const { selectedRole } = useRolesContext();

  return (
    <>
      <Toolbar disableGutters>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid className={isPromotionPath ? '' : classes.logo} item xs={3} container alignItems="center" onClick={onLogoClick}>
            <MainLogo />
          </Grid>
          {!isPromotionPath && (
            <Grid item xs={6}>
              <Box width="85%" justifyContent="flex-start">
                <MainInput
                  type="search"
                  handleChange={(e) => onSearhChange(e)}
                  placeholder="Buscar en toda la tienda..."
                  name="search"
                  icon={
                    <>
                      <Divider orientation="vertical" style={{ height: 28 }} />
                      <IconButton onClick={onSearchClick}>
                        <Search />
                      </IconButton>
                    </>
                  }
                  iconPossition="end"
                  value={searchValue}
                  fullWidth
                  customStyle={{ borderRadius: 8, border: `1px solid ${grey.normal}` }}
                  size="small"
                  customClass={classes.searchBar}
                  onKeyPress={onEnterPress}
                  id="search-input"
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={3} container direction="row" alignItems="center">
            <Grid item xs={10} container alignContent="center" justify="flex-end">
              {LOGIN_ENABLED && (
                <>
                  {selectedRole.rol_id === '' ? (
                    <Link className={classes.linkLogin} to="/login">
                      <Typography align="right" variant="body2" color="textPrimary">
                        Ingresá/Registrate
                      </Typography>
                    </Link>
                  ) : (
                    <UserCard logout={onSingOut} />
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={2} container justify="flex-end">
              <Box display="flex" justifyContent="flex-end" alignContent="flex-end">
                <CartMenu count={quantityItems} onOpenMenu={onCartClick} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider style={{ position: 'absolute', width: '100%', left:'0px' }}/>
      <AppHeaderMenu links={links} onOpenMenu={onOpenMenu} onOpenCategories={onOpenCategories} contents={contents} />
    </>
  );
};
