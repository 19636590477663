import { useEffect, useMemo } from 'react';
import { useTemplateContext, useFormContext, TemplateProperty } from 'src/lib/templates';
import { AnyObject } from 'src/commons';
import { checkoutAllValues, cardInfoInitialValue } from '../../../initialValues';
import { cardInfoTemplate } from '../../../templates/cardInfoTemplate';

export const useCardInfoTokenLogic = () => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { form, values } = useFormContext<AnyObject>();
  const { setTemplate } = useTemplateContext();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  useEffect(() => {
    if (valuesForm.tokenCardInfo.token !== '') {
      const childsTemp: TemplateProperty[] = cardInfoTemplate.type.kind as TemplateProperty[];
      // aqui quito el template adicional del responsable inscripto si existe
      setTemplate((template) => {
        if (!template.template.find((property) => property.name === cardInfoTemplate.name)) {
          return template;
        }
        form.change(cardInfoTemplate.name, cardInfoInitialValue);
        // pongo los cosos en optional
        const newCardTemplate = {
          ...cardInfoTemplate,
          type: {
            ...cardInfoTemplate.type,
            kind: childsTemp.map((item) => {
              if (item.name !== 'cardSecurityCode') {
                return { ...item, optional: true };
              }
              return item;
            }),
          },
        };
        return {
          ...template,
          template: [...template.template.filter((property) => property.name !== cardInfoTemplate.name), newCardTemplate],
        };
      });
    }
  }, [setTemplate, valuesForm.tokenCardInfo.token, form]);
};
