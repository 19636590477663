import React, { useState, useCallback, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { APP_ECOMMERCE } from '../../../../../../config';
import { useOrderDAO } from '../../../../../../app/business/Order';
import { ModalDialog } from '../../../../../../commons';

interface PendingCreditState {
  hasPending: boolean;
  openModal: boolean;
  document: string;
}

const initialValue = {
  hasPending: false,
  openModal: false,
  document: '',
};

export const useValidatePendingCredit = () => {
  const [state, setState] = useState<PendingCreditState>(initialValue);
  const orderDAO = useOrderDAO();

  const handleClose = useCallback(() => {
    setState((prev) => ({ ...prev, openModal: false }));
  }, []);

  const validatorPendingCredit = useCallback(
    async (value: string) => {
      if (!value) return 'Completa este campo.';
      if (APP_ECOMMERCE === 'detecnologia' && value.length > 9) {
        if (state.document !== value) {
          const res = await orderDAO.getOrderStateByBuyer(value);
          setState({ hasPending: res, openModal: res, document: value });
          if (res) {
            return 'El CUIL ingresado cuenta con un crédito pendiente.';
          }
        } else {
          setState((prev) => ({ ...prev, document: value }));
          if (state.hasPending) {
            return 'El CUIL ingresado cuenta con un crédito pendiente.';
          }
        }
      }
    },
    [orderDAO, state, setState],
  );

  const modal = useMemo(
    () => (
      <ModalDialog title="Crédito Pendiente" open={state.openModal} onHandleClose={handleClose}>
        <Grid container>
          <Typography>
            Usted ya posee una solicitud de préstamo creada, por favor, aguarde a que finalice su revisión para poder dar inicio a una nueva.
          </Typography>
        </Grid>
      </ModalDialog>
    ),
    [state, handleClose],
  );

  return { openModal: state.openModal, modal, handleClose, validatorPendingCredit };
};
