import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { HOP } from 'src/app';
import { useSavingHOPPoints } from '../hooks/useSavingHOPPoints';
import { MobileRows } from './MobileRows';

interface MobileTableProps {
  rows: HOP[];
  address: string;
}

export const MobileTable = ({ rows, address }: MobileTableProps) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const { savingHOPData, values } = useSavingHOPPoints();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography color="textSecondary" align="center">
          Resultados
        </Typography>
        <Box>
          {rows.map((row) => (
            <MobileRows
              data={rows}
              row={row}
              values={values}
              savingHOPData={savingHOPData}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              address={address}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
