import { useMemo } from 'react';
import { orderBy } from 'lodash';
import { PaymentExtraDataModel, PaymentModel, Publication } from 'src/app/models';
import { formatPriceNotDecimal, getDiscountPublication, getInterest, spliceText } from 'src/utils';
import { Product } from 'src/app/models/business/Product';

interface useBestPromotionsProps {
  publication: Publication | undefined | Product;
}
// TODO: deprecate
export const useBestPromotions = ({ publication }: useBestPromotionsProps) => {
  const bestPromotions: PaymentExtraDataModel[] = useMemo(() => {
    const publicationBestPromotions = publication?.best;
    if (publicationBestPromotions) {
      const promotionsOrder = orderBy(publication?.best, ['installments', 'amount']).reverse();
      return promotionsOrder.map((promotion: PaymentModel) => {
        const discount = getDiscountPublication(promotion.amount?.toString(), promotion.original_amount);
        const interest = discount <= 0 ? getInterest(promotion.original_amount, promotion.amount) : 0;
        return {
          ...promotion,
          amountTotal: formatPriceNotDecimal(promotion.amount / promotion.installments),
          discount,
          interest,
          typePayment: promotion.installments > 1 ? `cuotas` : 'pago',
        };
      });
    }

    return [];
  }, [publication]);

  return bestPromotions;
};
// TODO: deprecate
export const getBestPromotions = ({ publication }: useBestPromotionsProps) => {
  let bestPromotions: PaymentExtraDataModel[] = [];
  const publicationBestPromotions = publication?.best;
  if (publicationBestPromotions) {
    const promotionsOrder = orderBy(publication?.best, ['installments', 'amount']).reverse();
    bestPromotions = promotionsOrder.map((promotion: PaymentModel) => {
      const discount = getDiscountPublication(promotion.amount?.toString(), promotion.original_amount);
      const interest = discount <= 0 ? getInterest(promotion.original_amount, promotion.amount) : 0;
      return {
        ...promotion,
        amountTotal: formatPriceNotDecimal(promotion.amount / promotion.installments),
        discount,
        interest,
        typePayment: promotion.installments > 1 ? `cuotas` : 'pago',
      };
    });
  }
  return bestPromotions;
};

export const getPaymentByConfigAmount = (promotion: PaymentModel, amount: 'original_amount' | 'amount'): PaymentExtraDataModel => {
  const discount = getDiscountPublication(promotion?.[amount]?.toString(), promotion?.original_amount);
  const interest = discount <= 0 ? getInterest(promotion?.original_amount, promotion?.[amount]) : 0;
  const partPrice = (promotion?.amount / promotion?.installments).toString().split('.');
  const decimal = partPrice[1] || '0';
  const roundDacimal = Math.round(Number(spliceText(decimal || '', 2, 0, '.')));

  return {
    ...promotion,
    amountTotal: `${partPrice[0]}.${roundDacimal}`,
    discount,
    interest,
    typePayment: promotion?.installments > 1 ? `cuotas` : 'pago',
  };
};
