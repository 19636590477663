import React from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { Box, Grid, Typography, Radio, FormControlLabel } from '@material-ui/core';
import { TokenizedCard, CARD_EMITER } from '../../../../../../app/models';

interface CardsFormProps {
  options: TokenizedCard[];
  selected: string;
  recall: () => Promise<void>;
}

export const TokenCardsForm = ({ options, selected }: CardsFormProps) => (
  <Box width="100%">
    {orderBy(options, 'last_four_digits', 'desc')
      .filter((item) => (selected !== '' ? item.token === selected : true))
      .map((tokenCard, index) => (
        <Box
          key={tokenCard.token}
          width="100%"
          paddingBottom={index + 1 !== options.length ? 3 : 0}
          mb={index + 1 !== options.length ? 3 : 0}
          borderBottom={index + 1 !== options.length ? '1px solid #DADBE4' : ''}
        >
          <Grid container direction="row" item xs={12}>
            <Grid item container alignItems="center" justify="flex-start" xs={12}>
              <FormControlLabel
                value={tokenCard.token}
                control={<Radio id={tokenCard.token} color="primary" />}
                label={
                  <Grid item container direction="column" spacing={1}>
                    <Grid item container direction="row" spacing={1} alignItems="center">
                      <Grid item>
                        <Typography variant="body1" color="textPrimary">
                          {`${CARD_EMITER[tokenCard.payment_method_id].type} - Terminada en ${tokenCard.last_four_digits}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container direction="column">
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {`Tarjeta ${CARD_EMITER[tokenCard.payment_method_id].emitter}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {`Vencimiento: ${moment(`01/${tokenCard.expiration_month}/${tokenCard.expiration_year}`, 'DD/MM/YY').format('MM/YYYY')}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          </Grid>
        </Box>
      ))}
  </Box>
);
