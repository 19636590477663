import { useMemo } from 'react';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { IFieldComponent } from '../../FieldComponent';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { useBreakpointsState } from '../../../../../../app/hooks/useMobile';
import { TermsAndConditionsText, TermsAndConditionsLabel } from '../../../../../Public/TermsAndConditions';
import { DisclaimerIBB } from '../../../../../../commons';
import { useEmittersContext } from '../../../../../../context/EmittersContext/EmittersContextProvider';
import { EMITTERS } from '../../../../../../app/const/Emitters';
import { useValidatePendingCredit } from '../validators';
import { useFormLogicReCaptcha } from '../../../hooks/useFormLogicReCaptcha';
import { optionsInvoice, optionsGender } from '../../../constants';

export const useFields = () => {
  const { form, values } = useCheckoutFormContext();
  const { useMakeUp, useMakeDown } = useBreakpointsState();
  const { showReCAPTCHA } = useFormLogicReCaptcha();
  const { emitters } = useEmittersContext();
  const isCapresca = !!emitters.find((emiter) => emiter.card_emiter_id.toString() === EMITTERS.CATAMARCA_CREDITS.toString());
  const { modal, validatorPendingCredit } = useValidatePendingCredit();

  const isDownSm = useMakeDown('sm');
  const isUp768 = useMakeUp(768);

  const data: IFieldComponent[] = useMemo(
    () => [
      {
        hidden: isCapresca,
        component: FORM_FIELD_TYPE.RADIO_GROUP,
        componentProps: {
          row: true,
          marginRight: isDownSm ? '0px' : '100px',
          name: 'isResponsable',
          id: 'isResponsable',
          labelKey: 'label',
          options: optionsInvoice,
          form,
        },
      },
      {
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'personalInfo.name',
          label: 'Nombre',
          required: true,
          id: 'personalInfoName',
          autoComplete: 'name',
          form,
          inputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'personalInfo.lastName',
          label: 'Apellido',
          required: true,
          id: 'personalInfoLastName',
          autoComplete: 'family-name',
          form,
          inputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'personalInfo.document',
          label: isCapresca ? 'CUIL' : 'DNI',
          required: true,
          id: 'personalInfoDocument',
          autoComplete: 'on',
          form,
          inputLabelProps: { shrink: true },
          validator: validatorPendingCredit,
        },
      },
      {
        lg: 2,
        md: 2,
        sm: isUp768 ? 2 : 4,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'personalInfo.phone.areaCode',
          label: 'Cód. de Área',
          required: true,
          id: 'personalInfoAreaCode',
          autoComplete: 'tel-area-code',
          form,
          inputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        lg: 4,
        md: 4,
        sm: isUp768 ? 4 : 8,
        xs: 8,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'personalInfo.phone.number',
          label: 'Teléfono',
          required: true,
          id: 'personalInfoPhoneNumber',
          autoComplete: 'tel-local',
          form,
          inputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'personalInfo.email',
          label: 'E-mail',
          required: true,
          id: 'personalInfoEmail',
          autoComplete: 'email',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        lg: 3,
        md: 4,
        sm: isUp768 ? 4 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.DATE_PICKER,
        componentProps: {
          fullWidth: true,
          disableFuture: true,
          openTo: 'year',
          name: 'personalInfo.dateOfBirty',
          label: 'Fecha de nacimiento',
          views: ['year', 'month', 'date'],
          format: 'DD/MM/YYYY',
          inputLabelProps: { shrink: true },
          required: true,
          id: 'personalInfoDateOfBirty',
          form,
        },
      },
      {
        component: FORM_FIELD_TYPE.RADIO_GROUP,
        componentProps: {
          row: true,
          formlabel: 'Género del DNI',
          marginTop: '10px',
          name: 'personalInfo.gender',
          id: 'gender',
          labelKey: 'label',
          options: optionsGender,
          form,
        },
      },
      {
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        hidden: values.isResponsable === 'false',
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'responsableInscripto.business',
          label: 'Empresa',
          autoComplete: 'organization',
          form,
          inputLabelProps: {
            shrink: true,
          },
        },
      },
      {
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        hidden: values.isResponsable === 'false',
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: 'responsableInscripto.cuit',
          label: 'CUIT',
          autoComplete: 'document',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: values.isResponsable !== 'true',
        component: FORM_FIELD_TYPE.CUSTOMIZED,
        componentProps: { component: DisclaimerIBB },
      },
      {
        xs: 12,
        component: FORM_FIELD_TYPE.EMPTY,
      },
      {
        xs: 12,
        component: FORM_FIELD_TYPE.CHECKBOX_DIALOG,
        componentProps: {
          name: 'personalInfo.termsAndConditions',
          dialogContent: TermsAndConditionsText,
          id: 'termsAndConditions',
          label: TermsAndConditionsLabel,
          form,
        },
      },
      {
        xs: 12,
        hidden: !showReCAPTCHA,
        component: FORM_FIELD_TYPE.RECAPTCHA,
        componentProps: {
          name: 'recaptcha',
          id: 'recaptcha',
          form,
        },
      },
    ],
    [isCapresca, isDownSm, form, isUp768, validatorPendingCredit, values.isResponsable, showReCAPTCHA],
  );
  return { fields: data.filter((field) => !field.hidden), modal };
};
