import React, { useMemo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, Theme, useMediaQuery } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { usePublication } from 'src/app/business/Publication';
import { Loading } from 'src/commons';
import { useScrollToTopOnMount } from 'src/commons/hooks/useScrollToTopUnMount';
import { useGeneralContext } from 'src/context';
import { getProductImage, searchSkuPublication } from 'src/utils';
import { Feature, FeatureGroup, Publication } from 'src/app/models';
import { Analytics } from '../../../platform/analytics';
import {CatalogDetailsView} from "../../../app/views/components/CatalogDetailsView";

export const Catalog = () => {
  useScrollToTopOnMount();
  const { publicationId } = useParams<{ publicationId: string }>();
  const [isTrack, setIsTrack] = useState(false);
  const { data: publication, working } = usePublication(publicationId);

  const dataPublication = useMemo(() => {
    if (publication?.[0]) {
      const publicationData: Publication = { ...publication?.[0] };
      return publicationData;
    }
    return undefined;
  }, [publication]);

  const history = useHistory<any>();

  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));

  const { generalState, dispatch: generalDispatch } = useGeneralContext();


  useEffect(() => {
    if (generalState.paginate.path === '/product') return;
    generalDispatch.setPaginate({ ...generalState.paginate, path: '/product' });
  }, [generalDispatch, generalState.paginate, history.location.pathname]);

  const imagesCarousel = useMemo(() => {
    const images = publication?.[0].attributes?.images;
    const sku = searchSkuPublication({
      featuresPublication: publication?.[0].attributes.features,
    });
    if (images && sku) {
      return images.map((image) => ({
        img_url: getProductImage({
          sku,
          typeImage: image,
        }),
        alt: image,
      }));
    }
    return publication?.[0].attributes.image_url
      ? [
          {
            img_url: publication?.[0].attributes.image_url,
            alt: publicationId,
          },
        ]
      : [];
  }, [publication, publicationId]);

  useEffect(() => {
    if (publication?.[0] && !isTrack) {
      setIsTrack(true);
      Analytics.trackVisitPublication({ publication: publication[0] });
    }
  }, [isTrack, publication]);

  const landingFeatures: Feature[] = [];

  publication?.[0]?.attributes?.features?.forEach((group: FeatureGroup) => {
    if (group.name === 'Landing') {
      group.features?.forEach((feature: Feature) => landingFeatures?.push(feature));
    }
  });

  return (
    <>
      {dataPublication && (
        <Helmet>
          <title>{dataPublication.product}</title>
          <meta name="description" content={dataPublication.attributes.description} />
        </Helmet>
      )}
      <Container maxWidth="lg" disableGutters={isXs}>
        <Grid container>
          <Grid>
            {working ? (
              <Loading />
            ) : dataPublication ? (
                <CatalogDetailsView
                  product={dataPublication}
                  imagesCarousel={imagesCarousel}
                />
            ) : (
              <div>No existe este producto.</div>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
