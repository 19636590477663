import React from 'react';
import { Grid, Box } from '@material-ui/core';
import theme from 'src/theme';

interface PaymentCardPanelProps {
  image: string;
  border?: boolean;
  width?: string;
  widthImg?: string;
  nameId?: string;
  mobile: boolean;
}

export const PaymentCardPanel = ({ image, width = '60px', widthImg = '50px', mobile = true }: PaymentCardPanelProps) => (
  <Box
    position="relative"
    width={width}
    height="45px"
    borderRadius={2}
    justifyContent="center"
    alignContent="center"
    border="1px solid"
    borderColor="other.stroke"
    display="flex"
    color="text.primary"
    bgcolor={theme.palette.common.white}
    margin={mobile ? '3px' : '0px'}
  >
    <Box width={widthImg} justifyContent="center" alignContent="center" height="45px" display="grid">
      <Grid component="img" src={image} />
    </Box>
  </Box>
);
