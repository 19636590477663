import React from 'react';
import { SkeletonComponentProps, SOURCE } from 'src/app/models/Site';
import { Loading, useFavoriteManage } from 'src/commons';
import { ProductCarousel } from 'src/app/views/components';

export const SkeletonCarousel = ({ data, props }: SkeletonComponentProps) => {
  const { data: publiZoneName = { publications: [], name: '' }, working } = SOURCE.ZONE(data.source_id);

  const { favorites, addToFavorite, removeFromFavorite } = useFavoriteManage();

  const { publications, name } = publiZoneName;

  const { onClickProduct, toLink } = props || {};

  return working ? (
    <Loading />
  ) : (
    <ProductCarousel
      publications={publications}
      title={name || ''}
      favorites={favorites}
      zone={data.source_id}
      toLink={toLink}
      onClickProduct={onClickProduct}
      onAddFavorite={(item) => addToFavorite(item.productId.toString())}
      onRemoveFavorite={(item) => removeFromFavorite(item.productId.toString())}
    />
  );
};
