import React from 'react';
import { useHistory } from 'react-router';
import { Box, Typography, useMediaQuery, Theme } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { ProductNotFoundStyles as useStyles } from '../../../../styles';

export const ProductNotFound = () => {
  const classes = useStyles();
  const isDownMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const isDownXs = useMediaQuery('(max-width:500px)');
  const isMdUp = useMediaQuery('(min-width:50em)');

  const history = useHistory();

  const handleCategories = () => {
    history.push('/search');
  };

  return (
    <Box margin={!isMdUp ? '20px 0' : '16px 0'}>
      <Box display="flex" bgcolor="#fff" padding="16px 0" borderRadius="8px" flexDirection={isDownXs ? 'column' : 'row'}>
        <Box width={isDownXs ? '100%' : '10%'} color="primary.main" textAlign="center" display="flex" justifyContent="center" alignItems="center">
          <ErrorOutlineOutlinedIcon className={classes.icon} />
        </Box>
        <Box width="90%" margin={isDownXs ? '10px auto 0 auto' : 'auto 0'}>
          <Typography component="div">
            <Box fontSize={isDownMd ? '24px' : '34px'} marginBottom="16px" lineHeight="100%" textAlign={isDownXs ? 'center' : 'left'}>
              No hay publicaciones que coincidan con tu búsqueda.
            </Box>
          </Typography>
          <Typography component="div">
            <Box textAlign={isDownXs ? 'center' : 'left'} fontSize={isDownMd ? '14px' : '16px'}>
              Revise la ortografía de su búsqueda o lo invitamos a navegar por nuestras{' '}
              <span className={classes.stanStyle} onClick={() => handleCategories()} aria-hidden="true">
                categorías
              </span>
              .
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
