import { DECIDIR_PUBLIC_API_KEY, DECIDIR_PUBLIC_API_URL, DECIDIR_METRIX_URL, DECIDIR_ORG_ID, DECIDIR_MERCHANT_ID } from 'src/config';
import { ResponseDecidir } from '../models';

export interface TokenBody {
  card_number: string;
  card_expiration_month: string;
  card_expiration_year: string;
  security_code: string;
  card_holder_name: string;
  card_holder_door_number: number;
  card_holder_birthday: string;
  card_holder_identification: {
    type: string;
    number: string;
  };
  fraud_detection?: {
    device_unique_identifier: string;
  };
  ip_address?: string;
}

export interface TokenByTokenCard {
  token: string;
  fraud_detection?: {
    device_unique_identifier: string;
  };
  ip_address?: string;
}

class Decidir {
  private getHeader = () => ({
    'content-type': 'application/json;charset=UTF-8',
    apikey: `${DECIDIR_PUBLIC_API_KEY}`,
  });

  async getToken(body: TokenBody): Promise<ResponseDecidir> {
    /**
     * https://decidirv2.api-docs.io/1.0/transacciones-simples/solicitud-de-token-de-pago-1
     */
    try {
      const response = await fetch(`${DECIDIR_PUBLIC_API_URL}/tokens`, {
        method: 'post',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          apikey: DECIDIR_PUBLIC_API_KEY,
        },
        cache: 'no-cache',
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      if (error.response)
        // await DecidirErrorHandler(error.response, use_cyber_source, metadataError, true);
        console.log(error);
      throw error;
    }
  }

  async getTokenByTokenCard(body: TokenByTokenCard): Promise<ResponseDecidir> {
    /**
     * https://decidirv2.api-docs.io/1.0/transacciones-simples/solicitud-de-token-de-pago-1
     */
    try {
      const response = await fetch(`${DECIDIR_PUBLIC_API_URL}/tokens`, {
        method: 'post',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          apikey: DECIDIR_PUBLIC_API_KEY,
        },
        cache: 'no-cache',
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      if (error.response)
        // await DecidirErrorHandler(error.response, use_cyber_source, metadataError, true);
        console.log(error);
      throw error;
    }
  }

  getMetrixFingerprint = async (): Promise<string> => {
    const devideId = this.getDeviceFingerPrintId().toString();
    try {
      await fetch(`${DECIDIR_METRIX_URL}?org_id=${DECIDIR_ORG_ID}&session_id=${DECIDIR_MERCHANT_ID}${devideId}`, {
        method: 'get',
        headers: {
          'content-type': 'application/json',
        },
        cache: 'no-cache',
        mode: 'no-cors',
      });
      return devideId;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  getDeviceFingerPrintId(): number {
    return 100000 + Math.floor(Math.random() * 999999);
  }

  // TODO DON´T TOUCH THIS, IT´S MAGIC
  private createUniqueIdentifier = (): string => {
    const baseFormat = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    let currStep = new Date().getTime();
    const uniqueId = baseFormat
      .split('')
      .map((maskChar) => {
        if (maskChar === '-' || maskChar === '4') {
          return maskChar;
        }
        const randomNum = (currStep + 16 * Math.random()) % 16 | 0; // eslint-disable-line no-bitwise

        const yMaskValue = (3 & randomNum) | 8; // eslint-disable-line no-bitwise
        currStep = Math.floor(currStep / 16);
        return (maskChar === 'x' ? randomNum : yMaskValue).toString(16);
      })
      .join('');
    return uniqueId;
  };

  // TODO: Verificar si hay una mejor forma
  getIp = async (): Promise<string> => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const ip: { ip: string } = await response.json();
      return ip.ip || '';
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

const i: Decidir = new Decidir();
export { i as Decidir };
