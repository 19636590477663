import { Payment, PaymentCancelation, ValidPayment } from 'src/app/models';
import { EcommerceDAO } from './EcommerceDAO';

export class PaymentDAO extends EcommerceDAO<Payment> {
  constructor() {
    super('payment', 'paymentId');
  }

  async paymentDecidir(paymentData: Payment): Promise<any> {
    const response = await this.fetchData(`/payment/decidir`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(paymentData),
    });
    return response.json();
  }

  async checkPayment(id: string) {
    const response = await this.fetchData(`/payment/${id}`);
    return response.json();
  }

  async checkExistPayment(id: string) {
    const response = await this.fetchData(`/payment/check/${id}`);
    return response.json();
  }

  async sendCancelation(infoCancelation: PaymentCancelation) {
    const response = await this.fetchData(`/payment/cancelation`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(infoCancelation),
    });
    return response.json();
  }

  async paymentMercadoPago(paymentData: Payment): Promise<any> {
    const response = await this.fetchData(`/payment/mercadopago`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(paymentData),
    });
    return response.json();
  }

  async paymentCredit(paymentData: Payment): Promise<any> {
    const response = await this.fetchData(`/payment/credit`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(paymentData),
    });
    return response.json();
  }

  async paymentValidationIpAddress(ip_address: string): Promise<any> {
    const response = await this.fetchData(`/payment/validation/${ip_address}`);
    return response.json();
  }

  async paymentValidDecidir(paymentData: Payment): Promise<ValidPayment> {
    const response = await this.fetchData(`/payment/reject_validation`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(paymentData),
    });
    return response.json();
  }
}
