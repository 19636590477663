export const formatPrice = (toFormat: number | string): string => {
  if (typeof toFormat === 'string') toFormat = Number(toFormat);

  // return toFormat
  //   .toFixed(2)
  //   .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  //   .replaceAll('.', '#')
  //   .replaceAll(',', '.')
  //   .replaceAll('#', ',')
  //   .toString();
  return new Intl.NumberFormat().format(toFormat);
};
