import { useMemo, useCallback } from 'react';
import { FieldValidator } from 'final-form';
import Fuse from 'fuse.js';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { checkoutAllValues } from '../../../initialValues';

export const useValidatorCardHolder = () => {
  const { values } = useCheckoutFormContext();

  const valuesForm = useMemo(
    () => ({
      ...checkoutAllValues,
      ...values,
    }),
    [values],
  );

  const completeName = useMemo(
    () => [
      {
        fullNames: `${valuesForm.personalInfo.name} ${valuesForm.personalInfo.lastName}`,
      },
    ],
    [valuesForm.personalInfo.lastName, valuesForm.personalInfo.name],
  );

  const fuse = useMemo(
    () =>
      new Fuse(completeName, {
        keys: ['fullNames'],
        distance: 100,
        threshold: 0.3,
        includeScore: true,
        ignoreLocation: true,
        minMatchCharLength: 3,
      }),
    [completeName],
  );

  const validatorCardHolder: FieldValidator<any> = useCallback(
    (value: string = '') => {
      if (value.length >= 5) {
        const _name = value.split(' ').map((item) => fuse.search(item));
        if (!_name[1]?.length) return 'Los datos del titular deben coincidir con los datos del comprador.';
      }
    },
    [fuse],
  );
  return { validatorCardHolder };
};
