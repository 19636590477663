import React, { useMemo } from 'react';
import { SkeletonComponentProps } from 'src/app/models/Site';
import { InfoPromos } from 'src/app/views/components/PromotionsView/components';
import { StorefrontOutlined, CreditCard, LocalShipping } from '@material-ui/icons';
import { useMediaQuery, Theme } from '@material-ui/core';

export const SkeletonInfoDIscount = ({ data }: SkeletonComponentProps) => {
  console.log('data en SkeletonInfoDIscount', data);
  // TODO : Traer la informacion de la base de datos
  const isMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const listLink = useMemo(
    () => [
      {
        icon: <StorefrontOutlined htmlColor="rgba(0, 0, 0, 0.87)" fontSize={isMdDown ? 'small' : 'large'} />,
        title: '¡Podés pagar con crédito!',
        url: '/',
        onClick: () => console.log('hola'),
      },
      {
        icon: <CreditCard htmlColor="rgba(0, 0, 0, 0.87)" fontSize={isMdDown ? 'small' : 'large'} />,
        title: 'Hasta 48% OFF',
        url: '/',
        onClick: () => console.log('hola'),
      },
      {
        icon: <LocalShipping htmlColor="rgba(0, 0, 0, 0.87)" fontSize={isMdDown ? 'small' : 'large'} />,
        title: 'Envíos gratis a todo el país',
        url: '/',
        onClick: () => console.log('hola'),
      },
    ],
    [isMdDown],
  );

  return <InfoPromos list={listLink} />;
};
