import React, { useCallback } from 'react';
import { EmailInfoFormView } from 'src/app/views';
import { useConfirmationDAO } from 'src/app/business';
import { useSnackbar } from 'notistack';
import { VERIFICATION_STATUS } from 'src/app/models';
import { useRecoveryFormContext } from '../../hooks';

export const EmailInfoForm = () => {
  const { form, values, errors, handleSubmit } = useRecoveryFormContext();
  const confirmationDAO = useConfirmationDAO();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(async () => {
    if (Object.keys(errors as any).length !== 0) return;
    try {
      const result = await confirmationDAO.sendCode({ email: values.email });
      if (result.codeResult === VERIFICATION_STATUS.FAILED) {
        enqueueSnackbar('El email es incorrecto', { variant: 'error' });
      } else if (result.codeResult === VERIFICATION_STATUS.SENT) {
        handleSubmit();
      }
    } catch (error) {
      enqueueSnackbar('Error al enviar el codigo', { variant: 'error' });
    }
  }, [values.email, errors, confirmationDAO, handleSubmit, enqueueSnackbar]);

  return <EmailInfoFormView form={form} onSubmit={onSubmit} />;
};
