import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { HOPPoints } from 'src/app';

export interface MapProps {
  center: HOPPoints;
  mobile: boolean;
}

export const Map = ({ center, mobile = false }: MapProps) => (
  <>
    <GoogleMap center={center} mapContainerStyle={{ height: mobile ? '250px' : '350px', width: mobile ? '280px' : '500px' }} zoom={16}>
      <Marker position={center} />
    </GoogleMap>
  </>
);
