import { Feature, FeatureGroup } from 'src/app/models';

export interface searchFeatureProp {
  name: string;
  featuresAll: FeatureGroup[];
}

export const searchFeature = ({ name, featuresAll }: searchFeatureProp) => featuresAll.find((featureNew: FeatureGroup) => featureNew.name === name);

export const searchNotInFeature = ({ name, featuresAll }: searchFeatureProp) => featuresAll.find((featureNew: FeatureGroup) => featureNew.name !== name);


interface searchSkuPublicationParams {
  featuresPublication: FeatureGroup[] | undefined;
}

export const searchSkuPublication = ({ featuresPublication = [] }: searchSkuPublicationParams) => {
  const searchFeatureGeneral = searchFeature({
    name: 'Generales',
    featuresAll: featuresPublication,
  })?.features;

  return searchFeatureGeneral && searchFeatureGeneral.find((feature: Feature) => feature.name === 'SKU')?.value.replace('/', '');
};
