import { EcommerceDAO } from './EcommerceDAO';
import { Subscription } from '../../../app';

export class SubscriptionDAO extends EcommerceDAO<Subscription> {
  constructor() {
    super('subscription', 'subscriptionId');
  }

  async saveSubscription(email: string): Promise<void> {
    const response = await this.fetchData(`/subscription/new`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ email }),
    });
    return response.json();
  }
}
