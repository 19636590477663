import React from 'react';
import { Container } from '@material-ui/core';
import { MaintenancePageView } from 'src/app/views'

export const MaintenancePage = () => (
    <Container>
      <MaintenancePageView />
    </Container>
  );

