import React, { useMemo } from 'react';
import { EMITTERS } from 'src/app/const/Emitters';
import { CardInfoFormDecidir, CardInfoFormMercadoPago, CardInfoFormCredit } from './components';
import { useCheckoutFormContext } from '../../hooks/CheckoutFormContext';
import { checkoutAllValues } from '../../initialValues';

const CARD_INFO_ESPECIAL_PAYMENT: { [k: number]: () => JSX.Element } = {
  [EMITTERS.MERCADOPAGO]: CardInfoFormMercadoPago,
  [EMITTERS.CATAMARCA_CREDITS]: CardInfoFormCredit,
};

const CARD_INFO_PAYMENT_DECIDIR = CardInfoFormDecidir;

export const CardInfoForm = () => {
  const { values } = useCheckoutFormContext();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);
  const Comp = CARD_INFO_ESPECIAL_PAYMENT[valuesForm.paymentMethod.emitter.card_emiter_id] || CARD_INFO_PAYMENT_DECIDIR;
  return <Comp />;
};
