import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTemplateContext, useFormContext, Template } from 'src/lib/templates';
import { AnyObject } from 'src/commons';
import { responsableTemplate, docentInfoTemplate, alumnInfoTemplate, summaryAddressTemplate, loginTemplate, tokenCardTemplate } from '../templates';
import { MultiStepFormSteps } from '../../../../lib/MultiStepForm/types';
import { useMultiStepFormContext } from '../../../../lib/MultiStepForm';
import { checkoutAllValues, ICheckoutAll } from '../initialValues';

const isFormEmpty = (form: any) => !Object.keys(form).some((k) => !!form[k]);

export const useStepTemplateLogic = () => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  /* eslint-disable */
  const { form, values, errors } = useFormContext<AnyObject>();
  const { activeStep, completedSteps, steps } = useMultiStepFormContext();
  const { setTemplate } = useTemplateContext();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const ObjectSteps = useMemo(
    () =>
      steps.reduce<{ [k: number]: MultiStepFormSteps }>((accum, step, index) => {
        accum[index] = step;
        return accum;
      }, {}),
    [steps],
  );

  const valuesForm: ICheckoutAll = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const cleanerForm = useCallback(
    (template: Template) => {
      let changeTemplate = { ...template };

      if (template.template.find((property) => property.name === responsableTemplate.name) && isFormEmpty(valuesForm.responsableInscripto)) {
        changeTemplate = {
          ...template,
          template: template.template.filter((property) => property.name !== responsableTemplate.name),
        };
        form.change(responsableTemplate.name, undefined);
      }
      if (template.template.find((property) => property.name === summaryAddressTemplate.name) && isFormEmpty(valuesForm.summaryAddress)) {
        changeTemplate = {
          ...template,
          template: template.template.filter((property) => property.name !== summaryAddressTemplate.name),
        };
        form.change(summaryAddressTemplate.name, undefined);
      }
      if (template.template.find((property) => property.name === docentInfoTemplate.name) && isFormEmpty(valuesForm.docentInfo)) {
        changeTemplate = {
          ...template,
          template: template.template.filter((property) => property.name !== docentInfoTemplate.name),
        };
        form.change(docentInfoTemplate.name, undefined);
      }
      if (template.template.find((property) => property.name === alumnInfoTemplate.name) && isFormEmpty(valuesForm.alumnInfo)) {
        changeTemplate = {
          ...template,
          template: template.template.filter((property) => property.name !== alumnInfoTemplate.name),
        };
        form.change(alumnInfoTemplate.name, undefined);
      }

      if (template.template.find((property) => property.name === loginTemplate.name)) {
        changeTemplate = {
          ...template,
          template: template.template.filter((property) => property.name !== loginTemplate.name),
        };
        form.change(loginTemplate.name, undefined);
      }

      if (template.template.find((property) => property.name === tokenCardTemplate.name) && isFormEmpty(valuesForm.tokenCardInfo)) {
        changeTemplate = {
          ...template,
          template: template.template.filter((property) => property.name !== tokenCardTemplate.name),
        };
        form.change(tokenCardTemplate.name, undefined);
      }

      form.change('useDeliveryAddress', true);
      // form.change('recaptcha', '');

      return changeTemplate;
    },
    [valuesForm],
  );

  const setterTemplate = useCallback(
    (step: MultiStepFormSteps, extra?: (template: Template) => Template) => {
      if (currentStep > activeStep && !completedSteps.has(currentStep)) {
        setTemplate((template) => {
          let newTemplate = { ...template };
          if (!template.template.find((property) => property.name === steps[currentStep].template.name)) {
            return newTemplate;
          }
          form.change(steps[currentStep].template.name, undefined);
          if (extra) newTemplate = { ...extra(newTemplate) };

          return {
            ...template,
            template: newTemplate.template.filter((property) => property.name !== steps[currentStep].template.name),
          };
        });
        setCurrentStep(activeStep);
      }
      setTemplate((template) => {
        if (template.template.find((property) => property.name === step.template.name)) {
          return template;
        }
        form.change(step.template.name, step.initialValue);
        return {
          ...template,
          template: [...template.template, step.template],
        };
      });
    },
    [activeStep, currentStep, completedSteps, form, setTemplate],
  );

  useEffect(() => {
    if (currentStep < activeStep) {
      setCurrentStep(activeStep);
    }
    const stepValue = ObjectSteps[activeStep];
    setterTemplate(stepValue, cleanerForm);
  }, [setTemplate, completedSteps, currentStep, activeStep, form, steps]);
};
