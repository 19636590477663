import React from 'react';
import { Box, Grid } from '@material-ui/core';

interface GenericIconProps {
  width: string;
  height: string;
  image: string;
  styles?: any;
  children?: any;
  open?: boolean;
  onClick?: () => void;
}

export const GenericIcon = ({ width = '16px', height = '16px', image, styles, onClick }: GenericIconProps) => (
  <Box width={width} justifyContent="center" alignContent="center" height={height} display="grid">
    <Grid component="img" src={image} style={styles} onClick={onClick} />
  </Box>
);
