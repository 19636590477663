import { Template } from 'src/lib/templates';

export const addressesInfoTemplate: Template = {
  name: 'Addresses Information Template',
  template: [
    {
      name: 'province',
      type: {
        name: 'object',
        kind: [
          { name: 'id', type: { name: 'string' } },
          { name: 'name', type: { name: 'string' } },
          { name: 'matches', type: { name: 'array', kind: 'string' } },
        ],
      },
      label: 'Provincia',
    },
    {
      name: 'city',
      type: {
        name: 'object',
        kind: [
          { name: 'id', type: { name: 'string' } },
          { name: 'state_id', type: { name: 'number' } },
          { name: 'zip', type: { name: 'array', kind: 'number' } },
          { name: 'name', type: { name: 'string' } },
          { name: 'matches', type: { name: 'array', kind: 'string' } },
        ],
      },
      label: 'Ciudad',
    },
    {
      name: 'street',
      type: { name: 'string' },
      label: 'Calle',
      validators: [
        { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
        { name: 'max', signature: [45] },
        { name: 'empty', errorMessage: 'Completa este dato.' },
      ],
    },
    {
      name: 'streetNumber',
      type: { name: 'string' },
      label: 'Altura',
      validators: [
        {
          name: 'pattern',
          signature: ['^[0-9]+$'],
        },
      ],
    },
    {
      name: 'floor',
      type: { name: 'string' },
      label: 'Piso', 
      optional: true,
      validators: [
        {
          name: 'pattern',
          signature: ['^[A-Za-z]{0,2}[0-9]*$'],
        },
        {
          name: 'max',
          signature: [5],
        },
      ],
    },
    {
      name: 'apartament',
      type: { name: 'string' },
      label: 'Apartamento',
      optional: true,
      validators: [
        {
          name: 'pattern',
          signature: ['^[A-Za-z0-9]+$'],
        },
        {
          name: 'max',
          signature: [5],
        },
      ],
    },
    {
      name: 'zipCode',
      type: { name: 'string' },
      label: 'Código postal',
      validators: [
        { name: 'pattern', signature: ['^[0-9]+$'] },
        { name: 'max', signature: [6] },
        { name: 'empty', errorMessage: 'Completa este dato.' },
      ],
    },
    {
      name: 'additionalInfo',
      type: { name: 'string' },
      label: 'Información adicional',
      optional: true,
      validators: [
        { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ0-9\u00f1\u00d1 ]+$'] },
        { name: 'max', signature: [50] },
      ],
    },
    {
      name: 'is_selected',
      type: { name: 'boolean' },
      label: 'Usar este domicilio como domicilio de entrega',
      optional: true,
    },
  ],
};
