export interface JetGroups {
  id: string;
  name: string;
}
export interface JetUser {
  id: string;
  nick: string;
  email: string;
  username: string;
  name: string;
  lastname: string;
  groups: Array<JetGroups>;
  isGuest: boolean;
}

export interface ResponseLogin {
  user: JetUser;
  isGuest: boolean;
}

class SessionChangeEmitterRaw {
  // eslint-disable-next-line @typescript-eslint/ban-types
  subscriptions: Function[];

  constructor() {
    this.subscriptions = [];
  }

  subscribe(fn: (params: { isLogged: boolean; user: JetUser }) => any): () => void {
    this.subscriptions.push(fn);

    return () => this.unsubscribe(fn);
  }

  unsubscribe(fn: (params: any) => any): void {
    this.subscriptions = this.subscriptions.filter((item) => item !== fn);
  }

  emitChange<F extends any[]>(...args: F): void {
    this.subscriptions.forEach((func) => func(...args));
  }
}

export const SessionChangeEmitter = new SessionChangeEmitterRaw();
