import { makeStyles } from '@material-ui/core';

export const productFiltersStyles = makeStyles((theme) => ({
  container: {
    padding: 30,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 8,
    background: 'white',
    [theme.breakpoints.up('lg')]: {
      maxHeight: 1200,
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingTop: 0,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  menu: {},
  boxCategory: {
    color: '#373737',
    fontSize: '1rem',
    fontWeight: 500,
  },
  boxSubCategory: {
    color: '#8D8D8D',
    fontSize: '1rem',
    fontWeight: 500,
    paddingLeft: '.8em',
  },
}));
