import { useHistory } from "react-router-dom";


export const useHandleRedirect = () => {
    const history = useHistory();
    
    const handleRedirect = (url: any, to: "inner" | "external" = "inner") => {
        if(to === 'inner') history.push(url);
        else window.location.assign(url);     
    }
    return handleRedirect;
}