import { makeStyles } from '@material-ui/core';

export const stylesRetirementPoints = makeStyles(() => ({
  containerDesktop: {
    width: '70%',
  },
  containerMobile: {
    width: '100%',
  },
  inputUbicationDesktop: {
    width: '100%',
  },
  inputUbicationMobile: {
    width: '100%',
    fontSize: '10px',
  },
  buttonDesktop: {
    fontSize: '.8rem',
    borderRadius: '4px',
    padding: '0px .8rem',
    width: '25%',
  },
  buttonMobile: {
    marginTop: '1rem',
  },
  buttonMore: {
    fontSize: '.8rem',
    borderRadius: '4px',
    padding: '.5rem 1.5rem',
    marginTop: '1.2rem',
  },
}));
