import { TemplateProperty } from '../../../../lib/templates';

export const cardInfoTemplate: TemplateProperty = {
  name: 'cardInfo',
  type: {
    name: 'object',
    kind: [
      {
        name: 'cardHolder',
        type: { name: 'string' },
        label: 'Titular de la tarjeta',
        validators: [{ name: 'empty', errorMessage: 'Completa este dato.' }],
      },
      {
        name: 'cardNumber',
        type: { name: 'string' },
        label: 'Número de la tarjeta',
        validators: [
          { name: 'empty', errorMessage: 'Completa este dato.' },
          { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Solo podés ingresar números.' },
          { name: 'min', signature: [15], errorMessage: 'Número de tarjeta inválido.' },
          { name: 'max', signature: [16], errorMessage: 'Número de tarjeta inválido.' },
        ],
      },
      {
        name: 'cardExpiration',
        type: { name: 'string' },
        label: 'Vencimiento',
      },
      {
        name: 'cardSecurityCode',
        type: { name: 'string' },
        label: 'Código de seguridad',
        validators: [
          { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Solo podés ingresar números.' },
          { name: 'max', signature: [4] },
          { name: 'empty', errorMessage: 'Completa este dato.' },
        ],
      },
    ],
  },
};

export const makeCardInfoTemplate = ({ isCapresca }: any) => ({
  name: 'cardInfo',
  type: {
    name: 'object',
    kind: isCapresca ? (cardInfoTemplate.type.kind as TemplateProperty[]).map((item) => ({ ...item, optional: true })) : cardInfoTemplate.type.kind,
  },
});
