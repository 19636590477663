import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { Shipment } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'shipment';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idshipment',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idshipment',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Shipment
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Shipment,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Shipment.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Shipment>(DAO_NAME, optionsToUse);

// hooks
export const useShipmentFetchById = fetchById;
export const useShipmentLibbyCall = libbyCall;
export const useShipmentDAO = libbyDAO;
export const useShipmentLibbyFetch = libbyFetch;

// components/hoc
export const ShipmentAutocomplete = entityAutocomplete;
export const ShipmentCall = renderPropCall;
export const ShipmentById = renderPropFetchById;
export const ShipmentSelect = simpleSelect;
export const ShipmentAccordionSelector = accordionSelector;
export const ShipmentSelectedChips = selectedChips;

// context
// ByIdContext
export const useShipmentByIdContext = useByIdContext;
export const ShipmentByIdProvider = ByIdProvider;
export const ShipmentByIdContext = ByIdContext;
