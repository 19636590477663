import { makeStyles } from '@material-ui/core';

export const appFooterStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.appBar,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  bottom: {
    height: 62,
    background: '#F6F6F6',
    [theme.breakpoints.down('sm')]: {
      height: 95,
    },
    '& img': {
      marginRight: 8,
    },
  },
  fullHeight: {
    height: '100%',
  },
  autoWidth: {
    width: 'auto',
  },
  containerSubscription: {
    height: '120px',
    [theme.breakpoints.down(769)]: {
      height: '150px',
    },
    [theme.breakpoints.down(500)]: {
      height: '180px',
    },
  },
  containerForm: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(769)]: {
      marginTop: '5px',
    },
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      width: '100%',
      marginTop: '16px',
    },
  },
  inputStyleFooter: {
    width: '320px',
    margin: 'auto 16px auto 0',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down(769)]: {
      height: '71px',
    },
    [theme.breakpoints.down(500)]: {
      height: '100%',
      margin: 'auto 8px auto 0',
      width: '232px',
    },
  },
  buttonStyles: {
    width: '130px',
    height: '42px',
    margin: 'auto 0',
    [theme.breakpoints.down(500)]: {
      height: '32px',
      width: '101px',
    },
  },
  errorMsg: {
    color: theme.palette.error.main,
  },
  socialMediaIcons: {
    fontSize: '30px',
    marginRight: '5px',
    [theme.breakpoints.down(769)]: {
      fontSize: '40px',
    }
  }
}));
