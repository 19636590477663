import React, { useCallback, useEffect, useState } from 'react';
import { GOOGLE_OAUTH_CLIENT_ID } from '../../../../config';


interface GoogleButtonProps {
    gsiButtonConfig?: Partial<GsiButtonConfiguration>
    handleGoogleSignIn: (response: CredentialResponse) => void;

}
export const GoogleButton = ({ gsiButtonConfig, handleGoogleSignIn }: GoogleButtonProps) => {
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);

    const initializeGsi = useCallback(() => {
        // Typescript will complain about window.google
        // Add types to your `react-app-env.d.ts` or //@ts-ignore it.
        if (!window.google || gsiScriptLoaded) return;
        setGsiScriptLoaded(true);
        window.google.accounts.id.initialize({
            client_id: GOOGLE_OAUTH_CLIENT_ID,
            callback: handleGoogleSignIn,
        });

        window.google.accounts.id.renderButton(document.getElementById('google-button')!, { type: 'standard', size: 'large', width: "208px", ...gsiButtonConfig });
    }, [gsiScriptLoaded, handleGoogleSignIn, gsiButtonConfig]);

    useEffect(() => {
        if (gsiScriptLoaded) return;

        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = initializeGsi;
        script.async = true;
        script.id = 'google-client-script';
        document.querySelector('body')?.appendChild(script);

        return () => {
            // Cleanup function that runs when component unmounts
            window.google?.accounts.id.cancel();
            document.getElementById('google-client-script')?.remove();
        };
    }, [initializeGsi, gsiScriptLoaded]);

    return <div id="google-button" />;
};
