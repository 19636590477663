import { makeStyles } from '@material-ui/core';

export const NotFoundPageStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: 'white',
    padding: 25,
    width: '95%',
    borderRadius: 5,
    borderTop: '5px solid #E31B0C',
    margin: '2% auto',
    boxShadow: '0 4px 5px 0 rgba(0, 0, 0, .25)',
  },
  titleText: {
    alignItems: 'center',
    color: '#F44336',
    fontSize: '48px',
    marginBottom: '16px',
  },
  linkStyle: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  exclamationIcon: {
    height: '66px',
    width: '66px',
    color: '#F44336',
  },
}));
