import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { Buyer } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'buyer';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'buyer_id',
      labelKey: 'name',
    },
    withSelectedChips: {
      idKey: 'buyer_id',
      labelKey: 'name',
    },
  },
};

const optionsToUse = !businessOptions.Buyer
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Buyer,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Buyer.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Buyer>(DAO_NAME, optionsToUse);

// hooks
export const useBuyerFetchById = fetchById;
export const useBuyerLibbyCall = libbyCall;
export const useBuyerDAO = libbyDAO;
export const useBuyerLibbyFetch = libbyFetch;

// components/hoc
export const BuyerAutocomplete = entityAutocomplete;
export const BuyerCall = renderPropCall;
export const BuyerById = renderPropFetchById;
export const BuyerSelect = simpleSelect;
export const BuyerAccordionSelector = accordionSelector;
export const BuyerSelectedChips = selectedChips;

// context
// ByIdContext
export const useBuyerByIdContext = useByIdContext;
export const BuyerByIdProvider = ByIdProvider;
export const BuyerByIdContext = ByIdContext;
// GeneralContext
// export const useBuyerContext = useGeneralContext;
// export const BuyerProvider = GeneralProvider;
// export const BuyerContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
