import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { GenericIcon } from 'src/commons/components/GenericIcon';
import { productCardStyles as useStyles } from '../../styles';

export default function ProductWithOutStock() {
    const classes = useStyles();

    return (
        <Box className={classes.withOutStock}>
            <GenericIcon image='https://res.cloudinary.com/phinx-lab/image/upload/v1676658439/Assets/Vector.png' width='20px' height='20px' />
            <Typography style={{ fontSize: '14px' }}>
                Temporalmente sin stock
            </Typography>
        </Box>
    );
}