import { getPricePublication } from 'src/context/CartContext/utils';
import { AddToCartParams, PurchaseEventParams, StartCheckoutEventParams, VisitPublicationEventParams } from '../interfaces';

export const facebookAddToCart = (params: AddToCartParams) => ({
  content_name: params?.publication?.product,
  content_ids: [params?.publication?.id],
  value: Number(getPricePublication(params?.publication) || 0) * (params.quantity || 1),
  currency: 'ARS',
});

export const facebookPurchase = (params: PurchaseEventParams, name: string) => ({
  content_name: name,
  currency: 'ARS',
  value: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1) * Number(getPricePublication(item.publication) || 0), 0),
  content_ids: params.cart?.items?.map((item) => item.publication.id) || [],
  num_items: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1), 0) || 0,
  contents: params.cart?.items?.map(item => ({
    quantity: item.quantity,
    id: item.publication.id,
    item_price: Number(getPricePublication(item.publication)),
  })) || [],
});

export const facebookStartCheckout = (params: StartCheckoutEventParams) => ({
  num_items: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1), 0) || 0,
  currency: 'ARS',
  value: params.cart?.items?.reduce((acum, item) => acum + (item.quantity || 1) * Number(getPricePublication(item.publication) || 0), 0),
});

export const facebookViewContent = (params: VisitPublicationEventParams) => ({
  content_name: params?.publication?.product || 'Producto sin nombre',
})