import React from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useCheckoutFormContext } from '../../hooks/CheckoutFormContext';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';
import { useFields } from './hooks/useFields';
import { FieldComponent } from '../FieldComponent';
import { Loading } from '../../../../../commons';

export const PaymentMethodInfoForm = () => {
  const { errors, handleSubmit } = useCheckoutFormContext();
  const { useMakeDown } = useBreakpointsState();
  const { fields, loading } = useFields();

  const isDownSm = useMakeDown('sm');

  return (
    <Box paddingBottom={3} bgcolor="white" borderRadius={8} boxShadow={3} display="content">
      <Box padding={isDownSm ? 2 : 3}>
        <Grid container direction="row" spacing={3}>
          {loading ? <Loading /> : fields.map((item) => <FieldComponent {...item} />)}

          {isDownSm && (
            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={Object.keys(errors as any).length > 0}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Continuar
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
