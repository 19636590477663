import { FACEBOOK_OAUTH_APP_ID, FACEBOOK_REDIRECT_URI } from '../../config';
import { EventEmitter } from '../../utils';
import { ProfileUserFB } from '../models';

export const userInfoInit: ProfileUserFB = {
  id: '',
  name: '',
  email: '',
  last_name: '',
  picture: { data: { height: 0, is_silhouette: false, url: '', width: 0 } },
  first_name: '',
};

export type LoginStatus = 'authorization_expired' | 'connected' | 'not_authorized' | 'unknown';
export interface AuthResponse {
  accessToken: string;
  expiresIn: number;
  signedRequest: string;
  userID: string;
  grantedScopes?: string | undefined;
  reauthorize_required_in?: number | undefined;
}
export interface StatusResponse {
  status: LoginStatus;
  authResponse: AuthResponse | undefined;
}

interface FacebookConfig {
  appId: string;
  xfbml: boolean;
  cookie: boolean;
  scope: string;
  fields: string;
  version: string;
  language: string;
  redirectUri: string;
}

const facebookConfig: FacebookConfig = {
  appId: FACEBOOK_OAUTH_APP_ID,
  scope: 'public_profile,email',
  xfbml: true,
  cookie: true,
  fields: 'id,name,email,picture,last_name,first_name',
  version: '8.0',
  language: 'es_LA',
  redirectUri: FACEBOOK_REDIRECT_URI,
};

class FacebookApi {
  private config: FacebookConfig;

  private eventEmitter: EventEmitter = new EventEmitter();

  private isInitFacebook: boolean = false;

  constructor() {
    this.config = facebookConfig;
  }

  getConfig() {
    return this.config;
  }

  init() {
    window.FB.init({
      appId: this.config.appId,
      xfbml: this.config.xfbml,
      cookie: this.config.cookie,
      version: `v${this.config.version}`,
    });
  }

  async checkLoginState(response: StatusResponse) {
    // auto authenticate with the api if already logged in with facebook
    if (response.authResponse) {
      const userInfo = await this.getUserInfo();
      this.eventEmitter.emit({ ...response, userInfo });
    } else {
      this.eventEmitter.emit({ ...response });
    }
  }

  isLoaded() {
    return this.isInitFacebook;
  }

  logout() {
    window.FB.logout();
  }

  login() {
    window.FB.login(this.checkLoginState, { scope: this.config.scope });
  }

  async getLoginStatus(): Promise<StatusResponse> {
    let fbStatus: StatusResponse = { authResponse: undefined, status: 'unknown' };
    const promise = new Promise<StatusResponse>((resolve) => {
      window.FB.getLoginStatus((response) => {
        resolve(response);
      });
    });
    fbStatus = await promise;
    return fbStatus;
  }

  async getUserInfo() {
    let userInfo: ProfileUserFB = userInfoInit;
    const promise = new Promise<ProfileUserFB>((resolve) => {
      window.FB.api(`/me`, { fields: this.config.fields }, resolve);
    });
    const result = await promise;
    userInfo = result;
    return userInfo;
  }

  subscribe(fn: (...params: any[]) => any) {
    return this.eventEmitter.subscribe(fn);
  }

  getIsMobile() {
    let isMobile = false;
    const wNavigator: any = window.navigator;
    try {
      isMobile = !!(
        (wNavigator && wNavigator.standalone) ||
        navigator.userAgent.match('CriOS') ||
        navigator.userAgent.match(/mobile/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      );
    } catch (ex) {
      // continue regardless of error
      console.log('Error', ex);
      isMobile = false;
    }
    return isMobile;
  }

  async onInitLogin() {
    if (this.getIsMobile()) {
      const params = {
        client_id: this.config.appId,
        redirect_uri: this.config.redirectUri,
        scope: this.config.scope,
        response_type: 'token',
        language: this.config.language,
      };
      const urlParam = this.getParamsFromObject(params);
      window.location.href = `https://www.facebook.com/v${this.config.version}/dialog/oauth${urlParam}`;
    } else {
      if (!window.FB) this.eventEmitter.emit({ status: 'unknown', authResponse: undefined });
      const status = await this.getLoginStatus();
      if (status.status === 'connected') {
        await this.checkLoginState(status);
      } else {
        this.login();
      }
    }
  }

  getParamsFromObject(params: any) {
    const _params = `?${Object.keys(params)
      .map((param) => `${param}=${encodeURIComponent(params[param])}`)
      .join('&')}`;
    return _params;
  }
}

const i: FacebookApi = new FacebookApi();
export { i as FacebookApi };
