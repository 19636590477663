import React, { ChangeEvent } from 'react';
import { useField } from 'react-final-form-hooks';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';

type FunctionComponent<T> = (props: T) => JSX.Element;

export interface FormGenericControlProps<T = any> {
  name: string;
  form: any;
  componentProps?: T;
  customHelperText?: string;
  component: React.ComponentType<T> | FunctionComponent<T>;
  optionToValue?: (value: any) => any;
  valueToOption?: (value: any) => any;
}

export const FormGenericControl = ({
  component: Comp,
  componentProps = {},
  form,
  name,
  customHelperText,
  valueToOption,
  optionToValue,
}: FormGenericControlProps) => {
  const field = useField(name, form);

  const { error, helperText } = getErrorAndHelperTextNoTranslation(field);

  return (
    <Comp
      {...componentProps}
      onFocus={field.input.onFocus}
      onBlur={field.input.onBlur}
      value={field.input.value ? (valueToOption ? valueToOption(field.input.value) : field.input.value) : ''}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        field.input.onChange(optionToValue ? optionToValue(newValue) : newValue);
      }}
      error={error}
      helperText={!customHelperText ? helperText : error ? customHelperText : ''}
    />
  );
};
