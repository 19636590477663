/* eslint-disable */
import React from 'react';

interface AddShoppingCartProps {
  width?: string | number;
  height?: string | number;
  color?: string;
  opacity?: string;
}

export const AddShoppingCart = ({ width = 32, height = 32, color = 'black', opacity = '0.87' }: AddShoppingCartProps) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.63855 24.988C8.63855 23.7054 9.66806 22.656 10.9405 22.656C12.2129 22.656 13.254 23.7054 13.254 24.988C13.254 26.2706 12.2129 27.32 10.9405 27.32C9.66806 27.32 8.63855 26.2706 8.63855 24.988Z"
      fill={color}
      fillOpacity={opacity}
    />
    <path
      d="M20.206 24.988C20.206 23.7054 21.2355 22.656 22.5079 22.656C23.7804 22.656 24.8214 23.7054 24.8214 24.988C24.8214 26.2706 23.7804 27.32 22.5079 27.32C21.2355 27.32 20.206 26.2706 20.206 24.988Z"
      fill={color}
      fillOpacity={opacity}
    />
    <path
      d="M11.1718 18.7266L11.1371 18.8665C11.1371 19.0297 11.2759 19.158 11.4263 19.158H24.8214V21.49H10.9405C9.66806 21.49 8.62698 20.4406 8.62698 19.158C8.62698 18.7499 8.73109 18.3651 8.91617 18.0386L10.4778 15.1819L6.31349 6.332H4V4H7.78256L8.8699 6.332L9.96881 8.664L12.5599 14.1792L12.7103 14.494H20.8307L24.0233 8.664L25.2957 6.332H25.3073L27.32 7.45136L22.855 15.625C22.4617 16.3479 21.6982 16.826 20.8307 16.826H12.2129L11.1718 18.7266Z"
      fill={color}
      fillOpacity={opacity}
    />
  </svg>
);
