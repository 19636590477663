export const bannerStyles = (background: string, width: number) => ({
  containerColumn: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: width > 970 ? '100vh' : '100%',
    minHeight: '400px',
    margin: 0,
    backgroundColor: background,
  },
  containerRow: {
    paddingBottom:'60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
    minHeight: '400px',
    backgroundColor: background,
  },
  boxLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: width > 970 ? 'flex-start' : 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '500px',
    minWidth: '500px',
    height: '100%',
    margin: 0,
    backgroundColor: background,
  },
  boxRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: width > 970 ? 'flex-end' : 'center',
    justifyContent: width > 970 ? 'flex-end' : 'center',
    width: width > 970 ? '30%' : '100%',
    maxWidth: '500px',
    backgroundColor: background,
  },
  boxCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    margin: 0,
    backgroundColor: background,
  },
  background: {
    width: 'inherit',
    height: 'inherit',
    backgroundColor: background,
  },
  top: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
    height: '10%',
    maxHeight: '170px',
    minHeight: '100px',
    // border: '1px solid green'
  },
  formContainer: {
    paddingTop:6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: '50px',
    backgroundColor: '#FFFFFF',
    width: '70%',
    height: 'auto',
    maxHeight: '400px',
    borderRadius: '15px',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '15px',
    width: '300px',
  },
  textField: {
    margin: '10px',
    width: '100%',
  },
  text: {
    fontSize: '20px',
    lineHeight: '1.2',
    fontWeight: 600,
    color: '#FFFFFF',
  },
});
