import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Payment, CartItem } from '../../../../../../app/models';
import { usePaymentDAO, useCartDAO } from '../../../../../../app/business';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { createOrder, createPaymentCredit } from '../../../utils';
import { useCartContext, useGeneralContext } from '../../../../../../context';
import { useProcesingFile, ImagesUrl } from '../hooks/useProcesingFile';
import { Loading } from '../../../../../../commons';
import { useMultiStepFormContext } from '../../../../../../lib/MultiStepForm';
import { checkoutAllValues } from '../../../initialValues';

export const CardInfoFormCredit = () => {
  const { values } = useCheckoutFormContext();
  const { cartState } = useCartContext();
  const { dispatch } = useGeneralContext();
  const { setActiveStep } = useMultiStepFormContext();
  const cartDAO = useCartDAO();
  const paymentDAO = usePaymentDAO();
  const [requesting, setRequesting] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState('');

  const { getImagesUrl } = useProcesingFile();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  useEffect(() => {
    if (valuesForm && cartState && !requesting) {
      setRequesting(true);
      (async () => {
        try {
          const { items, owner, lastUpdate = new Date(), state_id } = cartState;
          const itemsFix = items.map<CartItem>(({ quantity, publication }) => ({
            quantity,
            publication,
          }));

          const { personalInfo, applicantInfo, guarantorInfo, docentInfo, alumnInfo } = valuesForm;

          const applicantInfoImg = applicantInfo
            ? await getImagesUrl([
                { item: applicantInfo.img_dni_back!, name: 'img_dni_back' },
                { item: applicantInfo.img_paycheck!, name: 'img_paycheck' },
                { item: applicantInfo.img_dni_front!, name: 'img_dni_front' },
                { item: applicantInfo.img_service_certificate!, name: 'img_service_certificate' },
              ])
            : ({} as ImagesUrl);

          const guarantorInfoImg = guarantorInfo
            ? await getImagesUrl([
                { item: guarantorInfo.img_dni_back!, name: 'img_dni_back' },
                { item: guarantorInfo.img_paycheck!, name: 'img_paycheck' },
                { item: guarantorInfo.img_dni_front!, name: 'img_dni_front' },
                { item: guarantorInfo.img_service_certificate!, name: 'img_service_certificate' },
              ])
            : ({} as ImagesUrl);
          const docentInfoImg = docentInfo
            ? await getImagesUrl([
                { item: docentInfo.img_dni_back!, name: 'img_dni_back' },
                { item: docentInfo.img_certificate!, name: 'img_certificate' },
                { item: docentInfo.img_dni_front!, name: 'img_dni_front' },
                { item: docentInfo.img_paycheck!, name: 'img_paycheck' },
              ])
            : ({} as ImagesUrl);
          const AlumnInfoImg = alumnInfo
            ? await getImagesUrl([
                { item: alumnInfo.img_dni_back!, name: 'img_dni_back' },
                { item: alumnInfo.img_certificate!, name: 'img_certificate' },
                { item: alumnInfo.img_dni_front!, name: 'img_dni_front' },
              ])
            : ({} as ImagesUrl);

          const order = await createOrder(
            { items: itemsFix, owner, lastUpdate, state_id, email: valuesForm.personalInfo.email, send: false },
            valuesForm,
            valuesForm.equifaxForm.validClient,
          );

          const paymentBody = createPaymentCredit(
            order,
            valuesForm.paymentMethod.payment,
            {
              ...(applicantInfo ? { applicantInfo: { ...applicantInfo, ...applicantInfoImg, ...personalInfo } } : {}),
              ...(guarantorInfo ? { guarantorInfo: { ...guarantorInfo, ...guarantorInfoImg } } : {}),
              ...(docentInfo ? { docentInfo: { ...docentInfo, ...docentInfoImg, ...personalInfo } } : {}),
              ...(alumnInfo ? { alumnInfo: { ...alumnInfo, ...AlumnInfoImg, ...personalInfo } } : {}),
            },
            values.coupon,
          );

          const resultPayment: Payment = await paymentDAO.paymentCredit(paymentBody);
          if (resultPayment.id) {
            dispatch.setIsBlocking(false);
            cartDAO.updateCart({ owner: cartState.owner, items: [], email: '', send: false });
            setTimeout(() => window.location.replace(`/cart/checkout/confirm/${resultPayment.id}?type=credit`), 0);
          } else {
            setShowButton(true);
            setError('');
          }
        } catch (error) {
          console.log(error);
          setError('Ocurrió un problema. Por favor pongase en contacto con nosotros');
          setShowButton(true);
        }
      })();
    }
  }, [cartState, paymentDAO, requesting, valuesForm, cartDAO, dispatch, getImagesUrl, values.coupon]);

  const onPay = useCallback(() => {
    dispatch.setIsBlocking(false);
    setRequesting(false);
  }, [setRequesting, dispatch]);

  const onBackStep = useCallback(() => setActiveStep((oldStep) => oldStep - 1), [setActiveStep]);

  return (
    <Grid container direction="column" spacing={1}>
      {!!error && (
        <Grid item>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      {showButton ? (
        <Grid item>
          <Grid container spacing={1} justify="center">
            <Grid item>
              <Button variant="outlined" onClick={onBackStep}>
                Volver
              </Button>
            </Grid>
            {!error && (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={onPay}>
                  Intentar Pagar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </Grid>
  );
};
