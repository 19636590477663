import React, { useCallback, useMemo } from 'react';
import { Toolbar, Grid, IconButton, GridJustification, useMediaQuery, Theme } from '@material-ui/core';
import { Menu, Search } from '@material-ui/icons';
import { MainInput } from 'src/commons';
import { CartMenu } from '../../CartMenu';
import { AppBarMobileProps } from './types';
import { appHeaderStyles as useStyles } from '../../../styles/appHeaderStyles';
import { ecommerceConfig } from '../../../../../config/ecommerce';
import { useIsPromotionPath } from '../../../../../utils';

export const AppBarMobile = ({
  quantityItems,
  onSearchClick,
  onCartClick,
  inputVisible,
  toggleInput,
  onSearchChange,
  searchValue,
  onOpenMenu,
  onLogoClick,
}: AppBarMobileProps) => {
  const classes = useStyles();
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(767));

  const { shortLogo: ShortLogo } = ecommerceConfig.ecommerceLogo;
  const containerGrid = useMemo(
    () => ({
      justify: (inputVisible ? 'flex-start' : 'space-between') as GridJustification,
    }),
    [inputVisible],
  );

  const isNovaStore: boolean = ecommerceConfig.general.title === 'Novatech Store';

  const onEnterPress = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>): void => {
      if (ev.key === 'Enter') {
        // Do code here
        ev.preventDefault();
        if (searchValue) {
          onSearchClick();
          toggleInput();
        }
      }
    },
    [onSearchClick, toggleInput, searchValue],
  );
  const onSearchClickCallBack = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.preventDefault();
      if (searchValue) {
        onSearchClick();
      }
      toggleInput();
    },
    [onSearchClick, toggleInput, searchValue],
  );

  const isPromotionPath = useIsPromotionPath();

  return (
    <Toolbar className={classes.ampliedBox}>
      {inputVisible ? (
        <Grid container direction="row" {...containerGrid}>
          <Grid item xs={9} container>
            <Grid item xs={1} container>
              <IconButton edge="start" color="primary" aria-label="open drawer" onClick={onOpenMenu}>
                <Menu />
              </IconButton>
            </Grid>
            <Grid item xs={11} container alignItems="center">
              <MainInput
                fullWidth
                placeholder=""
                iconPossition="end"
                customClass={classes.searchBarMobile}
                handleChange={(e) => onSearchChange(e)}
                value={searchValue}
                onBlur={() => !searchValue && toggleInput()}
                autoFocus
                onKeyPress={onEnterPress}
              />
            </Grid>
          </Grid>
          <Grid item xs={3} container justify="flex-end">
            <Grid item xs={4} sm={isDown768 ? 2 : 4} container justify="flex-end">
              {!isPromotionPath && (
                <IconButton
                  onClick={(e) => {
                    onSearchClickCallBack(e);
                  }}
                >
                  <Search fontSize="large" color="primary" />
                </IconButton>
              )}
            </Grid>

            <Grid item xs={4} sm={isDown768 ? 2 : 4} container justify="flex-end">
              <CartMenu count={quantityItems} onOpenMenu={onCartClick} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" {...containerGrid}>
          <Grid item xs={6} container>
            <Grid item xs={2} sm={isDown768 ? 1 : 2} md={1} container>
              <IconButton className={classes.icon} edge="start" color="primary" aria-label="open drawer" onClick={onOpenMenu}>
                <Menu />
              </IconButton>
            </Grid>
            <Grid
              className={isNovaStore ? classes.logoEcommerce : ''}
              item
              xs={10}
              sm={isDown768 ? 11 : 10}
              md={11}
              container
              onClick={onLogoClick}
              alignItems="center"
              justify="flex-start"
            >
              <ShortLogo />
            </Grid>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Grid item container justify="flex-end" sm={isDown768 ? 1 : 2} xs={2} md={1}>
              {!isPromotionPath && (
                <IconButton onClick={toggleInput}>
                  <Search fontSize="large" style={{ color: 'black', opacity: 0.87 }} />
                </IconButton>
              )}
            </Grid>

            <Grid item container xs={2} sm={isDown768 ? 1 : 2} md={1} justify="flex-end">
              <CartMenu count={quantityItems} onOpenMenu={onCartClick} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};
