import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'order';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idOrder',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idOrder',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Order
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Order,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Order.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Boolean>(DAO_NAME, optionsToUse);

// hooks
export const useOrderFetchById = fetchById;
export const useOrderLibbyCall = libbyCall;
export const useOrderDAO = libbyDAO;
export const useOrderLibbyFetch = libbyFetch;

// components/hoc
export const OrderAutocomplete = entityAutocomplete;
export const OrderCall = renderPropCall;
export const OrderById = renderPropFetchById;
export const OrderSelect = simpleSelect;
export const OrderAccordionSelector = accordionSelector;
export const OrderSelectedChips = selectedChips;

// context
// ByIdContext
export const useOrderByIdContext = useByIdContext;
export const OrderByIdProvider = ByIdProvider;
export const OrderByIdContext = ByIdContext;
// GeneralContext
// export const usePaymentContext = useGeneralContext;
// export const PaymentProvider = GeneralProvider;
// export const PaymentContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
