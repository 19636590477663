import { useMemo } from 'react';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { IFieldComponent } from '../../FieldComponent';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { useBreakpointsState } from '../../../../../../app/hooks/useMobile';
import { EquifaxForm, EquifaxResponse, Options, TransactionCompleted } from '../interfaces';
import { EquifaxSubmit } from '../components';

export const useFields = (
  questionary?: EquifaxResponse,
  approved?: TransactionCompleted,
  submited?: boolean,
  fail?: boolean,
  handleSubmitApproved?: (e: Event) => Promise<void>,
  working?: boolean,
) => {
  const { form } = useCheckoutFormContext();
  const { useMakeDown } = useBreakpointsState();

  const isDownSm = useMakeDown('sm');

  const data: IFieldComponent[] | undefined = useMemo(() => {
    const inputs = questionary?.questionnaire?.questionsOfGeneratedQuestionnaire.map((question, index) => ({
      key: question.id,
      component: FORM_FIELD_TYPE.RADIO_GROUP,
      title: question.description,
      componentProps: {
        row: !isDownSm,
        form,
        name: `equifaxForm.request[${index}]`,
        valueKey: 'id',
        labelKey: 'description',
        options: question.options,
        valueToOption: (value: EquifaxForm, options: Options[]) => options.find((item) => item.id === value.selectedOptionId)?.id.toString(),
        optionToValue: (value: number, options: Options[]) => {
          if (options.find((option) => option.id === +value)) {
            const EquifaxResponse = {
              id: question.id,
              selectedOptionId: +value,
            };
            return EquifaxResponse;
          }
        },
      },
    }));
    const button: any = {
      component: FORM_FIELD_TYPE.CUSTOMIZED,
      componentProps: {
        component: EquifaxSubmit,
        componentProps: {
          fail,
          submited,
          handleSubmitApproved,
          approved,
          working,
        },
      },
    };
    inputs?.push(button);
    return inputs;
  }, [approved, fail, form, handleSubmitApproved, isDownSm, questionary?.questionnaire?.questionsOfGeneratedQuestionnaire, submited, working]);
  return { fields: data?.filter((field) => !field.hidden) };
};
