import React, { useCallback } from 'react';
import { useField } from 'react-final-form-hooks';
import { FieldValidator } from 'final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { InputProps } from '../../../app/components/inputs/Input';
import { GOOGLE_RECAPTCHA_KEY } from '../../../config';

export interface FormRecaptchaInputProps {
  name: string;
  form: any;
  validator?: FieldValidator<any>;
}

export const FormRecaptchaInput = ({ name, form, validator }: FormRecaptchaInputProps & InputProps): JSX.Element => {
  const field = useField(name, form, validator);

  const handleChange = useCallback(
    (token: string | null) => {
      field.input.onChange(token || '');
    },
    [field.input],
  );

  return <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_KEY} onChange={handleChange} />;
};
