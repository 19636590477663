import React from 'react';
import { ORDER_STATE, PurchaseItem, SterOrder } from 'src/app/models';
import { PROVINCES } from 'src/app/const';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ecommerceConfig } from 'src/config/ecommerce';

interface ShipmentResult {
  color: string;
  state: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  caption?: React.ReactNode;
}

export const LINK_TRACKING_BY_COURIER: { [k: string]: string } = {
  1: 'https://www.seguituenvio.com',
  3: 'https://seguimiento.andreani.com/envio',
  4: 'https://www.seguituenvio.com',
};

export const getTrackingLink = ({ shipment: { tracking, service } }: SterOrder) => {
  const courier_id = service?.courier?.courier_id;
  const link = LINK_TRACKING_BY_COURIER[courier_id];
  return link ? `${link}/${tracking}` : '';
};

export const getDevileryState = (item: PurchaseItem): ShipmentResult => {
  let result: ShipmentResult = {} as ShipmentResult;
  const { street, number, state, city, zip } = item?.shipment_address;

  const isWithDelivery = zip !== '0000';

  const orderState = item?.sterOrder?.state?.order_state_id || '1';

  switch (orderState) {
    case ORDER_STATE.CREATED:
    case ORDER_STATE.SELLED:
    case ORDER_STATE.WAITING_FOR_EXTERNAL_DELIVERY:
    case ORDER_STATE.READY_FOR_PREPARATION:
    case ORDER_STATE.WAITING_FOR_PREPARATION:
    case ORDER_STATE.WAITNG_FOR_APPROVAL:
    case ORDER_STATE.ONQUEQUE:
    case ORDER_STATE.PRODUCTION:
    case ORDER_STATE.READY_FOR_DELIVERY:
      result = {
        color: 'text.primary',
        state: isWithDelivery ? 'Envío pendiente' : 'Su pedido ya se encuentra listo para ser retirado',
        title: isWithDelivery ? `Tu producto está siendo procesado` : 'Ya podés pasar a retirar tu pedido',
        subtitle: isWithDelivery
          ? `Una vez despachado, tu compra estará llegando a la dirección ${street} ${number}, ${city.name}, ${state.name}`
          : '',
      };
      break;
    case ORDER_STATE.OUT_FOR_DELIVERY:
    case ORDER_STATE.EXTERNAL_DELIVERY_READY:
      result = {
        color: 'info.main',
        state: isWithDelivery ? 'Enviado' : 'Su pedido ya se encuentra listo para ser retirado',
        title: isWithDelivery
          ? `Tu producto llegará aproximadamente el ${
              item?.sterOrder?.shipment?.deliver_estimate || state.id === PROVINCES.CABA.toString()
                ? moment(item.payment.created_at).add(24, 'hours')
                : moment(item.payment.created_at).add(72, 'hours')
            }`
          : 'Ya podés pasar a retirar tu pedido',
        subtitle: isWithDelivery
          ? `Tu compra ya fue despachada y estará llegando a la dirección ${street} ${number}, ${city.name}, ${state.name}`
          : '',
        caption: isWithDelivery ? (
          <>
            {`Podés ver donde se encuentra tu compra ingresando tu numero de seguimiento en `}
            <Link to={getTrackingLink(item.sterOrder)}>este link.</Link>
          </>
        ) : (
          ''
        ),
      };
      break;
    case ORDER_STATE.RETURNED:
    case ORDER_STATE.WAITING_FOR_RETURN:
    case ORDER_STATE.READY_TO_RESEND:
      result = {
        color: 'text.primary',
        state: 'No entregado',
        title: 'Tu compra no pudo ser entregada',
        subtitle: isWithDelivery ? 'Estaremos reenviandola al sigueinte dia hábil' : '',
      };
      break;
    case ORDER_STATE.CANCELLED:
    case ORDER_STATE.CANCELLATION_FOR_FRAUD:
    case ORDER_STATE.WAITING_TO_CANCEL:
    case ORDER_STATE.RETURNING_TO_CANCEL:
      result = { color: 'error.main', state: 'Cancelada', title: 'Tu orden fue cancelada', subtitle: '' };
      break;
    case ORDER_STATE.DELIVERED:
      result = {
        color: 'success.main',
        state: isWithDelivery ? 'Entregado' : 'Su pedido ya fue retirado. ¡Gracias por elegirnos!',
        title: isWithDelivery
          ? `Tu producto llegó el ${item?.sterOrder?.shipment?.updated_at}`
          : `Tu producto fue retirado el ${item?.sterOrder?.shipment?.updated_at}`,
        subtitle: isWithDelivery
          ? `Tu compra fue entregada por ${item?.sterOrder?.shipment.service.name} en a la dirección ${street} ${number}, ${city.name}, ${state.name}`
          : '',
        caption: (
          <>
            {`Tenés hasta el ${moment(item.sterOrder?.updated_at).add(30, 'days').format('DD/MM/YYYY')} para devolverlo. Ver `}
            <Link to="/preguntas-frecuentes" style={{ textDecoration: 'none', color: ecommerceConfig.ecommercePalette.materialUi.primary.main }}>
              preguntas frecuentes
            </Link>
            {` para más información`}
          </>
        ),
      };
      break;
    default:
      result = {
        color: 'text.primary',
        state: 'Envío pendiente',
        title: `Tu producto está siendo procesado`,
        subtitle: `Una vez despachado, tu compra estará llegando a la dirección ${street} ${number}, ${city.name}, ${state.name}`,
      };
      break;
  }
  return result;
};
