import { TemplateProperty } from '../../../../lib/templates';
import { addressTemplate, personalTemplate, cbuTemplate, cuilTemplate } from './common';

export const guarantorInfoTemplate: TemplateProperty = {
  name: 'guarantorInfo',
  type: {
    name: 'object',
    kind: [
      ...addressTemplate.map((temp) => {
        if (temp.name === 'province') {
          return { ...temp, validators: [{ name: 'province', signature: [3] }] };
        }
        return temp;
      }),
      ...personalTemplate,
      cuilTemplate,
      cbuTemplate,
      {
        name: 'img_dni_front',
        type: { name: 'any' },
        label: 'DNI frente',
        validators: [
          { name: 'fileSize', signature: [40000] },
          { name: 'fileType', signature: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword'] },
        ],
      },
      {
        name: 'img_dni_back',
        type: { name: 'any' },
        label: 'DNI dorso',
        validators: [
          { name: 'fileSize', signature: [40000] },
          { name: 'fileType', signature: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword'] },
        ],
      },
      {
        name: 'img_paycheck',
        type: { name: 'any' },
        label: 'Recibo de sueldo',
        validators: [
          { name: 'fileSize', signature: [40000] },
          { name: 'fileType', signature: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword'] },
        ],
      },
      {
        name: 'img_service_certificate',
        type: { name: 'any' },
        label: 'Certificación de servicios',
        validators: [
          { name: 'fileSize', signature: [40000] },
          { name: 'fileType', signature: ['image/png', 'image/jpeg', 'application/pdf', 'application/msword'] },
        ],
      },
    ],
  },
};
