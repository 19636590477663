import React, { useEffect, useState, useMemo } from 'react';
import palette from 'src/theme/palette';
import { ecommerceConfig } from 'src/config/ecommerce';
import { Theme, useMediaQuery } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { Link, useParams, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { PaymentMsg } from '../Payment/Payment';
import { approvedPayment as useStyles } from '../../styles';
import { useCartContext } from '../../../../context';
import { persistentCart } from '../../../../context/CartContext/utils';

const persistCart = persistentCart();
export const ApprovedPaymentView = () => {
  const { cartState, dispatch } = useCartContext();
  const params = useParams<{ payment_id: string; type: string }>();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const color = palette.success.dark;
  const classes = useStyles({ color });
  const [isTracked, setIsTracked] = useState(false);
  const location = useLocation();
  const parsed = useMemo(() => parse(location.search), [location.search]);
  const { approvedPayment: ApprovedPayment } = ecommerceConfig.ecommerceLogo;

  useEffect(() => {
    if (!isTracked && cartState.owner) {
      setIsTracked(true);
      persistCart.remove();
      dispatch.updateState({ owner: '', items: [], state_id: 0, email: '', send: false });
    }
  }, [cartState, dispatch, isTracked, params.payment_id]);
  return (
    <PaymentMsg
      title={parsed.type ? '¡Su solicitud fue enviada con éxito!' : 'Compra realizada con éxito'}
      color={palette.success.dark}
      description={
        <>
          {parsed.type
            ? `Nos pondremos en contacto a la brevedad para informar el estado de la misma. `
            : `Hemos recibido correctamente el pago y tus datos sobre la compra realizada. ¡Muchas gracias por confiar en nosotros! `}
          <Link to="/search" className={classes.lastLink}>
            Seguir comprando.
          </Link>
        </>
      }
      img={<ApprovedPayment width={isDownSm ? 303 : 506} height={isDownSm ? 213 : 326} />}
      icon={<CheckCircleOutline fontSize="large" className={classes.icon} />}
    />
  );
};
