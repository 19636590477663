import { useEffect } from 'react';
import { useField } from 'react-final-form-hooks';
import { useCheckoutFormContext } from '../hooks/CheckoutFormContext'; 
import { useMultiStepFormContext } from '../../../../lib/MultiStepForm'; 

export const useHandleCheckoutErrors = () => {
  const { form } = useCheckoutFormContext();
  const emitterField = useField('paymentMethod.emitter', form);
  const cardField = useField('paymentMethod.card', form);
  const { error, setError } = useMultiStepFormContext();


  useEffect(() =>{
    const onChangeCardToMercadoPago = () => {
      cardField.input.onChange({
        "card_id": 6,
        "name": "MERCADO PAGO",
        "emiter": 8,
        "type": "Pasarela de pago",
        "card_image_url": "https://res.cloudinary.com/phinx-lab/image/upload/w_auto,f_auto,q_auto/v1619807009/Assets/logos_mercadoPago.png",
        "emiter_image_url": "https://res.cloudinary.com/phinx-lab/image/upload/w_auto,f_auto,q_auto/v1619807009/Assets/logos_mercadoPago.png",
        "match": [
            "*"
        ]
    });
      emitterField.input.onChange({
        "card_emiter_id": 8,
        "name": "MERCADO PAGO",
        "image_url": "https://res.cloudinary.com/phinx-lab/image/upload/w_auto,f_auto,q_auto/v1619807009/Assets/logos_mercadoPago.png"
    });
    }
    if (error?.response === 'Mercado pago'){
      onChangeCardToMercadoPago()
      setError({
        error: false,
        message: {
          title: '',
          description: '',
          details: '',
        },
      })
    }
  }, [error, setError, cardField.input, emitterField.input])
}
