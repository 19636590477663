import { useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from 'src/config';
import { useShipmentDAO } from '../../../../../../app/business/Shipment/ShipmentBusinessGenerated';
import { HOP, HOPPoints, inverseGeocodification } from '../../../../../../app/models';

export const useHOP = () => {
  const [points, setPoints] = useState<HOP[]>([]);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<inverseGeocodification>();
  const [location, setLocation] = useState<HOPPoints>();
  const shipmentDao = useShipmentDAO();

  const handlePoints = async (coordinates?: HOPPoints, full: boolean = false) => {
    setLoading(true);
    try {
      if (coordinates) {
        shipmentDao.getPoints(coordinates).then((response: any) => {
          if (full) {
            setPoints(response);
          } else {
            setPoints(response.slice(0, 4));
          }
          setLoading(false);
        });
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const gettingAddress = async (coordinates: HOPPoints) => {
    try {
      const addressFetch = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${GOOGLE_MAPS_API_KEY}`,
      ).then((response) => response.json());
      setAddress(addressFetch);
    } catch (e) {
      console.log(e);
    }
  };

  const getLocation = () => {
    setLoading(true);
    const geoApi = navigator.geolocation;
    geoApi.getCurrentPosition((geolocation) => {
      gettingAddress({ lat: geolocation.coords.latitude, lng: geolocation.coords.longitude });
      handlePoints({ lat: geolocation.coords.latitude, lng: geolocation.coords.longitude });
      if (geolocation) {
        setLocation({ lat: geolocation.coords.latitude, lng: geolocation.coords.longitude });
      }
    });
    setLoading(false);
  };

  return { points, handlePoints, loading, gettingAddress, address, getLocation, location };
};
