import React, { useState, MouseEvent } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import typography from 'src/theme/typography';
import { MenuList } from '../MenuList';
import { grey } from '../../../theme/colors';

interface MenuListTypesProps {
  title: string;
  handleOnClick: (event: MouseEvent) => void;
}

export interface MainButtonTypesProps {
  title: React.ReactNode;
  disabled?: boolean;
  size?: string;
  handleOnClick?: (event: MouseEvent) => void;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  type?: string;
  typeButton?: any;
  customStyle?: any;
  customStyleIcon?: any;
  ListMenu?: MenuListTypesProps[];
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  variant?: 'text' | 'outlined' | 'contained';
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  fontWeight?: 'bold' | 'normal' | 'bolder' | 'lighter' | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  widthComplete?: boolean;
}

const sizeText: Record<string, any> = {
  small: 11,
  medium: 14,
  large: 15,
};

const paddingButton: Record<string, any> = {
  small: '3px 16px',
  medium: '7px 16px',
  large: '8px 16px',
};

const borderButton: Record<string, string> = {
  info: `1px solid ${grey.normal}`,
};

export const MainButton = ({
  title,
  size = 'large',
  iconLeft,
  iconRight,
  disabled = false,
  type = 'primary',
  handleOnClick,
  customStyle,
  customStyleIcon,
  ListMenu,
  typeButton = 'button',
  color = 'primary',
  variant = 'contained',
  textTransform = 'none',
  fontWeight = 'normal',
  widthComplete = false,
}: MainButtonTypesProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const buttonStyles = makeStyles(() => ({
    button: {
      display: 'flex',
      fontWeight,
      fontFamily: typography.fontFamily,
      fontSize: sizeText[size],
      textTransform,
      padding: paddingButton[size],
      borderRadius: 4,
      border: borderButton[type],
      width: widthComplete ? '100%' : 'auto',
      ...customStyle,
    },
    icon: {
      marginTop: 8,
      ...customStyleIcon,
    },
  }));

  const classes = buttonStyles();
  return (
    <>
      <Button
        type={typeButton}
        className={classes.button}
        startIcon={iconLeft && <div className={classes.icon}>{iconLeft}</div>}
        endIcon={iconRight && <div className={classes.icon}>{iconRight}</div>}
        disabled={disabled}
        onClick={ListMenu ? handleOpenMenu : handleOnClick}
        color={color}
        variant={variant}
      >
        <Typography>
          {title}
        </Typography>
      </Button>
      {ListMenu && <MenuList anchorEl={anchorEl} setAnchorEl={setAnchorEl} ListMenu={ListMenu} />}
    </>
  );
};
