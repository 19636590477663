import { Button, FormControl, FormHelperText, Grid, Input, InputLabel, Theme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { couponFormStyles as useStyles } from 'src/app/views/styles/couponForm';
import { useCouponSubmit } from 'src/commons/hooks/useValidatorSubmit';

export const CouponInput = () => {
  const { coupon, error, handleInputChange, handleSubmit } = useCouponSubmit();
  const classes = useStyles();
  const isMdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <Grid item justify="center" xs={12} className={classes.grid}>
      <form className={classes.containerForm} onSubmit={(e: any) => handleSubmit(e)}>
        <FormControl required>
          <InputLabel className={classes.inputLabel} shrink htmlFor="coupon">
            Ingresá tu código
          </InputLabel>
          <Input className={classes.input} id="coupon" value={coupon} onChange={(e: any) => handleInputChange(e)} />
          <FormHelperText className={error.err === false ? classes.errorMsg : ''}>
            {error.err ? error.msg : error.msg.length ? error.msg : null}
          </FormHelperText>
        </FormControl>
        <Button className={classes.buttonStyles} type="submit" variant="contained" color="primary">
          {isMdDown ? 'Validar' : 'Validar cupón'}
        </Button>
      </form>
    </Grid>
  );
};
