import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Menu, MenuItem, Divider, Box } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AnyObject } from 'src/commons/types';
import { makeStyles } from '@material-ui/styles';
import { primary } from 'src/theme/colors';

const useStyles = makeStyles({
  menuText: {
    '&:hover': {
      color: primary.lightBlue,
    },
  },
});

export type HideOptionFunction<T> = (data: T) => boolean;

export interface OptionsModalOption<T = any> {
  label: React.ReactNode;
  onClick: (data: T) => void;
  hide?: boolean | HideOptionFunction<T>;
  disable?: boolean;
  id?: number;
}

export type OptionsModalProps<T = any> = {
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  options: Array<OptionsModalOption<T>>;
  data?: any;
};

export const OptionsModal = ({ color = 'primary', options, data }: OptionsModalProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleMenu = (event: AnyObject) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const items = options.filter((item) => {
    if (item.hide === undefined) {
      return true;
    }
    return typeof item.hide === 'boolean' ? !item.hide : !item.hide(data);
  });

  return (
    <>
      <IconButton size="small" aria-label="options menu" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color={color}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="menu-appbar-option-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{ horizontal: -40, vertical: 10 }}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {items.map((item: OptionsModalOption, index) => (
          <Box  key={item.id}>
            <MenuItem
              key={item.id}
              onClick={() => {
                item.onClick(data);
                handleClose();
              }}
              className={classes.menuText}
            >
              {item.label}
            </MenuItem>
            {index + 1 !== items.length && <Divider variant="middle" />}
          </Box>
        ))}
      </Menu>
    </>
  );
};
