import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const getRemovalTitle = (title: string) => (
  <Grid container justify="center" direction="column">
    <Grid item container justify="center">
      <Box component={ErrorOutlineIcon} width={42} height={42} color="error.main" />
    </Grid>
    <Grid item container justify="center">
      <Box color="black" justifyContent="center" justifyItems="center">
        <Typography align="center" variant="body1">
          {title}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);
