import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { Subscription } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'subscription';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSubscription',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSubscription',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Subscription
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Subscription,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Subscription.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Subscription>(DAO_NAME, optionsToUse);

// hooks
export const useSubscriptionFetchById = fetchById;
export const useSubscriptionLibbyCall = libbyCall;
export const useSubscriptionDAO = libbyDAO;
export const useSubscriptionLibbyFetch = libbyFetch;

// components/hoc
export const SubscriptionAutocomplete = entityAutocomplete;
export const SubscriptionCall = renderPropCall;
export const SubscriptionById = renderPropFetchById;
export const SubscriptionSelect = simpleSelect;
export const SubscriptionAccordionSelector = accordionSelector;
export const SubscriptionSelectedChips = selectedChips;

// context
// ByIdContext
export const useSubscriptionByIdContext = useByIdContext;
export const SubscriptionByIdProvider = ByIdProvider;
export const SubscriptionByIdContext = ByIdContext;
