import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { primary } from 'src/theme/colors';

const colorBar = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: `linear-gradient(90deg, ${primary.yellow} 0%, rgba(253, 211, 6, 0.3) 69.27%, rgba(0, 179, 227, 0.2) 76.04%, ${primary.paleBlueStrong} 100%)`,
    height: 10,
  },
}));

export const ColorBar = () => {
  const classes = colorBar();
  return <div className={classes.container} />;
};
