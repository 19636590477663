import React, { useEffect, useMemo } from 'react';
import { useMediaQuery, Theme, Box, Grid, Typography, Button } from '@material-ui/core';
import { useCheckoutFormContext } from '../../hooks/CheckoutFormContext';
import { useScrollToTopOnMount } from '../../../../../commons/hooks/useScrollToTopUnMount';
import { useCartContext } from '../../../../../context';
import { useCartDAO } from '../../../../../app/business';
import { useFields } from './hooks/useFields';
import { FieldComponent } from '../FieldComponent';
import { isEmail } from '../../../../../utils';
import { checkoutAllValues } from '../../initialValues';
import { useIsOtherPerson } from '../IdentityValidationForm/hooks/useIsOtherPerson';

export const PersonalInfoForm = () => {
  useScrollToTopOnMount();
  const cartDAO = useCartDAO();
  const { values, errors, handleSubmit } = useCheckoutFormContext();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const { cartState } = useCartContext();
  const { fields, modal: ModalPendingCredit } = useFields();
  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);
  useIsOtherPerson();

  useEffect(() => {
    if (valuesForm.personalInfo.termsAndConditions && isEmail(valuesForm.personalInfo.email)) {
      cartDAO.updateCart({ owner: cartState.owner, items: cartState.items, state_id: cartState.state_id, email: valuesForm.personalInfo.email });
    }
  }, [cartDAO, cartState.items, cartState.owner, cartState.state_id, valuesForm.personalInfo.email, valuesForm.personalInfo.termsAndConditions]);

  return (
    <Box paddingBottom={3} bgcolor="white" borderRadius={8} boxShadow={3} display="content">
      <Box padding={isDownSm ? 2 : 3}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={isDownSm && '1px solid'}>
              <Typography variant="h5" color="textPrimary">
                Datos del comprador
              </Typography>
            </Box>
          </Grid>

          {fields.map((field) => (
            <FieldComponent {...field} />
          ))}

          {isDownSm && (
            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={Object.keys(errors as any).length !== 0}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Continuar
              </Button>
            </Grid>
          )}

          {ModalPendingCredit}
        </Grid>
      </Box>
    </Box>
  );
};
