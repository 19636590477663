// Palette
import palette from '../palette';

type MuiTableCellType = { [k: string]: any };

const MuiTableCell: MuiTableCellType = {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
  },
  head: {
    fontSize: '14px',
    color: palette.text.primary,
  },
};

export default MuiTableCell;
