import { Purchases } from 'src/screens';
import { MenuItem } from 'src/commons';
import { PurchaseItemDetails } from 'src/screens/Private/Purchases/components/PurchaseItemDetails';

export const routes: MenuItem[] = [
  {
    key: 'privatePurchaseByIdMain',
    basePath: '/details/:id?',
    target: {
      component: PurchaseItemDetails, // PURCHASES
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Detalle de la compras',
    children: [],
    default: true,
  },
  {
    key: 'privatePurchasesMain',
    basePath: '/',
    target: {
      component: Purchases, // PURCHASES
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Compras',
    children: [],
    default: true,
  },
];
