import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FormContextProvider } from 'src/lib/templates';
import { getErrorMsgDecidirCS } from 'src/screens/Common/Checkout/utils';
import { useIdentityValidation } from 'src/commons';
import { MultiStepFormContext } from './MultiStepFormContext';
import { MultiStepForm, MultiStepFormContextValue, MultiStepFormSteps, CheckoutError } from '../types';
import { ICheckoutAll } from '../../../screens/Common/Checkout/initialValues';
import useAnalytics from '../hooks/useAnalytics';
import { useCartContext, useGeneralContext } from '../../../context';
import { usePaymentProcessing } from '../../../screens/Common/Checkout/hooks/usePaymentProcessing';
import { Decidir } from '../../../app/services';
import { PAYMENT_STATUS } from '../../../app/models';
import { Analytics } from '../../../platform/analytics';
import { useCartDAO } from '../../../app/business';
import { useMountFingerPrintScriptCallback } from '../../../screens/Common/Checkout/components/CardInfoForm/hooks';

const initialError = { error: false, message: { title: '', description: '', details: '' } };

export const MultiStepFormContextProvider = ({ children, steps: _steps, initialValues, template }: MultiStepForm) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set<number>());
  const [steps, setSteps] = useState<MultiStepFormSteps[]>(_steps);
  const filterSteps = steps.filter((step) => !step.hidden);
  const maxStep = useMemo(() => filterSteps.length, [filterSteps]);
  const preSteps = useMemo(() => steps.filter((step) => step.preStep && !step.hidden), [steps]);
  const _template = useRef(template);
  const _initialValue = useRef(initialValues);
  const analyticsCheckout = useAnalytics();
  const [error, setError] = useState<CheckoutError>(initialError);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const { dispatch } = useGeneralContext();
  const [deviceId, setDeviceId] = useState('');
  const cartDAO = useCartDAO();
  const { cartState } = useCartContext();
  const history = useHistory();
  const generateFingerprint = useMountFingerPrintScriptCallback();
  const [tagsMounted, setTagsMounted] = useState<boolean>(false);
  const { getPayment, checkStatusPayment } = usePaymentProcessing();
  const {
    error: errorEquifax,
    questionary,
    handleSubmitEquifax,
    isSend,
    bloqued,
    isWhiteList,
  } = useIdentityValidation({ allSteps: steps, setSteps });
  const [equifaxRequestLoading, setEquifaxRequestLoading] = useState(false);

  useEffect(() => {
    if (!steps.length) {
      setSteps(_steps);
    }
  }, [_steps, steps]);

  useEffect(() => {
    if (!deviceId) {
      const id = Decidir.getDeviceFingerPrintId().toString();
      setDeviceId(id);
    }
  }, [setDeviceId, deviceId]);

  useEffect(() => {
    if (!tagsMounted && deviceId) {
      setTagsMounted(true);
      generateFingerprint(deviceId);
    }
  }, [deviceId, tagsMounted, generateFingerprint]);

  const onHandleSubmit = useCallback(
    async (values: ICheckoutAll) => {
      if (activeStep === maxStep - 1) {
        setOpenBackdrop(true);
        try {
          const payment = await getPayment(values, deviceId);
          if (payment.id) {
            checkStatusPayment(payment.id, (result) => {
              dispatch.setIsBlocking(false);
              if (result.payment_state_id === PAYMENT_STATUS.APPROVED && result.metadata.fraud_detection?.status.decision === 'green') {
                Analytics.trackPurchaseApproved({ cart: cartState, transactionId: payment.id, userData: values.personalInfo });
                cartDAO.updateCart({ owner: cartState.owner, items: [], email: '', send: false });
                setTimeout(() => window.location.replace(`/cart/checkout/confirm/${payment.id}`), 0);
              } else {
                setError({
                  error: true,
                  message: getErrorMsgDecidirCS(result),
                });
              }
              setOpenBackdrop(false);
            });
          } else {
            dispatch.setIsBlocking(false);
            setError({
              error: true,
              message: getErrorMsgDecidirCS({
                payment_state_id: '',
                payment_state_name: 'Error',
                metadata: {
                  status: 500,
                  result: 'Error',
                  status_details: JSON.stringify(payment),
                },
              }),
            });
            setOpenBackdrop(false);
          }
        } catch (error) {
          console.log(error);
          dispatch.setIsBlocking(false);
          Analytics.trackPurchaseFailed({ cart: cartState, userData: values.personalInfo });
          history.push('/cart/checkout/failure');
        }
      } else {
        const { personalInfo: userData, delivered: deliveryType, paymentMethod } = values;
        analyticsCheckout({ activeStep, filterSteps, userData, paymentMethod, deliveryType });

        try {
          if (
            filterSteps[activeStep].title === 'Datos del comprador' &&
            steps.find((item) => item.title === 'Cuestionario de verificación' && !item.hidden)
          ) {
            setEquifaxRequestLoading(true);
            await handleSubmitEquifax(
              values.personalInfo.document,
              `${values.personalInfo.name} ${values.personalInfo.lastName}`,
              values.personalInfo.gender,
            );
            setEquifaxRequestLoading(false);
          }

          const newCompletedStep = new Set(completedSteps);
          newCompletedStep.add(activeStep);
          setCompletedSteps(newCompletedStep);
          setActiveStep((oldValue) => oldValue + 1);
        } catch (error) {
          console.log(error);
          setEquifaxRequestLoading(false);
          dispatch.setIsBlocking(false);
          Analytics.trackPurchaseFailed({ cart: cartState, userData: values.personalInfo });
          history.push('/cart/checkout/failure');
        }
      }
    },
    [
      activeStep,
      maxStep,
      getPayment,
      deviceId,
      checkStatusPayment,
      dispatch,
      cartState,
      cartDAO,
      history,
      steps,
      analyticsCheckout,
      filterSteps,
      completedSteps,
      handleSubmitEquifax,
    ],
  );

  const value = useMemo<MultiStepFormContextValue>(
    () => ({
      steps: filterSteps,
      preSteps,
      activeStep,
      completedSteps,
      allSteps: steps,
      setSteps,
      setActiveStep,
      setCompletedSteps,
      error,
      setError,
      openBackdrop,
      setOpenBackdrop,
      equifax: {
        error: errorEquifax,
        questionary,
        isSend,
        bloqued,
        isWhiteList,
      },
      equifaxRequestLoading,
    }),
    [
      filterSteps,
      preSteps,
      activeStep,
      completedSteps,
      steps,
      error,
      openBackdrop,
      errorEquifax,
      questionary,
      isSend,
      bloqued,
      isWhiteList,
      equifaxRequestLoading,
    ],
  );

  return (
    <MultiStepFormContext.Provider value={value}>
      <FormContextProvider initialValues={_initialValue.current} template={_template.current} onSubmit={onHandleSubmit}>
        {children}
      </FormContextProvider>
    </MultiStepFormContext.Provider>
  );
};
