interface ConfigurationValue {
  enable: boolean;
  interval: number;
}
export interface Configuration {
  general_config_id: number;
  name: string;
  value: ConfigurationValue;
}

export enum CONFIGURATION_ID {
  RECAPTCHA = 1,
  FRAUD = 5,
  STORE_PICKUP = 6,
  MANTENANCE_SCREEN = 7,
  EQUIFAX = 8,
  PAYMENT_METHODS = 9,
  HOP = 10,
}
// TODO CHECK THIS , INTERVAL VALUE
export const defaultValue: Configuration = {
  general_config_id: -1,
  name: '',
  value: { enable: false, interval: 0 },
};
