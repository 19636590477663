import React, { useMemo } from 'react';
import { SkeletonComponentProps, SOURCE } from 'src/app/models/Site';
import { Legales } from 'src/app/views/components/PromotionsView/components';
import { Loading } from '../../../../commons';

export const SkeletonLegales = ({ data }: SkeletonComponentProps) => {
  const { data: legalInfo, working } = SOURCE.PUBLICATION(data.source_id);
  const text = useMemo(() => legalInfo?.[0].config_values.text, [legalInfo]);
  return working ? <Loading /> : <Legales title="Ver legales" info={text} />;
};
