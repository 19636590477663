import { Coupon } from 'src/app/models';
import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'coupon';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCoupon',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCoupon',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Coupon
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Coupon,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Coupon.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Coupon>(DAO_NAME, optionsToUse);

// hooks
export const useCouponFetchById = fetchById;
export const useCouponLibbyCall = libbyCall;
export const useCouponDAO = libbyDAO;
export const useCouponLibbyFetch = libbyFetch;

// components/hoc
export const CouponAutocomplete = entityAutocomplete;
export const CouponCall = renderPropCall;
export const CouponById = renderPropFetchById;
export const CouponSelect = simpleSelect;
export const CouponAccordionSelector = accordionSelector;
export const CouponSelectedChips = selectedChips;

// context
// ByIdContext
export const useCouponByIdContext = useByIdContext;
export const CouponByIdProvider = ByIdProvider;
export const CouponByIdContext = ByIdContext;
