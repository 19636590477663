import { MenuItem } from '../../../../commons/ui/menu';
import { Redirector } from './redirector';

export const routes: MenuItem[] = [
  {
    key: 'customredirects',
    basePath: '/',
    target: {
      component: Redirector,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Home',
    children: [],
    default: true,
  },
];
