import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import { ICheckoutAll } from '../initialValues';
import { DeliveryInfoView, PaymentMethodView, PersonalInfoView } from '../components/AccordionInfo';
import { useRolesContext } from '../../../../context';
import { deliveryType } from '../../../../app/models/DeliveryType';

interface IInfoCard {
  title: string;
  id: number;
  render: JSX.Element;
  complete: boolean;
}

export const useInfoCards = (data: ICheckoutAll, completedSteps: Set<number>): IInfoCard[] => {
  const { userInfo } = useRolesContext();
  return useMemo<any[]>(() => {
    const renders: IInfoCard[] = [
      {
        title: deliveryType[data.delivered],
        id: 2,
        render: <DeliveryInfoView data={data.deliveryInfo} isStorePickup={data.delivered === 'store_pickup'} />,
        complete: false,
      },
      {
        title: 'Medio de pago',
        id: 3,
        render: <PaymentMethodView paymentInfo={data.paymentMethod} cardInfo={data.cardInfo} couponData={data.coupon} />,
        complete: false,
      },
      {
        title: 'Datos del comprador',
        id: 1,
        render: <PersonalInfoView personalInfo={data.personalInfo} isResponsable={data.isResponsable} responsable={data.responsableInscripto} />,
        complete: false,
      },
    ];
    const isLogin = !!userInfo.id;

    return orderBy(renders, 'id', 'asc').map((render) => {
      const id = isLogin ? render.id - 1 : render.id;
      return { ...render, complete: completedSteps.has(id) };
    });
  }, [data, completedSteps, userInfo.id]);
};
