import React from 'react';
import { Grid, Typography, Box, Card } from '@material-ui/core';
import { TokenizedCard, CARD_EMITER } from 'src/app/models';
import moment from 'moment';

export const getRemovalTokenizedCardContent = (tokenCard: TokenizedCard) => (
  <Box width="100%" justifyItems="center" padding={2} component={Card} minHeight={124} borderRadius={8} boxShadow={3} display="content">
    <Box component="span" display="flex" flexDirection="row" width="100%">
      <Grid item xs={12} container direction="column" spacing={1}>
        <Grid item container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="body1" color="textPrimary">
              {`${CARD_EMITER[tokenCard.payment_method_id].type} - Terminada en ${tokenCard.last_four_digits}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {`Tarjeta ${CARD_EMITER[tokenCard.payment_method_id].emitter}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {`Vencimiento: ${moment(`01/${tokenCard.expiration_month}/${tokenCard.expiration_year}`, 'DD/MM/YY').format('MM/YYYY')}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Box>
);
