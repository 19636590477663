import React, { useState, useCallback } from 'react';
import { Menu, Typography, Grid, Divider, Avatar, Box, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { AnyObject } from 'src/commons/types';
import { useRolesContext } from 'src/context/RolesContext/RolesContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useHistory } from 'react-router';

type UserCardProps = {
  logout: () => void;
};

export const UserCard = ({ logout }: UserCardProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const { userInfo } = useRolesContext();
  const handleMenu = (event: AnyObject) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onRedirectTo = useCallback(
    (to: string) => {
      handleClose();
      history.push(`${to}`);
    },
    [history],
  );

  const nick = userInfo.name?.split(' ')[0].substring(0, 1).toUpperCase() + userInfo.lastname?.split(' ')[0].substring(0, 1).toUpperCase();

  return (
    <Grid container justify="center" alignContent="center">
      <Grid container justify="flex-end" alignItems="center">
        <Grid item>
          <Avatar aria-label="recipe">{nick}</Avatar>
        </Grid>
        <Grid item>
          <Box pl={1}>
            <Typography variant="body2" color="textPrimary">
              {userInfo.name.split(' ')[0]}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <IconButton aria-label="change language" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu}>
            {!anchorEl ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Grid>
      </Grid>

      <Menu
        id="menu-appbar-language"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: -50 }}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onRedirectTo('/dashboard/profile')}>Perfil</MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={() => onRedirectTo('/dashboard/purchases')}>Compras</MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={() => onRedirectTo('/dashboard/configuration/security')}>Configuración</MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
      </Menu>
    </Grid>
  );
};
