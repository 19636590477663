import React from 'react';
import { Grid, Typography, ListItemText, ListItem, TableBody, TableCell, Table, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Publication } from 'src/app/models';
import { StyledTableRowProductDescription } from 'src/app/views/styles';

interface ValueDetailsShowProps {
  bold?: boolean;
  value: string;
}

const ValueDetailsShow = ({ bold = false, value }: ValueDetailsShowProps) => (
  <TableCell component="td">
    <Typography variant={bold ? 'subtitle2' : 'body2'}>
      <Box component="text" color="textPrimary" fontWeight={bold ? 'bold' : 'normal'}>
        {value}
      </Box>
    </Typography>
  </TableCell>
);

interface ProductEspecificationProps {
  data: Publication;
}
export const CatalogEspecification = ({ data }: ProductEspecificationProps) => {
  const isSm = useMediaQuery<any>((theme) => theme.breakpoints.down('sm'));


  return (
    <Grid item xs={12} md={10}>
      <Box pt={4}>
        <ListItem disableGutters dense divider={!isSm}>
          <Typography variant="h5" style={{ marginBottom: 16 }}>
            <Box component="text" color="primary.dark" fontWeight="bold">
              Especificaciones técnicas
            </Box>
          </Typography>
        </ListItem>
        {data.attributes?.features ? (
          data.attributes.features
            .filter((feature) => feature.name !== 'Landing')
            .map((feature, index) => (
              <ListItem disableGutters dense divider={!isSm} key={feature.name}>
                <Grid container justify="space-between">
                  <Grid item xs={!isSm ? 4 : 12}>
                    <Box pb={!isSm ? 0 : 2} pt={!isSm || index === 0 ? 0 : 1}>
                      <ListItemText
                        primary={
                          <Typography variant={!isSm ? 'h6' : 'body1'}>
                            <Box component="text" color="textPrimary" fontWeight="bold" py={index === 0 ? 0 : 1}>
                              {feature.name}
                            </Box>
                          </Typography>
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={!isSm ? 8 : 12}>
                    <Table aria-label="customized table">
                      <TableBody>
                        {feature.features?.map(({ name, value }) => (
                          <StyledTableRowProductDescription key={`${name}-${value}`}>
                            <ValueDetailsShow value={name} />
                            <ValueDetailsShow value={value} bold />
                          </StyledTableRowProductDescription>
                        )) || (
                          <StyledTableRowProductDescription key="N/A">
                            <ValueDetailsShow value="N/A" />
                          </StyledTableRowProductDescription>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </ListItem>
            ))
        ) : (
          <Box p={2}>
            <Typography variant="subtitle1" align="center">
              No hay especificaciones técnica para este producto.
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
};
