import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const faqsMenu: MenuItem = {
  key: 'publicFaqs',
  basePath: '/preguntas-frecuentes',
  enabled: true,
  icon: 'List',
  text: 'Preguntas frecuentes',
  children: routes,
  default: true,
};
