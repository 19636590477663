import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { AnyObject } from 'src/commons';

interface ProductVariantIconColorPickerProps {
  size?: number;
  mobile?: boolean;
  onClick: ()=> void;
  isSelected: boolean;
  colorName: string
}

const availableColors: AnyObject = {
    'Gris': '#898d85',
    'Negro': '#000000',
    'Rojo': '#EC0000',
    'Verde': '#00BF15',
    'Azul': '#0324C6',
    'Amarillo': '#E4E700',
    'Dorado': '#E7C800',
    'Oro': '#E7C800',
    'Plateado': '#D8D8D3',
    'Plata': '#D8D8D3',
}


export const ProductVariantIconColorPicker = ({ size = 30, mobile = true, onClick, isSelected, colorName }: ProductVariantIconColorPickerProps) => {
    const colorSelected = React.useMemo(() =>{
        if (!colorName.includes(' y ')) return [availableColors[colorName]]
            const splittedColors = colorName.split(' y ')
            return splittedColors
    },[colorName])
    
   return <Tooltip title={colorName}>     
    <Box onClick={onClick} position="relative" bgcolor='#ffffff' padding='1px' borderRadius="50%" borderColor={isSelected ? '#000000' : 'other.stroke'} border='1px solid' display='flex' justifyContent='center' alignItems='center' style={{cursor: isSelected ? 'default' : 'pointer'}}>
    <Box
      style={{
        width: `${size}px`,
        height: `${size}px`,
        background: colorSelected.length === 1 ? colorSelected[0] : `conic-gradient(${availableColors[colorSelected[0]]} 180deg, ${availableColors[colorSelected[1]]} 90deg 10deg)`,
        pointerEvents: 'none', // Ensure pseudo-element doesn't intercept mouse events
        opacity: '1', // Adjust opacity as needed
        borderRadius:"50%",
        margin:mobile ? '3px' : '0px'
      }}
    />
  </Box>
    </Tooltip>
};