import { City, State } from 'src/app/models';

export interface ShipmentAddressInitialValue {
  province: State;
  city: City;
  street: string;
  streetNumber: string;
  zipCode: string;
  deliveryCost: number;
}

export const shipmentAddressinitialValue: ShipmentAddressInitialValue = {
  province: { id: '', name: '', matches: [] },
  city: { id: '', zip: [0], name: '', matches: [] },
  street: '',
  streetNumber: '',
  zipCode: '',
  deliveryCost: 0,
};

export interface InitialValue {
  shipmentAddress: ShipmentAddressInitialValue;
}

export const initialValue: InitialValue = {
  shipmentAddress: shipmentAddressinitialValue,
};
