export enum FacebookTrackEvent {
  ADD_TO_CART = 'AddToCart',
  REMOVE_FROM_CART = 'Remover del Carrito',
  PURCHASE_APPROVED = 'Purchase',
  PURCHASE_PENDING = 'Compra Pendiente',
  PURCHASE_FAILED = 'La Compra Falló',
  START_CHECKOUT = 'InitiateCheckout',
  PERSONAL_INFO = 'Datos Comprador Completado',
  EQUIFAX_VERIFY = 'Datos de Equifax Verificados',
  DELIVERY_INFO = 'Información de Envio Completada',
  PAYMENT_METHOD = 'Medios de Pago Completado',
  APPLICANT_INFO = 'Información Aplicante Completada',
  GAURANTOR_INFO = 'Información de Garante Completada',
  VIEW_CONTENT = 'ViewContent',
}

export interface EventParams {
  content_ids?: object[] | object;
  content_category?: string;
  content_name?: string;
  content_type?: string;
  contents?: object[] | Contents[];
  currency?: string;
  num_items?: number;
  value?: number;
}

export interface Contents {
  quantity: number;
  id: string;
  item_price: number;
}

export interface EventObject {
  event: string;
  user_data: UserData;
  data: object;
  test_event_code?: string;
  hash_user_data?: HashUserData;
}

export interface HashUserData {
  fn: string;
  ln: string;
  em: string;
  ge: string;
  ph: string;
  db: string;
}

export interface UserData {
  client_user_agent: string;
  external_id: Array<string>;
}