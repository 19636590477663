import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { BuyerAddress } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'buyer_address';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'buyer_address_id',
      labelKey: 'name',
    },
    withSelectedChips: {
      idKey: 'buyer_address_id',
      labelKey: 'name',
    },
  },
};

const optionsToUse = !businessOptions.BuyerAddress
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.BuyerAddress,
      hoc: { ...defaultOptions.hoc, ...businessOptions.BuyerAddress.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<BuyerAddress>(DAO_NAME, optionsToUse);

// hooks
export const useBuyerAddressFetchById = fetchById;
export const useBuyerAddressLibbyCall = libbyCall;
export const useBuyerAddressDAO = libbyDAO;
export const useBuyerAddressLibbyFetch = libbyFetch;

// components/hoc
export const BuyerAddressAutocomplete = entityAutocomplete;
export const BuyerAddressCall = renderPropCall;
export const BuyerAddressById = renderPropFetchById;
export const BuyerAddressSelect = simpleSelect;
export const BuyerAddressAccordionSelector = accordionSelector;
export const BuyerAddressSelectedChips = selectedChips;

// context
// ByIdContext
export const useBuyerAddressByIdContext = useByIdContext;
export const BuyerAddressByIdProvider = ByIdProvider;
export const BuyerAddressByIdContext = ByIdContext;
// GeneralContext
// export const useBuyerAddressContext = useGeneralContext;
// export const BuyerAddressProvider = GeneralProvider;
// export const BuyerAddressContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
