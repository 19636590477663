import React, { ReactNode } from 'react';
import { Grid, GridSize, Typography, Box, BoxProps } from '@material-ui/core';
import { FORM_FIELD_COMPONENTS, FORM_FIELD_TYPE } from '../../hooks/useFieldForm';

export interface IFieldComponent {
  hidden?: boolean;
  key?: string | number;
  lg?: GridSize;
  md?: GridSize;
  sm?: GridSize;
  xs?: GridSize;
  title?: ReactNode;
  titleProps?: BoxProps;
  gridClassName?: string;
  component: FORM_FIELD_TYPE;
  componentProps?: any;
}

export const FieldComponent = ({ key, lg, md, sm, xs, title, titleProps = {}, gridClassName, component, componentProps = {} }: IFieldComponent) => {
  const Comp = FORM_FIELD_COMPONENTS[component];
  return (
    <Grid item key={key} lg={lg} md={md} sm={sm} xs={xs} className={gridClassName}>
      {title ? (
        <Box {...titleProps}>
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
      <Comp {...componentProps} />
    </Grid>
  );
};
