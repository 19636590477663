import React, { useMemo } from 'react';
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import { IMAGE_BASE_URL } from 'src/config';
import { makeStyles } from '@material-ui/styles';
import { SkeletonComponentProps, SOURCE } from 'src/app/models/Site';
import { Loading } from 'src/commons';
import { useHandleRedirect } from 'src/app/hooks/useRedirection';
import { ConfigValues } from 'src/app/models';

const styles = makeStyles(() => ({
  banner: {
    borderRadius: '8px',
    marginBottom: '24px',
    cursor: 'pointer',
  },
}));

export const SkeletonBanner = ({ data }: SkeletonComponentProps) => {
  const classes = styles();
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down(500));
  const { data: publication, working } = SOURCE.PUBLICATION(data.source_id);

  const imageConfig = useMemo<ConfigValues>(() => publication?.[0].config_values as ConfigValues, [publication]);
  const redirection = useHandleRedirect();
  return working ? (
    <Loading />
  ) : (
    <Grid item container>
      {imageConfig && (
        <Grid
          onClick={() => redirection(imageConfig.redirectTo, imageConfig.where)}
          className={classes.banner}
          component="img"
          src={`${IMAGE_BASE_URL}${imageConfig?.imagen[isDownXs ? 'small' : 'normal']}`}
          alt="Banner promos"
        />
      )}
    </Grid>
  );
};
