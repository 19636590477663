import React from 'react';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import CodeOutlined from '@material-ui/icons/CodeOutlined';
import { ecommerceConfig } from '../../../../config/ecommerce';
import { NotFoundPageStyles as useStyles } from '../../styles';

export const MaintenancePageView = () => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const { maintenancePage: MaintenancePage } = ecommerceConfig.ecommerceLogo

  return (
    <Grid container justify="center" className={classes.gridContainer}>
      <Box width={isDownSm ? '95%' : '80%'}>
        <Box textAlign="center">
          <CodeOutlined className={classes.exclamationIcon} />
        </Box>
        <Typography className={classes.titleText} variant="h3" component="h3" align="center">
          Sitio en Mantenimiento!
        </Typography>
        <Box width="65%" margin="0 auto 60px auto">
          <Typography variant="body1" color="textSecondary" align="center">
          ¡El sitio web se encuentra en mantenimiento, regresaremos pronto!
          <br />
            Si tiene algún problema, puede comunicarse con nuestro{' '}
            <a className={classes.linkStyle} href="mailto:contacto@novatechstore.com.ar">
              servicio de soporte
            </a>{' '}
            para poder brindarle ayuda.
          <br />
            ¡Disculpe las molestias!
          </Typography>
        </Box>
        <Grid container justify="center">
          <MaintenancePage height={isDownSm ? '260' : '347'} width={isDownSm ? '367' : '490'} />
        </Grid>
      </Box>
    </Grid>
  );
};
