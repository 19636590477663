import React, { useCallback } from 'react';
import { useField } from 'react-final-form-hooks';
import { Button, FormControl, FormHelperText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';
import pallet from '../../../theme/palette';

const useStyles = makeStyles({
  label: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: '17px',
    fontWeight: 400,
    color: pallet.text.primaryAlternative,
  },
  button: {
    minWidth: 160,
  },
});

// FIXME: type form props
export interface FormFileInputButtonProps {
  name: string;
  label: string;
  form: any;
  placeholder?: string;
  errorMessage?: string;
}

export const FormFileInputButton = ({ name, form, label, placeholder, errorMessage }: FormFileInputButtonProps): JSX.Element => {
  const classes = useStyles();
  const field = useField(name, form);
  const onChange = useCallback(
    ({ target }) => {
      field.input.onChange({ files: target.files, value: target.value });
    },
    [field.input],
  );
  const files = !field.input.value || !Object.keys(field.input.value).length ? [] : Array.from(field.input.value.files);
  const errors = getErrorAndHelperTextNoTranslation(field);
  return (
    <div>
      <Typography className={classes.label}>{label}</Typography>
      <FormControl>
        <Button variant="outlined" component="label" color="primary" startIcon={<SaveAltIcon />} className={classes.button}>
          <input
            type="file"
            hidden
            value={!field.input.value || !Object.keys(field.input.value).length ? '' : field.input.value.value}
            onChange={onChange}
          />
          {files.length ? files.map((item: any) => item.name).join(', ') : placeholder}
        </Button>
        {errors.error && <FormHelperText error>{errorMessage || errors.helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};
