import { useEffect, useMemo } from 'react';
import { HOPInitialValues } from 'src/app/models';
import { useTemplateContext, useFormContext } from '../../../../lib/templates';
import { checkoutAllValues, deliveryInitialValue, authorizeReceiverInitialValues } from '../initialValues';
import { deliveryInfoTemplate, hopTemplate, authorizeReceiverTemplate } from '../templates';
import { AnyObject } from '../../../../commons';
import { useMultiStepFormContext } from '../../../../lib/MultiStepForm';

export const useDeliveryPickupLogic = () => {
  const { form, values } = useFormContext<AnyObject>();
  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);
  const { setTemplate } = useTemplateContext();
  const { steps } = useMultiStepFormContext();
  const authorizedTemplateName = authorizeReceiverTemplate().name
  useEffect(() => {
    if (valuesForm.delivered === 'delivered') {
      setTemplate((template) => {
        if (template.template.find((property) => property.name === deliveryInfoTemplate.name)) {
          return {
            ...template,
            template: [
              ...template.template.filter((property) => property.name !== authorizedTemplateName),
              authorizeReceiverTemplate(valuesForm.authorizeReceiver.authorize),
            ],
          };
        }
        form.change(deliveryInfoTemplate.name, steps.find((item) => item.template.name === 'deliveryInfo')?.initialValue || deliveryInitialValue);
        form.change(hopTemplate.name, undefined);
        form.change(authorizedTemplateName, authorizeReceiverInitialValues);
        return {
          ...template,
          template: [
            ...template.template.filter(
              (property) =>
                property.name !== deliveryInfoTemplate.name &&
                property.name !== hopTemplate.name &&
                property.name !== authorizedTemplateName,
            ),
            deliveryInfoTemplate,
            authorizeReceiverTemplate(valuesForm.authorizeReceiver.authorize),
          ],
        };
      });
    } else if (valuesForm.delivered === 'HOP') {
      setTemplate((template) => {
        if (template.template.find((property) => property.name === 'HOP')) {
          form.change(hopTemplate.name, steps.find((item) => item.template.name === 'retirementPoints')?.initialValue || HOPInitialValues);
          form.change(deliveryInfoTemplate.name, undefined);
          form.change(authorizedTemplateName, undefined);
          return {
            ...template,
            template: [...template.template.filter((property) => property.name !== deliveryInfoTemplate.name && property.name !== authorizedTemplateName)],
          };
        }
        form.change(deliveryInfoTemplate.name, undefined);
        form.change(authorizedTemplateName, undefined);
        return {
          ...template,
          template: [...template.template.filter((property) => property.name !== deliveryInfoTemplate.name && property.name !== authorizedTemplateName), hopTemplate],
        };
      });
    } else {
      setTemplate((template) => {
        if (!template.template.find((property) => property.name === deliveryInfoTemplate.name || property.name === hopTemplate.name)) {
          return {
            ...template,
            template: [
              ...template.template.filter((property) => property.name !== authorizedTemplateName),
              authorizeReceiverTemplate(valuesForm.authorizeReceiver.authorize),
            ],
          };
        }
        form.change(deliveryInfoTemplate.name, undefined);
        form.change(hopTemplate.name, undefined);
        form.change(authorizedTemplateName, authorizeReceiverInitialValues);
        return {
          ...template,
          template: [
            ...template.template.filter(
              (property) =>
                property.name !== deliveryInfoTemplate.name &&
                property.name !== hopTemplate.name &&
                property.name !== authorizedTemplateName,
            ),
            authorizeReceiverTemplate(valuesForm.authorizeReceiver.authorize),
          ],
        };
      });
    }
  }, [setTemplate, valuesForm, form, steps, authorizedTemplateName]);
};
