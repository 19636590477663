import React, { useCallback } from 'react';
import { InputLabel, Input as MuiInput, Button, Box, InputAdornment } from '@material-ui/core';
import { useField } from 'react-final-form-hooks';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';
import { Input } from '../../../app/components/inputs/Input';

export interface FormUploadFileProps {
  name: string;
  label?: React.ReactNode;
  form: any;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
  accept?: string;
}

export const FormUploadFile = ({ name, form, label, disabled, accept = 'image/*', error, helperText, id }: FormUploadFileProps): JSX.Element => {
  const field = useField(name, form);

  const { helperText: helperTextHelper, error: errorHelper } = getErrorAndHelperTextNoTranslation(field);
  const { onChange, value, name: nameField, ...rest } = field.input;

  const helperTextEnd = error || errorHelper ? (!helperText ? helperTextHelper : helperText) : '';

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      onChange(file);
    },
    [onChange],
  );

  return (
    <>
      <MuiInput
        disabled={disabled}
        style={{ display: 'none' }}
        id={name}
        aria-describedby="my-helper-text"
        type="file"
        inputProps={{ accept: { accept } }}
        name={nameField}
        onChange={handleChange}
      />
      <Input
        InputLabelProps={{
          shrink: true,
        }}
        label={
          <Box color="text.disabled" minWidth="104px">
            {label}
          </Box>
        }
        disabled
        variant="standard"
        error={error || errorHelper}
        helperText={helperTextEnd || ''}
        size="small"
        {...rest}
        name={nameField}
        value={value.name}
        onChange={() => console.log('change')}
        positionLabel="inner"
        id={id}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box mb={2}>
                <InputLabel htmlFor={name}>
                  <Button {...rest} size="small" key={id} variant="contained" id={name} disabled={disabled} color="primary" component="span">
                    <Box
                      fontFamily="buttonSmall.fontFamily"
                      fontSize="buttonSmall.fontSize"
                      letterSpacing="buttonSmall.letterSpacing"
                      color="primary.contrastText"
                      lineHeight="buttonSmall.lineHeight"
                    >
                      Subir
                    </Box>
                  </Button>
                </InputLabel>
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
