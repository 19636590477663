import { Template } from '../../../../lib/templates';
import { couponTemplate, ubicationInfoTemplate } from './common';

export const template: Template = {
  name: 'Checkout Form',
  template: [
    { name: 'recaptcha', type: { name: 'string' }, optional: true, validators: [{ name: 'empty', errorMessage: '' }] },
    { name: 'isResponsable', type: { name: 'string' } },
    { name: 'useDeliveryAddress', type: { name: 'boolean' } },
    { name: 'delivered', type: { name: 'string' } },
    { name: 'shipment_cost', type: { name: 'number' }, optional: true },
    {
      name: 'coupon',
      type: {
        name: 'object',
        kind: [...couponTemplate],
      },
    },
    {
      name: 'ubicationInfo',
      type: {
        name: 'object',
        kind: [...ubicationInfoTemplate],
      },
    },
  ],
};
