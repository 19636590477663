import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { Publication } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'simulate';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSimulate',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSimulate',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Simulate
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Simulate,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Simulate.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Publication>(DAO_NAME, optionsToUse);

// hooks
export const useSimulateFetchById = fetchById;
export const useSimulateLibbyCall = libbyCall;
export const useSimulateDAO = libbyDAO;
export const useSimulateLibbyFetch = libbyFetch;

// components/hoc
export const SimulateAutocomplete = entityAutocomplete;
export const SimulateCall = renderPropCall;
export const SimulateById = renderPropFetchById;
export const SimulateSelect = simpleSelect;
export const SimulateAccordionSelector = accordionSelector;
export const SimulateSelectedChips = selectedChips;

// context
// ByIdContext
export const useSimulateByIdContext = useByIdContext;
export const SimulateByIdProvider = ByIdProvider;
export const SimulateByIdContext = ByIdContext;
// GeneralContext
