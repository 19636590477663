import { TemplateProperty } from 'src/lib/templates';
import moment from 'moment';

// * Contiene los templates comunes para el personalInfo!!
export const personalTemplate: TemplateProperty[] = [
  {
    name: 'name',
    type: { name: 'string' },
    label: 'Nombre',
    validators: [
      { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$'] },
      { name: 'max', signature: [30] },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
  {
    name: 'lastName',
    type: { name: 'string' },
    label: 'Apellido',
    validators: [
      { name: 'pattern', signature: ['^[A-Za-zÀ-ÿ\u00f1\u00d1 ]+$'] },
      { name: 'max', signature: [30] },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
  {
    name: 'dateOfBirty',
    type: { name: 'date' },
    label: 'Fecha de nacimiento',
    validators: [
      { name: 'base', signature: [], errorMessage: 'Completa el campo con una fecha valida' },
      { name: 'less', signature: [moment().subtract(18, 'y')], errorMessage: 'Debes ser mayor de edad!' },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
  {
    name: 'email',
    type: { name: 'string' },
    label: 'Correo electrónico',
    validators: [
      { name: 'email', signature: [{ tlds: { allow: false } }] },
      { name: 'empty', errorMessage: 'Completa este dato.' },
    ],
  },
  {
    name: 'phone',
    type: {
      name: 'object',
      kind: [
        {
          name: 'areaCode',
          type: { name: 'string' },
          label: 'Cód. Área',
          validators: [
            { name: 'pattern', signature: ['^[0-9]+$'] },
            { name: 'max', signature: [4] },
            { name: 'empty', errorMessage: 'Completa este dato.' },
          ],
        },
        {
          name: 'number',
          type: { name: 'string' },
          label: 'Número de teléfono',
          validators: [
            { name: 'pattern', signature: ['^[0-9]+$'], errorMessage: 'Completa solo con números.' },
            /**
             * Los números fijos locales tienen 6, 7 u 8 dígitos, dependiendo en dónde se encuentren:
             * El Área Metropolitana de Buenos Aires tiene 8 dígitos.
             * Las ciudades grandes fuera de esta área tienen 7 dígitos. También algunas localidades medianas, como Tandil y Junín.
             * El resto de las ciudades y pueblos tienen 6 dígitos.
             */
            { name: 'min', signature: [6], errorMessage: 'Ingresa un numero valido.' },
            { name: 'max', signature: [20], errorMessage: 'Ingresa un numero valido.' },
            { name: 'empty', errorMessage: 'Completa este dato.' },
          ],
        },
      ],
    },
  },
  {
    name: 'gender',
    type: { name: 'string' },
    label: 'Género',
    validators: [{ name: 'empty', errorMessage: 'Completa este dato.' }],
  },
];
