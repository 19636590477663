import {
  SkeletonBannerCarousel,
  SkeletonBannerMultiple,
  SkeletonCarousel,
  SkeletonTimerCount,
  SkeletonSubcription,
  SkeletonBanner,
  SkeletonLegales,
  SkeletonInfoDIscount,
  SkeletonListProducts,
} from '../skeletons/components';
import { usePublicationZoneName } from '../business/PublicationByZone';
import { usePublication } from '../business';
import { Product } from './business/Product';

export interface SkeletonType {
  BANNER: string;
  BANNER_CAROUSEL: string;
  PRODUCT_CAROUSEL: string;
  BANNER_PROMOS: string;
  TIMER: string;
  SUBCRIPTION: string;
  INFO_DISCOUNT: string;
  LISTPRODUCTS: string;
  LEGALES: string;
}

export enum SKELETON_TYPE {
  BANNER = 'skeletonBanner',
  BANNER_CAROUSEL = 'skeletonBannerCarousel',
  PRODUCT_CAROUSEL = 'skeletonProductCarousel',
  BANNER_PROMOS = 'skeletonBannerPromos',
  TIMER = 'skeletonTimerCount',
  SUBCRIPTION = 'skeletonSubcription',
  INFO_DISCOUNT = 'skeletonInfoPromo',
  LISTPRODUCTS = 'skeletonListProducts',
  LEGALES = 'skeletonLegales',
}

export interface Site {
  site_id: number;
  name: string;
  url: string;
  skeletons: Skeleton[];
}
export interface Skeleton {
  skeleton_id: number;
  name: string;
  url: string;
  skeleton_components: SkeletonComponent[];
}
export interface Campaign {
  campaingn_id: number;
  name: string;
  startet_at: string;
  end_at: string;
  campaign_state_id: number;
  site_id: number;
  last_change: string;
  updated_by: string;
  priority: number;
}

export interface SkeletonConfigValues {
  component_key: keyof typeof SKELETON_TYPE;
  source: 'ZONE' | 'PUBLICATION';
  source_id: string;
  transition_time?: number;
}

interface MetadataProps {
  toLink?: (event: any) => void;
  onClickProduct: (value: Product) => void;
}

export interface SkeletonComponentProps {
  data: SkeletonConfigValues;
  props?: MetadataProps;
}

export interface SkeletonComponent {
  skeleton_component_id: number;
  name: string;
  url: string;
  config_values: SkeletonConfigValues;
  relevance: number;
  skeleton_id: number;
}
export interface SkeletonComponentPublication {
  skeleton_component_id: number;
  publication_id: number;
  campaign_id: number;
  type: SkeletonType;
  onClick?: (value?: any) => void;
}

export type SkeletonComponentsRender = {
  [key in SKELETON_TYPE]: (props: SkeletonComponentProps) => JSX.Element;
};

export const SkeletonComponents: SkeletonComponentsRender = {
  [SKELETON_TYPE.BANNER_CAROUSEL]: SkeletonBannerCarousel,
  [SKELETON_TYPE.BANNER_PROMOS]: SkeletonBannerMultiple,
  [SKELETON_TYPE.PRODUCT_CAROUSEL]: SkeletonCarousel,
  [SKELETON_TYPE.TIMER]: SkeletonTimerCount,
  [SKELETON_TYPE.SUBCRIPTION]: SkeletonSubcription,
  [SKELETON_TYPE.BANNER]: SkeletonBanner,
  [SKELETON_TYPE.LEGALES]: SkeletonLegales,
  [SKELETON_TYPE.LISTPRODUCTS]: SkeletonListProducts,
  [SKELETON_TYPE.INFO_DISCOUNT]: SkeletonInfoDIscount,
};

export const SOURCE = {
  ZONE: (id: string) => usePublicationZoneName(id),
  PUBLICATION: (id: string) => usePublication(id),
};
