import { Box, InputLabelProps, InputProps } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useField } from 'react-final-form-hooks';
import { DatePicker } from 'src/app/components';
import { DatePickerView } from '@material-ui/pickers';

/* eslint-disable */
export interface FormDatePickerProps {
  form: any;
  name: string;
  label: ReactNode;
  clearable?: boolean;
  placeholder?: string;
  disablePast?: boolean;
  openTo?: DatePickerView;
  format?: string;
  positionLabel?: 'inner' | 'outer';
  views?: DatePickerView[];
  inputLabelProps?: InputLabelProps;
  inputProps?: InputProps;
  fullWidth?: boolean;
  disableFuture?: boolean;
  error?: boolean;
  helperText?: string;
  InputAdornmentProps?: { position: 'start' | 'end' | undefined };
}

export const FormDatePicker = ({
  name,
  clearable = true,
  placeholder,
  disablePast,
  form,
  openTo,
  label,
  format,
  disableFuture,
  views,
  positionLabel = 'inner',
  inputLabelProps,
  inputProps,
  fullWidth,
  error,
  helperText,
  InputAdornmentProps,
}: FormDatePickerProps) => {
  const field = useField(name, form);
  const date = (field.input.value && new Date(field.input.value)) || null;

  return (
    <>
      {positionLabel === 'outer' && <Box color="text.disabled">{label}</Box>}
      <DatePicker
        openTo={openTo}
        label={positionLabel === 'inner' && label}
        format={format}
        fullWidth={fullWidth}
        value={date}
        disableFuture={disableFuture}
        views={views}
        clearable={clearable}
        placeholder={placeholder}
        disablePast={disablePast}
        error={!field.input.value ? false : error || !!field.meta.error}
        helperText={error ? helperText : !field.input.value ? '' : field.meta.error}
        inputLabelProps={inputLabelProps}
        inputProps={inputProps}
        onChange={(_date) => field.input.onChange(_date?.toDate())}
        InputAdornmentProps={InputAdornmentProps}
      />
    </>
  );
};
