import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { HOP } from 'src/app';
import { GenericIcon } from 'src/commons/components/GenericIcon';
import { MapModal } from '../components/MapModal';

export const columns = [
  {
    id: 'name',
    label: 'Nombre',
    render: (value: number, row: HOP) => <Typography color="textPrimary">{row.description}</Typography>,
  },
  {
    id: 'shipment_hop_direction.street',
    label: 'Calle',
  },
  {
    id: 'shipment_hop_direction.number',
    label: 'Num',
  },
  {
    id: 'service_hours',
    label: 'Horarios',
  },
  {
    id: 'googleDistance.text',
    label: 'Distancia',
    render: (value: number, row: HOP) => {
      const distance = row.googleDistance?.text;
      return <Typography color="primary">{distance}</Typography>;
    },
  },
  {
    id: 'ubication',
    label: 'ubicación',
    render: (value: number, row: HOP) => {
      const center = { lat: +row.coordinates.latitud, lng: +row.coordinates.longitud };
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [open, setOpen] = useState(false);
      return (
        <>
          <GenericIcon
            image="https://res.cloudinary.com/phinx-lab/image/upload/v1677597018/Assets/point.png"
            width="20px"
            height="20px"
            styles={{ cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          />
          <MapModal center={center} open={open} handleClose={() => setOpen(!open)} row={row} mobile={false} />
        </>
      );
    },
  },
];
