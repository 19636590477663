import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';

export const StorePickUpMap = () => {
  const { sm } = useBreakpointsState();
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={sm ? '1px solid' : ''}>
          <Typography variant="h6" color="textPrimary">
            Acercate a nuestra sucursal en: Santa Elena 983 (entre Osvaldo Cruz y Villarino), Barracas, C.P. 1273, de lunes a viernes de 9 a 16hs.
          </Typography>
        </Box>
        <iframe
          // eslint-disable-next-line max-len
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1111.171957758683!2d-58.38273847731105!3d-34.654312390128126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccb57704fefc3%3A0x14df05ef1f286acf!2sSta.%20Elena%20983%2C%20C1273%20CABA!5e0!3m2!1ses!2sar!4v1651691930098!5m2!1ses!2sar"
          title="store_pickup"
          width="80%"
          height="300vh"
          loading="lazy"
          style={{ borderRadius: '1em', border: '1px solid #000', marginLeft: '10%' }}
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Grid>
    </Grid>
  );
};
