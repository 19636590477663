import moment from 'moment';
import {
  Order,
  BuyerAddress,
  OrderState,
  OrderItem,
  Cart,
  PaymentOrder,
  PaymentModel,
  Payment,
  ResponseDecidir,
  Buyer,
  Publication,
  MetadataMercadoPago,
  DELIVERY_TYPE,
  DeliveryTypes,
} from '../../../../app/models';
import { APP_ECOMMERCE } from '../../../../config';
import { ICheckoutAll, ICoupon, IDeliveryAddressInfo, IPersonalCredit, IUbicationInfo } from '../initialValues';
import { getPricePublicationCart } from '../../../../context/CartContext/utils';
import { AnyObject } from '../../../../commons';

interface CreateAddress {
  street: string;
  number: number;
  floor: string;
  department: string;
  cityId: string;
  zip: string;
  additionalInfo: string;
}

interface DataBuyer {
  email: string;
  name: string;
  phone: string;
  dateOfBirty: string;
  lastName: string;
  address: CreateAddress;
  document_number: string;
  document_type: string;
  company: string;
  valid_user: boolean;
}

interface CreateBuyer {
  buyer: Buyer;
  address?: BuyerAddress | {};
}

export const createBuyerShipment = (deliveryInfo: IDeliveryAddressInfo, ubicationInfo: IUbicationInfo, delivered: string): BuyerAddress => {
  const isHop = delivered === 'HOP';
  const city_id = isHop ? ubicationInfo.id : deliveryInfo?.city?.id !== '' ? deliveryInfo?.city?.id : '0';
  const zip = isHop ? ubicationInfo.zip.toString() : deliveryInfo?.zipCode !== '' ? deliveryInfo?.zipCode : '0000';

  return {
    street: deliveryInfo?.street,
    number: +deliveryInfo?.streetNumber,
    floor: deliveryInfo?.floor,
    department: deliveryInfo?.apartament,
    city_id,
    zip,
    comments: deliveryInfo?.additionalInfo,
  };
};
export const createBuyerAddress = (data: CreateAddress): BuyerAddress => ({
  street: data.street,
  number: data.number,
  floor: data.floor,
  department: data.department,
  city_id: data.cityId,
  zip: data.zip,
  comments: data.additionalInfo,
});

export const createBuyer = (
  data: DataBuyer,
  summaryAddress: IDeliveryAddressInfo,
  deliveryInfo: IDeliveryAddressInfo,
  ubicationInfo: IUbicationInfo,
  delivered: string,
): CreateBuyer => {
  const address = data.address.street !== '' ? createBuyerAddress(data.address) : undefined;
  const shipment = createBuyerShipment(deliveryInfo, ubicationInfo, delivered);

  const buyer: Buyer = {
    email: data.email,
    name: data.name,
    last_name: data.lastName,
    date_of_birth: moment(data.dateOfBirty).toISOString(),
    ...(address && { address }),
    ...(shipment && { shipment }),
    document_number: data.document_number,
    document_type: data.document_type,
    phone: data.phone,
    company: data.company,
    valid_user: data.valid_user,
  };

  return {
    buyer,
    address,
  };
};

const createDeliveryType = (delivery: string) => {
  const delivery_type: DeliveryTypes = {
    delivered: DELIVERY_TYPE.SHIPMENT,
    store_pickup: DELIVERY_TYPE.BRANCH_PICKUP,
    HOP: DELIVERY_TYPE.HOP,
  };

  const delivery_type_id = delivery_type[delivery];
  return { delivery_type_id };
};

type documentType = 'DNI' | 'CUIL' | 'CUIT';

const getDocumentType = (isResponsable: string, documentNumber: string): documentType => {
  if (APP_ECOMMERCE !== 'detecnologia') {
    return isResponsable === 'false' ? 'DNI' : 'CUIT';
  }
  return documentNumber.length >= 10 ? 'CUIL' : 'DNI';
};

export async function createOrder(cart: Cart, values: ICheckoutAll, validClient: boolean): Promise<Order> {
  const orderItems = new Array<OrderItem>();
  let total = 0;

  const {
    authorizeReceiver,
    isResponsable,
    personalInfo: { name, lastName, email, document, phone, dateOfBirty },
    deliveryInfo,
    responsableInscripto: { cuit, business } = { cuit: '', business: '' },
    paymentMethod: { payment },
    delivered,
    retirementPoints,
    summaryAddress,
    ubicationInfo,
  } = values;

  cart.items.forEach(({ quantity, publication: { has_stock, ...rest } }) => {
    const orderItem: OrderItem = {
      publication: rest,
      quantity,
    };
    orderItems.push(orderItem);
    const price = getPricePublicationCart(orderItem.publication as Publication, payment);
    total += price * orderItem.quantity;
  });

  const dataBuyer: DataBuyer = {
    email,
    name,
    lastName,
    dateOfBirty,
    phone: `${phone.areaCode} ${phone.number}`,
    document_number: isResponsable === 'false' ? document : cuit,
    document_type: getDocumentType(isResponsable, isResponsable === 'false' ? document : cuit),
    company: business,
    address: {
      street: summaryAddress?.street || deliveryInfo?.street,
      number: Number(summaryAddress?.streetNumber || deliveryInfo?.streetNumber),
      floor: summaryAddress?.floor || deliveryInfo?.floor,
      department: summaryAddress?.apartament || deliveryInfo?.apartament,
      cityId: summaryAddress?.city.id || deliveryInfo?.city.id,
      zip: summaryAddress?.zipCode || deliveryInfo?.zipCode,
      additionalInfo: summaryAddress?.additionalInfo || deliveryInfo?.additionalInfo,
    },
    valid_user: validClient || false,
  };

  const { buyer } = createBuyer(dataBuyer, summaryAddress, deliveryInfo, ubicationInfo, delivered);
  const { delivery_type_id } = createDeliveryType(delivered);

  const isHop = retirementPoints && retirementPoints?.selectedPoint?.shipment_hop_options_id !== '';

  const order: Order = {
    buyer,
    shipment_cost: values.shipment_cost || 0,
    authorizeReceiver,
    delivery: {
      delivery_type_id,
      ...(isHop && { order_shipment_hop: retirementPoints?.selectedPoint }),
    },
    tax: 0,
    subtotal: total,
    total: total + (values.shipment_cost || 0) - (values.coupon.amountCoupon || 0),
    created_at: new Date(),
    updated_at: new Date(),
    state: 'Created' as OrderState,
    items: orderItems,
  };

  return order;
}

export const createPayment = (order: Order, payment: PaymentModel, reference: string, metadata: AnyObject, coupon?: ICoupon): Payment => {
  const paymentOrder: PaymentOrder = {
    order,
    state: 'Simulation',
    created_at: new Date(),
    updated_at: new Date(),
  };

  return {
    ...paymentOrder,
    ...payment,
    installments: +payment.installments,
    type: '352beb41-25f6-47f8-adc9-6d1cb3afd69c',
    reference,
    metadata,
    amount: coupon ? payment.amount - coupon.amountCoupon : payment.amount,
    original_amount: payment.original_amount,
    promotion_id: Number(payment.reference),
    ...(coupon && { coupon }),
  };
};

export const createPaymentDecidir = (order: Order, payment: PaymentModel, decidirResponse: ResponseDecidir, coupon?: ICoupon): Payment =>
  createPayment(order, payment, decidirResponse.id, decidirResponse, coupon);

export const createPaymentMercadoPago = (order: Order, payment: PaymentModel, metadata: MetadataMercadoPago, coupon?: ICoupon) =>
  createPayment(order, payment, 'mercadopago', metadata, coupon);

export const createPaymentCredit = (order: Order, payment: PaymentModel, applicantDetails: IPersonalCredit, coupon?: ICoupon): Payment =>
  createPayment(order, payment, 'credit', applicantDetails, coupon);
