import { Tooltip, withStyles } from '@material-ui/core';

const AppTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    padding: '4px 16px',
    borderRadius: '4px',
  },
}))(Tooltip);

const i = AppTooltip;

export { i as AppTooltip };
