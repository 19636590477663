import { EcommerceDAO } from './EcommerceDAO';
import { Cart } from '../../../app/models';

export class SimulateDAO extends EcommerceDAO<Cart> {
  constructor() {
    super('simulate', 'emitter');
  }

  async generateSimulate(cart: Cart, emitter: string, stateId: number): Promise<void> {
    const { items, owner, send = false } = cart;
    const itemsFix = items.map(({ quantity, publication: { has_stock, ...rest } }) => ({
      quantity,
      publication: rest,
    }));
    const response = await this.fetchData(`/order/simulate/${emitter}`, {
      method: 'post',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ items: itemsFix, owner, state_id: stateId, send }),
    });
    return response.json();
  }
}
