import { useMemo } from 'react';
import { useConfigurationsContext } from 'src/context/Configuration';
import { FORM_FIELD_TYPE } from '../../../hooks/useFieldForm';
import { IFieldComponent } from '../../FieldComponent';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { State, City, BuyerAddressInfo } from '../../../../../../app/models';
import { useGenerateValues } from './useGenerateValues';
import { checkoutAllValues, IDeliveryAddressInfo, deliveryInitialValue } from '../../../initialValues';
import { validateZipCode } from '../validators';
import { useBreakpointsState } from '../../../../../../app/hooks/useMobile';
import { StorePickUpMap } from '../../StorePickUpMap';
import { useRolesContext } from '../../../../../../context';
import { AddressForm } from '../components';
import { TransformFunction } from '../../../types';
import { RetirementPoints } from '../../RetirementPoints';
import { useEnablDeliveries } from './useEnableDeliveries';

export const transformer: TransformFunction<IDeliveryAddressInfo, BuyerAddressInfo> = (value) => ({
  buyer_address_id: value.buyer_address_id,
  city: { ...value.city, id: value.city.id.toString() },
  province: { ...value.state, id: value.state.id.toString() },
  street: value.street,
  streetNumber: value.number.toString(),
  floor: value.floor,
  apartament: value.department,
  zipCode: value.zip,
  additionalInfo: value.comments,
});

export const useFields = () => {
  const { form, values } = useCheckoutFormContext();
  const { useMakeDown, useMakeUp } = useBreakpointsState();
  const { userInfo } = useRolesContext();
  const { cities, provinces, loading, search, loadingProvinces, addresses, loadingAddress, onInputChangeCity, recall } = useGenerateValues();
  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);
  const isDownSM = useMakeDown('sm');
  const { hideDeliveries, cleanedDeliveries } = useEnablDeliveries();
  const isUp768 = useMakeUp(768);
  const selectedProvince = useMemo(() => valuesForm.deliveryInfo.province?.id !== '', [valuesForm.deliveryInfo.province?.id]);
  const { configurations } = useConfigurationsContext();
  const authorize_receiver = configurations.find((config)=> config.name === 'authorize_receiver')?.value;

  const hiddenAddress = useMemo(() => !userInfo.id || addresses.length === 0, [userInfo, addresses]);

  const data: IFieldComponent[] = useMemo(
    () => [
      {
        hidden: hideDeliveries,
        component: FORM_FIELD_TYPE.RADIO_GROUP,
        componentProps: {
          row: true,
          marginRight: isDownSM ? '0px' : '30px',
          name: 'delivered',
          id: 'delivered',
          labelKey: 'label',
          options: cleanedDeliveries,
          form,
        },
      },
      {
        hidden: values.delivered !== 'delivered' || hiddenAddress,
        component: FORM_FIELD_TYPE.RADIO_GROUP_CUSTOM,
        xs: 12,
        componentProps: {
          row: !isDownSM,
          name: 'deliveryInfo',
          id: 'deliveryInfo',
          valueKey: 'buyer_address_id',
          // labelKey: 'image_url',
          options: addresses,
          customHelperText: 'Debés seleccionar una opción',
          component: AddressForm,
          componentProps: {
            labelToFilter: 'card_emiter_id',
            recall,
          },
          optionToValue: (value: string, options: BuyerAddressInfo[]) => {
            const option = options.find((item) => item.buyer_address_id === value);
            return option ? transformer(option) : deliveryInitialValue;
          },
          valueToOption: (value: IDeliveryAddressInfo, options: BuyerAddressInfo[]) =>
            options.find((item) => item.buyer_address_id === value.buyer_address_id)?.buyer_address_id || '',
          form,
        },
      },
      {
        hidden: values.delivered !== 'delivered' || !hiddenAddress,
        key: 'deliveryInfo.province',
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.SELECT,
        componentProps: {
          form,
          loading: loadingProvinces,
          fullWidth: true,
          label: 'Provincia',
          name: 'deliveryInfo.province',
          content: provinces,
          valueKey: 'id',
          labelKey: 'name',
          helperText: 'El campo Provincia es requerido',
          inputLabelProps: { shrink: true },
          optionToValue: (value: string, options: State[]) => options?.find((province) => province.id === value),
          valueToOption: (value: State, options: State[]) => options.find((option: State) => option.id === value.id)?.id || search,
        },
      },
      {
        hidden: values.delivered !== 'delivered' || !hiddenAddress,
        key: 'deliveryInfo.city',
        lg: 5,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.AUTOCOMPLETE_FETCH,
        componentProps: {
          disabled: !selectedProvince,
          name: 'deliveryInfo.city',
          form,
          label: 'Ciudad',
          options: cities,
          optionLabelKey: 'name',
          optionToValue: (option: City) => option,
          valueToOption: (value: City, options: City[]) =>
            search?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && search?.length > 0 && options.length > 0
              ? options.find((option: City) => option.id === value.id)?.name
              : search,
          helperText: 'El campo Ciudad es requerido',
          getOptionSelected: (option: City, value: City) => option.id === value?.id,
          onInputChange: onInputChangeCity,
          inputLabelProps: { shrink: true },
          loading,
          enabledOpenAutocomplete: search.length > 1,
        },
      },
      {
        hidden: values.delivered !== 'delivered' || !hiddenAddress,
        key: 'deliveryInfo.zipCode',
        lg: 2,
        md: 4,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `deliveryInfo.zipCode`,
          label: 'Cód. postal',
          required: true,
          id: 'deliveryInfoZipCode',
          autoComplete: 'postal-code',
          validator: validateZipCode,
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: values.delivered !== 'delivered' || !hiddenAddress,
        key: 'deliveryInfo.street',
        lg: 6,
        md: 8,
        xs: 8,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `deliveryInfo.street`,
          label: 'Calle',
          required: true,
          id: 'deliveryInfoStreet',
          autoComplete: 'street-address',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: values.delivered !== 'delivered' || !hiddenAddress,
        key: 'deliveryInfo.streetNumber',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `deliveryInfo.streetNumber`,
          label: 'Altura',
          required: true,
          id: 'deliveryInfoStreetNumber',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: values.delivered !== 'delivered' || !hiddenAddress,
        key: 'deliveryInfo.floor',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `deliveryInfo.floor`,
          label: 'Piso',
          required: true,
          id: 'deliveryInfoFloor',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: values.delivered !== 'delivered' || !hiddenAddress,
        key: 'deliveryInfo.apartament',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `deliveryInfo.apartament`,
          label: 'Dpto.',
          required: true,
          id: 'deliveryInfoApartment',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: values.delivered !== 'delivered' || !hiddenAddress,
        key: 'deliveryInfo.additionalInfo',
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `deliveryInfo.additionalInfo`,
          label: 'Información adicional',
          id: 'deliveryInfoAditionalInfo',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: values.delivered !== 'store_pickup',
        component: FORM_FIELD_TYPE.CUSTOMIZED,
        componentProps: { component: StorePickUpMap },
      },
      {
        hidden: (values.delivered !== 'delivered' && values.delivered !== 'store_pickup') || !hiddenAddress || !authorize_receiver?.enable,
        key: 'authorizeReceiver.authorize',
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
        component: FORM_FIELD_TYPE.CHECKBOX,
        componentProps: {
          fullWidth: true,
          marginRight: isDownSM ? '0px' : '100px',
          form,
          variant: 'standard',
          name: `authorizeReceiver.authorize`,
          label: 'Autorizo a recibir',
          id: 'authorizeReceiverAuthorize',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden:
          (values.delivered !== 'delivered' && values.delivered !== 'store_pickup') || !hiddenAddress || !valuesForm.authorizeReceiver.authorize || !authorize_receiver?.enable,
        key: 'authorizeReceiver.name',
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `authorizeReceiver.name`,
          label: 'Nombre',
          id: 'authorizeReceiverName',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden:
          (values.delivered !== 'delivered' && values.delivered !== 'store_pickup') || !hiddenAddress || !valuesForm.authorizeReceiver.authorize || !authorize_receiver?.enable,
        key: 'authorizeReceiver.lastname',
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `authorizeReceiver.lastname`,
          label: 'Apellido',
          id: 'authorizeReceiverLastname',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden:
          (values.delivered !== 'delivered' && values.delivered !== 'store_pickup') || !hiddenAddress || !valuesForm.authorizeReceiver.authorize || !authorize_receiver?.enable,
        key: 'authorizeReceiver.dni',
        lg: 4,
        md: 4,
        xs: 6,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `authorizeReceiver.dni`,
          label: 'DNI',
          id: 'authorizeReceiverDni',
          inputLabelProps: { shrink: true },
        },
      },
      {
        hidden: values.delivered !== 'HOP',
        component: FORM_FIELD_TYPE.CUSTOMIZED,
        componentProps: { component: RetirementPoints },
        name: 'retirementPoints.selectedPoint.shipment_hop_options_id',
      },
    ],
    [
      hideDeliveries,
      isDownSM,
      cleanedDeliveries,
      form,
      values.delivered,
      hiddenAddress,
      addresses,
      recall,
      isUp768,
      loadingProvinces,
      provinces,
      selectedProvince,
      cities,
      onInputChangeCity,
      loading,
      search,
      valuesForm.authorizeReceiver.authorize,
      authorize_receiver?.enable
    ],
  );
  return { fields: data.filter((field) => !field.hidden), loading: loadingAddress };
};
