import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { UserIfnoInitialValue, useRolesContext } from 'src/context';
import { logout } from 'src/platform/libby/session';

export const useLogOut = () => {
  const { setUserInfo, deselectRole } = useRolesContext();
  const history = useHistory();

  const onLogOut = useCallback(async () => {
    await logout();
    deselectRole();
    setUserInfo(UserIfnoInitialValue);
    history.push('/');
    window.location.reload();
  }, [history, setUserInfo, deselectRole]);

  return { onLogOut };
};
