import { Search } from 'src/screens/Public';
import { MenuItem } from '../../../../commons/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'publicSearchMainWithValue',
    basePath: '/:value',
    target: {
      component: Search,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Search',
    children: [],
  },
  {
    key: 'publicSearchMain',
    basePath: '/',
    target: {
      component: Search,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Search',
    children: [],
    default: true,
  },
];
