import { useEffect, useMemo, useCallback } from 'react';
import { checkoutAllValues } from '../initialValues';
import { AnyObject } from '../../../../commons';
import { useFormContext } from '../../../../lib/templates';
import { useMultiStepFormContext } from '../../../../lib/MultiStepForm';
import { useConfigurationsContext } from '../../../../context/Configuration';
import { CONFIGURATION_ID, defaultValue } from '../../../../app/models';
import { limitPrice } from '../../../../commons/const/limitPriceIdentityValidation';
import { useCartContext } from '../../../../context';

// If is reponsableInscripto the verification step is not needed

export const useEquifaxStepHidden = () => {
  const { values } = useFormContext<AnyObject>();
  const {
    allSteps,
    setSteps,
    equifax: { isWhiteList },
  } = useMultiStepFormContext();
  const { configurations } = useConfigurationsContext();
  const { cartState } = useCartContext();
  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const isEnableEquifax = useMemo(() => {
    const config = configurations.find((config) => config.general_config_id.toString() === CONFIGURATION_ID.EQUIFAX.toString()) || defaultValue;
    return config.value.enable;
  }, [configurations]);

  const price: boolean = useMemo(() => cartState.totals.totalPrice > limitPrice, [cartState.totals.totalPrice]);

  const validStepper = useCallback(
    (newStepper) => {
      if (JSON.stringify(newStepper) !== JSON.stringify(allSteps)) {
        setSteps(newStepper);
      }
    },
    [setSteps, allSteps],
  );

  useEffect(() => {
    const newSteper = allSteps.map((step) => {
      if (step.title === 'Cuestionario de verificación') return { ...step, hidden: !price || !isEnableEquifax || isWhiteList };
      return step;
    });
    validStepper(newSteper);
  }, [allSteps, isEnableEquifax, price, validStepper, valuesForm.isResponsable, isWhiteList]);
};
