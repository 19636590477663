import { makeEntityFetchById, makeEntityLibbyCall, makeEntityLibbyDAO, makeEntityLibbyFetch } from '../hooks';

export const makeBusinessHooks = <T>(daoName: string) => {
  const fetchById = makeEntityFetchById<T>(daoName);
  const libbyCall = makeEntityLibbyCall(daoName);
  const libbyDAO = makeEntityLibbyDAO(daoName);
  const libbyFetch = makeEntityLibbyFetch<T>(daoName);

  return {
    fetchById,
    libbyCall,
    libbyDAO,
    libbyFetch,
  };
};
