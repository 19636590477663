import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Links } from 'src/app/views';

export interface LinksBreadcrumbs {
  label: string;
  url?: string;
  onClick?: () => void;
}

interface useBreadcrumbsProps {
  links: LinksBreadcrumbs[];
  oldLinks?: Links[];
}

export const useBreadcrumbs = ({ oldLinks = [], links = [] }: useBreadcrumbsProps) => {
  const match = useRouteMatch();
  const linksNew: Links[] = useMemo(() => {
    const copyLinks = [...oldLinks];
    links.forEach((link) => {
      copyLinks.push({
        ...link,
        url: link.url || match.url,
      });
    });
    return copyLinks;
  }, [links, match, oldLinks]);

  return linksNew;
};
