import { createContext, useContext } from 'react';
import { MultiStepFormContextValue } from '../types';

export const MultiStepFormContext = createContext<MultiStepFormContextValue>({
  steps: [],
  preSteps: [],
  activeStep: 0,
  completedSteps: new Set<number>(),
  allSteps: [],
  error: {
    error: false,
    message: {
      title: '',
      description: '',
      details: '',
    },
  },
  openBackdrop: false,
  setSteps: () => {},
  setActiveStep: () => {},
  setCompletedSteps: () => {},
  setError: () => {},
  setOpenBackdrop: () => {},
  equifax: {
    isWhiteList: false,
  },
  equifaxRequestLoading: false
});

export const useMultiStepFormContext = () => useContext(MultiStepFormContext);
