import { Grid } from '@material-ui/core';
import React from 'react';
import { PaymentExtraDataModel, Publication } from 'src/app/models';
import { PriceView } from 'src/commons/components/PriceView';

interface ProductPaymentMethodsInstallmentsProps {
  installment: number;
  interest: number;
  alterPrice: PaymentExtraDataModel;
  data: Publication;
  amount: number;
}

export const ProductPaymentMethodsInstallments = ({ amount, installment, interest, alterPrice, data }: ProductPaymentMethodsInstallmentsProps) => { // eslint-disable-line arrow-body-style
    return (
        <>
            <Grid container direction="row" alignItems="center">
                <PriceView
                    variant="subtitle1"
                    key={`${alterPrice.amountTotal}-best-promos`}
                    id={`${data.id}-best-promos`}
                    beforeText={`${installment > 1 ? `${installment} ${alterPrice.typePayment}` : '1 pago'} de`}
                    afterText={interest > 0 ? 'fijas' : 'sin interés'}
                    withDecimals={installment > 1}
                    price={installment === 1 ? `${amount}` :`${interest > 0 ? (alterPrice.amount + interest) / installment : alterPrice.original_amount / installment}`}
                    priceProps={{color: interest > 0 ? '#000' : 'success.dark', fontWeight: 'fontWeightBold'}}
                    afterProps={{
                        color: interest > 0 ? '#000' : 'success.dark',
                        fontWeight: 'fontWeightBold',
                        pl: alterPrice.installments > 1 ? 2 : 0.5
                    }}
                />
            </Grid>
        </>
    )
};
