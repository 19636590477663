import React from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import palette from 'src/theme/palette';
import { ecommerceConfig } from 'src/config/ecommerce';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { PaymentMsg } from '../Payment/Payment';
import { approvedPayment as useStyles } from '../../styles';

export const FailurePaymentView = () => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const color = palette.error.main;
  const classes = useStyles({ color });
  const {failurePayment: FailurePayment} = ecommerceConfig.ecommerceLogo;

  return (
    <PaymentMsg
      title="Ocurrio un error!"
      color={color}
      description={
        <>
          No hemos recibido correctamente el pago y tus datos sobre la compra realizada. Comunicate con nosotros para poder ayudarte, solicitar
          soporte.
        </>
      }
      icon={<ErrorOutlineIcon fontSize="large" className={classes.icon} />}
      img={<FailurePayment width={isDownSm ? 303 : 506} height={isDownSm ? 213 : 326} />}
    />
  );
};
