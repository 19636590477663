import React, { useState, useCallback } from 'react';
import { FormContextProvider } from 'src/lib/templates';
import { Container, Grid, Typography, Box, useMediaQuery, Theme } from '@material-ui/core';
import { usePaymentDAO } from 'src/app/business/Payment';
import { PaymentCancelation } from 'src/app/models';
import {  useLocation } from 'react-router';
import { RegretForm } from './components/RegretForm';
import { initialValue } from './InitialValues';
import { template } from './templates';
import { errorEmailType } from './types';

const initialErrorEmailState = {
  err: false,
  msg: '',
};

export const Regret = () => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [errorEmail, setErrorEmail] = useState<errorEmailType>(initialErrorEmailState);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(true);
  const [errorSave, setErrorSave] = useState<boolean>(false);
  const location = useLocation<any>();

  const paymentDAO = usePaymentDAO();

  const updateErrorEmail = useCallback(
    (newState) => {
      if (JSON.stringify(errorEmail) !== JSON.stringify(newState)) {
        setErrorEmail(newState);
      }
    },
    [errorEmail],
  );

  const emailValidation = useCallback(
    async (values: PaymentCancelation) => {
      if (values.emailConfirm === values.email) {
        updateErrorEmail({
          err: false,
          msg: '',
        });
      } else {
        updateErrorEmail({
          err: true,
          msg: 'Verifique los correos ingresados.',
        });
      }
    },
    [updateErrorEmail],
  );

  const onClickForm = useCallback(
    async (values: PaymentCancelation, form) => {
      emailValidation(values);
      if (errorEmail.err) {
        setOpen(true);
        setErrorSave(true);
      }
      setLoading(true);
      paymentDAO.sendCancelation(values).then((res: boolean) => {
        if (res) {
          setOpen(true);
          form.restart();
          setBtnDisable(true);
          setLoading(false);
        } else {
          setOpen(true);
          setErrorSave(true);
          setLoading(false);
        }
      });
    },
    [emailValidation, errorEmail.err, paymentDAO],
  );

  return (
    <>
      <Container maxWidth={false} disableGutters>
        <Box bgcolor="primary.dark" mt={-1}>
          <Container>
            <Box py={4}>
              <Grid container>
                <Grid item lg={7} xs={12}>
                  <Box color="white">
                    <Typography variant="h3">Arrepentimiento</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Container>
      <Container>
        <Box mt={4}>
          <Grid>
            <Typography variant="h4">Resolución 424/2020 - Devoluciones de compras en internet</Typography>
            {/* eslint-disable max-len  */}
            <Box maxWidth={isDownSm ? '100%' : '65%'}>
              <Typography variant="body1">
                Te recordamos que el producto a devolver debe de estar tal cual lo recibiste. Tenes hasta 10 días de corridos desde que lo compraste
                y/o recibiste para realizar la devolución.
              </Typography>
            </Box>
          </Grid>
          <Box mt={4} maxWidth={isDownSm ? '100%' : '65%'}>
            <FormContextProvider
              initialValues={{ ...initialValue, ...(location.state?.payment_id ? { buyNumber: location.state.payment_id } : {}) }}
              onSubmit={onClickForm}
              template={template}
            >
              <RegretForm
                emailValidation={emailValidation}
                errorEmail={errorEmail}
                open={open}
                setOpen={setOpen}
                loading={loading}
                setLoading={setLoading}
                btnDisable={btnDisable}
                setBtnDisable={setBtnDisable}
                errorSave={errorSave}
                paymentDAO={paymentDAO}
              />
            </FormContextProvider>
          </Box>
        </Box>
      </Container>
    </>
  );
};
