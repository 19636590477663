export enum PROMOTION_OPERATION_STATUS {
  PENDIENTE = 1,
  APROBADO = 2,
  RECHAZADO = 3,
  PENDIENTE_PAGO = 4,
  PAGADO = 5,
  PAGO_RECHAZADO = 6,
  PAGO_EN_ESPERA = 7,
  CANCELADO = 8,
  DEVOLUCION = 9,
  PAGO_ESTADO_DESCONOCIDO = 10,
  PENDIENTE_SCORING = 11,
  RECHAZADA_SCORING = 12,
}
