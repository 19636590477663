import {
  FormDatePicker,
  FormAutocomplete,
  FormAutocompleteFetch,
  FormCheckbox,
  FormGenericControl,
  FormRadioGroup,
  FormRecaptchaInput,
  FormInput,
  FormSelect,
  FormUploadFile,
  FormRadioGroupCustom,
  FormDialogCheckbox,
} from '../../../../lib/templates';
import { Empty, CustomRender } from '../../../../commons/components';

export enum FORM_FIELD_TYPE {
  EMPTY = 0,
  DATE_PICKER = 1,
  AUTOCOMPLETE = 2,
  AUTOCOMPLETE_FETCH = 3,
  SELECT = 4,
  INPUT = 5,
  RADIO_GROUP = 6,
  RADIO_GROUP_CUSTOM = 7,
  RECAPTCHA = 8,
  UPLOAD_FILE = 9,
  CHECKBOX = 10,
  GENERIC_CONTROL = 11,
  CHECKBOX_DIALOG = 12,
  CUSTOMIZED = 13,
}

interface IFormFieldComponent {
  [key: number]: (props: any) => JSX.Element;
}

export const FORM_FIELD_COMPONENTS: IFormFieldComponent = {
  [FORM_FIELD_TYPE.EMPTY]: Empty,
  [FORM_FIELD_TYPE.DATE_PICKER]: FormDatePicker,
  [FORM_FIELD_TYPE.AUTOCOMPLETE]: FormAutocomplete,
  [FORM_FIELD_TYPE.AUTOCOMPLETE_FETCH]: FormAutocompleteFetch,
  [FORM_FIELD_TYPE.CHECKBOX]: FormCheckbox,
  [FORM_FIELD_TYPE.GENERIC_CONTROL]: FormGenericControl,
  [FORM_FIELD_TYPE.INPUT]: FormInput,
  [FORM_FIELD_TYPE.RADIO_GROUP]: FormRadioGroup,
  [FORM_FIELD_TYPE.RADIO_GROUP_CUSTOM]: FormRadioGroupCustom,
  [FORM_FIELD_TYPE.RECAPTCHA]: FormRecaptchaInput,
  [FORM_FIELD_TYPE.SELECT]: FormSelect,
  [FORM_FIELD_TYPE.UPLOAD_FILE]: FormUploadFile,
  [FORM_FIELD_TYPE.CHECKBOX_DIALOG]: FormDialogCheckbox,
  [FORM_FIELD_TYPE.CUSTOMIZED]: CustomRender,
};
