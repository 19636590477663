import ReactPixel, { AddToCart, InitiateCheckout, Options, Purchase, ViewContent } from 'react-facebook-pixel';
import { FACEBOOK_PIXEL_ID } from '../../config';
import { EventParams, FacebookTrackEvent } from './interfaces';

export type PurchaseEventParams = Purchase | EventParams;
export type CheckOutEventParams = InitiateCheckout  | EventParams;
export type AddToCartEventParams = AddToCart  | EventParams;
export type ViewContentEventParams = ViewContent | EventParams; 

const options: Options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class FacebookPixelNav {
  initialize() {
    ReactPixel.init(FACEBOOK_PIXEL_ID, undefined, options);
  }

  track(event: string, params: EventParams) {
    const owner = localStorage.getItem('owner') || '';
    ReactPixel.fbq('trackCustom', event, {...params}, {external_id: owner});
  }

  purchaseTrack(params: PurchaseEventParams) {
    const owner = localStorage.getItem('owner') || '';
    ReactPixel.fbq('track', FacebookTrackEvent.PURCHASE_APPROVED, {...params}, {external_id: owner});
  }

  checkOutTrack(params: CheckOutEventParams) {
    const owner = localStorage.getItem('owner') || '';
    ReactPixel.fbq('track', FacebookTrackEvent.START_CHECKOUT, {...params}, {external_id: owner});
  }

  addToCartTrack(params: AddToCartEventParams) {
    const owner = localStorage.getItem('owner') || '';
    ReactPixel.fbq('track', FacebookTrackEvent.ADD_TO_CART, {...params}, {external_id: owner});
  }

  viewContent(params: ViewContentEventParams) {
    const owner = localStorage.getItem('owner') || '';
    ReactPixel.fbq('track', FacebookTrackEvent.VIEW_CONTENT, {...params}, {external_id: owner});
  }
}

const i: FacebookPixelNav = new FacebookPixelNav();
export { i as FacebookPixelNav };