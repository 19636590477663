export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const GOOGLE_OAUTH_API_KEY = process.env.REACT_APP_GOOGLE_OAUTH_API_KEY as string;
export const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string;
export const REACT_APP_GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY as string;
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL as string;
export const VIDEO_BASE_URL = process.env.REACT_APP_VIDEO_BASE_URL as string;
export const DECIDIR_PUBLIC_API_KEY = (process.env.REACT_APP_DECIDIR_PUBLIC_API_KEY as string) || 'cf1bbc8b2fef45ae900f1b1a637da556';
export const DECIDIR_PUBLIC_API_URL = (process.env.REACT_APP_DECIDIR_PUBLIC_API_URL as string) || 'https://developers.decidir.com/api/v2';
export const DECIDIR_ORG_ID = (process.env.REACT_APP_DECIDIR_ORG_ID as string) || '1snn5n9w';
export const DECIDIR_MERCHANT_ID = (process.env.REACT_APP_DECIDIR_MERCHANT_ID as string) || 'decidir_agregador';
export const DECIDIR_METRIX_URL = (process.env.REACT_APP_DECIDIR_METRIX_URL as string) || 'https://h.online-metrix.net/fp/tags.js';
export const SHOW_PRODUCTS_WITH_OUT_STOCK = (process.env.REACT_APP_SHOW_PRODUCTS_WITH_OUT_STOCK as string) === 'true';
export const APP_ECOMMERCE = (process.env.REACT_APP_ECOMMERCE as string) || 'enova';
export const LOGIN_ENABLED = (process.env.REACT_APP_LOGIN_ENABLED as string) === 'true';
export const FACEBOOK_REDIRECT_URI = process.env.REACT_APP_FACEBOOK_REDIRECT_URI as string;
export const FACEBOOK_OAUTH_APP_ID = process.env.REACT_APP_FACEBOOK_OAUTH_APP_ID as string;
export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string;
export const FACEBOOK_LOGIN_ENABLED = (process.env.REACT_APP_FACEBOOK_LOGIN_ENABLED as string) === 'true';
export const GOOGLE_LOGIN_ENABLED = (process.env.REACT_APP_GOOGLE_LOGIN_ENABLED as string) === 'true';
export const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID as string;
// DEV
// export const GOOGLE_OAUTH_API_KEY = 'AIzaSyAYIlh8ZTpi3tRsOhDzzbO6KrmHhKMa2k8';
// export const GOOGLE_OAUTH_CLIENT_ID =
//  '196502985379-obtkk9v4r4994v479hnha8obslds2c84.apps.googleusercontent.com';
// export const GOOGLE_MAP_API_KEY = 'AIzaSyDszJRRSlfzenwffr9lVpagB-SA_q6ma6w';
