import {
    Box,
    Button,
    Grid,
    Typography
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'src/commons';
import { ListMenu } from 'src/commons/ui/view/GenericMenu';
import theme from 'src/theme';

function TabMenuItem({ item, onOpenCategories, contents, closeMenu, bottom }: {
    item: ListMenu,
    onOpenCategories: (item: ListMenu) => void,
    contents: ListMenu[],
    closeMenu: () => void,
    bottom: DOMRect | undefined
}) {
    const [openMenu, setOpenMenu] = useState(false);
    const { t } = useTranslation();
    const subCategoriesRef = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState<any>({ top: '0px;' })

    const subCategories = useMemo(() =>
        contents.filter((itemC) => itemC.name.includes('.') && itemC.name.split('.')[0] === item.name)
            .map((itemC) => ({ ...itemC, name: itemC.name.split('.')[1] })),
        [contents, item]
    );


    useEffect(() => {
        const getPositions = () => {
            if (bottom && subCategoriesRef.current) {
                const subCategoriesBottom = subCategoriesRef.current.getBoundingClientRect().bottom;
                setPosition(bottom.bottom < subCategoriesBottom ? { bottom: '0px' } : { top: '0px' });
            }
        };

        getPositions();
        window.addEventListener('resize', getPositions);

        return () => {
            window.removeEventListener('resize', getPositions);
        };
    }, [bottom, openMenu]);

    return (
        <Box
            onMouseOver={() => setOpenMenu(true)}
            onMouseLeave={() => setOpenMenu(false)}
            position='relative'
        >
            <Button
                style={{ textTransform: 'none', padding: '9px', width: '100%', position: 'relative', justifyContent: 'left', paddingLeft: '20px', paddingRight: '20px' }}
                onClick={() => {
                    onOpenCategories(item);
                    closeMenu();
                }}
            >
                <Typography style={{ fontSize: '14px' }}>{item.name}</Typography>
                {!!subCategories.length && <ChevronRight color='primary' style={{ position: 'absolute', right: '10px' }} />}
            </Button>

            {
                !!subCategories.length &&
                <div
                    ref={subCategoriesRef}
                    onMouseLeave={() => setOpenMenu(false)}
                    style={{
                        display: openMenu ? 'flex' : 'none',
                        flexDirection: 'column',
                        position: 'absolute',
                        width: '700px',
                        backgroundColor: 'white',
                        left: '300px',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        borderTopRightRadius: '5px',
                        borderBottomRightRadius: '5px',
                        ...position
                    }}
                >
                    <Box display='flex' height={40} justifyContent='center' alignItems='center' boxShadow='rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'>
                        <Box width='50%' textAlign='center' borderRight={`2px solid ${theme.palette.primary.main}`} color='primary' fontSize='14px'>
                            {item.name}
                        </Box>

                        <Button
                            style={{ width: '50%', textTransform: 'none', fontSize: '14px' }}
                            color='primary'
                            onClick={() => {
                                setOpenMenu(false);
                                onOpenCategories(item);
                                closeMenu();
                            }}
                        >
                            {t('See all')}
                        </Button>
                    </Box>

                    <Box marginTop="10px">
                        <Grid container spacing={2} style={{ padding: '10px' }}>
                            {subCategories.map((itemSC) => (
                                <Grid item xs={3} key={itemSC.name}>
                                    <Button
                                        onClick={() => {
                                            setOpenMenu(false);
                                            onOpenCategories(itemSC);
                                            closeMenu();
                                        }}
                                        style={{ textTransform: 'none', width: '100%', textAlign: 'start', justifyContent: 'flex-start', color: theme.palette.text.secondary }}
                                    >
                                        {itemSC.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </div>
            }
        </Box>
    );
}

export function TabMenu({ contents, onOpenCategories, closeMenu }: {
    contents: ListMenu[],
    onOpenCategories: (item: ListMenu) => void,
    closeMenu: () => void
}) {
    const { t } = useTranslation();
    const mainCategories = contents.filter((item) => !item.name.includes('.'));
    const history = useHistory();
    const finalComponentRef = useRef<HTMLButtonElement | null>(null);

    return (
        <Box bgcolor='white' display='flex' flexDirection='column' boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' width='300px'>
            {
                mainCategories.map((item) => (
                    <TabMenuItem
                        key={item.name}
                        item={item}
                        onOpenCategories={onOpenCategories}
                        contents={contents}
                        closeMenu={closeMenu}
                        bottom={finalComponentRef?.current?.getBoundingClientRect()}
                    />
                ))
            }
            <Button
                ref={finalComponentRef}
                style={{ textTransform: 'none', paddingLeft: '20px', paddingRight: '20px', width: '100%', justifyContent: 'left' }}
                onClick={() => {
                    history.push('/search');
                }}
            >
                <Typography color='primary' style={{ fontSize: '14px' }}>{t('See all')}</Typography>
            </Button>
        </Box>
    );
}