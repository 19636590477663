import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, Theme, useMediaQuery } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router';
import { orderBy } from 'lodash';
import { AppBreadcrumbsApp, InfoModalData, ProductDetailsLandingView, ProductDetailsView } from 'src/app/views';
import { usePublication } from 'src/app/business/Publication';
import { LinksBreadcrumbs, Loading, useBreadcrumbs, useBreadcrumbsPromotions, useFavoriteManage } from 'src/commons';
import { usePublicationByStock } from 'src/app/business/PublicationByStock';
import { useScrollToTopOnMount } from 'src/commons/hooks/useScrollToTopUnMount';
import { useCartContext, useGeneralContext } from 'src/context';
import { getProductImage, searchSkuPublication, validateFreeShipping, searchFeature, getDiscountPublication } from 'src/utils';
import { Feature, FeatureGroup, Publication, ConfigValuesPublication, ConfigValuesImages } from 'src/app/models';
import { useBreadcrumbsCategory } from 'src/commons/hooks/useBreadcrumbsCategory';
import { PUBLICATION_TYPE } from 'src/app/const';
import { PaymentMethod } from 'src/app/views/components/PaymentMethod';
import { useInfoPublicationPayment } from 'src/commons/hooks/useInfoPublicationPayment';
import { getPricePublication } from 'src/context/CartContext/utils';
import { PoliticsText } from '../Politicas/components';
import { GuaranteesText } from '../guarantees';
import { Analytics } from '../../../platform/analytics';
import { CalculateShipment } from '../CalculateShipments';
import { ShipmentData } from '../CalculateShipments/types';
import { initialValue } from '../CalculateShipments/initialValues';
import { usePaymentInfoShow } from './hooks/paymentInfoShow';

export const Product = () => {
  useScrollToTopOnMount();
  const { publicationId } = useParams<{ publicationId: string }>();
  const [isTrack, setIsTrack] = useState(false);
  const { search: adsQuery } = useLocation();
  const { data: publication, working, recall } = usePublication(publicationId);

  useEffect(()=>{
    recall(publicationId)
  },[publicationId, recall])
    
  const dataPublication = useMemo(() => {
    if (publication?.[0]) {
      const publicationData: Publication = { ...publication?.[0] };
      return publicationData;
    }
    return undefined;
  }, [publication]);

  const { data: stock, working: workingStock } = usePublicationByStock(publicationId);
  // TODO : esta es la publicacion que tiene configurado la imagenes de los metodos de pago
  const { data: images = [] } = usePublication('1e574739-8813-4f0d-a7bf-ae7ee7b30fa6');

  const paymentImages = useMemo<ConfigValuesImages>(() => {
    const imgs = images
      .filter((publication) => publication.type === PUBLICATION_TYPE.IMAGES)
      .map<ConfigValuesImages>((publication) => publication.config_values as ConfigValuesImages)[0] || { images: [] };
    const hasBapro = publication?.[0].targets.find((target) => target === 'BAPRO');
    const newImages = hasBapro ? imgs.images : imgs.images.filter((img) => img.card_emiter_id !== 5);
    return { ...imgs, images: [...newImages.filter((img) => img.enable)] };
  }, [images, publication]);

  const history = useHistory<any>();

  const [openMeansPayment, setOpenMeansPayment] = useState(false);

  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));

  const { generalState, dispatch: generalDispatch } = useGeneralContext();

  const [shipmentData, setShipmentData] = useState<ShipmentData>({ deliveryDate: '', ...initialValue });

  useEffect(() => {
    if (generalState.paginate.path === '/product') return;
    generalDispatch.setPaginate({ ...generalState.paginate, path: '/product' });
  }, [generalDispatch, generalState.paginate, history.location.pathname]);

  const links = useMemo(() => {
    if (publication?.[0]) {
      const linksProduct: LinksBreadcrumbs[] = [{ label: publication?.[0].product }];
      return linksProduct;
    }
    return [];
  }, [publication]);

  const nameCategory = useMemo(() => {
    if (publication?.[0]?.attributes?.features) {
      const featureCategory: FeatureGroup | undefined = searchFeature({
        name: 'Categories',
        featuresAll: publication?.[0]?.attributes.features,
      });

      return featureCategory?.features?.[0]?.name;
    }
    return '';
  }, [publication]);

  const newLinksCategory = useBreadcrumbsCategory({ name: nameCategory });

  const newLinksPromo = useBreadcrumbsPromotions();

  const newLinks = useBreadcrumbs({
    links,
    oldLinks: history.location.state?.prevPath ? newLinksPromo : newLinksCategory,
  });

  const { cartState, dispatch } = useCartContext();

  const productIdInCart = cartState.items.find((item) => item.publication.id === publicationId);

  const [quantity, setQuantity] = useState(productIdInCart?.quantity || 1);
  // here should go the logic of buying
  const onClickUpdateCart = useCallback(() => {
    if (productIdInCart) {
      dispatch.editQuantity({ id: publicationId, newQuantity: quantity + 1 });
    } else {
      dispatch.addItem({ publication: publication?.[0], quantity, stock: stock?.quantity || 0 });
    }
    dispatch.updateCartTotals();
    // Tracking event
    if (publication) {
      Analytics.trackAddToCart({ publication: publication[0], quantity });
    }
  }, [publication, dispatch, productIdInCart, publicationId, quantity, stock]);

  const onBuyClick = () => {
    onClickUpdateCart();
    history.push({
      pathname: `/cart/checkout/${adsQuery}`,
      state: {
        goBack: newLinks,
      },
    });
  };

  const onAddToCartClick = () => {
    onClickUpdateCart();
    history.push({
      pathname: `/cart/${adsQuery}`,
      state: {
        goBack: newLinks,
      },
    });
  };

  const imagesCarousel = useMemo(() => {
    const images = publication?.[0].attributes?.images;
    const sku = searchSkuPublication({
      featuresPublication: publication?.[0].attributes.features,
    });
    if (images && sku) {
      return images.map((image) => ({
        img_url: getProductImage({
          sku,
          typeImage: image,
        }),
        alt: image,
      }));
    }
    return publication?.[0].attributes.image_url
      ? [
          {
            img_url: publication?.[0].attributes.image_url,
            alt: publicationId,
          },
        ]
      : [];
  }, [publication, publicationId]);

  const [idInfoModalShow, setIdInfoModalShow] = useState('payment');

  const onClickOpenModal = useCallback(
    (id: string) => {
      setIdInfoModalShow(id);
      setOpenMeansPayment(!openMeansPayment);
    },
    [openMeansPayment],
  );

  const { mainPrice, mainAmount } = useInfoPublicationPayment(publication?.[0]);

  const {
    cards,
    provinces,
    cardOptionsSelected,
    validEmitters,
    selectedCard,
    selectedEmitter,
    selectedProvince,
    setSelectedCard,
    setSelectedProvince,
    setSelectedEmitter,
    bankOptions,
  } = usePaymentInfoShow(quantity, publication?.[0] as Publication);

  const onChanteProvince = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedProvince((old) => ({ ...old, id: event.target.value }));
    },
    [setSelectedProvince],
  );

  const onChanteCard = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setSelectedCard((old) => ({ ...old, card_id: value }));
    },
    [setSelectedCard],
  );
  const onChanteEmiter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setSelectedEmitter((old) => ({ ...old, card_emiter_id: Number(value) }));
    },
    [setSelectedEmitter],
  );

  const infoModalShow = useCallback(
    (id: string): InfoModalData | undefined => {
      const modalsData: InfoModalData[] = [
        {
          id: 'payment',
          name: 'Otras Promociones',
          render: (
            <PaymentMethod
              publicConfig={publication?.[0]?.config_values as ConfigValuesPublication}
              cards={cards}
              selectedCard={selectedCard}
              provinces={orderBy(provinces, 'name')}
              selectedProvince={selectedProvince}
              emitters={validEmitters}
              selectedEmitter={selectedEmitter}
              cardOptionsSelected={cardOptionsSelected}
              handleChangeCard={onChanteCard}
              handleChangeEmitter={onChanteEmiter}
              HandleChangeStateId={onChanteProvince}
              publication={publication}
              cartState={cartState}
              quantity={quantity}
            />
          ),
        },
        {
          id: 'politics',
          name: 'Política de devolución',
          render: <PoliticsText />,
        },
        {
          id: 'guarantees',
          name: 'Garantía',
          render: <GuaranteesText />,
        },
        {
          id: 'calculateShipment',
          name: 'Datos para envío',
          render: (
            <CalculateShipment publication={publication?.[0]} quantity={quantity} shipmentData={shipmentData} setShipmentData={setShipmentData} />
          ),
        },
      ];
      return modalsData.find((modalData) => modalData.id === id);
    },
    [
      publication,
      cards,
      selectedCard,
      provinces,
      selectedProvince,
      validEmitters,
      selectedEmitter,
      cardOptionsSelected,
      onChanteCard,
      onChanteEmiter,
      onChanteProvince,
      quantity,
      shipmentData,
      cartState,
    ],
  );

  const enableStock = useMemo(() => !!(stock && stock.quantity > 0), [stock]);

  const availableQuantity = useMemo(() => (stock && stock?.quantity > 0 ? stock?.quantity - quantity : 0), [quantity, stock]);

  const discount = useMemo(
    () => (publication?.[0] ? getDiscountPublication(mainPrice[mainAmount].toString(), publication?.[0].old_price) : 0),
    [publication, mainPrice, mainAmount],
  );

  useEffect(() => {
    if (publication?.[0] && !isTrack) {
      setIsTrack(true);
      Analytics.trackVisitPublication({ publication: publication[0] });
    }
  }, [isTrack, publication]);

  const landingFeatures: Feature[] = [];

  publication?.[0]?.attributes?.features?.forEach((group: FeatureGroup) => {
    if (group.name === 'Landing') {
      group.features?.forEach((feature: Feature) => landingFeatures?.push(feature));
    }
  });

  const { favorites, addToFavorite, removeFromFavorite } = useFavoriteManage();

  return (
    <>
      {dataPublication && (
        <Helmet>
          <title>{dataPublication.product}</title>
          <meta name="description" content={dataPublication.attributes.description} />
        </Helmet>
      )}
      <Container maxWidth="lg" disableGutters={isXs}>
        <Grid container>
          <Grid>
            <AppBreadcrumbsApp links={newLinks} msgReturn="Ver más" />
          </Grid>
          <Grid>
            {working ? (
              <Loading />
            ) : dataPublication ? (
              landingFeatures?.length ? (
                <ProductDetailsLandingView
                  product={dataPublication}
                  stock={stock}
                  workingStock={workingStock}
                  onBuyClick={onBuyClick}
                  onAddToCartClick={onAddToCartClick}
                  onChangeQuantity={setQuantity}
                  quantity={quantity}
                  freeShipping={validateFreeShipping(getPricePublication(dataPublication).toString()) && dataPublication.shipment_category_id !== 2}
                  imagesCarousel={imagesCarousel}
                  onClickMeansPayment={() => onClickOpenModal('payment')}
                  openMeansPayment={openMeansPayment}
                  infoModalShow={infoModalShow}
                  idInfoModalShow={idInfoModalShow}
                  onClickGuarantees={() => onClickOpenModal('guarantees')}
                  onClickPolitics={() => onClickOpenModal('politics')}
                  onHandleCloseModal={() => setOpenMeansPayment(false)}
                  enableStock={enableStock}
                  availableQuantity={availableQuantity}
                  discount={discount}
                  calculateShipment={() => onClickOpenModal('calculateShipment')}
                  shipmentData={shipmentData}
                  paymentImages={paymentImages}
                  isFavorite={!!favorites.find((fav) => fav.publication.id === dataPublication.id)}
                  onAddFavorite={() => addToFavorite(dataPublication.id)}
                  onRemoveFavorite={() => removeFromFavorite(dataPublication.id)}
                  bankOptions={bankOptions}
                />
              ) : (
                <ProductDetailsView
                  product={dataPublication}
                  stock={stock}
                  workingStock={workingStock}
                  onBuyClick={onBuyClick}
                  onAddToCartClick={onAddToCartClick}
                  onChangeQuantity={setQuantity}
                  quantity={quantity}
                  freeShipping={validateFreeShipping(getPricePublication(dataPublication).toString()) && dataPublication.shipment_category_id !== 2}
                  imagesCarousel={imagesCarousel}
                  onClickMeansPayment={() => onClickOpenModal('payment')}
                  openMeansPayment={openMeansPayment}
                  infoModalShow={infoModalShow}
                  idInfoModalShow={idInfoModalShow}
                  onClickGuarantees={() => onClickOpenModal('guarantees')}
                  onClickPolitics={() => onClickOpenModal('politics')}
                  onHandleCloseModal={() => setOpenMeansPayment(false)}
                  enableStock={enableStock}
                  availableQuantity={availableQuantity}
                  discount={discount}
                  calculateShipment={() => onClickOpenModal('calculateShipment')}
                  shipmentData={shipmentData}
                  paymentImages={paymentImages}
                  isFavorite={!!favorites.find((fav) => fav.publication.id === dataPublication.id)}
                  onAddFavorite={() => addToFavorite(dataPublication.id)}
                  onRemoveFavorite={() => removeFromFavorite(dataPublication.id)}
                  bankOptions={bankOptions}
                />
              )
            ) : (
              <div>No existe este producto.</div>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
