import { routes } from './routes';
import {MenuItem} from "../../../../../commons";

export const TermsAndConditionsQuantumMenu: MenuItem = {
  key: 'publicPrivacyPolicy',
  basePath: '/terminos-y-condiciones-quantum',
  enabled: true,
  icon: 'List',
  text: 'Terminos y condiciones de Quantum',
  children: routes,
  default: true,
};
