import { City, State, Emitter, CardType, PaymentModel, HOPInitialValues } from 'src/app/models';
import { HOP } from '../../../app/models';

export interface IPersonalInfo {
  name: string;
  lastName: string;
  document: string;
  dateOfBirty: string;
  phone: { areaCode: string; number: string };
  termsAndConditions: boolean;
  email: string;
  gender: string;
}

export interface ILogin {
  email: string;
  password: string;
}

export interface ICoupon {
  name: string;
  discount: number;
  response: string[];
  amountCoupon: number;
  checked: boolean;
}

export interface IEquifax {
  request: { id: number; selectedOptionId: number }[];
  validClient: boolean;
}

export interface IHOP {
  retirementPoints: HOP[];
  selectedPoint: HOP;
  approved: boolean;
  address: string;
}
export interface AuthorizeReceiver {
  authorize: boolean;
  name: string;
  lastname: string;
  dni: string;
}

export interface IUbicationInfo {
  id: string;
  state_id: number;
  zip: number;
  name: string;
  matches: string[];
}

// * Interface general del checkout valor inicial
export interface ICheckout {
  recaptcha: string;
  isResponsable: string;
  useDeliveryAddress: boolean;
  delivered: string;
  shipment_cost: number;
  coupon: ICoupon;
  ubicationInfo: IUbicationInfo;
}

export interface IDeliveryAddressInfo {
  buyer_address_id?: string;
  province: State;
  city: City;
  street: string;
  streetNumber: string;
  floor: string;
  apartament: string;
  zipCode: string;
  additionalInfo: string;
}

export interface ICardInfo {
  cardHolder: string;
  cardNumber: string;
  cardExpiration: string;
  cardSecurityCode: string;
}

export interface IPaymentInfo {
  emitter: Emitter;
  card: CardType;
  payment: PaymentModel;
}

export interface ITokenCardInfo {
  token: string;
  payment_method_id: number;
  bin: string;
  last_four_digits: string;
  expiration_month: string;
  expiration_year: string;
  card_holder: {
    identification: {
      type: string;
      number: string;
    };
    name: string;
    nro_puerta: number;
  };
  expired: boolean;
}

export interface IResponsableInfo {
  business: string;
  cuit: string;
}

export interface IApplicantInfo<T> extends Omit<IDeliveryAddressInfo, 'additionalInfo'> {
  cbu: string;
  img_dni_front: T;
  img_dni_back: T;
  img_paycheck: T;
  img_service_certificate: T;
}

export interface IGuarantorInfo<T> extends IApplicantInfo<T>, Omit<IPersonalInfo, 'termsAndConditions'> {}

export interface IDocentInfo<T> extends Omit<Omit<Omit<IApplicantInfo<T>, 'img_paycheck'>, 'img_service_certificate'>, 'cbu'> {
  docentType: { id: number; name: string };
  img_certificate: T;
  img_paycheck?: T;
}

export interface IAlumnInfo<T> extends Omit<Omit<Omit<IApplicantInfo<T>, 'img_paycheck'>, 'img_service_certificate'>, 'cbu'> {
  img_certificate: T;
}

export interface IPersonalCredit {
  applicantInfo?: IApplicantInfo<string>;
  guarantorInfo?: IGuarantorInfo<string>;
  docentInfo?: IDocentInfo<string>;
  alumnInfo?: IAlumnInfo<string>;
}
export interface ICheckoutAll extends ICheckout {
  deliveryInfo: IDeliveryAddressInfo;
  personalInfo: IPersonalInfo;
  paymentMethod: IPaymentInfo;
  tokenCardInfo: ITokenCardInfo;
  cardInfo: ICardInfo;
  responsableInscripto: IResponsableInfo;
  summaryAddress: IDeliveryAddressInfo;
  applicantInfo: IApplicantInfo<Partial<File> | undefined>;
  guarantorInfo: IGuarantorInfo<Partial<File> | undefined>;
  docentInfo: IDocentInfo<Partial<File> | undefined>;
  alumnInfo: IAlumnInfo<Partial<File> | undefined>;
  equifaxForm: IEquifax;
  retirementPoints: IHOP;
  authorizeReceiver: AuthorizeReceiver;
}

export const tokenCardInitialValue: ITokenCardInfo = {
  token: '',
  payment_method_id: 0,
  bin: '',
  last_four_digits: '',
  expiration_month: '',
  expiration_year: '',
  card_holder: {
    identification: {
      type: '',
      number: '',
    },
    name: '',
    nro_puerta: 0,
  },
  expired: false,
};

export const personalInfoInitialValue: IPersonalInfo = {
  name: '',
  lastName: '',
  document: '',
  dateOfBirty: '',
  phone: { areaCode: '', number: '' },
  termsAndConditions: false,
  email: '',
  gender: '',
};

export const equifaxInitialValue: IEquifax = {
  request: [],
  validClient: false,
};

export const retirementPointsInitialValues: IHOP = {
  retirementPoints: [HOPInitialValues],
  selectedPoint: HOPInitialValues,
  approved: false,
  address: '',
};

export const authorizeReceiverInitialValues: AuthorizeReceiver = {
  authorize: false,
  name: '',
  lastname: '',
  dni: '',
};

export const checkoutInitialValue: ICheckout = {
  recaptcha: '',
  isResponsable: 'false',
  useDeliveryAddress: true,
  delivered: 'delivered',
  shipment_cost: 0,
  coupon: { name: '', discount: 0, response: [], amountCoupon: 0, checked: false },
  ubicationInfo: { id: '', state_id: 0, zip: 0, name: '', matches: [] },
};

export enum TYPE_CARD {
  CREDITO = 'Credito',
  DEBITO = 'Debito',
}

export const deliveryInitialValue: IDeliveryAddressInfo = {
  buyer_address_id: '',
  province: { id: '', name: '', matches: [] },
  city: { id: '', zip: [0], name: '', matches: [], site_id: 0 },
  street: '',
  streetNumber: '',
  floor: '',
  apartament: '',
  zipCode: '',
  additionalInfo: '',
};

export const cardInfoInitialValue: ICardInfo = {
  cardHolder: '',
  cardNumber: '',
  cardExpiration: '',
  cardSecurityCode: '',
};

export const paymentInitialValue: IPaymentInfo = {
  emitter: {
    card_emiter_id: 0,
    name: '',
    image_url: '',
  },
  card: {
    card_id: '',
    name: '',
    emiter: '',
    type: '',
    card_image_url: '',
    emiter_image_url: '',
    match: [],
  },
  payment: {
    reference: '',
    type: '',
    installments: 0,
    original_amount: 0,
    amount: 0,
    metadata: {},
    coupon: { name: '', discount: 0, response: [], amountCoupon: 0, checked: false },
  },
};

export const responsableInitialValues: IResponsableInfo = {
  business: '',
  cuit: '',
};

export const applicantInitialValue: IApplicantInfo<Partial<File> | undefined> = {
  province: { id: '', name: '', matches: [] },
  city: { id: '', zip: [0], name: '', matches: [], site_id: 0 },
  street: '',
  streetNumber: '',
  floor: '',
  apartament: '',
  zipCode: '',
  cbu: '',
  img_dni_front: undefined,
  img_dni_back: undefined,
  img_paycheck: undefined,
  img_service_certificate: undefined,
};

export const guarantorInitialValue: IGuarantorInfo<Partial<File> | undefined> = {
  province: { id: '', name: '', matches: [] },
  city: { id: '', zip: [0], name: '', matches: [], site_id: 0 },
  street: '',
  streetNumber: '',
  floor: '',
  apartament: '',
  zipCode: '',
  name: '',
  lastName: '',
  document: '',
  dateOfBirty: '',
  phone: { areaCode: '', number: '' },
  email: '',
  gender: '',
  cbu: '',
  img_dni_front: undefined,
  img_dni_back: undefined,
  img_paycheck: undefined,
  img_service_certificate: undefined,
};

export const docentInitialValue: IDocentInfo<Partial<File> | undefined> = {
  docentType: { id: 0, name: '' },
  province: { id: '', name: '', matches: [] } || '0',
  city: { id: '', zip: [0], name: '', matches: [], site_id: 0 } || '0',
  street: '',
  streetNumber: '' || '0',
  floor: '',
  apartament: '',
  zipCode: '' || '0000',
  img_dni_front: undefined,
  img_dni_back: undefined,
  img_certificate: undefined,
  img_paycheck: undefined,
};

export const alumnInitialValue: IAlumnInfo<Partial<File> | undefined> = {
  province: { id: '', name: '', matches: [] },
  city: { id: '', zip: [0], name: '', matches: [], site_id: 0 },
  street: '',
  streetNumber: '',
  floor: '',
  apartament: '',
  zipCode: '',
  img_dni_front: undefined,
  img_dni_back: undefined,
  img_certificate: undefined,
};

export const loginInitialValue: ILogin = {
  email: '',
  password: '',
};

export const checkoutAllValues: ICheckoutAll = {
  ...checkoutInitialValue,
  deliveryInfo: deliveryInitialValue,
  personalInfo: personalInfoInitialValue,
  paymentMethod: paymentInitialValue,
  tokenCardInfo: tokenCardInitialValue,
  cardInfo: cardInfoInitialValue,
  responsableInscripto: responsableInitialValues,
  summaryAddress: deliveryInitialValue,
  applicantInfo: applicantInitialValue,
  guarantorInfo: guarantorInitialValue,
  docentInfo: docentInitialValue,
  alumnInfo: alumnInitialValue,
  equifaxForm: equifaxInitialValue,
  retirementPoints: retirementPointsInitialValues,
  authorizeReceiver: authorizeReceiverInitialValues,
};
