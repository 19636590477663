import { useCallback } from 'react';
import { AnyObject } from '../../../commons';
import { useCartContext } from '../../../context';
import { Analytics } from '../../../platform/analytics';
import { IPaymentInfo, IPersonalInfo } from '../../../screens/Common/Checkout/initialValues';

export interface AnalyticsHookProps {
  activeStep: number;
  filterSteps: Array<AnyObject>;
  userData: IPersonalInfo;
  paymentMethod: IPaymentInfo;
  deliveryType: string;
}

const useAnalytics = () => {

  const { cartState } = useCartContext();

  const analitycsCheckout = useCallback(({activeStep, filterSteps, userData, paymentMethod, deliveryType}: AnalyticsHookProps)=>{
    switch(filterSteps[activeStep].id){
      case 1:
        Analytics.trackPersonalInfo({cart: cartState, userData});
        break;
      case 2:
        Analytics.trackEquifaxVerify({cart: cartState, userData});
        break;
      case 3:
        Analytics.trackDeliveryInfo({cart: cartState, userData, deliveryType});
        break;
      case 4:
        Analytics.trackPaymentMethodInfo({cart: cartState, userData, paymentMethod});
        break;
      case 5:
        Analytics.trackApplicantInfo({cart: cartState, userData});
        break;
      case 6:
        Analytics.trackGuarantorInfo({cart: cartState, userData});
        break;
      default:
        break;
    }
  }, [cartState])

  return analitycsCheckout

};

export default useAnalytics;
