export interface DeliveryTypes {
  [k: string]: string;
}

export const deliveryType: DeliveryTypes = {
  delivered: 'Envio a domicilio',
  store_pickup: 'Retiro en casa central',
  HOP: 'Puntos de retiro',
};

export const HOPInitialValues = {
  shipment_hop_options_id: '',
  andreani_id: 0,
  code: '',
  number: '',
  description: '',
  canal: '',
  coordinates: { latitud: '', longitud: '' },
  service_hours: '',
  additional_information: {
    seHaceAtencionAlCliente: false,
    tipo: '',
    admiteEnvios: false,
    entregaEnvios: false,
    conBuzonInteligente: false,
  },
  phone_numbers: [],
  accepted_zip: [],
  active: false,
  shipment_hop_direction: { shipment_hop_direction_id: '', street: '', number: '', province: '', city: '', region: '', country: '', zip: '' },
  straightLine: 0,
  googleDistance: { text: '', value: 0 },
};

export enum DELIVERY_TYPE {
  SHIPMENT = '9ec56a4d-193a-4ea4-958e-d868af3ad3a0',
  BRANCH_PICKUP = '80acc218-de06-4552-84b8-44c44b96c744',
  HOP = '2373070a-7ae8-4f44-a8ce-e8291444d291',
}
