import { TransformFunction } from '../types';
import { IDeliveryAddressInfo, IPersonalInfo } from '../initialValues';
import { BuyerAddressInfo, Buyer } from '../../../../app/models';

export const transformerDeliveryInfo: TransformFunction<IDeliveryAddressInfo, BuyerAddressInfo> = (value) => ({
  city: { ...value.city, id: value.city.id.toString() },
  province: { ...value.state, id: value.state.id.toString() },
  buyer_address_id: value.buyer_address_id,
  street: value.street,
  streetNumber: value.number.toString(),
  floor: value.floor,
  apartament: value.department,
  zipCode: value.zip,
  additionalInfo: value.comments,
});

export const transformerPersonalInfo: TransformFunction<IPersonalInfo, Buyer> = (value) => ({
  name: value.name,
  lastName: value.last_name,
  document: value.document_number,
  dateOfBirty: value.date_of_birth,
  phone: { areaCode: value.phone?.split(' ')[0], number: value.phone?.split(' ')[1] },
  termsAndConditions: true,
  email: value.email,
});
