import {MenuItem} from "../../../../../commons";
import {TermsAndConditionsQuantum} from "../../../../../screens/Public/TermsAndConditionsQuantum";

export const routes: MenuItem[] = [
  {
    key: 'publicTermsAndConditionsQuantumMain',
    basePath: '/',
    target: {
      component: TermsAndConditionsQuantum,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Terminos y condiciones de Quantum',
    children: [],
    default: true,
  },
];
