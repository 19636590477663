export const blankSpaces = (name: string) => {
  let result = name;
  const fixName = name.split(' ').join('.');
  const lastCharAt = fixName.charAt(fixName.length - 1);
  if (lastCharAt === '.') {
    const res = fixName.slice(0, -1);
    const wad = res.split('.').join(' ');
    blankSpaces(wad);
  } else {
    result = fixName.split('.').join(' ');
  }
  return result;
};
