import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();
  const id = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) savedCallback.current();
    };
    if (delay !== null) {
      id.current = setInterval(tick, delay);
      return () => clearInterval(id.current);
    }
  }, [delay]);

  const resetInterval = () => {
    clearInterval(id.current)
    const tick = () => { 
      if (savedCallback.current) savedCallback.current();
    };
    id.current = setInterval(tick, delay);
  }

  return resetInterval;
};
