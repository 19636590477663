import { MenuItem } from '../../../../commons/ui/menu';
import { Faqs } from '../../../../screens/Public/Faqs';

export const routes: MenuItem[] = [
  {
    key: 'privateFaqsMain',
    basePath: '/',
    target: {
      component: Faqs,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Preguntas frecuentes',
    children: [],
    default: true,
  },
];
