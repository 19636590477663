import { Buyer } from '../../../app/models';
import { EcommerceDAO } from './EcommerceDAO';

export class BuyerDAO extends EcommerceDAO<Buyer> {
  constructor() {
    super('buyer', 'buyer_id');
  }

  async getBuyerInfo() {
    const data = await this.fetchData(`/buyer/@ALL@`);
    return data.json();
  }

  async save(data: Buyer) {
    await this.fetchData(`/buyer`, {
      method: data.buyer_id ? 'PUT' : 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(data),
    });
  }

  async fetchById(id: string) {
    const data = await this.fetchData(`/buyer/${id}`);
    return [await data.json()];
  }
}
