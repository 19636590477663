import { MenuCollection } from '../../../commons/ui/menu';
import { routes } from './routes';

export const publicMenu: MenuCollection = [
  {
    key: 'public',
    basePath: '/',
    enabled: true,
    icon: 'List',
    text: 'Public',
    children: routes,
  },
];
