import React, { ChangeEvent } from 'react';
import { useField } from 'react-final-form-hooks';
import { Radio, RadioGroup, FormControlLabel, Box, FormHelperText, FormLabel } from '@material-ui/core';
import { labelRadioGroupStyles as useStyles } from 'src/app/views/styles/labelRadioGroupStyles';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';

export interface FormRadioGroupProps {
  name: string;
  form: any;
  row?: boolean;
  options: Array<any>;
  formlabel?: string;
  valueKey?: string;
  labelKey?: string;
  marginLeft?: string | number;
  marginRight?: string | number;
  marginTop?: string | number;
  marginBottom?: string | number;
  loading?: boolean;
  customHelperText?: string;
  optionToValue?: (value: any, options: any) => any;
  valueToOption?: (value: any, options: any) => any;
}

export const FormRadioGroup = ({
  name,
  form,
  row,
  options,
  marginLeft,
  marginRight,
  marginTop,
  formlabel,
  valueKey = 'id',
  labelKey = 'description',
  customHelperText,
  optionToValue,
  valueToOption,
}: FormRadioGroupProps): JSX.Element => {
  const field = useField(name, form);
  const classes = useStyles();
  const { error, helperText } = getErrorAndHelperTextNoTranslation(field);

  return (
    <RadioGroup
      row={row}
      onFocus={field.input.onFocus}
      onBlur={field.input.onBlur}
      value={field.input.value ? (valueToOption ? valueToOption(field.input.value, options) : field.input.value) : ''}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        field.input.onChange(optionToValue ? optionToValue(newValue, options) : newValue);
      }}
    >
      {formlabel ? <FormLabel className={classes.radioGroupLabel}>{formlabel}</FormLabel> : <></>}
      {options?.map((item) => (
        <Box key={item[valueKey].toString()} marginRight={marginRight} marginLeft={marginLeft} marginTop={marginTop}>
          <FormControlLabel
            key={item[valueKey].toString()}
            value={item[valueKey].toString()}
            control={item?.control || <Radio id={`type-${item[valueKey]}`} color="primary" />}
            label={item[labelKey]}
          />
        </Box>
      ))}
      {error && <FormHelperText error={error}>{!customHelperText ? helperText : customHelperText}</FormHelperText>}
    </RadioGroup>
  );
};
