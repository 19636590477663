import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { HomeView } from 'src/app/views';
import { useScrollToTopOnMount } from 'src/commons/hooks/useScrollToTopUnMount';
import { useGeneralContext } from 'src/context';
import { sortOptions } from 'src/commons';

export const HomePublic = () => {
  useScrollToTopOnMount();
  const history = useHistory();

  const { generalState, dispatch: generalDispatch } = useGeneralContext();

  useEffect(() => {
    if (generalState.paginate.path === history.location.pathname) return;
    generalDispatch.setPaginate({ ...generalState.paginate, path: history.location.pathname });
    generalDispatch.sortOption(sortOptions[0]);
  }, [generalDispatch, generalState.paginate, history.location.pathname]);

  return (
      <HomeView />
  );
};
