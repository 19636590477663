import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, CardMedia } from '@material-ui/core';
import { useBreakpointsState } from 'src/app/hooks/useMobile';

interface Highlight {
  name: string;
  color: string;
  type?: string;
  position?: string;
  url?: string;
}

interface HighLightMultipleProps {
  highlights: Array<Highlight>;
  isAbsolute?: boolean;
  semiBorderRadius?: boolean;
  productId: string | number;
}

const useStyles = makeStyles(() => ({
  highlight: {
    marginBottom: '0px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const HighLightMultiple = ({ semiBorderRadius = true, highlights, isAbsolute = true, productId }: HighLightMultipleProps) => {
  const classes = useStyles();
  const { xs } = useBreakpointsState();

  const setBorderRadius = (highlight: Highlight) => {
    if (highlight.type !== 'image') {
      if (semiBorderRadius) {
        if (highlight.position === 'top/left') {
          return '5px 0px 5px 0px';
        }
        if (highlight.position === 'bottom/right') {
          return '5px 0px 0px 0px';
        }
        if (highlight.position === 'bottom/left') {
          return '0px 5px 0px 0px';
        }
        return '0px 5px 0px 5px';
      }
      return '5px';
    }
  };

  return (
    <>
      {highlights?.map((highlight) => (
        <Box
          position="absolute"
          top={(highlight.position?.split('/')[0] === 'top' || !highlight.position) && 0}
          left={highlight.position?.split('/')[1] === 'left' && 0}
          right={(highlight.position?.split('/')[1] === 'right' || !highlight.position) && 0}
          bottom={highlight.position?.split('/')[0] === 'bottom' && 0}
          key={highlight.name}
          borderRadius={setBorderRadius(highlight)}
          color={highlight.type !== 'image' && (highlight.color?.split('/')[1] || 'white')}
          bgcolor={highlight.type !== 'image' && (highlight.color?.split('/')[0] || 'success.main')}
          p={highlight.type !== 'image' && (xs ? '1px 2px 1px 2px' : '3px 12px 4px 12px')}
          marginLeft={highlight.type !== 'image' && (xs ? '0.8rem' : '0px')}
          width={isAbsolute ? 'auto' : 'fit-content'}
          maxHeight="60px"
          maxWidth="37%"
          paddingX={highlight.type !== 'image' ? 0.5 : 1}
          paddingY={0.5}
        >
          {highlight.type === 'image' ? (
            <CardMedia component="img" title={highlight.name} image={highlight.url} />
          ) : (
            <Typography className={isAbsolute ? '' : classes.highlight} gutterBottom variant="caption" id={`${productId.toString()}-highlight`}>
              {highlight.name}
            </Typography>
          )}
        </Box>
      ))}
    </>
  );
};
