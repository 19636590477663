import { PUBLICATION_TYPE } from 'src/app/const';
import { FeatureGroup, Publication } from 'src/app/models';
import { Product } from 'src/app/models/business/Product';
import { getProductImage } from './getProductImage';
import { searchSkuPublication } from './getSku';

export const normalizePublications = (publications: Publication[]) =>
  publications
    .map((pub: Publication) => ({
      ...pub,
      attributes: {
        ...pub.attributes,
        features:
          pub.attributes?.features?.map((group: FeatureGroup) => ({
            ...group,
            features: group.features || [],
          })) || [],
      },
    }))
    .filter((publication) => publication.type === PUBLICATION_TYPE.SALE)
    .map((item): Product => {
      const sku = searchSkuPublication({ featuresPublication: item.attributes?.features || [] }) || 'sku';
      return {
        productId: item.id,
        name: item.product,
        sku,
        price: item.price,
        old_price: item.old_price.toString(),
        images: item.attributes?.images?.map((image: string) => getProductImage({ sku, typeImage: image, width: 265 })) || [],
        description: '',
        priority: '',
        attributes: { features: item.attributes?.features || [] },
        best: item.best,
        highlights: item.highlights,
        shipment_category_id: item.shipment_category_id,
        targets: item.targets,
        config_values: item.config_values,
        has_stock: item.has_stock
      };
    });
