import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const registerMenu: MenuItem = {
  key: 'publicRegister',
  basePath: '/register',
  enabled: true,
  icon: 'List',
  text: 'Registro',
  children: routes,
};
