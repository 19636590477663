import { useMemo } from 'react';
import { useFormContext } from '../../../../../../../../lib/templates';
import { useGenerateAddressManagerValues } from './useGenerateAddressManageValues';
import { useBreakpointsState } from '../../../../../../../../app/hooks/useMobile';
import { IFieldComponent } from '../../../../FieldComponent';
import { State, City } from '../../../../../../../../app/models';
import { FORM_FIELD_TYPE } from '../../../../../hooks/useFieldForm';
import { IDeliveryManage } from '../types';

export const validateZipCode = async (value: any, allValues: any) => {
  if (value === '') {
    return 'Completa este dato.';
  }
  const regex = new RegExp('^[0-9]+$');
  if (!regex.test(value)) {
    return 'Solo podés ingresar números.';
  }
  if (value.length > 6) {
    return 'Código postal inválido.';
  }
  if (!allValues.city?.zip?.includes(Number(value))) {
    return 'El código postal no coincide.';
  }
};

export const useAddressManagerFields = () => {
  const { form, values } = useFormContext<IDeliveryManage>();
  const { useMakeUp } = useBreakpointsState();
  const { cities, provinces, loading, search, loadingProvinces, onInputChangeCity } = useGenerateAddressManagerValues();

  const isUp768 = useMakeUp(768);

  const selectedProvince = useMemo(() => values.province?.id !== '', [values.province?.id]);

  const data: IFieldComponent[] = useMemo(
    () => [
      {
        key: 'province',
        lg: 5,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.SELECT,
        componentProps: {
          form,
          loading: loadingProvinces,
          fullWidth: true,
          label: 'Provincia',
          name: 'province',
          content: provinces,
          valueKey: 'id',
          labelKey: 'name',
          helperText: 'El campo Provincia es requerido',
          inputLabelProps: { shrink: true },
          optionToValue: (value: string, options: State[]) => options?.find((province) => province.id === value),
          valueToOption: (value: State, options: State[]) => options.find((option: State) => option.id === value.id)?.id || search,
        },
      },
      {
        key: 'city',
        lg: 5,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.AUTOCOMPLETE_FETCH,
        componentProps: {
          disabled: !selectedProvince,
          name: 'city',
          form,
          label: 'Ciudad',
          options: cities,
          optionLabelKey: 'name',
          optionToValue: (option: City) => option,
          valueToOption: (value: City, options: City[]) =>
            search?.toLocaleLowerCase() === value?.name?.toLocaleLowerCase() && search?.length > 0 && options.length > 0
              ? options.find((option: City) => option.id === value.id)?.name
              : search,
          helperText: 'El campo Ciudad es requerido',
          getOptionSelected: (option: City, value: City) => option.id === value?.id,
          onInputChange: onInputChangeCity,
          inputLabelProps: { shrink: true },
          loading,
          enabledOpenAutocomplete: search.length > 1,
        },
      },
      {
        key: 'zipCode',
        lg: 2,
        md: 4,
        sm: isUp768 ? 4 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `zipCode`,
          label: 'Cód. postal',
          required: true,
          id: 'InfoZipCode',
          autoComplete: 'postal-code',
          validator: validateZipCode,
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'street',
        lg: 6,
        md: 8,
        sm: isUp768 ? 8 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          variant: 'standard',
          name: `street`,
          label: 'Calle',
          required: true,
          id: 'InfoStreet',
          autoComplete: 'street-address',
          form,
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'streetNumber',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `streetNumber`,
          label: 'Altura',
          required: true,
          id: 'InfoStreetNumber',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'floor',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `floor`,
          label: 'Piso',
          required: true,
          id: 'InfoFloor',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'apartament',
        lg: 2,
        md: 2,
        xs: 4,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `apartament`,
          label: 'Dpto.',
          required: true,
          id: 'InfoApartment',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'additionalInfo',
        lg: 6,
        md: 6,
        sm: isUp768 ? 6 : 12,
        xs: 12,
        component: FORM_FIELD_TYPE.INPUT,
        componentProps: {
          form,
          variant: 'standard',
          name: `additionalInfo`,
          label: 'Información adicional',
          id: 'InfoAditionalInfo',
          inputLabelProps: { shrink: true },
        },
      },
      {
        key: 'is_selected',
        xs: 12,
        component: FORM_FIELD_TYPE.CHECKBOX,
        componentProps: {
          form,
          variant: 'standard',
          name: `is_selected`,
          label: 'Usar este domicilio como domicilio de entrega',
          id: 'is_selected',
          inputLabelProps: { shrink: true },
        },
      },
    ],
    [cities, form, loading, loadingProvinces, provinces, search, selectedProvince, isUp768, onInputChangeCity],
  );
  return { fields: data.filter((field) => !field.hidden) };
};
