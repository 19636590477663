import React from 'react';
import { RegisterView } from 'src/app/views';
import { useMultiStepFormContext } from 'src/lib/MultiStepForm';
import { useRegisterFormContext, useStepTemplateLogic } from '../hooks';
import { useLogin } from '../../Login/hooks';

export const RegisterForm = () => {
  const { steps, activeStep, completedSteps } = useMultiStepFormContext();
  useStepTemplateLogic(activeStep, completedSteps, steps);
  const { values } = useRegisterFormContext();
  const { singIn } = useLogin({ redirectOnSuccess: '/register/success', msgOnSuccess: 'Registro completado!' });

  return <RegisterView steps={steps} activeStep={activeStep} email={values.accountInfo.email} handlerSocialSingIn={singIn} />;
};
