import React, { useCallback, useState } from 'react';
import { Grid, Container, Box } from '@material-ui/core';
import { FormContextProvider } from 'src/lib/templates';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { StepFormRecord } from 'src/commons';
import { useAccountDAO } from 'src/app/business';
import { UpdatePassword } from 'src/app/models';
import { RecoveryForm } from './components';
import { recoveryInitialValue, changePasswordInitialValue, FormValues } from './initialValues';
import { recoveryTemplate, emailTemplate, changePasswordTemplate } from './templates';
import { checkCodeTemplate } from '../Register/Templates';
import { checkCodeInitialValue } from '../Register/initialValues';
import { EmailInfoForm } from './components/EmailInfoForm/EmailInfoForm';
import { ChangePasswordInfoForm } from './components/ChangePasswordInfoForm';
import { CheckCodeRecoveryForm } from './components/CheckCodeRecoveryForm';

export const steps: Array<StepFormRecord> = [
  {
    id: 1,
    title: 'Recuperar cuenta',
    subTitle:
      'Completa con tu correo y te enviaremos un código para que puedas ingresar a tu cuenta. Luego de ingresar te pediremos cambiar la contraseña',
    hidden: false,
    template: emailTemplate,
    initialValue: recoveryInitialValue,
    render: EmailInfoForm,
  },
  {
    id: 2,
    title: 'Recuperar cuenta',
    subTitle:
      'Te enviamos un correo a <b>$1</b> con un código de 6 dígitos para que puedas ingresar a tu cuenta. Si no lo encontrás, verificá en tu casilla de no deseados.',
    hidden: false,
    template: checkCodeTemplate,
    initialValue: checkCodeInitialValue,
    render: CheckCodeRecoveryForm,
  },
  {
    id: 3,
    title: 'Modificar contraseña',
    subTitle: 'Crea una contraseña nueva para poder ingresar.',
    hidden: false,
    template: changePasswordTemplate,
    initialValue: changePasswordInitialValue,
    render: ChangePasswordInfoForm,
  },
];

const maxStep = steps.length;

export const Recovery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set<number>());
  const accountDAO = useAccountDAO();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (activeStep === maxStep - 1) {
        try {
          const newPasword: UpdatePassword = {
            email: values.email,
            newPassword: values.changePasswordInfo.password,
          };
          await accountDAO.updateAccess(newPasword);
          enqueueSnackbar('Modificaste tu contraseña', { variant: 'success' });
          history.push('/login');
        } catch (error) {
          console.log(error);
          enqueueSnackbar('Ocurrio un error, intentalo mas tarde.', { variant: 'error' });
        }
      } else {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);
        setActiveStep((oldValue) => oldValue + 1);
      }
    },
    [completed, activeStep, history, accountDAO, enqueueSnackbar],
  );

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Box mt={4}>
          <FormContextProvider initialValues={recoveryInitialValue} onSubmit={onSubmit} template={recoveryTemplate}>
            <RecoveryForm activeStep={activeStep} steps={steps} completedSteps={completed} />
          </FormContextProvider>
        </Box>
      </Grid>
    </Container>
  );
};
