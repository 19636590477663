import { useMemo, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { City, State } from '../../../../../../app/models';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { useLocationLibbyCall, useLocationDAO } from '../../../../../../app/business';
import { useDebounce } from '../../../../../../commons';
import { PROVINCES } from '../../../../../../app/const';
import { checkoutAllValues, guarantorInitialValue } from '../../../initialValues';

export const useGenerateValues = () => {
  const { form, values } = useCheckoutFormContext();
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const locationDAO = useLocationDAO();
  const { data, working } = useLocationLibbyCall<State[]>({ methodName: 'getStates' });

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  const [search, setSearch] = useState(valuesForm.guarantorInfo.city?.name || '');
  const searchDebunce = useDebounce(search, 600);

  const selectedProvince = useMemo(() => valuesForm.guarantorInfo.province?.id !== '', [valuesForm.guarantorInfo.province?.id]);

  const provinces = useMemo(() => {
    const provincesFiltered: State[] = data?.filter((item) => item.id.toString() === PROVINCES.CATAMARCA.toString()) || [];
    return provincesFiltered;
  }, [data]);

  /*   useEffect(() => {
    if (valuesForm.useDeliveryAddress && search !== valuesForm..city.name) {
      setSearch(valuesForm.guarantorInfo.city.name);
    } else if (!valuesForm.useDeliveryAddress && search === valuesForm.guarantorInfo.city.name) {
      setSearch('');
    }
  }, [valuesForm.useDeliveryAddress, valuesForm.guarantorInfo.city.name, search]); */

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  useEffect(() => {
    if (
      (valuesForm.guarantorInfo?.city?.name?.length > 0 && valuesForm.guarantorInfo?.city?.name !== search) ||
      valuesForm.guarantorInfo?.city === null
    ) {
      const copyValuesForm = { ...values, guarantorInfo: valuesForm.guarantorInfo };
      copyValuesForm.guarantorInfo.city = guarantorInitialValue.city;
      form.reset(copyValuesForm);
    }
  }, [search, values, form, valuesForm]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== valuesForm.guarantorInfo.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(valuesForm.guarantorInfo.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, valuesForm.guarantorInfo.province, searchDebunce, valuesForm.guarantorInfo.city]);
  return { cities, search, provinces, loading, loadingProvinces: working, onInputChangeCity };
};
