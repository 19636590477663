import { routes } from './routes';
import {MenuItem} from "../../../../../commons";

export const TermsAndConditionsLotteryMenu: MenuItem = {
  key: 'publicPrivacyPolicy',
  basePath: '/terminos-y-condiciones-sorteo',
  enabled: true,
  icon: 'List',
  text: 'Terminos y condiciones de sorteo',
  children: routes,
  default: true,
};
