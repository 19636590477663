export const white = '#FFFFFF';
export const black = '#000000';

type Grey = {
  heading: string;
  textPrimary: string;
  textSecondary: string;
  dividers: string;
  background: string;
  normal: string;
  medium: string;
  dark: string;
  disabled: string;
};

export const grey: Grey = {
  heading: '#464646',
  textPrimary: '#595959',
  textSecondary: '#8C8C8C',
  dividers: '#E8E8E8',
  background: '#F5F5F5',
  normal: '#D9D9D9',
  medium: '#737373',
  dark: '#4A4A4A',
  disabled: '#BFBFBF',
};

type Primary = {
  white: string;
  whiteLight: string;
  black: string;
  yellow: string;
  lightBlue: string;
  success: string;
  error: string;
  warning: string;
  paleBlue: string;
  paleBlueStrong: string;
  primaryLight: string;
  primaryMedium: string;
  successLight: string;
  successMedium: string;
  warningLight: string;
  warningMedium: string;
  errorLight: string;
  errorMedium: string;
  disabled: string;
  headerGreenCard: string;
  violet: string;
  azul: string;
};

export const primary: Primary = {
  white: '#ffffff',
  whiteLight: '#f5f5f5',
  black: '#000000',
  yellow: '#FDD306',
  lightBlue: '#00A0D6',
  success: '#52C41A',
  error: '#F5222D',
  warning: '#FAAD14',
  paleBlue: '#ccf0f9',
  paleBlueStrong: '#0079F1',
  primaryLight: '#E6F7FF',
  primaryMedium: '#91D5FF',
  successLight: '#F6FFED',
  successMedium: '#B7EB8F',
  warningLight: '#FFFBE6',
  warningMedium: '#FFE58F',
  errorLight: '#FFF1F0',
  errorMedium: '#FFA39E',
  disabled: '#bdbdbd',
  headerGreenCard: '#7CB342',
  violet: '#9B59B6',
  azul: '#34485E',
};

type Secondary = {
  gradient: string;
  headerCard: string;
  toastError: string;
  toastDefault: string;
};

export const secondary: Secondary = {
  gradient: `linear-gradient(90deg, ${primary.yellow} 0%, rgba(253, 211, 6, 0.3) 69.27%, rgba(0, 179, 227, 0.2) 76.04%, ${primary.lightBlue} 100%)`,
  headerCard: '#A0DA84',
  toastError: '#FFCED1',
  toastDefault: '#EDFFE4',
};

type ContentCard = {
  historia: string;
  biologia: string;
  literatura: string;
  transversal: string;
  tecnologia: string;
};

export const contentCard: ContentCard = {
  historia: '#13C2C2',
  biologia: '#52C41A',
  literatura: '#FAAD14',
  transversal: '#9B59B6',
  tecnologia: '#34485E',
};
