import React from 'react';
import { Grid, Typography, Box, useMediaQuery } from '@material-ui/core';
import { Publication } from 'src/app/models';
import { AnyObject } from 'src/commons';
import { useHistory } from 'react-router';
import { IMAGE_BASE_URL, APP_ECOMMERCE } from 'src/config';
import { ProductVariantCardImg, ProductVariantIconColorPicker } from './components';

const ecommerce = APP_ECOMMERCE;

const variantTextStyle = {
  border: '1px',
  borderColor: '#b9b9b9',
  borderRadius: '10px',
  padding: '5px',
  backgroundColor: '#f5f5f5',
  borderStyle: 'dashed',
  cursor: 'pointer',
};
const variantTextStyleSelected = {
  border: '1.5px',
  borderColor: ecommerce === 'enova' ? '#894093' : '#223092',
  borderRadius: '10px',
  padding: '5px',
  backgroundColor: '#f5f5f5',
  borderStyle: 'solid',
};

interface ProductVariantsPanelProp {
  publication: Publication;
}

export default function ProductVariantsPanel({ publication }: ProductVariantsPanelProp) {
  const [selectedVariant, setSelectedVariant] = React.useState<AnyObject>(
    publication.variants.find((variant) => variant.publication_id === publication.id),
  );
  const [isColorIcons] = React.useState(true);
  const [selectedFilters, setSelectedFilters] = React.useState<AnyObject>({});
  const [lastSelectedFilter, setLastSelectedFilter] = React.useState<AnyObject>({});
  const history = useHistory();
  const isSm = useMediaQuery<any>((theme) => theme.breakpoints.down('sm'));
  const handleChangeVariant = React.useCallback(() => {
    // If no selected filters/variants it returns the the initial publication
    if (Object.keys(selectedFilters).length === 0) {
      return selectedVariant;
    }

    // This finds the variant for every filter
    const foundObject = publication.variants.find((obj) => Object.entries(selectedFilters).every(([key, value]) => obj.feature[key] === value));

    // If it can't find the variant because all the selections doesn't match with any then it will only try to find by the last selection
    if (!foundObject) {
      const lastFeature = Object.entries(lastSelectedFilter);
      const [lastKey] = lastFeature;
      setSelectedFilters(lastSelectedFilter);
      return publication.variants.find((obj) => obj.feature[lastKey[0]] === lastKey[1]);
    }

    return foundObject;
  }, [selectedFilters, lastSelectedFilter, publication.variants, selectedVariant]);
  React.useEffect(() => {
    setSelectedVariant(handleChangeVariant());
  }, [handleChangeVariant]);

  React.useEffect(() => {
    if (selectedVariant && selectedVariant.publication_id) {
      history.push(`/product/${selectedVariant.publication_id}`);
    }
  }, [selectedVariant, history]);

  const handleVariantFilterChange = (obj: AnyObject) => {
    const objectKey = Object.keys(obj)[0];
    setLastSelectedFilter({ [objectKey]: obj[objectKey] });
    setSelectedFilters((prevState) => { // eslint-disable-line arrow-body-style
      return { ...prevState, [objectKey]: obj[objectKey] };
    });
  };

  const uniqueVariants = React.useMemo(() => {
    const result: AnyObject = {};
    let finalResult;
    publication.variants.forEach((variant) => {
      const features = variant.feature;
      Object.keys(features).forEach((key) => {
        if (!result[key]) {
          result[key] = [];
        }
        if (!result[key].find((variant: AnyObject) => variant.name === features[key])) {
          if (key.toLowerCase() === 'color') {
            result.Color.push({ name: features[key], sku: variant.sku, image: variant.images[0] });
          } else {
            result[key].push({ name: features[key] });
          }
        }
      });
    });
    finalResult = result;
    const hasColor = !!result?.Color;
    if (hasColor) {
      const colorArray = result.Color;
      delete result.Color;
      finalResult = { Color: colorArray, ...result };
    }
    return finalResult;
  }, [publication.variants]);
  return (
    <Grid item>
      <Grid item sm={12}>
        {Object.keys(uniqueVariants).map((category) => (
          <Box key={category} my={1}>
            <Typography>{category}</Typography>
            <Box my={0.2} display="flex" style={{ gap: 5 }} flexDirection={isSm ? 'row' : ''} flexWrap="wrap">
              {uniqueVariants[category].map((variant: any) => {
                if (category.toLowerCase() === 'color') {
                  const imgUrl = `${IMAGE_BASE_URL}/${'w_auto'},f_auto/Products/${variant.sku}/${variant.image}`;
                  return (
                    // TODO: isColorIcons is always setted to true we should make it change according to the publication settings (not developed yet)
                    <Box>
                      {isColorIcons ? (
                        <ProductVariantIconColorPicker
                          colorName={variant.name}
                          isSelected={selectedVariant.feature[category] === variant.name}
                          onClick={() => handleVariantFilterChange({ [category]: variant.name })}
                        />
                      ) : (
                        <ProductVariantCardImg
                          image={imgUrl}
                          width="60px"
                          widthImg="50px"
                          nameId={variant.name}
                          onClick={() => handleVariantFilterChange({ [category]: variant.name })}
                          isSelected={selectedVariant.feature[category] === variant.name}
                        />
                      )}
                    </Box>
                  );
                }
                return (
                  <Typography
                    key={variant.name}
                    onClick={() => handleVariantFilterChange({ [category]: variant.name })}
                    style={selectedVariant.feature[category] === variant.name ? variantTextStyleSelected : variantTextStyle}
                  >
                    {variant.name}
                  </Typography>
                );
              })}
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
