import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useBankOptions } from './hook';
import { EmitterInstallments } from './interfaces';
import { ProductPaymentMethodsEmitters } from './components/ProductPaymentMethodsEmitters';

export const EmittersInstallmentsView = ({ data, bankOptions, alterPrice }: EmitterInstallments) => {
  const { finalArray } = useBankOptions({ data, bankOptions });
  return (
    <>
      <Box pb={2}>
        <Typography variant="body1">¡Nuestras promociones bancarias!</Typography>
      </Box>
      {finalArray.map((bank: any) => (
        <ProductPaymentMethodsEmitters
          bank={bank.options}
          alterPrice={alterPrice}
          installment={bank.installments}
          amount={bank.options[0].amount}
          interest={bank.options[0]?.interest || 0}
          data={data}
        />
      ))}
    </>
  );
};
