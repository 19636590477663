/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Button, Box, Grid, useMediaQuery, Theme, Typography, InputAdornment, IconButton, Fade, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';
import { FormRegret, errorEmailType, validBuyNumberType } from 'src/screens/Public/regret/types';
import InfoIcon from '@material-ui/icons/Info';
import { CommonFormInput } from '../CommonFormInput';
import { regretFoormStyle, CustomToolTip } from '../../styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface RegretFormViewProps {
  form: FormRegret;
  validBuyNumber: validBuyNumberType;
  errorEmail: errorEmailType;
  errorSave: boolean;
  btnDisable: boolean;
  loading: boolean;
  handleSubmit: (value?: React.SyntheticEvent<HTMLFormElement, Event>) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const RegretFormView = ({
  form,
  validBuyNumber,
  errorEmail,
  errorSave,
  btnDisable,
  loading,
  handleSubmit,
  open,
  setOpen,
}: RegretFormViewProps) => {
  const [openIconHelper, setOpenIconHelper] = useState(false);
  const history = useHistory();
  const classes = regretFoormStyle();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down(500));

  const onMouseEnterHandler = () => {
    setOpenIconHelper(true);
  };

  const onMouseLeaveHandler = () => {
    setOpenIconHelper(false);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box className={`${classes.containerBox} ${loading ? classes.containerLoading : ''}`}>
        <Grid>
          <Box display="flex" className={classes.containerInputs}>
            <CommonFormInput<FormRegret>
              className={classes.generalInput}
              name="firstName"
              form={form}
              variant="standard"
              label="Nombre"
              id="paymentCancelationName"
              disabled={loading}
            />
            <CommonFormInput<FormRegret>
              className={classes.generalInput}
              name="lastName"
              form={form}
              variant="standard"
              label="Apellido"
              id="paymentCancelationLastName"
              disabled={loading}
            />
          </Box>
          <Box display="flex" className={classes.containerInputs}>
            <CommonFormInput<FormRegret>
              className={classes.generalInput}
              name="DNI"
              form={form}
              variant="standard"
              label="DNI"
              id="paymentCancelationDNI"
              disabled={loading}
            />
            <CommonFormInput<FormRegret>
              className={classes.generalInput}
              name="phoneNumber"
              form={form}
              variant="standard"
              label="Telefono"
              id="paymentCancelationPhoneNumber"
              disabled={loading}
            />
          </Box>
          <Box display="flex" className={classes.containerInputs}>
            <CommonFormInput<FormRegret>
              className={classes.generalInput}
              name="email"
              form={form}
              variant="standard"
              label="Email"
              id="paymentCancelationEmail"
              error={errorEmail.err}
              helperText={errorEmail.msg}
              disabled={loading}
            />
            <CommonFormInput<FormRegret>
              className={classes.generalInput}
              name="emailConfirm"
              form={form}
              variant="standard"
              label="Confirmación email"
              id="paymentCancelationEmailConfirm"
              error={errorEmail.err}
              helperText={errorEmail.msg}
              disabled={loading}
            />
          </Box>
          <Box display="flex" className={classes.containerInputs}>
            <CommonFormInput<FormRegret>
              className={classes.generalInput}
              name="buyNumber"
              form={form}
              variant="standard"
              label="Nro. de compra"
              id="paymentCancelationbuyNumber"
              error={validBuyNumber.err}
              helperText={validBuyNumber.msg}
              disabled={loading}
              inputBaseProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CustomToolTip
                      placement={isDownSm ? (isDownXs ? 'top-end' : undefined) : 'top-start'}
                      title={
                        <>
                          <Typography>Porás encontrar tu número de compra en el correo que te enviamos con la confirmación de tu compra.</Typography>
                        </>
                      }
                    >
                      <IconButton onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
                        <InfoIcon color={openIconHelper ? 'primary' : undefined} />
                      </IconButton>
                    </CustomToolTip>
                  </InputAdornment>
                ),
              }}
            />
            <CommonFormInput<FormRegret>
              className={classes.generalInput}
              name="product"
              form={form}
              variant="standard"
              label="Producto"
              id="paymentCancelationProduct"
              disabled={!validBuyNumber.valid ? true : loading}
            />
          </Box>
          <Box mb={3}>
            <CommonFormInput<FormRegret>
              lg={12}
              md={12}
              xs={12}
              name="motive"
              form={form}
              variant="standard"
              label="Motivo"
              id="paymentCancelationMotive"
              disabled={!validBuyNumber.valid ? true : loading}
            />
          </Box>
          <Box display="flex" justifyContent="end">
            <Button onClick={() => history.push('/')} color="primary" disabled={loading}>
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={btnDisable ? true : loading}
              className={classes.btnEnviar}
            >
              Enviar
            </Button>
          </Box>
        </Grid>
        <Box className={classes.loading}>
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <CircularProgress color="primary" />
          </Fade>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isDownXs ? 'center' : 'right',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert className={errorSave ? undefined : classes.alert} onClose={handleClose} severity={errorSave ? 'error' : 'success'}>
            {errorSave ? 'Error al enviar la solicitud.' : 'La solicitud fue enviada correctamente.'}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};
