import React from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { useCheckoutFormContext } from '../../hooks/CheckoutFormContext';
import { useDeliveryPickupLogic } from '../../hooks/useDeliveryPickupLogic';
import { useFields } from './hooks/useFields';
import { FieldComponent } from '../FieldComponent';
import { useBreakpointsState } from '../../../../../app/hooks/useMobile';
import { Loading } from '../../../../../commons';

export const DeliveryInfoForm = () => {
  const { errors, handleSubmit } = useCheckoutFormContext();
  const { useMakeDown } = useBreakpointsState();
  const { fields, loading } = useFields();
  useDeliveryPickupLogic();

  const isDownSm = useMakeDown('sm');

  return (
    <Box paddingBottom={3} bgcolor="white" borderRadius={8} boxShadow={3} display="content">
      <Box padding={isDownSm ? 2 : 3}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Box mb={1} paddingBottom={1} borderColor="other.stroke" borderBottom={isDownSm ? '1px solid' : undefined}>
              <Typography variant="h5" color="textPrimary">
                Información de envío
              </Typography>
            </Box>
          </Grid>

          {loading ? <Loading /> : fields.map((field) => <FieldComponent {...field} />)}

          {isDownSm && (
            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={Object.keys(errors as any).length > 0}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Continuar
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
