import { EcommerceAsset } from '../../../app/models/general/ecommerceAsset';
import BgPromotional from '../../../assets/images/tramaEsquinero2.png';

export const assets: EcommerceAsset = {
  loadingSrc: 'https://res.cloudinary.com/phinx-lab/image/upload/v1620397067/Assets/loading.svg',
  timerBackgroundImage: {
    backgroundImage: BgPromotional,
    backgroundColor: `linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%),linear-gradient(94.59deg, #8A1BB9 0.53%, #D36CFF 101.04%)`,
  },
};
