import React from 'react';
import { Grid, Button } from '@material-ui/core';

export const Footer = ({ selected, onChangePayment, onNewPayment }: any) => (
  <Grid container justify="flex-end">
    <Grid item>
      {selected === '' ? (
        <Button style={{ textTransform: 'none' }} variant="text" color="primary" size="large" onClick={onNewPayment}>
          Otro medio de pago
        </Button>
      ) : (
        <Button style={{ textTransform: 'none' }} variant="text" color="primary" size="large" onClick={onChangePayment}>
          Cambiar tarjeta
        </Button>
      )}
    </Grid>
  </Grid>
);
