import React, { useMemo } from 'react';
import { useBuyerAddressInfoFetchById } from 'src/app/business';
import { BuyerAddressInfo } from 'src/app/models';
import { TransformFunction } from '../types';

type ChildrenValue<T> = {
  data: T | null;
  working: boolean;
  raw: BuyerAddressInfo | undefined;
};

export interface AddressesInfoEditValueProps<T> {
  idBuyerAddress: string;
  children: (value: ChildrenValue<T>) => JSX.Element;
  transformer: TransformFunction<T>;
}

// TODO: convertir en un componente de business makeEntityByIdTransformer
export function AddressesInfoEditValue<T>({ idBuyerAddress, children, transformer }: AddressesInfoEditValueProps<T>) {
  const { data, working } = useBuyerAddressInfoFetchById(idBuyerAddress);

  const transformed = useMemo<T | null>(() => {
    if (!data || working) {
      return null;
    }
    return transformer(data);
  }, [data, transformer, working]);

  return <>{children({ data: transformed, working, raw: data })}</>;
}
