import { useEffect } from 'react';
import { AnyObject } from 'src/commons';
import { useMultiStepFormContext } from 'src/lib/MultiStepForm';
import { useFormContext } from 'src/lib/templates';
import { EquifaxUser } from '../interfaces';

export const useIsOtherPerson = () => {
  const { values, form } = useFormContext<AnyObject>();
  const { setCompletedSteps, completedSteps } = useMultiStepFormContext();

  // This hook works with an if/if else in EquifaxQuestionary.tsx where i play with validClient property and isPerson

  useEffect(() => {
    if (values?.equifaxForm) {
      const { userToValidate } = values?.equifaxForm;

      // when user tries to change one of these values to cheat the equifax form it would reset the checkout and clean the form
      const validatingSameUser = (user: EquifaxUser): boolean => {
        const { name, lastName, document, gender } = user;

        return (
          name === values.personalInfo?.name &&
          lastName === values.personalInfo?.lastName &&
          document === values.personalInfo?.document &&
          gender === values.personalInfo?.gender
        );
      };

      if (completedSteps.size > 1 && !validatingSameUser(userToValidate)) {
        setCompletedSteps(new Set<number>().add(0));
        form.change('equifaxForm.isPerson', false);
        form.change('equifaxForm.request', []);
      }
    }
  }, [
    values.personalInfo?.name,
    values.personalInfo?.lastName,
    values.personalInfo?.document,
    values.personalInfo?.gender,
    completedSteps,
    setCompletedSteps,
    form,
    values?.equifaxForm,
  ]);
};
