import React from 'react';
import { Theme, useMediaQuery, Container, Box, Card, Grid, Typography, Button } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import palette from 'src/theme/palette';
import { Link } from 'react-router-dom';
import { useRolesContext } from 'src/context';
import { ecommerceConfig } from 'src/config/ecommerce';

export const RegisterSuccess = () => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const { userInfo } = useRolesContext();

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <Card elevation={2}>
          <Box borderTop={`12px solid ${palette.primary.main}`} borderRadius={8} py={3}>
            <Container maxWidth="md">
              <Grid container justify="center" alignContent="center">
                <Grid item xs={12} container justify="center">
                  <Box color="primary.main">
                    <CheckCircleOutline style={{ width: '50px', height: '50px' }} />
                  </Box>
                </Grid>

                <Grid item xs={9}>
                  <Box>
                    <Typography align="center" variant={isDownSm ? 'h6' : 'h5'}>
                      <Box color="text.primary">¡Bienvenido a</Box>
                    </Typography>
                    <Typography align="center" variant={isDownSm ? 'h6' : 'h5'}>
                      <Box color="text.primary">{`${ecommerceConfig.general.nameMp} ${userInfo.name}`}</Box>
                    </Typography>
                  </Box>
                  <Box mt={2} mb={3}>
                    <Typography align="center" variant="body1" color="textSecondary">
                      {`Ya creaste tu cuenta en Novastore, te recomendamos continuar la configuración de tu cuenta en la sección de `}
                      <Link to="/dashboard/profile" style={{ textDecoration: 'none' }}>
                        Mi cuenta
                      </Link>
                      .{` Sino también te invitamos a explorar todos nuestros productos.`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Link
                    to="/"
                    component={(props: any) => (
                      <Button {...props} variant="contained">
                        Continuar
                      </Button>
                    )}
                    color="primary"
                    style={{ textDecoration: 'none' }}
                  >
                    Continuar
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};
