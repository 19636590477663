// Material helpers
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { CommonColors, Palette } from '@material-ui/core/styles/createPalette';
import palette from './palette';
import overrides from './overrides';
import { ecommerceConfig } from '../config/ecommerce';

export interface AppCommonColors extends CommonColors {
  muted: string;
  neutral: string;
}

export interface AppPalette extends Palette {
  common: AppCommonColors;
}

export interface AppTheme extends Theme {
  palette: AppPalette;
}

const theme = createMuiTheme({
  palette,
  typography: ecommerceConfig.ecommerceTypography,
  overrides,
  breakpoints: {
    values: {
      xs: 321,
      sm: 376,
      md: 769, // mobile/tablet
      lg: 1281, // web
      xl: 1921,
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
}) as AppTheme;

export default theme;
