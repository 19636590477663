import { routes } from './routes';
import { MenuItem } from '../../../../../commons/ui/menu';

export const privacyPolicyMenu: MenuItem = {
  key: 'privatePrivacyPolicy',
  basePath: '/politicas-de-privacidad',
  enabled: true,
  icon: 'List',
  text: 'Políticas de Privacidad',
  children: routes,
  default: true,
};
