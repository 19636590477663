import { Box, Divider, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { HOP } from 'src/app';
import { GenericIcon } from 'src/commons/components/GenericIcon';
import { MapModal } from './MapModal';

interface MobileRowsProps {
  data: HOP[];
  row: HOP;
  values: any;
  savingHOPData: (row: any) => void;
  selectedId: number | undefined;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
  address: string;
}

export const MobileRows = ({ data, row, values, savingHOPData, selectedId, setSelectedId, address }: MobileRowsProps) => {
  const { street, number } = row.shipment_hop_direction;
  const center = { lat: +row.coordinates.latitud, lng: +row.coordinates.longitud };
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        width="100%"
        display="flex"
        margin="1em auto"
        padding="8px"
        borderRadius=".5rem"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          savingHOPData({ retirementPoints: data, selectedPoint: row, approved: true, address });
          setSelectedId(row.andreani_id);
        }}
        style={{
          background: (selectedId ? selectedId === row.andreani_id : values.retirementPoints?.selectedPoint?.andreani_id === row.andreani_id)
            ? '#F4DCFD'
            : '#fff',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography color="textPrimary">{row.description}</Typography>
          <Typography color="textSecondary">{`${street} ${number}`}</Typography>
          <Typography color="textSecondary">{row.service_hours}</Typography>
          <Box display="flex">
            <Typography color="textSecondary">Distancia:</Typography>
            <Typography color="primary">{`+${row.googleDistance?.text}`}</Typography>
          </Box>
        </Box>
        <>
          <GenericIcon
            image="https://res.cloudinary.com/phinx-lab/image/upload/v1677597018/Assets/point.png"
            width="20px"
            height="20px"
            styles={{ cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          />
          <MapModal center={center} open={open} handleClose={() => setOpen(!open)} row={row} mobile />
        </>
      </Box>
      <Divider style={{ background: '#000' }} />
    </>
  );
};
