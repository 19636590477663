import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { orderBy } from 'lodash';
import { Box, Grid, Card, Typography, useMediaQuery, Theme, Button, IconButton } from '@material-ui/core';
import { CARD_EMITER } from 'src/app/models';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useConfirmationDAO } from 'src/app/business';
import { useSnackbar } from 'notistack';
import confirmDialog from 'src/commons/services/confirmDialog';
import { withStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import moment from 'moment';
import { confirmationTimeManager } from 'src/utils';
import { useRolesContext } from 'src/context';
import { useTokenizedCardDAO } from 'src/app/business/TokenizedCard';
import { useHistory } from 'react-router';
import { getRemovalTokenizedCardContent } from './components';
import { getRemovalTitle } from '../../common/RemovalModalTitle';
import { TokenizedCard } from '../../../../../app/models';

interface TokenizedCardsProps {
  tokenizedCards: TokenizedCard[];
  recall: () => void;
}

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const getContentModalInfo = (data: TokenizedCard) =>
  confirmDialog.show({
    maxWidth: 'sm',
    title: getRemovalTitle(
      data.expired
        ? 'Tu tarjeta ha vencido. Por favor eliminala de tu cuenta y cargala con los datos actualizados.'
        : 'Estas por eliminar la siguiente tarjeta.',
    ),
    content: getRemovalTokenizedCardContent(data),
    confirmText: 'ELIMINAR',
    cancelText: 'Cancelar',
    buttonConfirm: ColorButton,
  });

export const TokenizedCards = ({ tokenizedCards }: TokenizedCardsProps) => {
  const confirmationDAO = useConfirmationDAO();
  const { userInfo } = useRolesContext();
  const history = useHistory();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [allTokenizedCards, setAllTokenizedCards] = useState(tokenizedCards);
  const { enqueueSnackbar } = useSnackbar();
  const tokenizedCardDAO = useTokenizedCardDAO();

  const expiredCards = useMemo(() => allTokenizedCards.filter((item) => item.expired), [allTokenizedCards]);

  const verifyUserAccount = useCallback(async () => {
    const nowTime = moment();
    // INFO: verifica cada 24 horas, tras la ultima verificacion
    const time = await confirmationTimeManager.retrieve();
    if (!moment(time).isValid() || moment(time).diff(nowTime) < 0) {
      await confirmationDAO.sendCode({ email: userInfo.email });
      history.push({
        pathname: '/dashboard/confirm',
        state: {
          goBack: '/dashboard/profile',
          goNext: '/dashboard/profile',
        },
      });
      return false;
    }
    return true;
  }, [confirmationDAO, history, userInfo.email]);

  const handleDeleteTokenizedCard = useCallback(
    async (data: TokenizedCard) => {
      try {
        const isValid = await verifyUserAccount();
        if (isValid) {
          const confirm = await getContentModalInfo(data);
          if (confirm) {
            await tokenizedCardDAO.remove(data.token);
            setAllTokenizedCards((old) => old.filter((item) => item.token !== data.token));
            enqueueSnackbar(`Eliminaste la tarjeta Terminada en${data.last_four_digits}`, {
              variant: 'error',
            });
          }
        }
      } catch (error) {
        console.log('ERROR', error);
      }
    },
    [tokenizedCardDAO, enqueueSnackbar, verifyUserAccount],
  );

  const handleExpireCards = useCallback(
    async (data: TokenizedCard) => {
      try {
        const confirm = await getContentModalInfo(data);
        if (confirm) {
          const isValid = await verifyUserAccount();
          if (isValid) {
            await tokenizedCardDAO.remove(data.token);
            setAllTokenizedCards((old) => old.filter((item) => item.token !== data.token));
            enqueueSnackbar(`Eliminaste la tarjeta Terminada en${data.last_four_digits}`, {
              variant: 'error',
            });
          }
        }
      } catch (error) {
        console.log('ERROR', error);
      }
    },
    [tokenizedCardDAO, enqueueSnackbar, verifyUserAccount],
  );

  useEffect(() => {
    if (expiredCards.length > 0) {
      handleExpireCards(expiredCards[0]);
    }
  }, [expiredCards, handleExpireCards]);

  return (
    <Box
      justifyItems="center"
      width="100%"
      padding={isDownSm ? 2 : 3}
      component={Card}
      minHeight={149}
      borderRadius={8}
      boxShadow={3}
      display="content"
    >
      {allTokenizedCards.length === 0 ? (
        <Grid container justify="center">
          <Typography variant="body1">Las tarjetas que uses en tus futuras compras aparecerán aqui</Typography>
        </Grid>
      ) : (
        orderBy(allTokenizedCards, 'last_four_digits', 'desc').map((tokenCard, index) => (
          <Box
            key={tokenCard.token.slice(0, 5)}
            width="100%"
            paddingBottom={index + 1 !== allTokenizedCards.length ? 3 : 0}
            mb={index + 1 !== allTokenizedCards.length ? 3 : 0}
            borderBottom={index + 1 !== allTokenizedCards.length ? '1px solid #DADBE4' : ''}
          >
            <Box display="flex" flexDirection="row" width="100%">
              <Grid item xs={11} container direction="column" spacing={1}>
                <Grid item container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="body1" color="textPrimary">
                      {`${CARD_EMITER[tokenCard.payment_method_id].type} - Terminada en ${tokenCard.last_four_digits}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {tokenCard.expired && (
                      <Box
                        display="flex"
                        bgcolor="action.main"
                        borderRadius="4px"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        padding="2px 8px"
                      >
                        <Typography variant="caption" color="textSecondary">
                          Vencida
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      {`Tarjeta ${CARD_EMITER[tokenCard.payment_method_id].emitter}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      {`Vencimiento: ${moment(`01/${tokenCard.expiration_month}/${tokenCard.expiration_year}`, 'DD/MM/YY').format('MM/YYYY')}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} container alignItems="center" justify="flex-end">
                <IconButton size="small" onClick={() => handleDeleteTokenizedCard(tokenCard)}>
                  <DeleteOutlineIcon color="inherit" />
                </IconButton>
              </Grid>
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
};
