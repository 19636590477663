import React, {FC, useState} from 'react';
import 'react-image-lightbox/style.css';
import Lightbox from "react-image-lightbox";

export interface ImagesLightboxProps {
  images: string[];
  initialActive: number;
  onClose: () => void;
}

export const ImagesLightbox: FC<ImagesLightboxProps> = ({ images, initialActive, onClose }) => {
  const [photoIndex, setPhotoIndex] = useState(initialActive);
  return (
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % images.length]}
      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      onCloseRequest={onClose}
      onMovePrevRequest={() => setPhotoIndex(prev => prev - 1 < 0 ? images.length - 1 : prev - 1)}
      onMoveNextRequest={() => setPhotoIndex(prev => prev + 1 >= images.length ? 0 : prev + 1)}
    />
  );
};
