import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { Category } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'category';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCategory',
      labelKey: 'name',
    },
    withSelectedChips: {
      idKey: 'idCategory',
      labelKey: 'name',
    },
  },
};

const optionsToUse = !businessOptions.Category
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Category,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Category.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<Category>(DAO_NAME, optionsToUse);

// hooks
export const useCategoryFetchById = fetchById;
export const useCategoryLibbyCall = libbyCall;
export const useCategoryDAO = libbyDAO;
export const useCategoryLibbyFetch = libbyFetch;

// components/hoc
export const CategoryAutocomplete = entityAutocomplete;
export const CategoryCall = renderPropCall;
export const CategoryById = renderPropFetchById;
export const CategorySelect = simpleSelect;
export const CategoryAccordionSelector = accordionSelector;
export const CategorySelectedChips = selectedChips;

// context
// ByIdContext
export const useCategoryByIdContext = useByIdContext;
export const CategoryByIdProvider = ByIdProvider;
export const CategoryByIdContext = ByIdContext;
