import React, { useMemo, useState } from 'react';
import { Button, Toolbar, Grid, Collapse, Typography } from '@material-ui/core';
import { TabMenu } from './TabMenu';
import { AppHeaderMenuProps } from './types';

export const AppHeaderMenu = ({ contents, onOpenCategories, onOpenMenu }: AppHeaderMenuProps) => {
  const [checked, setCheked] = useState<boolean>(false);

  contents.sort((a, b) => (a.relevance! > b.relevance! ? 1 : b.relevance! > a.relevance! ? -1 : 0));

  const handleClick = (checked: boolean) => setCheked(checked);

  const buttonsInHeader = useMemo(() => {
    const buttons = [
      <Button
        color="primary"
        variant="contained"
        onClick={onOpenMenu}
        onMouseOver={() => handleClick(true)}
        style={{ textTransform: 'none', fontSize: '14px' }}
      >
        Todas las categorías
      </Button>,
    ];

    contents.forEach(
      (item) =>
        item.in_header &&
        buttons.push(
          <Button style={{ textTransform: 'none' }} onClick={() => onOpenCategories(item)} id={`${item.name}-nav`}>
            <Typography variant="body2">{item.name.includes('.') ? item.name.split('.').pop() : item.name}</Typography>
          </Button>,
        ),
    );

    return buttons;
  }, [contents, onOpenCategories, onOpenMenu]);

  return (
    <Toolbar variant="dense" disableGutters onMouseLeave={() => handleClick(false)}>
      <Grid container direction="row" justify="space-between">
        <Grid item container style={{ position: 'relative' }}>
          {buttonsInHeader}
          <Collapse timeout={200} in={checked} style={{ position: 'absolute', top: '42px' }}>
            <TabMenu contents={contents} onOpenCategories={onOpenCategories} closeMenu={() => setCheked(false)} />
          </Collapse>
        </Grid>
      </Grid>
    </Toolbar>
  );
};
