import React, { useState, useCallback } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import parse from 'react-html-parser';
import { jsPDF as JsPDF } from 'jspdf';
import { ModalDialog } from '../../../../../commons';

interface LegalesProps {
  title: string;
  info: string;
}

export const Legales = ({ title, info }: LegalesProps) => {
  const [openModal, setOpenModal] = useState(false);
  const onHandleCloseModal = useCallback(() => setOpenModal(false), []);
  const onOpenModals = useCallback(() => setOpenModal(true), []);
  const [downloading, setDownloading] = useState<boolean>(false);

  const printRef = React.useRef<HTMLDivElement | null>(null);

  const handleDownloadPDF = useCallback(() => {
    const element = printRef.current;
    if (!element) return;
    try {
      setDownloading(true);
      const pdf = new JsPDF({ orientation: 'portrait', unit: 'cm' });
      const pdfWidth = pdf.internal.pageSize.getWidth() - 4;
      pdf.setFontSize(36).setFont('helvetica', 'bold', 600).setFillColor('#0000de');
      pdf.text('Legales', 2, 2, { align: 'left', maxWidth: pdfWidth });
      const texts: string[] = [];
      element.childNodes.forEach((item) => {
        if (!item.textContent) return;
        texts.push(
          (item.textContent || '')
            .replace('\n', '')
            .split(' ')
            .filter((item) => item)
            .join(' '),
        );
      });
      pdf.setFontSize(24).setFont('helvetica', 'bold', 600).setFillColor('#0000de');
      pdf.text(texts[0], 2, 4, { align: 'left', maxWidth: pdfWidth });
      pdf.text(texts[1], 2, 6, { align: 'left', maxWidth: pdfWidth });
      pdf.text(texts[2], 2, 6, { align: 'left', maxWidth: pdfWidth });
      pdf.setFontSize(16).setFont('helvetica', 'normal', 600).setFillColor('#C0C0C0');
      pdf.text(texts[3], 2, 8, { align: 'justify', maxWidth: pdfWidth });
      pdf.save('legales.pdf');
      setDownloading(false);
    } catch (error) {
      const e = error as any;
      setDownloading(false);
      console.log('Error to download pdf.', e);
    }
  }, []);

  return (
    <Grid item container xs={12} alignItems="center" justify="flex-end">
      <Button onClick={onOpenModals} color="primary">
        <Typography style={{ textTransform: 'none' }} variant="body2" color="primary">
          {title}
        </Typography>
      </Button>
      <ModalDialog title="Legales" open={openModal} onHandleClose={onHandleCloseModal}>
        <Grid container>
          <Grid component="div" container ref={printRef}>
            {parse(info)}
          </Grid>
          <Grid container justify="flex-end" alignContent="center">
            <Button disabled={downloading} variant="text" color="primary" onClick={handleDownloadPDF}>
              Descargar
            </Button>
          </Grid>
        </Grid>
      </ModalDialog>
    </Grid>
  );
};
