import { API_URL } from 'src/config';
import { IPersonalInfo } from '../../screens/Common/Checkout/initialValues';
import { EventObject, EventParams, FacebookTrackEvent } from './interfaces';

const url = `${API_URL}/analytics/facebookPixel`;

class FacebookPixelApi {
  async send(event: string, data: object, user_data: IPersonalInfo | null = null) {
    const owner = localStorage.getItem('owner') || '';
    const eventObject: EventObject = {
      event,
      user_data: {
        client_user_agent: navigator.userAgent,
        external_id: [owner]
      },
      data,
    };
    if (process.env.REACT_APP_ANALYTICS !== 'true') eventObject.test_event_code = 'TEST1109';
    if(user_data) eventObject.hash_user_data = {
      fn: user_data.name,
      ln: user_data.lastName,
      em: user_data.email,
      ge: user_data.gender,
      ph: user_data.phone.areaCode + user_data.phone.number,
      db: user_data.dateOfBirty,
    }
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventObject),
    })
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((err) => console.log('error: ', err));
  }

  track(event: string, { content_name, content_ids }: EventParams) {
    this.send(event, { content_name, content_ids });
  }

  purchaseTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.PURCHASE_APPROVED, params, user_data);
  }

  purchasePendingTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.PURCHASE_PENDING, params, user_data);
  }

  purchaseFailedTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.PURCHASE_FAILED, params, user_data);
  }

  checkOutTrack(params: EventParams) {
    this.send(FacebookTrackEvent.START_CHECKOUT, params);
  }

  PersonalInfoTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.PERSONAL_INFO, params, user_data);
  }

  EquifaxVerifyTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.EQUIFAX_VERIFY, params, user_data);
  }

  DeliveryInfoTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.DELIVERY_INFO, params, user_data);
  }

  PaymentMethodInfoTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.PAYMENT_METHOD, params, user_data);
  }

  ApplicantInfoTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.APPLICANT_INFO, params, user_data);
  }

  GuarantorInfoTrack(params: EventParams, user_data: any) {
    this.send(FacebookTrackEvent.GAURANTOR_INFO, params, user_data);
  }

  addToCartTrack(params: EventParams) {
    this.send(FacebookTrackEvent.ADD_TO_CART, params);
  }

  removeToCartTrack(params: EventParams) {
    this.send(FacebookTrackEvent.REMOVE_FROM_CART, params);
  }

  viewContent(params: EventParams) {
    this.send(FacebookTrackEvent.VIEW_CONTENT, params);
  }
}

const i: FacebookPixelApi = new FacebookPixelApi();
export { i as FacebookPixelApi };
