import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AnyObject, SetActiveFilter } from '../../../commons/types';
import { makeEntityLibbyCall } from '../hooks';

export function makeGeneralContext<T>(daoName: string) {
  const useHook = makeEntityLibbyCall(daoName);

  interface GeneralContextValue<T> {
    activeFilter?: AnyObject;
    setActiveFilter: SetActiveFilter;
    data: T[] | null;
    working: boolean;
  }

  const GeneralContext = createContext<GeneralContextValue<T>>({
    setActiveFilter: () => {},
    data: null,
    working: false,
  });

  const useGeneralContext = () => useContext<GeneralContextValue<T>>(GeneralContext);

  interface ProviderProps {
    filter?: AnyObject;
    setFilter?: SetActiveFilter;
    children: JSX.Element;
  }

  const GeneralProvider = ({ filter, setFilter, children }: ProviderProps) => {
    const [activeFilter, setActiveFilter] = useState(filter);

    const { working, data, recall } = useHook({
      methodName: 'fetch',
      params: [],
      noAutoCall: true,
    });

    const value = useMemo<GeneralContextValue<T>>(
      () => ({
        activeFilter: filter !== undefined && setFilter ? filter : activeFilter,
        setActiveFilter: setFilter || setActiveFilter,
        data: data as T[],
        working,
      }),
      [activeFilter, data, filter, setFilter, working],
    );

    useEffect(() => {
      if (recall) {
        const filterToUse = filter !== undefined && setFilter ? filter : activeFilter;
        // TODO: fix limit usage
        const params = { filter: filterToUse, limit: 20000 };
        recall(params);
      }
    }, [filter, setFilter, activeFilter, recall]);
    return <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>;
  };

  return {
    GeneralContext,
    GeneralProvider,
    useGeneralContext,
  };
}
