import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { IPersonalInfo, IResponsableInfo } from '../../initialValues';

interface PersonalInfoViewProps {
  personalInfo: IPersonalInfo;
  isResponsable: string;
  responsable: IResponsableInfo;
}

export const PersonalInfoView = ({ personalInfo, isResponsable, responsable }: PersonalInfoViewProps) => (
  <>
    {!!personalInfo.name && (
      <Grid container>
        <Grid item container justify="flex-start" xs={8}>
          <Typography variant="caption" color="textPrimary">
            {`Nombre: ${personalInfo.name} ${personalInfo.lastName}`}
          </Typography>
        </Grid>
        <Grid item container justify="flex-start" xs={4}>
          <Typography variant="caption" color="textPrimary">
            {`DNI: ${personalInfo.document}`}
          </Typography>
        </Grid>
        <Grid item container justify="flex-start" xs={8}>
          <Typography variant="caption" color="textPrimary">
            {`Email: ${personalInfo.email}`}
          </Typography>
        </Grid>
        <Grid item container justify="flex-start" xs={4}>
          <Typography variant="caption" color="textPrimary">
            {`Celular: ${personalInfo.phone.areaCode}${personalInfo.phone.number}`}
          </Typography>
        </Grid>
        <Grid item container justify="flex-start" xs={4}>
          <Typography variant="caption" color="textPrimary">
            {`Género: ${personalInfo.gender}`}
          </Typography>
        </Grid>
        {isResponsable === 'true' && (
          <>
            <Grid item container justify="flex-start" xs={8}>
              <Typography variant="caption" color="textPrimary">
                {`Empresa: ${responsable.business}`}
              </Typography>
            </Grid>
            <Grid item container justify="flex-start" xs={4}>
              <Typography variant="caption" color="textPrimary">
                {`CUIT: ${responsable.cuit}`}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    )}
  </>
);
