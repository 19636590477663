import { Box, Card, Divider, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { BuyerHOPPurchases } from 'src/app';

interface HOPInfo {
  hop: BuyerHOPPurchases[];
}

export const HOPView = ({ hop }: HOPInfo) => {
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <Box
      justifyItems="center"
      width="100%"
      padding={isDownSm ? 2 : 3}
      component={Card}
      minHeight={149}
      borderRadius={8}
      boxShadow={3}
      display="content"
    >
      {hop.map((point) => {
        const { street, number, city, description, province, zip } = point;
        return (
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h6" color="primary">
                {description}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                Retirado en:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {`Calle: ${street} ${number}`}
              </Typography>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {`${city}, ${province}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {`Código postal: ${zip}`}
                </Typography>
              </Grid>
            </Grid>
            <Box margin=".5em 0">
              <Divider />
            </Box>
          </Grid>
        );
      })}
    </Box>
  );
};
