/* eslint max-len: ["error", { "ignorePattern": "^\\s*var\\s.+=\\s*require\\s*\\(", "code": 2000 }] */
import * as React from 'react';

interface MainLogoProps {
  width?: string;
  height?: string;
}

export const MainLogo = ({ width = '199', height = '28' }: MainLogoProps) => (
  <svg width={width} height={height} viewBox="0 0 199 28" fill="none" xmlns="http://www.w3.org/2000/svg" id="store-icon">
    <path
      d="M58.7038 11.3888C57.8463 10.7318 56.7826 10.402 55.7038 10.4588C54.8461 10.4457 53.9919 10.5706 53.1738 10.8288C52.5132 11.0384 51.8737 11.3096 51.2638 11.6388V26.4488C50.9991 26.551 50.7242 26.6248 50.4438 26.6688C50.0819 26.7422 49.7131 26.7757 49.3438 26.7688C48.7308 26.8224 48.1177 26.6674 47.6038 26.3288C47.3993 26.1399 47.2414 25.9061 47.1425 25.6458C47.0435 25.3856 47.0064 25.1059 47.0338 24.8288V11.5188C47.006 10.9945 47.1311 10.4734 47.3938 10.0188C47.7219 9.5688 48.1325 9.18532 48.6038 8.8888C50.759 7.67103 53.1986 7.04647 55.6738 7.0788C57.8575 6.95458 60.0187 7.57508 61.8038 8.8388C62.5609 9.48751 63.1545 10.3053 63.5367 11.2261C63.9189 12.1469 64.0789 13.1447 64.0038 14.1388V26.4488C63.7395 26.5524 63.4645 26.6262 63.1838 26.6688C62.8151 26.7418 62.4397 26.7753 62.0638 26.7688C61.4512 26.8178 60.8396 26.6631 60.3238 26.3288C60.1185 26.1409 59.9607 25.9069 59.8634 25.6462C59.7661 25.3854 59.7319 25.1053 59.7638 24.8288V14.2788C59.8032 13.7459 59.7251 13.2109 59.535 12.7116C59.3449 12.2122 59.0476 11.7606 58.6638 11.3888H58.7038Z"
      fill="black"
    />
    <path
      d="M86.2838 17.0688C86.3064 18.4857 86.0726 19.895 85.5938 21.2288C85.1782 22.4001 84.5245 23.4726 83.6738 24.3788C82.8321 25.2567 81.808 25.9394 80.6738 26.3788C78.1176 27.3192 75.3101 27.3192 72.7538 26.3788C71.6209 25.9371 70.5973 25.2548 69.7538 24.3788C68.9032 23.4726 68.2495 22.4001 67.8338 21.2288C67.3635 19.8931 67.1333 18.4847 67.1538 17.0688C67.138 15.6524 67.3715 14.2442 67.8438 12.9088C68.2565 11.7383 68.9068 10.6659 69.7538 9.7588C70.6001 8.88623 71.6229 8.20437 72.7538 7.7588C75.2774 6.8086 78.0603 6.8086 80.5838 7.7588C81.7191 8.19612 82.7435 8.8791 83.5838 9.7588C84.4378 10.6661 85.0979 11.7379 85.5238 12.9088C86.0025 14.2322 86.2363 15.6317 86.2138 17.0388L86.2838 17.0688ZM76.7138 10.4688C75.9831 10.437 75.2551 10.577 74.5882 10.8774C73.9214 11.1779 73.3342 11.6304 72.8738 12.1988C71.8528 13.6092 71.3543 15.331 71.4638 17.0688C71.3477 18.8013 71.8315 20.521 72.8338 21.9388C73.3233 22.4811 73.9211 22.9145 74.5886 23.2112C75.256 23.508 75.9784 23.6613 76.7088 23.6613C77.4393 23.6613 78.1616 23.508 78.8291 23.2112C79.4966 22.9145 80.0944 22.4811 80.5838 21.9388C81.6032 20.5271 82.1047 18.8071 82.0038 17.0688C82.1029 15.3352 81.613 13.619 80.6138 12.1988C80.1565 11.6205 79.5665 11.1608 78.8938 10.8588C78.2246 10.5497 77.4899 10.4089 76.7538 10.4488"
      fill="black"
    />
    <path
      d="M103.934 7.2488C104.451 7.22089 104.962 7.36894 105.384 7.6688C105.58 7.82923 105.735 8.03518 105.834 8.2688C105.949 8.49826 106.007 8.75207 106.004 9.0088C105.964 9.55822 105.863 10.1015 105.704 10.6288C105.504 11.3988 105.224 12.2888 104.894 13.3188C104.564 14.3488 104.154 15.4388 103.724 16.6188C103.294 17.7988 102.824 18.9888 102.344 20.1488C101.864 21.3088 101.344 22.4388 100.904 23.5088C100.464 24.5788 100.004 25.5088 99.6038 26.2288C99.2899 26.4015 98.9533 26.5294 98.6038 26.6088C98.1277 26.7365 97.6368 26.8004 97.1438 26.7988C95.8238 26.7988 94.9638 26.4288 94.5638 25.7088C94.0105 24.6755 93.5105 23.6155 93.0638 22.5288C92.4438 21.0988 91.8138 19.5288 91.1638 17.8188C90.5138 16.1088 89.8938 14.3988 89.2838 12.6888C88.6738 10.9788 88.2038 9.5088 87.8538 8.2788C88.1299 7.96449 88.4698 7.7127 88.8509 7.54025C89.232 7.3678 89.6455 7.27867 90.0638 7.2788C90.5908 7.24394 91.1137 7.39232 91.5438 7.6988C91.9744 8.09074 92.2774 8.6028 92.4138 9.1688L94.6338 16.1088C94.7938 16.6188 94.9838 17.1788 95.1938 17.8088C95.4038 18.4388 95.6238 19.0488 95.8438 19.6788C96.0638 20.3088 96.2738 20.9188 96.4938 21.4888C96.7138 22.0588 96.8838 22.5688 97.0138 22.9688H97.2238C98.1305 20.4355 99.0038 17.8855 99.8438 15.3188C100.684 12.7521 101.404 10.2055 102.004 7.6788C102.586 7.37363 103.237 7.22543 103.894 7.2488"
      fill="black"
    />
    <path
      d="M115.754 7.0788C117.877 6.96371 119.981 7.53524 121.754 8.7088C123.254 9.7888 124.004 11.5055 124.004 13.8588V23.2588C124.042 23.7738 123.893 24.2852 123.584 24.6988C123.25 25.0712 122.863 25.3914 122.434 25.6488C121.576 26.0939 120.665 26.4299 119.724 26.6488C118.468 26.9605 117.177 27.1084 115.884 27.0888C113.737 27.2141 111.603 26.684 109.764 25.5688C109.042 25.054 108.461 24.3663 108.074 23.5688C107.691 22.7756 107.516 21.8982 107.564 21.0188C107.523 20.2678 107.651 19.5173 107.941 18.823C108.23 18.1287 108.672 17.5086 109.234 17.0088C110.676 15.9609 112.393 15.3562 114.174 15.2688L119.794 14.7088V13.8088C119.838 13.3164 119.755 12.8209 119.554 12.3693C119.353 11.9178 119.04 11.5252 118.644 11.2288C117.672 10.6262 116.535 10.343 115.394 10.4188C114.34 10.4076 113.291 10.5594 112.284 10.8688C111.372 11.1321 110.483 11.4664 109.624 11.8688C109.342 11.6451 109.104 11.3703 108.924 11.0588C108.747 10.7547 108.651 10.4105 108.644 10.0588C108.631 9.64582 108.757 9.2405 109.004 8.9088C109.289 8.57142 109.653 8.31024 110.064 8.1488C110.883 7.75495 111.756 7.48211 112.654 7.3388C113.667 7.15715 114.695 7.07011 115.724 7.0788H115.754ZM115.754 23.8188C116.576 23.8273 117.396 23.7298 118.194 23.5288C118.725 23.4183 119.233 23.2152 119.694 22.9288V17.8188L115.284 18.2588C114.304 18.311 113.349 18.5882 112.494 19.0688C112.191 19.2811 111.947 19.5666 111.784 19.8988C111.614 20.2321 111.541 20.6062 111.574 20.9788C111.552 21.383 111.634 21.786 111.814 22.1488C111.987 22.5109 112.248 22.8238 112.574 23.0588C113.525 23.6284 114.628 23.8903 115.734 23.8088"
      fill="black"
    />
    <path
      d="M132.054 23.0588C132.766 23.4996 133.598 23.7091 134.434 23.6588C134.924 23.6575 135.412 23.5902 135.884 23.4588C136.347 23.3483 136.796 23.1874 137.224 22.9788C137.42 23.2031 137.591 23.4479 137.734 23.7088C137.983 24.1746 138.037 24.7199 137.885 25.2258C137.734 25.7316 137.388 26.1569 136.924 26.4088C135.92 26.9301 134.793 27.1686 133.664 27.0988C131.951 27.1876 130.257 26.7096 128.844 25.7388C127.644 24.8388 127.054 23.3588 127.044 21.2888V2.4588L127.854 2.2488C128.232 2.16737 128.617 2.12714 129.004 2.1288C129.612 2.07952 130.218 2.2382 130.724 2.5788C130.914 2.77423 131.058 3.0101 131.144 3.2688C131.235 3.52851 131.262 3.80629 131.224 4.0788V8.0088H137.484C137.614 8.22091 137.715 8.44965 137.784 8.6888C137.884 8.99216 137.934 9.30948 137.934 9.6288C137.934 10.7788 137.434 11.3588 136.434 11.3588H131.204V21.0088C131.164 21.392 131.215 21.7794 131.354 22.1388C131.495 22.4979 131.721 22.8171 132.014 23.0688"
      fill="black"
    />
    <path
      d="M145.214 19.2188C145.475 20.5959 146.26 21.8181 147.404 22.6288C148.655 23.4186 150.116 23.8092 151.594 23.7488C152.651 23.7582 153.7 23.5787 154.694 23.2188C155.508 22.9407 156.284 22.561 157.004 22.0888C157.343 22.2563 157.627 22.5164 157.824 22.8388C158.026 23.1589 158.134 23.53 158.134 23.9088C158.123 24.3806 157.933 24.8307 157.604 25.1688C157.201 25.5972 156.714 25.9377 156.174 26.1688C155.486 26.476 154.764 26.7009 154.024 26.8388C153.153 26.9989 152.269 27.0792 151.384 27.0788C149.938 27.0977 148.498 26.8781 147.124 26.4288C145.895 26.0381 144.77 25.3747 143.833 24.4881C142.896 23.6015 142.172 22.5147 141.714 21.3088C141.186 19.8999 140.931 18.4031 140.964 16.8988C140.94 15.449 141.188 14.0076 141.694 12.6488C142.133 11.4941 142.815 10.4472 143.694 9.5788C144.546 8.74267 145.57 8.10127 146.694 7.6988C147.849 7.27192 149.072 7.05518 150.304 7.0588C151.509 7.0476 152.707 7.26125 153.834 7.6888C154.855 8.07915 155.79 8.66702 156.584 9.4188C157.353 10.1683 157.956 11.0712 158.354 12.0688C158.782 13.1298 158.996 14.2649 158.984 15.4088C159.004 15.6755 158.973 15.9435 158.894 16.1988C158.806 16.4502 158.674 16.6838 158.504 16.8888C158.103 17.2323 157.608 17.4482 157.084 17.5088L145.214 19.2188ZM150.214 10.3488C149.529 10.3289 148.848 10.4515 148.214 10.7088C147.58 10.9675 147.005 11.3519 146.524 11.8388C145.472 13.0109 144.917 14.5453 144.974 16.1188L154.904 14.7088C154.823 13.558 154.354 12.4687 153.574 11.6188C153.139 11.1798 152.615 10.8392 152.037 10.6202C151.459 10.4011 150.841 10.3085 150.224 10.3488H150.214Z"
      fill="black"
    />
    <path
      d="M172.244 10.5088C171.428 10.4987 170.617 10.6412 169.854 10.9288C169.151 11.2095 168.519 11.6434 168.004 12.1988C167.451 12.8006 167.026 13.5082 166.754 14.2788C166.423 15.1884 166.264 16.1513 166.284 17.1188C166.224 17.9979 166.333 18.8803 166.604 19.7188C166.876 20.5608 167.315 21.3395 167.894 22.0088C168.465 22.5715 169.146 23.0106 169.894 23.2988C170.636 23.5847 171.429 23.7141 172.224 23.6788C173.118 23.7059 174.009 23.5703 174.854 23.2788C175.526 23.0315 176.161 22.6952 176.744 22.2788C177.074 22.4784 177.363 22.7399 177.594 23.0488C177.813 23.369 177.926 23.7507 177.914 24.1388C177.87 24.6063 177.695 25.0518 177.408 25.4236C177.121 25.7953 176.735 26.078 176.294 26.2388C174.948 26.8578 173.474 27.149 171.994 27.0888C170.614 27.1013 169.24 26.8955 167.924 26.4788C166.745 26.0962 165.663 25.4615 164.754 24.6188C163.841 23.7391 163.131 22.6709 162.674 21.4888C162.151 20.0924 161.896 18.6097 161.924 17.1188C161.909 15.6128 162.188 14.1184 162.744 12.7188C163.205 11.5372 163.927 10.4744 164.854 9.6088C165.777 8.74214 166.88 8.08995 168.084 7.6988C170.086 7.0344 172.226 6.90342 174.294 7.3188C174.933 7.45748 175.552 7.67937 176.134 7.9788C176.594 8.22845 176.999 8.56861 177.324 8.9788C177.573 9.28253 177.72 9.65688 177.744 10.0488C177.762 10.4379 177.657 10.8229 177.444 11.1488C177.26 11.447 177.002 11.6919 176.694 11.8588C176.099 11.4875 175.47 11.1759 174.814 10.9288C173.993 10.6359 173.125 10.5002 172.254 10.5288"
      fill="black"
    />
    <path
      d="M192.604 11.4988C191.719 10.7923 190.605 10.4364 189.474 10.4988C188.668 10.4877 187.867 10.6302 187.114 10.9188C186.447 11.1708 185.815 11.5069 185.234 11.9188V26.4488C184.973 26.5524 184.701 26.6262 184.424 26.6688C184.058 26.7417 183.686 26.7753 183.314 26.7688C182.701 26.8216 182.088 26.6667 181.574 26.3288C181.372 26.1392 181.215 25.9069 181.114 25.6488C181.016 25.3874 180.981 25.1062 181.014 24.8288V0.338802L181.824 0.138802C182.211 0.0481926 182.607 0.00454423 183.004 0.00880155C183.612 -0.0404754 184.218 0.118195 184.724 0.458802C184.923 0.646709 185.074 0.879996 185.164 1.1388C185.261 1.39696 185.296 1.67469 185.264 1.9488V8.3288C185.957 7.9782 186.68 7.69038 187.424 7.4688C188.322 7.19944 189.256 7.06794 190.194 7.0788C192.253 6.97994 194.277 7.63799 195.884 8.9288C197.297 10.1688 198.004 12.0655 198.004 14.6188V26.4488C197.743 26.5524 197.471 26.6262 197.194 26.6688C196.835 26.7419 196.47 26.7755 196.104 26.7688C195.484 26.8262 194.864 26.6711 194.344 26.3288C194.144 26.1371 193.994 25.9005 193.904 25.6388C193.806 25.3808 193.771 25.1029 193.804 24.8288V14.7488C193.857 14.1516 193.775 13.5501 193.564 12.9888C193.361 12.4257 193.033 11.9161 192.604 11.4988V11.4988Z"
      fill="black"
    />
    <path
      d="M29.0938 9.5688C27.4339 7.90606 25.2549 6.86144 22.9191 6.60857C20.5832 6.3557 18.2312 6.90981 16.2538 8.1788C16.702 8.50862 17.1263 8.86951 17.5238 9.2588L19.5238 11.2588C19.7582 11.1368 19.9985 11.0267 20.2438 10.9288C20.9496 10.6961 21.6912 10.5911 22.4338 10.6188C23.2092 10.5997 23.9797 10.7463 24.6938 11.0488C25.4106 11.3533 26.0522 11.8106 26.5738 12.3888C27.768 13.5823 28.4405 15.2005 28.4438 16.8888C28.4407 17.7263 28.2743 18.5551 27.9538 19.3288C27.633 20.0991 27.1641 20.799 26.5738 21.3888C26.0452 21.989 25.4028 22.4784 24.6838 22.8288C23.9594 23.1692 23.1737 23.3597 22.3738 23.3888C21.5427 23.3782 20.7212 23.2084 19.9538 22.8888C19.1828 22.5703 18.4851 22.0972 17.9038 21.4988L11.3738 15.0088C11.0821 14.7163 10.7094 14.5178 10.3038 14.4388C9.89827 14.3595 9.47848 14.3977 9.09385 14.5488C8.70842 14.7066 8.37801 14.9744 8.14385 15.3188C7.91183 15.662 7.7834 16.0647 7.77385 16.4788C7.7819 16.6986 7.81546 16.9167 7.87385 17.1288C8.01591 17.4822 8.23448 17.7998 8.51385 18.0588L12.1938 21.7388L14.5838 24.1288C14.967 24.5318 15.3854 24.9 15.8338 25.2288C17.9262 26.8469 20.5621 27.5953 23.1925 27.3182C25.8229 27.0411 28.245 25.7598 29.9542 23.7412C31.6634 21.7227 32.528 19.1225 32.3677 16.4824C32.2074 13.8423 31.0347 11.3658 29.0938 9.5688V9.5688Z"
      fill="#006FF0"
    />
    <path
      d="M12.3738 21.9388L12.8138 22.3988L12.1538 22.6288C11.0832 23.0217 9.92639 23.1186 8.80532 22.9091C7.68425 22.6997 6.64043 22.1917 5.78385 21.4388C5.14305 20.8766 4.62162 20.1915 4.25046 19.424C3.87929 18.6566 3.66594 17.8225 3.62305 16.9711C3.58015 16.1197 3.70858 15.2683 4.00073 14.4675C4.29288 13.6666 4.74281 12.9326 5.32385 12.3088C6.09933 11.4713 7.08681 10.8591 8.18176 10.537C9.27672 10.2148 10.4384 10.1947 11.5438 10.4788C12.6497 10.7515 13.6596 11.3222 14.4638 12.1288L21.0038 18.6188C21.3719 18.9882 21.8632 19.2089 22.3838 19.2388C22.9012 19.269 23.4111 19.1049 23.8138 18.7788H23.8638L23.9538 18.6988L24.0438 18.5988V18.5088L24.1238 18.4388L24.1938 18.3288V18.2788C24.3766 18.0011 24.4901 17.6837 24.5249 17.3531C24.5597 17.0225 24.5148 16.6884 24.3938 16.3788C24.2889 16.1323 24.1473 15.903 23.9738 15.6988L23.9138 15.6388L23.7538 15.4488L23.6138 15.2988L19.9638 11.6588L19.7738 11.4688L17.5238 9.2588C17.1263 8.86951 16.7019 8.50862 16.2538 8.1788C14.7347 7.09504 12.9493 6.4452 11.089 6.29898C9.22865 6.15276 7.36361 6.51567 5.69385 7.3488C4.02849 8.18219 2.61635 9.44586 1.60385 11.0088C0.602314 12.5837 0.0489041 14.4018 0.00309463 16.2677C-0.0427148 18.1335 0.420808 19.9766 1.34385 21.5988C2.03509 22.8117 2.96591 23.8713 4.07966 24.713C5.19341 25.5547 6.4668 26.161 7.82232 26.495C9.17783 26.829 10.5871 26.8836 11.9644 26.6556C13.3417 26.4276 14.6583 25.9218 15.8338 25.1688C15.3783 24.8481 14.9502 24.4902 14.5538 24.0988L12.3738 21.9388Z"
      fill="#223092"
    />
  </svg>
);
