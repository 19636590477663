import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { useIsResponsableInscriptoLogic } from '../hooks/useIsResponsableInscriptoLogic';
import { useStepTemplateLogic } from '../hooks/useStepTemplateLogic';
import { useBillingAddressLogic } from '../hooks/useBillingAddressLogic';
import { useShipmentCostLogic } from '../hooks/useShipmentCostLogic';
import { useBreakpointsState } from '../../../../app/hooks/useMobile';
import { StepperFlow } from './StepperFlow';
import { OrderSummay } from './OrderSummary';
import { useMultiStepFormContext } from '../../../../lib/MultiStepForm';
import { usePersonalCreditLogic } from '../hooks/usePersonalCreditLogic';
import { useHandleCheckoutErrors } from '../utils/useHandleCheckoutErrors';
import { useEquifaxStepHidden } from '../hooks/useEquifaxStepHidden';

export const CheckoutForm = () => {
  useIsResponsableInscriptoLogic();
  useBillingAddressLogic();
  useStepTemplateLogic();
  useShipmentCostLogic();
  usePersonalCreditLogic();
  useHandleCheckoutErrors();
  useEquifaxStepHidden();

  const { steps, activeStep } = useMultiStepFormContext();
  const { sm } = useBreakpointsState();
  const Comp = steps[activeStep].render;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box mb={sm ? -2 : 1}>
          <StepperFlow />
        </Box>
      </Grid>
      <Grid item lg={8} md={8} xs={12}>
        <Comp />
      </Grid>
      <Grid item lg={4} md={4} xs={12}>
        <OrderSummay />
      </Grid>
    </Grid>
  );
};
