import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { parse } from 'query-string';
import { useConfirmationDAO } from 'src/app/business';
import { Container, Grid } from '@material-ui/core';
import { Loading } from 'src/commons';
import { OAuthProvider } from 'src/app/models';
import { useSnackbar } from 'notistack';
import { useLogin } from '../../Login/hooks';

export const FacebookLogin = () => {
  const history = useHistory();
  const confirmationDAO = useConfirmationDAO();
  const { singIn } = useLogin({ redirectOnFailure: "/login" });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        const search = parse(history.location.search)
        if (search.error) {
          enqueueSnackbar(`Inicio cancelado`, {
            variant: 'error',
          });
          history.push('/login');
        } else {
          const params = parse(history.location.hash);
          const result = await confirmationDAO.verifyToken(params.access_token);
          if (result.isValid) {
            await singIn({ provider: OAuthProvider.FACEBOOK, token_id: params.access_token as string });
          } else {
            history.push('/login');
          }
        }
      } catch (error) {
        console.log("error", error)
      }
    })();
  }, [confirmationDAO, history, singIn, enqueueSnackbar]);

  return (
    <Container maxWidth="md">
      <Grid container justify="center" alignContent="center">
        <Loading />
      </Grid>
    </Container>
  );
};
