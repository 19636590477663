import { Box } from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';
import { HOP } from 'src/app';
import { useBreakpointsState } from 'src/app/hooks/useMobile';
import { InfoTable, Loading } from 'src/commons';
import { useSavingHOPPoints } from '../hooks/useSavingHOPPoints';
import { columns } from '../utils/columns';
import { HOPErrorMessage } from './HOPErrorMessage';
import { MobileTable } from './MobileTable';
import { StatelessTable } from './StatelessTable';

interface TablePointsProps {
  rows: HOP[];
  loading: boolean;
  savingHOPData: (rows: any) => void;
  address: string;
  coordsExist?: boolean;
}

export const TablePoints = ({ rows, loading, savingHOPData, address, coordsExist }: TablePointsProps) => {
  const { useMakeDown, useMakeUp } = useBreakpointsState();
  const sm = useMakeDown(1050);
  const lg = useMakeUp(1050);
  const { values } = useSavingHOPPoints();
  const selectedPointId = values?.retirementPoints?.selectedPoint?.andreani_id;

  if (loading) {
    return (
      <Box
        width={lg ? '700px' : !sm ? '50vw' : '62.5vw'}
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="30%"
        marginBottom="30%"
      >
        <Loading />
      </Box>
    );
  }
  if (rows.length && coordsExist && !sm) {
    return (
      <InfoTable
        rowIdKey="andreani_id"
        columns={columns}
        rows={orderBy(rows, 'googleDistance.value', 'asc').map((row) => ({ ...row }))}
        onRowClick={(row) => {
          savingHOPData({ retirementPoints: rows, selectedPoint: row, approved: true, address });
        }}
        clickableRows
        alternValue={selectedPointId}
      />
    );
  }
  if (sm && rows.length && coordsExist) {
    return <MobileTable rows={orderBy(rows, 'googleDistance.value', 'asc')} address={address} />;
  }
  if (!rows.length && coordsExist) {
    return <StatelessTable />;
  }
  return <HOPErrorMessage />;
};
