import { EcommerceDAO } from './EcommerceDAO';
import { Zone } from '../../../app/models';

export class ZoneDAO extends EcommerceDAO<Zone> {
  constructor() {
    super('zone', 'id');
  }

  async getAll(): Promise<Zone[]> {
    const response = await this.fetchData(`/zone/@ALL@`);
    return response.json();
  }
}
