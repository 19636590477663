import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { CartView, Links } from 'src/app/views';
import { useCartContext } from 'src/context/CartContext/CartContextProvider';
import { EditQuantity } from 'src/context/CartContext/types';
import { useHistory, useLocation } from 'react-router';
import { CartItem } from 'src/app/models';
import { LinksBreadcrumbs, useBreadcrumbs } from 'src/commons';
import { Container, Grid } from '@material-ui/core';
import { useCheckCartStock } from '../../../app/hooks';
import { Analytics } from '../../../platform/analytics';
import { ecommerceConfig } from '../../../config/ecommerce';

// TODO: falta agregar un loading state
export const Cart = () => {
  const { cartState, dispatch } = useCartContext();
  const { state, search: adsQuery } = useLocation<{ goBack: Links[] }>();
  const history = useHistory();

  const onChangeQuantity = ({ id, newQuantity, publication }: EditQuantity) => {
    if (newQuantity === 0) {
      dispatch.removeItem(id);
      Analytics.trackRemoveFromCart({ publication, quantity: 1 });
    } else {
      dispatch.editQuantity({ id, newQuantity });
    }
    dispatch.updateCartTotals();
  };

  const onDeleteItem = (item: CartItem) => {
    dispatch.removeItem(item.publication.id);
    Analytics.trackRemoveFromCart({ publication: item.publication, quantity: item.quantity });
    dispatch.updateCartTotals();
  };

  const linksNew = useMemo(() => {
    const linksProduct: LinksBreadcrumbs[] = [{ label: 'Mi carrito' }];
    if (!state?.goBack) {
      linksProduct.unshift({ label: 'Inicio', url: '/' });
    }
    return linksProduct;
  }, [state]);

  const links = useBreadcrumbs({
    links: linksNew,
    oldLinks: state?.goBack,
  });

  const onBuyClick = useCallback(() => {
    history.push({
      pathname: `/cart/checkout/${adsQuery}`,
      state: {
        goBack: links,
      },
    });
  }, [history, links, adsQuery]);

  useCheckCartStock();

  const shipping = cartState.items.filter(({ publication }) => publication.shipment_category_id === 2);

  return (
    <>
      <Helmet>
        <title>{ecommerceConfig.general.title}</title>
        <meta name="description" content={ecommerceConfig.general.description} />
      </Helmet>
      <Container maxWidth="lg">
        <Grid container>
          <CartView
            shipping={!!shipping.length}
            products={cartState.items}
            onChangeQuantity={({ id, newQuantity, publication }) => onChangeQuantity({ id, newQuantity, publication })}
            onDeleteItem={(item) => onDeleteItem(item)}
            dataResume={cartState.totals}
            onBuyClick={onBuyClick}
          />
        </Grid>
      </Container>
    </>
  );
};
