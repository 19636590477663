import { makeStyles } from '@material-ui/core';

export const emailSubcriberStyles = makeStyles((theme) => ({
  title: {
    color: '#fc0303',
    fontSize: theme.typography.body2.fontSize,
    margin: '0 0 30px',
    padding: '25px 0 0 0',
    [theme.breakpoints.down('md')]: {
      margin: '0px',
      padding: '0px',
    },
  },
  input: {
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '30px',
    },
  },
}));
