import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useBuyerDAO } from 'src/app/business';
import { useRolesContext } from 'src/context';
import { FormContextProvider } from 'src/lib/templates/context';
import { Buyer } from '../../../../../../../app/models';
import { FormContent } from './components';
import { userInformationTemplate } from '../../template';
import { InitialValueDefinition } from '../../types';

export interface MainFormProps {
  initialValue: InitialValueDefinition;
  raw?: Buyer;
}

export const MainForm = ({ initialValue, raw }: MainFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useRolesContext();
  const history = useHistory();
  const buyerDAO = useBuyerDAO();

  const onSubmit = useCallback(
    async (values: InitialValueDefinition) => {
      try {
        const transformed: Buyer = {
          buyer_id: userInfo.id,
          email: userInfo.email,
          name: values.name,
          phone: `${values.phone.areaCode} ${values.phone.number}`,
          date_of_birth: values.dateOfBirty,
          last_name: values.lastName,
          document_number: values.document,
          document_type: 'DNI',
          company: '',
          valid_user: true,
        };
        const { account_id, ...rest } = raw || ({} as Buyer);
        const toSave = raw ? { ...rest, ...transformed } : transformed;
        await buyerDAO.save(toSave);
        enqueueSnackbar('Tus datos modificados correctamente.', { variant: 'success' });
        history.goBack();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('No se pudo completar la operacion.', { variant: 'error' });
      }
    },
    [raw, userInfo.id, userInfo.email, buyerDAO, enqueueSnackbar, history],
  );
  return (
    <FormContextProvider initialValues={initialValue} onSubmit={onSubmit} template={userInformationTemplate}>
      <FormContent />
    </FormContextProvider>
  );
};
