import { Box, Button, Grid, makeStyles, Typography, } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import React, { useCallback, useEffect, useState } from 'react';
import { ProfileUserFB } from 'src/app/models';
import { FacebookApi, StatusResponse } from 'src/app/services';

export interface ResponseHandler extends StatusResponse {
  userInfo?: ProfileUserFB;
}

export interface FbState {
  isSdkLoaded?: boolean;
  isProcessing?: boolean;
  signedIn?: boolean;
  autoLoad?: boolean;
}

export const useStyles = makeStyles(() => ({
  facebook: {
    maxWidth: "400px",
    maxHeight: "40px",
    minWidth: "208px",
    padding: 0,
    borderRadius: '4px',
    height: '40px',
    textTransform: 'none',
    backgroundColor: '#1877F2',
    '&:hover': {
      backgroundColor: 'rgb(27, 38, 117)',
    },
  },
  container: { width: '100%', borderRadius: '4px' },
  image: { borderRadius: '0px 4px 4px 0px', padding: 0 },
}));

export interface FacebookButtonProps {
  appId: string;
  xfbml?: boolean;
  cookie?: boolean;
  scope?: string;
  fields?: string;
  version?: string;
  language?: string;
  redirectUri?: string;
}

export const FacebookButton = () => {
  const [fbSdkloaded, setFbSdkloaded] = useState(false);
  const [user, setUser] = useState<ProfileUserFB | null>(null)
  const classes = useStyles()

  const getCurrentStatus = useCallback(async () => {
    const status = await FacebookApi.getLoginStatus();
    const isConnected = status.status === 'connected'
    if (isConnected) {
      const useData = await FacebookApi.getUserInfo()
      setUser(useData)
    }
  }, [])

  const initializeFbSdk = useCallback(() => {
    if (!window.FB || fbSdkloaded) return;
    setFbSdkloaded(true);
    FacebookApi.init()
    getCurrentStatus()
  }, [fbSdkloaded, getCurrentStatus])

  useEffect(() => {
    if (fbSdkloaded) return;
    const script = document.createElement('script');
    script.src = '//connect.facebook.net/es_LA/sdk.js';
    script.onload = initializeFbSdk;
    script.async = true;
    script.id = 'facebook-jssdk';
    document.querySelector('body')?.appendChild(script);
    return () => {
      // Cleanup function that runs when component unmounts
      document.getElementById('facebook-jssdk')?.remove();
    };
  }, [fbSdkloaded, initializeFbSdk])

  return (<Button className={classes.facebook} variant="contained" onClick={() => FacebookApi.onInitLogin()}>
    <Grid className={classes.container} container justify="center" alignItems="center">
      <Grid item xs={2} container alignItems="center" justify="center">
        <FacebookIcon color="inherit" htmlColor='white' />
      </Grid>
      <Grid item xs={10} container direction="row" justify="space-between">
        <Grid item style={{ alignItems: "center", display: "flex" }}>
          <Typography align="left" variant="caption" >
            <Box display="flex" color="primary.contrastText" alignItems="center">
              {user?.id ? `Continuar como ${user.first_name}` : `Continuar con Facebook`}
            </Box>
          </Typography>
        </Grid>

        {user?.id ? <Grid
          item
          className={classes.image}
          component="img"
          width={40}
          height={40}
          src={user?.picture.data.url}
        /> : <></>}
      </Grid>
    </Grid>
  </Button>)
};
