import React, { useCallback } from 'react';
import { Product } from 'src/app/models/business/Product';
import { Link } from 'react-router-dom';
import { ProductCard } from './ProductCard';
import { ProductCardRow } from './ProductCardRow';

interface ProductCardViewProps {
  listName: string | undefined;
  data: Product;
  viewGrid?: boolean;
  isFavorite: boolean;
  onAddFavorite?: () => void;
  onRemoveFavorite?: () => void;
  tracking: boolean | undefined;
}

export const ProductCardView = ({
  listName,
  data,
  viewGrid = true,
  isFavorite = false,
  onRemoveFavorite,
  onAddFavorite,
  tracking = false,
}: ProductCardViewProps) => {

  const onRemoveF = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (onRemoveFavorite) {
        onRemoveFavorite();
      }
    },
    [onRemoveFavorite],
  );

  const onAddF = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (onAddFavorite) {
        onAddFavorite();
      }
    },
    [onAddFavorite],
  );

  return (
    <Link to={`/product/${data.productId}`} style={{ textDecoration: 'none' }}>
      {viewGrid ? (
        <ProductCard
          listName={listName || undefined}
          data={data}
          enableStock={data.has_stock}
          onAddFavorite={onAddF}
          onRemoveFavorite={onRemoveF}
          isFavorite={isFavorite}
          tracking={tracking}
        />
      ) : (
        <ProductCardRow
          listName={listName || undefined}
          data={data}
          enableStock={data.has_stock}
          onRemoveFavorite={onRemoveF}
          onAddFavorite={onAddF}
          isFavorite={isFavorite}
          tracking={tracking}
        />
      )}
    </Link>
  )
};
