import { Box, Typography, Button } from '@material-ui/core';
import React from 'react';
import { ApprovedCheck } from 'src/assets/images/svgs/ApprovedCheck';
import { FailedCheck } from 'src/assets/images/svgs/FailedCheck';
import { couponFormStyles as useStyles } from 'src/app/views/styles/couponForm';
import { useMultiStepFormContext } from 'src/lib/MultiStepForm';
import { ecommerceConfig } from 'src/config/ecommerce';
import { useBreakpointsState } from 'src/app/hooks/useMobile';
import { ResponseModalEquifaxProps } from '../interfaces';
import  SelectSpecificEquifaxError  from './SelectSpecificEquifaxError'; 

export const ResponseModalEquifax = ({ bloqued, approved, failed, error }: ResponseModalEquifaxProps) => {
  const classes = useStyles();
  const { setActiveStep } = useMultiStepFormContext();
  const { sm } = useBreakpointsState();

  const ecommerce = ecommerceConfig.general.title;
  let link;

  switch (ecommerce) {
    case 'Novatech Store':
      link = 'contacto@novatech.com.ar';
      break;
    case 'enova - Tecnología pensada para vos':
      link = 'contacto@tiendaenova.com.ar';
      break;
    default:
      link = 'contacto@novatech.com.ar';
      break;
  }

  const handleStep = () => setActiveStep(0);

  if (approved && !bloqued && !failed) {
    return (
      <Box className={sm ? classes.containerMessageMobile : classes.containerMessage}>
        <ApprovedCheck width="34" height="34" color={ecommerceConfig.ecommercePalette.themeColor} />
        <Typography className={classes.TypographyBold} align="center">
          ¡Confirmamos tu información!
        </Typography>
        <Typography className={classes.TypographyLight}>Podés continuar con tu operación.</Typography>
      </Box>
    );
  }
  if (bloqued && error) {
    return <Box>
      <SelectSpecificEquifaxError error={error} link={link}/>
    </Box>
  }
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" paddingTop="25%" marginBottom="25%">
      <FailedCheck width="34" height="34" color="#4F4F4F" />
      <Typography className={classes.TypographyBold}>Respuestas incorrectas.</Typography>
      <Typography className={classes.TypographyLight}>No pudimos comprobar tu identidad.</Typography>
      <Typography className={classes.TypographyLight}>Verifica que las respuestas sean correctas.</Typography>
      <Typography className={classes.TypographyLight}>Dispone unicamente de 3 reintentos.</Typography>
      <Button type="button" color="primary" variant="contained" className={classes.buttonStyles} onClick={handleStep}>
        Reintentar
      </Button>
    </Box>
  );
};
