import { EcommerceDAO } from './EcommerceDAO';
import { Publication } from '../../../app/models';

export class PublicationDAO extends EcommerceDAO<Publication> {
  constructor() {
    super('publication', 'publicationId');
  }

  async getByPublication(publication: string): Promise<Publication[]> {
    // TODO: ver como hacer esto mejor
    const response = await this.fetchData(`/publication/${publication}`);
    return response.json();
  }
}
