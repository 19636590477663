/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ValidationErrors } from 'final-form';
import _ from 'lodash';
import { Template, TemplateProperty, TemplatePropertyValidator } from './types';
import { AnyObject } from '../../commons/types';
import { getJoiSchema } from './joiHelpers';
import { getErrorMessage } from './errors';

export const validateTemplate = async (value: any, template: Template) => {
  const schema = await getJoiSchema(template.template);
  const result = schema.validate(value, { abortEarly: false });

  const error = result?.error || result?.error;
  if (error) {
    return error.details.reduce((acum: AnyObject, item) => {
      const fieldName = item?.context?.key;
      const errorType = item.type.split('.')[1];
      const evalTemplate = template.template.find((template) => template.name === item.path[0].toString());
      if (evalTemplate?.type.name === 'object') {
        const errorTemplates = _.get(evalTemplate, 'type.kind');
        const correspondingTemplate = errorTemplates?.find((templateItem: TemplateProperty) => templateItem.name === fieldName);
        const templateValidators = correspondingTemplate?.validators;
        let customMessage = null;
        if (templateValidators) {
          const correspondingValidator = templateValidators.find((validator: TemplatePropertyValidator) => validator.name === errorType);
          customMessage = correspondingValidator?.errorMessage;
        }
        _.set(acum, item.path.join('.'), customMessage || getErrorMessage(item));
      } else {
        const correspondingTemplate: any = evalTemplate?.name === fieldName ? evalTemplate : undefined;
        const templateValidators = correspondingTemplate?.validators;
        let customMessage = null;
        if (templateValidators) {
          const correspondingValidator = templateValidators.find((validator: TemplatePropertyValidator) => validator.name === errorType);
          customMessage = correspondingValidator?.errorMessage;
        }
        _.set(acum, item.path.join('.'), customMessage || getErrorMessage(item));
      }
      return acum;
    }, {});
  }
  if (!result.value) {
    throw new Error(`Invalid value for template ${template.name}`);
  }
  return undefined;
};

export const makeFinalFormValidate =
  <T>(template: Template) =>
  (values: T): ValidationErrors | Promise<ValidationErrors> | undefined =>
    validateTemplate(values, template);

/**
 *  TODO hacer un fix en la toma de los errores custom ya que para niveles
 * mas internos no esta tomando el errorMessage plasmado en el template ya que si es un objeto no entra
 * validar recursividad
 * */
