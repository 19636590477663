import React, { useMemo } from 'react';
import { Grid, Typography, Box, useMediaQuery, Theme, Button, IconButton } from '@material-ui/core';
import { FormDialogCheckbox } from 'src/lib/templates/components';
import { FormRegister } from 'src/screens/Public/Register/types';
import ErrorIcon from '@material-ui/icons/Error';
import { AppTooltip } from 'src/commons/components/AppTooltip';
import { FieldValidator } from 'final-form';
import { FormInputData, CommonFormInput } from '../CommonFormInput';
import { useScrollToTopOnMount, ComponentFormProps } from '../../../../commons';
import { TermsAndConditionsText } from '../../../../screens/Public/TermsAndConditions';

export interface AccountInfoFormViewProps extends ComponentFormProps<FormRegister> {
  disabledNext: boolean;
  validateEmail?: FieldValidator<any>;
}

const Icon = (
  <AppTooltip
    title={
      <Typography variant="caption" color="textSecondary">
        Tu clave debe tener entre 6 y 20 caracteres. No incluyas tu nombre, apellido o e-mail, ni caracteres idénticos consecutivos.
      </Typography>
    }
  >
    <IconButton size="small" aria-label="passwordInfo" color="primary">
      <ErrorIcon color="primary" fontSize="small" />
    </IconButton>
  </AppTooltip>
);

export const AccountInfoFormView = ({ form, disabledNext, onSubmit, validateEmail }: AccountInfoFormViewProps) => {
  useScrollToTopOnMount();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isDown768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));

  const formInput: FormInputData[] = useMemo(
    () => [
      {
        variant: 'standard',
        name: 'accountInfo.name',
        label: 'Nombre',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: 'accountInfoName',
        autoComplete: 'name',
      },
      {
        variant: 'standard',
        name: 'accountInfo.lastname',
        label: 'Apellido',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: 'accountInfoLastname',
        autoComplete: 'family-name',
      },
      {
        variant: 'standard',
        type: 'email',
        name: 'accountInfo.email',
        label: 'E-mail',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: 'accountInfoEmail',
        autoComplete: 'email',
        validator: validateEmail,
      },
      {
        variant: 'standard',
        name: 'accountInfo.document',
        label: 'DNI',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: 'accountInfoDocument',
        autoComplete: 'on',
      },
      {
        variant: 'standard',
        type: 'password',
        name: 'accountInfo.password',
        label: 'Clave',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: 'accountInfoPassword',
        autoComplete: 'new-password',
        inputBaseProps: { endAdornment: Icon },
      },
      {
        variant: 'standard',
        type: 'password',
        name: 'accountInfo.confirmPassword',
        label: 'Confirmar Clave',
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
        required: true,
        id: 'accountInfoConfirmPassword',
        autoComplete: 'new-password',
      },
    ],
    [validateEmail],
  );

  return (
    <Grid item lg={8} md={8} sm={isDown768 ? 6 : 12} xs={12}>
      <Box bgcolor="white" borderRadius={8} boxShadow={3} display="content">
        <Box padding={isDownSm ? 2 : 3}>
          <Grid container direction="row" spacing={3}>
            {formInput.map((input) => (
              <CommonFormInput<FormRegister>
                key={input.name}
                form={form}
                name={input.name}
                label={input.label}
                variant={input.variant}
                type={input.type}
                lg={input.lg}
                md={input.md}
                sm={input.sm}
                xs={input.xs}
                id={input.id}
                autoComplete={input.autoComplete}
                helperText={input.helperText}
                inputBaseProps={input.inputBaseProps}
                validator={input.validator}
              />
            ))}
            <Grid item xs={12} />
            <Grid item xs={12}>
              <FormDialogCheckbox
                name="termsAndConditions"
                form={form}
                dialogContent={TermsAndConditionsText}
                label={({ onOpenModals }) => (
                  <Typography variant="body2" color="inherit">
                    <span>Acepto los </span>
                    <Box color="primary.main" display="inline" onClick={onOpenModals}>
                      términos y condiciones
                    </Box>
                    <span>. Autorizo el uso de mis datos y recibir mails.</span>
                  </Typography>
                )}
              />
            </Grid>
            <Grid item container xs={12} justify="flex-end">
              <Button size="large" disabled={disabledNext} variant="contained" color="primary" onClick={onSubmit}>
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
