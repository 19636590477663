import { useMemo, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { orderBy } from 'lodash';
import { useFormContext } from '../../../../../../../../lib/templates';
import { City, State } from '../../../../../../../../app/models';
import { useLocationLibbyCall, useLocationDAO } from '../../../../../../../../app/business';
import { useDebounce } from '../../../../../../../../commons';
import { deliveryInitialValue } from '../../../../../initialValues';
import { IDeliveryManage } from '../types';

export const useGenerateAddressManagerValues = () => {
  const { form, values } = useFormContext<IDeliveryManage>();
  const [cities, setCities] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const locationDAO = useLocationDAO();

  const { data = [], working } = useLocationLibbyCall<State[]>({ methodName: 'getStates' });
  const filteredData = data.filter((province) => province.name !== 'default');
  const [search, setSearch] = useState(values.city?.name || '');
  const searchDebunce = useDebounce(search, 600);

  const selectedProvince = useMemo(() => values.province?.id !== '', [values.province?.id]);

  const onInputChangeCity = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setSearch(value);
    },
    [setSearch],
  );

  useEffect(() => {
    if (values?.city === null) {
      const copyValuesForm = { ...values };
      copyValuesForm.city = deliveryInitialValue.city;
      form.reset(copyValuesForm);
    }
  }, [search, values, form]);

  useEffect(() => {
    if (selectedProvince && searchDebunce && searchDebunce !== values.city?.name) {
      setLoading(true);
      (async () => {
        const city = await locationDAO.getCityByState(values.province?.id, searchDebunce);
        setCities(city);
        setLoading(false);
      })();
    }
  }, [selectedProvince, locationDAO, values.province, searchDebunce, values.city]);

  return { cities, search, provinces: orderBy(filteredData, 'name'), loading, loadingProvinces: working, onInputChangeCity };
};
