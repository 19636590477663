import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

interface CardDiscountProps {
  discount: number;
  isAbsolute?: boolean;
  semiBorderRadius?: boolean;
  invert?: boolean;
  padding?: string;
  productId: string | number; 
}
export const CardDiscount = ({
  discount,
  isAbsolute = true,
  semiBorderRadius = true,
  invert = false,
  padding = '3px 12px 4px 12px',
  productId,
}: CardDiscountProps) => (
  <Box position={isAbsolute ? 'absolute' : 'relative'} top={0} right={!invert && 0} left={invert && 0}>
    <Grid container justify="flex-end">
      <Box
        borderRadius={semiBorderRadius ? (invert ? '5px 0px 5px 0px' : '0px 5px 0px 5px') : '5px'}
        color="primary.main"
        flexDirection="column"
        bgcolor="primary.light"
        p={padding}
      >
        <Typography gutterBottom variant="caption" id={`${productId.toString()}-discount`}>
          {`${discount.toFixed()}% OFF `}
        </Typography>
      </Box>
    </Grid>
  </Box>
);
