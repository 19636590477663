import { Confirmation } from '../../../app/models';
import { EcommerceDAO } from './EcommerceDAO';

export class ConfigurationDAO extends EcommerceDAO<Confirmation> {
  constructor() {
    super('configuration', 'configuration_id');
  }

  async getData() {
    const res = await this.fetchData(`/configuration`, { method: 'get' });
    return res.json();
  }
}
