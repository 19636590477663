import { EcommerceDAO } from './EcommerceDAO';
import { TargetPromotion } from '../../../app/models';

export class TargetPromotionDAO extends EcommerceDAO<TargetPromotion> {
  constructor() {
    super('targetpromotion', 'name');
  }

  async getTargetByName(name: number): Promise<any> {
    const response = await this.fetchData(`/target/${name}`);
    return response.json();
  }
}
