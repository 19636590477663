import { useState, useEffect, useMemo } from 'react';
import { CardData, Emitter, CardType, PaymentSimulation, State, Publication } from 'src/app/models';
import { useSimulateDAO } from 'src/app/business/Simulate';
import { useCardLibbyCall, useLocationLibbyCall } from 'src/app/business';
import { paymentInitialValue } from '../../../Common/Checkout/initialValues';

export const usePaymentInfoShow = (quantity: number, publication?: Publication) => {
  const [promotions, setPromotions] = useState<CardData[]>([]);
  const [bankOptions, setBankOptions] = useState<PaymentSimulation[]>([]);
  const [validEmitters, setValidEmitters] = useState<Emitter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedEmitter, setSelectedEmitter] = useState<Emitter>(paymentInitialValue.emitter);
  const [selectedCard, setSelectedCard] = useState<CardType>(paymentInitialValue.card);
  const [selectedProvince, setSelectedProvince] = useState<State>({
    id: '2',
    name: 'Ciudad Autonoma de Buenos Aires',
    matches: ['caba', 'c.a.b.a', 'ciudad autonoma de buenos aires', 'ciudad autónoma de buenos aires'],
  });
  const simulateDAO = useSimulateDAO();

  const { data: emitters = [], working } = useCardLibbyCall<Emitter[]>({
    methodName: 'getAllEmitters',
  });

  const { data: _provinces = [], working: workingProvinces } = useLocationLibbyCall<State[]>({ methodName: 'getStates' });
  const provinces = _provinces.filter((province) => province.name !== 'default');
  const isSelectedEmitter = selectedEmitter.card_emiter_id !== 0;
  const isSelectedCard = selectedCard.card_id !== '' && Number(selectedCard.card_id) > 0;

  useEffect(() => {
    (async () => {
      if (!(emitters.length && !validEmitters.length && selectedProvince.id !== '')) return;

      setLoading(true);
      const cart = {
        items: [
          {
            quantity,
            publication: {
              ...publication,
            },
          },
        ],
        owner: `${publication?.id}`,
      };

      const results: PaymentSimulation[] = await Promise.all(
        emitters.map((_emiter) => simulateDAO.generateSimulate(cart, _emiter.card_emiter_id, selectedProvince.id)),
      );

      const filter = results.filter((result) => Object.keys(result.options).length);
      const emiter = emitters.filter((_emiter) => filter.find((fil) => fil.emiter === _emiter.card_emiter_id.toString()));
      if (emiter.length) {
        setValidEmitters(emiter);
      }
      setBankOptions(results);
      setLoading(false);
    })();
  }, [quantity, publication, simulateDAO, emitters, validEmitters, selectedProvince.id]);

  useEffect(() => {
    (async () => {
      if (isSelectedEmitter) {
        setLoading(true);
        const cart = {
          items: [
            {
              quantity,
              publication: {
                ...publication,
              },
            },
          ],
          owner: `${publication?.id}`,
        };
        const data: PaymentSimulation = await simulateDAO.generateSimulate(cart, selectedEmitter.card_emiter_id, selectedProvince.id);
        setPromotions(Object.values(data.options));
        setLoading(false);
      }
    })();
  }, [quantity, publication, selectedEmitter, isSelectedEmitter, simulateDAO, selectedProvince.id]);

  const cards = useMemo<CardType[]>(() => promotions.map((item) => item.card), [promotions]);

  const cardOptionsSelected = useMemo<CardData[]>(() => {
    if (isSelectedCard && Number(selectedCard.card_id) > 0)
      return promotions.filter((item) => item.card.card_id.toString() === selectedCard.card_id.toString());
    return [] as CardData[];
  }, [promotions, selectedCard.card_id, isSelectedCard]);

  return {
    provinces,
    emitters,
    selectedEmitter,
    selectedProvince,
    selectedCard,
    promotions,
    cards,
    cardOptionsSelected,
    validEmitters,
    loading,
    bankOptions,
    working: working || workingProvinces,
    setSelectedEmitter,
    setSelectedCard,
    setSelectedProvince,
  };
};
