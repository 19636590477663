import { makeStyles } from '@material-ui/core';
import palette from 'src/theme/palette';

export const categoriesViewStyles = makeStyles((theme) => ({
  drawer: {
    width: '18em',
    flexShrink: 0,
  },
  cardContainer: {
    minHeight: '100px',
  },
  fullWidth: {
    width: '100%',
  },
  drawerPaper: {
    width: '18em',
  },
  container: {
    marginBottom: 60,
  },
  filterTextContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  resultResume: {
    padding: 16,
    '@media (max-width:48em)': {
      background: theme.palette.primary.dark,
      '& .MuiTypography-colorTextSecondary': {
        color: 'white !important',
      },
    },
  },
  banner: {
    borderRadius: '8px',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
    },
  },
  resultResumeMobile: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  resultTextMobile: { fontSize: 18, lineHeight: '22,77px' },
  filterButton: {
    textTransform: 'none',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  filtrarGrid: {
    textAlign: 'start',
    marginTop: 8,
  },
  distanceItem: {
    marginLeft: 7,
  },
  productGrid: {
    [theme.breakpoints.up('lg')]: {
      width: '51em',
    },
  },
  filterCollapsedContainer: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  divider: {
    width: '100%',
    marginBottom: 16,
  },
  paginationGrid: {
    marginTop: 20,
  },
  cardItems: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(265px, 1fr))',
  },
  buttonViewGridSmall: { height: 25, width: 25, '&:hover': { backgroundColor: 'transparent' } },
  buttonViewGridLarge: { height: 40, width: 40, '&:hover': { backgroundColor: 'transparent' } },
  iconViewGridSmall: { fontSize: 18 },
  iconViewGridLarge: { fontSize: 22 },
  iconViewGridRowSmall: { fontSize: 25 },
  iconViewGridRowLarge: { fontSize: 28 },
  dropdown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  dropdownOpen: {
    transform: 'rotate(-180deg)',
  },
  dropdownClosed: {
    transform: 'rotate(0)',
  },
  onOptionHover: {
    '&:hover': {
      color: palette.primary.main,
    },
  },
}));

export const ProductNotFoundStyles = makeStyles((theme) => ({
  icon: {
    width: '50px',
    height: '50px',
    verticalAlign: 'middle',
  },
  stanStyle: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));
