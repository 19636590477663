import { checkCodeInitialValue, CheckCodeInitialValue } from '../Register/initialValues';

export interface RecoveryInitialValue {
  email: string;
}

export const recoveryInitialValue = {
  email: '',
};

export const ValuesForm = {
  email: '',
  checkCodeInfo: checkCodeInitialValue,
};

export interface ChangePasswordInitialValue {
  password: string;
  confirmPassword: string;
}

export const changePasswordInitialValue = {
  password: '',
  confirmPassword: '',
};

export interface FormValues extends RecoveryInitialValue {
  checkCodeInfo: CheckCodeInitialValue;
  changePasswordInfo: ChangePasswordInitialValue;
}
