import { useEffect } from 'react';
import { AnyObject } from '../../../../../../commons';
import { useFormContext, useTemplateContext } from '../../../../../../lib/templates';
import { TokenizedCard } from '../../../../../../app/models';
import { useRolesContext } from '../../../../../../context';
import { tokenCardTemplate } from '../../../templates';
import { tokenCardInitialValue } from '../../../initialValues';

export const useTokenCardLogic = ({ tokenCards, showEmitters }: { tokenCards: TokenizedCard[]; showEmitters: boolean }) => {
  // TODO: como es dinamico tiparlo trae problemas al querer setear el estado de la key si esta no esta en el initial value
  const { form, values } = useFormContext<AnyObject>();
  const { setTemplate } = useTemplateContext();
  const { userInfo } = useRolesContext();

  useEffect(() => {
    if (!userInfo.id) return;

    if (tokenCards.length === 0 || showEmitters) {
      // aqui quito el template adicional del responsable inscripto si existe
      setTemplate((template) => {
        if (!template.template.find((property) => property.name === tokenCardTemplate.name)) {
          return template;
        }
        form.change(tokenCardTemplate.name, undefined);
        return {
          ...template,
          template: template.template.filter((property) => property.name !== tokenCardTemplate.name),
        };
      });
    } else {
      // aqui agrego el template adicional del responsable si no existe
      setTemplate((template) => {
        if (template.template.find((property) => property.name === tokenCardTemplate.name)) {
          return template;
        }
        form.change(tokenCardTemplate.name, tokenCardInitialValue);
        return {
          ...template,
          template: [...template.template, tokenCardTemplate],
        };
      });
    }
  }, [setTemplate, values, form, userInfo.id, tokenCards, showEmitters]);
};
