import { makeBusinessEntity, PartialBusinessEntityOptions } from '../../../lib/libby/generator';
import { TokenizedCard } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'tokenized_cards';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'token_id',
      labelKey: 'name',
    },
    withSelectedChips: {
      idKey: 'token_id',
      labelKey: 'name',
    },
  },
};

const optionsToUse = !businessOptions.TokenizedCard
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TokenizedCard,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TokenizedCard.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
  },
} = makeBusinessEntity<TokenizedCard>(DAO_NAME, optionsToUse);

// hooks
export const useTokenizedCardFetchById = fetchById;
export const useTokenizedCardLibbyCall = libbyCall;
export const useTokenizedCardDAO = libbyDAO;
export const useTokenizedCardLibbyFetch = libbyFetch;

// components/hoc
export const TokenizedCardAutocomplete = entityAutocomplete;
export const TokenizedCardCall = renderPropCall;
export const TokenizedCardById = renderPropFetchById;
export const TokenizedCardSelect = simpleSelect;
export const TokenizedCardAccordionSelector = accordionSelector;
export const TokenizedCardSelectedChips = selectedChips;

// context
// ByIdContext
export const useTokenizedCardByIdContext = useByIdContext;
export const TokenizedCardByIdProvider = ByIdProvider;
export const TokenizedCardByIdContext = ByIdContext;
// GeneralContext
// export const useTokenizedCardContext = useGeneralContext;
// export const TokenizedCardProvider = GeneralProvider;
// export const TokenizedCardContext = GeneralContext; // Para esta entidad usaremos un contexto personalizado
