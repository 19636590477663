import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, Backdrop, CircularProgress, Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { EMITTERS } from 'src/app/const/Emitters';
import { EmittersContextProvider, useEmittersContext } from '../../../context/EmittersContext/EmittersContextProvider';
import { MultiStepFormContextProvider, useMultiStepFormContext } from '../../../lib/MultiStepForm';
import { Loading, BlockRoute, sortOptions, ModalDialog } from '../../../commons';
import { ecommerceConfig } from '../../../config/ecommerce';
import { checkoutInitialValue } from './initialValues';
import { template } from './templates';
import { useStepsCheckout } from './steps';
import { CheckoutForm } from './components';
import { useCartContext, useGeneralContext } from '../../../context';
import { Analytics } from '../../../platform/analytics';
import theme from '../../../theme';
import { useCheckCartStock } from '../../../app/hooks';
import { FailurePaymentMethodView } from '../../../app/views/components/FailurePaymentMethodView';
import { useLoadingHook } from './hooks/useLoadingHook';

type CheckoutRAWProps = {
  setIsCapresca: React.Dispatch<React.SetStateAction<boolean>>
}



const CheckoutRAW = ({ setIsCapresca }: CheckoutRAWProps) => {
  const [isTracked, setIsTracked] = useState(false);
  const history = useHistory();
  const { working, initialLoad, emitters } = useEmittersContext();
  const { cartState } = useCartContext();
  const { dispatch, generalState } = useGeneralContext();
  const { stockChecked } = useCheckCartStock();

  // checking cart items
  useEffect(() => {
    if (stockChecked && !cartState.items.length) {
      dispatch.setIsBlocking(false);
      setTimeout(() => history.replace('/cart'), 0);
    }
  }, [cartState.items.length, history, stockChecked, dispatch]);

  useEffect(() => {
    // calling start checkout
    if (cartState?.owner && cartState?.items?.length && !isTracked) {
      setIsTracked(true);
      Analytics.trackStartCheckout({ cart: cartState });
    }
  }, [cartState, isTracked]);

  useEffect(() => {
    if (generalState.paginate.path === history.location.pathname) return;
    dispatch.setPaginate({ ...generalState.paginate, path: history.location.pathname });
    dispatch.sortOption(sortOptions[0]);
  }, [dispatch, generalState.paginate, history.location.pathname]);

  const isCapresca = useMemo(() => {
    const checkIsCapresca = !!emitters.find((emiter) => emiter.card_emiter_id.toString() === EMITTERS.CATAMARCA_CREDITS.toString());
    setIsCapresca(checkIsCapresca);
    return checkIsCapresca;
  }, [emitters, setIsCapresca]);
  const { variable } = useLoadingHook([isCapresca]);

  const { working: wSteps } = useStepsCheckout({ isCapresca });

  const { error, openBackdrop, setError } = useMultiStepFormContext();

  const closeErrorModal = () => {
    setError({
      error: false,
      message: {
        title: '',
        description: '',
        details: '',
      },
    });
  };

  return working || wSteps || variable ? (
    <Loading />
  ) : (
    <>
      <Helmet>
        <title>{ecommerceConfig.general.title}</title>
        <meta name="description" content={ecommerceConfig.general.description} />
      </Helmet>
      <Container maxWidth="lg">
        <BlockRoute isBlocked={generalState.isBlocking} content="Seguro quieres salir de la compra ?" />
        <Grid container justify={initialLoad ? 'flex-start' : 'center'}>
          {initialLoad ? (
            <CheckoutForm />
          ) : (
            <Grid item>
              <FailurePaymentMethodView />
            </Grid>
          )}
        </Grid>
        <Backdrop style={{ zIndex: theme.zIndex.appBar + 1, background: 'rgba(255, 255, 255, 0.54)' }} open={openBackdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
        <ModalDialog maxWidth="xs" title={`${error?.message?.title}`} open={error.error} onHandleClose={closeErrorModal}>
          <Grid container direction="column">
            <Grid item>
              <Box bgcolor="primary.background" p={2} borderRadius={4} mb={4}>
                <Typography variant="body1">{error?.message?.description}</Typography>
                <Typography variant="body1">{error?.message?.details}</Typography>
              </Box>
            </Grid>

            <Grid item container justify="flex-end" style={{ gap: 7 }}>
              <Button onClick={closeErrorModal} color="primary" variant="contained" autoFocus>
                Volver
              </Button>
              {error?.message?.button &&
                error?.message?.button.length > 0 &&
                error.message.button.map((button) => (
                  <Button onClick={() => setError({ ...error, response: button.title })} color="primary" variant={button.variant} autoFocus>
                    {button.title}
                  </Button>
                ))}
            </Grid>
          </Grid>
        </ModalDialog>
      </Container>
    </>
  );
};

export const Checkout = () => {
  const [isCapresca, setIsCapresca] = useState<boolean>(false);
  const { steps } = useStepsCheckout({ isCapresca });

  return (
    <EmittersContextProvider>
      <MultiStepFormContextProvider initialValues={checkoutInitialValue} template={template} steps={steps}>
        <CheckoutRAW setIsCapresca={setIsCapresca} />
      </MultiStepFormContextProvider>
    </EmittersContextProvider>
  );
};
