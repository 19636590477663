import { makeStyles } from '@material-ui/core';

export const promotionsViewStyles = makeStyles((theme) => ({
  drawer: {
    width: 300,
    flexShrink: 0,
  },
  cardContainer: {
    minHeight: '400px',
  },
  fullWidth: {
    width: '100%',
  },
  drawerPaper: {
    width: 300,
  },
  container: {
    marginBottom: 60,
  },
  filterTextContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  resultResume: {
    padding: 16,
    [theme.breakpoints.down('md')]: {
      background: theme.palette.primary.dark,
      '& .MuiTypography-colorTextSecondary': {
        color: 'white !important',
      },
    },
  },
  banner: {
    borderRadius: '8px',
    marginBottom: '24px',
  },
  resultResumeMobile: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  resultTextMobile: { fontSize: 18, lineHeight: '22,77px' },
  filterButton: {
    textTransform: 'none',
    padding: 0,
  },
  filtrarGrid: {
    textAlign: 'start',
    marginTop: 8,
  },
  distanceItem: {
    marginLeft: 7,
  },
  productGrid: {
    [theme.breakpoints.up('lg')]: {
      width: 827,
    },
  },
  filterCollapsedContainer: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  divider: {
    width: '100%',
    marginBottom: 16,
  },
  paginationGrid: {
    marginTop: 20,
  },
  cardItems: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(265px, 1fr))',
  },
}));
