import React, { useMemo } from 'react';
import { Box, BoxProps, Grid, Typography, TypographyVariant } from '@material-ui/core';
import { ecommerceConfig } from 'src/config/ecommerce';
import { formatPriceNotDecimal, spliceText } from 'src/utils';
import {useBreakpointsState} from 'src/app/hooks/useMobile';

interface PriceViewProps {
  price: string;
  id?: string;
  color?: 'inherit' | 'initial' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  priceProps?: BoxProps;
  withDecimals?: boolean;
  beforeText?: string;
  afterText?: string;
  variant?: TypographyVariant;
  beforeProps?: BoxProps;
  afterProps?: BoxProps;
}

export const PriceView = ({
  price,
  variant = 'body1',
  id,
  color,
  withDecimals = false,
  beforeText = '',
  afterText = '',
  priceProps = {},
  afterProps = {},
  beforeProps = {},
}: PriceViewProps) => {
  const typographyDecimals = useMemo(() => ecommerceConfig.ecommerceTypography[variant], [variant]);
  const {xs} = useBreakpointsState();
  const wholeNumber = price.split('.')[0];
  const fixDecimal = Number(price.split('.')[1]) * 10 ** 1;
  const wholeDecimal = Math.round(Number(spliceText(fixDecimal.toString() || '', 2, 0, '.')) || 0);
  const parseDecimal = wholeDecimal > 0 ? (wholeDecimal < 10 ? wholeDecimal.toString().padStart(2, '0') : wholeDecimal.toString()) : '';

  return (
    <Grid container direction="row" alignItems="center">
      <Typography component="div" color={color} variant={variant}>
        <Box fontSize= '14px' display="flex" width= {xs ? "120%" : "100%"}  flexDirection="row"
         id={id}>
          <Box display="inline" {...beforeProps}>
            {beforeText}
          </Box>
          <Box display="inline" position="relative" fontSize={xs ? '13px' : '15px'} pl={0.5} {...priceProps}>
            {`$${formatPriceNotDecimal(Number(withDecimals ? wholeNumber : price))}`}
            {withDecimals && (
              <Box
                top={0}
                p={0}
                m={0}
                mt={-0.3}
                display="inline"
                position="absolute"
                {...typographyDecimals}
                fontSize={typographyDecimals?.fontSize?.replace('px', '') / 1.5}
              >
                {parseDecimal}
              </Box>
            )}
          </Box>
          <Box pl={parseDecimal ? 1.3 : 0} display="inline" {...afterProps}>
            {afterText}
          </Box>
        </Box>
      </Typography>
    </Grid>
  );
};
