import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const promotionsMenu: MenuItem = {
  key: 'privatePromotions',
  basePath: '/promotions/:id',
  enabled: true,
  icon: 'List',
  text: 'Promotions',
  children: routes,
};
