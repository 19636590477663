import { makeStyles } from '@material-ui/core';

export const couponFormStyles = makeStyles((theme) => ({
  grid: {
    border: '1px solid #B8B8B8',
    padding: '1rem',
    borderRadius: '.3rem',
  },
  containerForm: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
      width: '100%',
      marginTop: '16px',
    },
  },
  inputLabel: {
    [theme.breakpoints.down(500)]: {
      marginBottom: '1rem',
      width: '200px',
    },
  },
  input: {
    width: '200px',
    height: '40px',
    [theme.breakpoints.down(500)]: {
      width: '140px',
    },
  },
  buttonStyles: {
    width: '160px',
    height: '38px',
    margin: '1.2rem 0 0 1rem',
    [theme.breakpoints.down(500)]: {
      width: '101px',
    },
  },
  buttonLoading: {
    width: '160px',
    height: '38px',
    margin: '1.2rem 0 0 1rem',
    background: '#4F4F4F',
    [theme.breakpoints.down(500)]: {
      width: '101px',
    },
  },
  buttonModalStyles: {
    width: '160px',
    height: '38px',
    float: 'right',
    [theme.breakpoints.down(500)]: {
      width: '101px',
    },
  },
  errorMsg: {
    color: theme.palette.error.main,
  },
  TypographyBold: {
    fontWeight: 700,
    fontSize: '1.7rem',
    marginTop: '1rem',
    marginBottom: '.8rem',
  },
  TypographyLight: {
    fontSize: '.9rem',
  },
  containerMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '25%',
    marginBottom: '25%',
  },
  containerMessageMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
}));
