import React, { useCallback } from 'react';
import { Theme, useMediaQuery, Container, Box, Grid, Typography, Card, Button } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useBreadcrumbs, Loading } from 'src/commons';
import { AppBreadcrumbsApp } from 'src/app/views';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useBuyerLibbyCall, useConfirmationDAO } from 'src/app/business';
import { BuyerData, TokenizedCard } from 'src/app/models';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useScrollToTopOnMount } from 'src/commons/hooks/useScrollToTopUnMount';
import { confirmationTimeManager } from 'src/utils';
import { useTokenizedCardLibbyCall } from 'src/app/business/TokenizedCard';
import { useRolesContext } from 'src/context';
import { AddressView } from './component/AddressView';
import { TokenizedCards } from './component/TokenizedCards';
import { TextInput } from './common';
import { HOPView } from './component/HOPView';

export const Profile = () => {
  useScrollToTopOnMount();
  const confirmationDAO = useConfirmationDAO();
  const { userInfo } = useRolesContext();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const isBw768 = useMediaQuery<Theme>((theme) => theme.breakpoints.between(541, 769));
  const history = useHistory();

  const { data, working, recall } = useBuyerLibbyCall<BuyerData>({
    methodName: 'getBuyerInfo',
  });

  const { data: tokens, working: tokenWorking } = useTokenizedCardLibbyCall<TokenizedCard[]>({
    methodName: 'getAllTokenizedCards',
  });

  const links = useBreadcrumbs({
    links: isDownSm
      ? [
          { label: 'Perfil', url: '/dashboard' },
          { label: 'Mi cuenta', url: '/dashboard/profile' },
        ]
      : [
          { label: 'Mi cuenta', url: '/dashboard' },
          { label: 'Perfil', url: '/dashboard/profile' },
        ],
  });

  const handleUpdatePersonalInfo = useCallback(
    async (redirectTo: string) => {
      const nowTime = moment();
      // INFO: verifica cada 24 horas, tras la ultima verificacion
      const time = await confirmationTimeManager.retrieve();
      if (!moment(time).isValid() || moment(time).diff(nowTime) < 0) {
        await confirmationDAO.sendCode({ email: userInfo.email });
        history.push({
          pathname: '/dashboard/confirm',
          state: {
            goBack: '/dashboard/profile',
            goNext: redirectTo,
          },
        });
      } else {
        history.push(redirectTo);
      }
    },
    [history, userInfo.email, confirmationDAO],
  );

  return (
    <>
      <Helmet>
        <title>User Profile</title>
        <meta name="description" content="User profile" />
      </Helmet>
      <Container maxWidth="lg">
        <Box px={isDownSm ? 0 : '10%'} mt={2}>
          <AppBreadcrumbsApp
            links={links}
            iconSeparator={<Box component="div">/</Box>}
            iconGoBack={<ChevronLeftIcon color="primary" />}
            invert
            msgReturn=" "
            noMargin
          />

          <Grid container item spacing={isDownSm ? 0 : 2} xs={12} sm={isBw768 ? 8 : 12} md={8}>
            {!isDownSm && (
              <Grid item>
                <Typography variant="h5" color="textPrimary">
                  Perfil
                </Typography>
              </Grid>
            )}

            <Grid item container>
              {working ? (
                <Loading />
              ) : (
                <Box mb={2} mt={2}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography variant={isDownSm ? 'subtitle1' : 'h6'} color="textPrimary">
                        Mis datos
                      </Typography>
                    </Grid>
                    <Box display="flex" m={0} boxSizing="border-box" alignItems="flex-end">
                      <Button
                        style={{ textTransform: 'none', textDecoration: 'none' }}
                        color="primary"
                        onClick={() => handleUpdatePersonalInfo('/dashboard/profile/info')}
                      >
                        <Typography variant="caption" color="primary">
                          Modificar datos personales
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                  <Box justifyItems="center" component={Card} borderRadius={8} boxShadow={3} display="content">
                    <Box padding={isDownSm ? 2 : 3}>
                      <Grid container direction="row" spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextInput label="Nombre" value={data?.name || ''} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextInput label="Apellido" value={data?.last_name || ''} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextInput label="Telefono" value={data?.phone || ''} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextInput label="DNI" value={data?.document_number || ''} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextInput label="Fecha de nacimiento" value={moment(data?.date_of_birth || '').format('DD/MM/YYYY') || ''} />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item container>
              {working ? (
                <Loading />
              ) : (
                <Box mb={2} width="100%">
                  <Grid item container justify="space-between">
                    <Grid item>
                      <Typography variant={isDownSm ? 'subtitle1' : 'h6'} color="textPrimary">
                        Domicilios
                      </Typography>
                    </Grid>
                    <Box display="flex" m={0} mb={1} boxSizing="border-box" alignItems="flex-end">
                      <Button
                        style={{ textTransform: 'none', textDecoration: 'none' }}
                        color="primary"
                        onClick={() => handleUpdatePersonalInfo('/dashboard/profile/address/new')}
                      >
                        <Typography variant="caption" color="primary">
                          Agregar domicilio
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                  <AddressView addresses={data?.addresses || []} recall={recall} />
                </Box>
              )}
            </Grid>

            {data?.hop && (
              <Grid item container>
                {working ? (
                  <Loading />
                ) : (
                  <Box mb={2} width="100%">
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Typography variant={isDownSm ? 'subtitle1' : 'h6'} color="textPrimary">
                          Puntos HOP
                        </Typography>
                      </Grid>
                    </Grid>
                    <HOPView hop={data?.hop} />
                  </Box>
                )}
              </Grid>
            )}

            <Grid item container>
              {tokenWorking ? (
                <Loading />
              ) : (
                <Box mb={2} width="100%">
                  <Grid item container justify="space-between">
                    <Grid item>
                      <Typography variant={isDownSm ? 'subtitle1' : 'h6'} color="textPrimary">
                        Tarjetas
                      </Typography>
                    </Grid>
                  </Grid>
                  <TokenizedCards tokenizedCards={tokens || []} recall={recall} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
